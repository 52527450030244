import * as React from 'react';
import ProjectEntity from 'Models/Entities/ProjectEntity';
import { observer } from 'mobx-react';
import WizardStep from 'Views/Components/ProjectWizard/WizardStep';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { ElementStructureUtils } from 'Util/ElementStructureUtils';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import { computed } from 'mobx';
import { RadioButtonGroup } from 'Views/Components/RadioButton/RadioButtonGroup';

@observer
export default class WizardTemporaryWorksStep extends WizardStep {
	@computed get tributaryArea(): number {
		if (!this.props.project.parsedElementStructure.info.loading) {
			return 0;
		}
		const { columnX, columnY } = this.props.project.parsedElementStructure.info.loading;

		if (!columnX || !columnY) {
			return 0;
		}

		return columnX * columnY;
	}

	public render() {
		return (
			<>
				<div className="form-section">
					<h3 className="section-title">Temporary works design</h3>
					<Combobox
						className="wind-region"
						model={this.props.project.parsedElementStructure.info.temporaryWorks}
						modelProperty="windRegion"
						label="Wind Region"
						options={ElementStructureUtils.windRegionDropdownValues}
						searchable={false}
						isDisabled={!this.props.projectCanBeEdited}
						tooltip="Wind region as specified in AS/NZS 1170.2 for the project site. Refer to the engineering notes or seek clarification from the project engineer."
						isRequired
					/>
					<Combobox
						className="terrain-category"
						model={this.props.project.parsedElementStructure.info.temporaryWorks}
						modelProperty="terrainCategory"
						label="Terrain Category"
						options={ElementStructureUtils.terrainCategoryDropdownValues}
						searchable={false}
						isDisabled={!this.props.projectCanBeEdited}
						tooltip="Terrain Category as specified in AS/NZS 1170.2 for the project site. Refer to the engineering notes or seek clarification from the project engineer."
						isRequired
					/>
					<NumberTextField
						className="ground-level-rl"
						model={this.props.project.parsedElementStructure.info.temporaryWorks}
						modelProperty="groundLevelRL"
						label="Project ground level RL (m)"
						isDisabled={!this.props.projectCanBeEdited}
						tooltip="Specify the RL for the ground level. This is the DATUM from which all other levels are calculated.
This should be set as the SSL (Structural slab level) of the ground floor or the level that is closest to the natural ground surrounding the building. This RL is used to calculate the height above ground level when determining the wind loading for the temporary case."
						isRequired
					/>
				</div>
				<div className="form-section">
					<h3 className="section-title">Loading</h3>
					<div className="loading-section">
						<NumberTextField
							className="column-x"
							model={this.props.project.parsedElementStructure.info.loading}
							modelProperty="columnX"
							label="Typical column grid"
							isDisabled={!this.props.projectCanBeEdited}
							placeholder="X"
							isRequired
							tooltip="Enter a typical grid size to calculate the typical tributary area on each element. The tributary area is adjustable for each element later. This information is only uses to fill in the default tributary area for each column or wall.
The tributary area is used to create a quick and simple load run-down for each column."
						/>
						<span className="x-meters">meters</span>
						<span className="x">X</span>
						<NumberTextField
							className="column-y"
							isDisabled={!this.props.projectCanBeEdited}
							model={this.props.project.parsedElementStructure.info.loading}
							placeholder="Y"
							modelProperty="columnY"
						/>
						<span className="y-meters">meters</span>
					</div>
					<NumberTextField
						className="tributary-area"
						model={this}
						modelProperty="tributaryArea"
						label="Calculated tributary area"
						isDisabled
					/>
					<NumberTextField
						className="distributed-load"
						model={this.props.project.parsedElementStructure.info.loading}
						modelProperty="distributedLoad"
						isDisabled={!this.props.projectCanBeEdited}
						label="Uniformly distributed load (kPa)"
						tooltip="Uniformly distributed load is used for a quick and simple load rundown for each column.
For a residential project with 200mm slabs, use 12kPa.
For a carpark project with 180mm slabs, use 11 kPa
For a retail project with 250mm slabs, use 20 kPa
For an Office Project with 250mm slabs, use 13 kPa
(These are approximate values for the purposes of generating a preliminary design only - Contact the project structural engineer for accurate column loading)
This loading should be the total ultimate limit state load per square meter. e.g for a project with a live load of 2.0 kPA and a superimposed dead load of 1.5 kPa on 200mm slabs:
0.2x25x1.2+1.5x1.2+2.0x1.5=10.8kPa Ult"
					/>
				</div>
				<div className="form-section slab-depths-under">
					<h3 className="section-title">Slab Depths under 515mm</h3>
					<p>
						Select an APTUS part for the whole project to use for any slab depth up to 515mm thick.
						(Note: This can still be changed on an element-by-element basis where required)
					</p>
					<div>
						<RadioButtonGroup
							name="slab depths under 515mm"
							model={this.props.project.parsedElementStructure.info.temporaryWorks}
							modelProperty="defaultExtensionUnderDepth"
							options={[
								{ value: 'variable-stud-length', display: 'Variable Stud Length' },
								{ value: 'blockouts', display: 'Blockouts' },
							]}
							optionsTooltips={[
								'Longer threaded studs',
								'Bars are extended to protrude further with 180mm socket spacing',
							]}
						/>
					</div>
				</div>
				<div className="form-section slab-depths-over">
					<h3 className="section-title">Slab Depths over 515mm</h3>
					<p>
						Select an APTUS part for the whole project to use for any slab depth over 515mm thick.
						(Note: This can still be changed on an element-by-element basis where required)
					</p>
					<div>
						<RadioButtonGroup
							name="slab depths over 515mm"
							model={this.props.project.parsedElementStructure.info.temporaryWorks}
							modelProperty="defaultExtensionOverDepth"
							options={[
								{ value: 'blockouts', display: 'Blockouts' },
								{ value: 'sx-bars', display: 'SX Bars' },
								{ value: 'socket-extensions', display: 'Socket Extensions' },
							]}
							optionsTooltips={[
								'Bars are extended to protrude further with 180mm socket spacing',
								'SX Bars with 180mm socket spacing',
								'330m Socket Extension with threaded stud',
							]}
						/>
					</div>
				</div>
				<div className="form-section structural-ductility-factor">
					<h3 className="section-title">Structural Ductility Factor (µ)</h3>
					<div>
						<RadioButtonGroup
							name="structural ductility factor"
							model={this.props.project.parsedElementStructure.info.temporaryWorks}
							modelProperty="structuralDuctilityFactor"
							options={[
								{ value: '1.0', display: '1.0' },
								{ value: '2.0', display: '2.0' },
							]}
							optionsTooltips={[
								'Horizontal bars for all elements will not be designed or checked for detailing in accordance with AS3600 CL 14.5.4. Note that design/checking of boundary elements is outside the scope of this software, and any wall type elements that require boundary elements will need to be allowed for separately by the user.',
								'Horizontal bars for all elements will typically be designed and checked for detailing in accordance with AS3600 CL 14.5.4. Note that design/checking of boundary elements is outside the scope of this software, and any wall type elements that require boundary elements will need to be allowed for separately by the user.',
							]}
						/>
					</div>
				</div>
			</>
		);
	}

	public static validateStep = (project: ProjectEntity): boolean => {
		// Check that all the fields this step cares about are set
		// If any aren't, we return false.
		// Double exclamation marks are there to satisfy typescript
		return !!(project.parsedElementStructure.info.temporaryWorks.windRegion
			&& project.parsedElementStructure.info.temporaryWorks.terrainCategory
			&& project.parsedElementStructure.info.temporaryWorks.groundLevelRL !== null
			&& project.parsedElementStructure.info.temporaryWorks.groundLevelRL !== undefined
			&& project.parsedElementStructure.info.loading.columnX !== null
			&& project.parsedElementStructure.info.loading.columnY !== null);
	};
}
