import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import CSS from 'csstype';
import { observable, action, computed } from 'mobx';

export interface LoadViewProps {
	estimate: number
	completed: ProgressBarState
	text: string
}

export enum ProgressBarState {
	Hidden = 'hidden',
	Loading = 'loading',
	Complete = 'complete'
}

@observer
export class LoadView extends React.Component<LoadViewProps> {
	@observable
	public percentProgress: number = 0;

	@computed get styles(): CSS.Properties {
		return { width: `${this.percentProgress}%` };
	}

	private timerId: NodeJS.Timeout;

	componentDidMount() {
		this.timerId = setInterval(() => this.updateStyle(), 10 * this.props.estimate);
	}

	componentWillUnmount() {
		clearInterval(this.timerId);
	}

	public render() {
		const className: string[] = ['project-building-overlay'];
		if (this.props.completed === ProgressBarState.Complete) {
			className.push('complete');
		}

		return (
			<div className={classNames(...className)}>
				<div className="overlay-content-wrap">
					<h3 className="building-text">{this.props.text}</h3>
					<div id="prog-bar" className="progress">
						<div style={this.styles} className="progress-bar" />
					</div>
				</div>
			</div>
		);
	}

	@action public updateStyle(setProgress?: number) {
		this.percentProgress = setProgress || this.percentProgress + 1;

		if (this.percentProgress >= 100 || this.props.completed === ProgressBarState.Complete) {
			this.percentProgress = 100;
			clearInterval(this.timerId);
		}
	}
}
