/* eslint-disable no-lonely-if */
/**
 * Contains functions for handling selection and multiple selection
 */

import type {
	Cell, Column, ColumnType, Level,
	BarType,
	cellHorizontalReoDesign,
	CellShape,
	Corbel,
	DesignConfiguration,
	ElementStructure,
	ElementVoid,
	NonAptusColour,
	TransitionColour,
	ExtensionUnderDepth,
	ExtensionOverDepth,
} from 'Util/ElementStructureUtils';
import { EditPricingPartList } from 'Views/Components/ElementGrid/CellPartsEditView';
import { ElementStructureUtils } from 'Util/ElementStructureUtils';
import { action, computed, observable } from 'mobx';
import * as React from 'react';
import type { EditColumn, EditColumnErrors } from 'Views/Components/ElementGrid/ColumnEditView';
import type { EditLevel, EditLevelErrors } from 'Views/Components/ElementGrid/LevelEditView';
import type { EditColumnType, EditColumnTypeErrors } from 'Views/Components/ElementGrid/ColumnTypeEditView';
import alert from 'Util/ToastifyUtils';
import alertToast from 'Util/ToastifyUtils';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { cloneObject, mergeObjectsKeepingAllFields, mergeObjectsKeepingLikeFields } from 'Util/CodeUtils';

export enum SelectionAction {
	Select,
	Deselect,
	Toggle,
}

export enum SelectionItemType {
	Cell = 'cell',
	Column = 'column',
	ColumnType = 'columntype',
	Level = 'level',
	None = 'none',
}

// When we select a columnm, we also want to know it's type
// This is a type that should encompass any possible selected item
export type SelectedItem = SelectedCell | SelectedColumn | SelectedColumnType | SelectedLevel

// helper to sit on top of mobx list. We use the index to efficiently
type SelectionListWrapper = {
	getList: () => SelectedItem[],
	has: (item: SelectedItem) => boolean,
	add: (item: SelectedItem) => void,
	remove: (item: SelectedItem) => void,
}

export interface SelectedCell {
	model: Cell;
}
export interface SelectedColumn {
	model: Column;
	parent: ColumnType;
}
export interface SelectedColumnType {
	model: ColumnType;
}
export interface SelectedLevel {
	model: Level;
}

type JSONValue =
	| string
	| number
	| boolean
	| null
	| undefined
	| { [x: string]: JSONValue }
	| Array<JSONValue>;

export interface EditCell {
	model: {
		shape?: CellShape;
		width?: number;
		depth?: number;
		height?: number;
		slabThicknessAtBase?: number;
		levelHeight?: number;
		slabThicknessAtTop?: number;

		ssl?: number;
		overrideSSL?: boolean;

		// Only applies to top cell in column
		topSsl?: number;
		overrideTopSsl?: boolean;

		additionalLoad?: number;
		calculatedLoad?: number;
		applyLoadAtEveryLevel?: boolean;
		overrideCalculatedLoad?: boolean;
		constructionZone?: string;
		loadTransfers?: {receivingId: string | undefined, percent: number | undefined}[];

		aptusDesignConfiguration?: DesignConfiguration;
		insituElement?: boolean;

		useReoRate?: boolean;
		reoRate?: number;

		corbel?: Corbel | null;
		elementVoid?: ElementVoid | null;

		forceDesignAsColumn?: boolean;
		designOverride?: cellHorizontalReoDesign | null;
		aptusBarsAlongWidth?: number;
		aptusBarsAlongDepth?: number;
		aptusBarType?: BarType;
		nonAptusBarsAlongWidth?: number;
		nonAptusBarsAlongDepth?: number;
		nonAptusBarType?: BarType;
		ligDesign?: string;
		aptusBarLigSpacing?: number;
		aptusBarLigType?: BarType;
		nonAptusBarLigSpacing?: number;
		nonAptusBarLigType?: BarType;
		concreteStrength?: number;
		unitMass?: number;
		disableInsituStarters?: boolean;

		kFactor?: number;
		majorAxisMoment?: number;
		minorAxisMoment?: number;
		workingMajorAxisMoment?: number;
		workingMinorAxisMoment?: number;
		majorInteractionCurve?: number[][];
		minorInteractionCurve?: number[][];
		majorProppingChkRatio?: number;
		minorProppingChkRatio?: number;
		majorAxialChkRatio?: number;
		minorAxialChkRatio?: number;

		approved?: boolean;

		transitionDescription?: string;
		transitionColour?: TransitionColour;

		overrideParts?: boolean;

		nonAptusColour?: NonAptusColour;

		extensionUnderDepth?: ExtensionUnderDepth;
		extensionOverDepth?: ExtensionOverDepth;
	}
	info: {
		deleted?: boolean;
		atBottomOfColumn?: boolean;
		atTopOfStack?: boolean;
		atTopOfBuilding?: boolean;
		aboveApprovedCell?: boolean;
		parts?: EditPricingPartList;
	}
}

export interface EditCellErrors {}

/**
 * Checks if a value is a JSON primitive.
 * @param value The value to check
 * @returns True iff value is a string, boolean or number.
 */
function isPrimitive(value: unknown): value is string | boolean | number {
	return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
}

/**
 * Checks if something is nothing :thinking:
 * @param value The value to check if it is nothing
 * @returns true iff value is null or undefined
 */
function isNothing(value: unknown): value is null | undefined {
	return value === null || value === undefined;
}

/**
 * Deep clones any JSON object.
 * @param data The data to clone
 * @returns A copy of the data parameter.
 */
function deepCopy(data: JSONValue): JSONValue | JSONValue[] {
	if (Array.isArray(data)) {
		return data.map(x => deepCopy(x));
	}

	if (isPrimitive(data) || isNothing(data)) {
		return data;
	}

	const result: JSONValue = {};
	for (const key of Object.keys(data)) {
		const value = data[key];
		result[key] = deepCopy(value);
	}
	return result;
}

/**
 * Copies an edit cell to a regular cell.
 * This function will iterate over all keys that are set and will deep copy their values across, leaving any keys
 * that are not set unchanged.
 * @param editCell The edit cell to copy from.
 * @param cellData The cell to copy to
 * @param nullFieldsToCopy Fields to copy, even if their value is null
 */
function copyCellStructure(editCell: Record<string, JSONValue>, cellData: Record<string, JSONValue>, nullFieldsToCopy: string[] = [], excludedFields: string[] = []) {
	for (const key of Object.keys(editCell)) {
		const value = editCell[key];

		if (key in SelectionUtils.EditCellComboboxMask && value === SelectionUtils.EditCellComboboxMask[key]) {
			continue;
		}

		if ((!isNothing(value) || nullFieldsToCopy.includes(key)) && !excludedFields.includes(key)) {
			cellData[key] = deepCopy(value);
		}
	}
}

// Carefully copy across only the changed corbel properties
// if none have changed, leave selected cell as-is
function copyCorbelSelectively(editCell: EditCell, selectedCell: SelectedCell, changedProperties: Record<string, boolean> | null, originalCombinedCorbel: Corbel | null | undefined) {
	// if editCell corbel is null but original calculated combined corbel isn't, it means the user has removed corbels for these cells
	if (editCell.model.corbel === null && originalCombinedCorbel !== null) {
		selectedCell.model.corbel = null;
		return;
	}

	// if the editCell has a null values, and the originalCombinedCorbel has a null value
	// is means no changes have been made
	if (editCell.model.corbel === null && originalCombinedCorbel === null) return;

	// if nothing changed...just return
	if (JSON.stringify(editCell.model.corbel) === JSON.stringify(originalCombinedCorbel)) return;

	// if properties have been changed, we need to add or update corbels on the selected cell
	if (changedProperties !== null) {
		if (!selectedCell.model.corbel) {
			// @ts-ignore
			selectedCell.model.corbel = {
				useCellWidth: false,
				useCellDepth: false,
				useCellHeight: false,
			};
		}

		Object.keys(changedProperties).forEach(key => {
			if (changedProperties[key] === true) selectedCell.model.corbel![key] = editCell.model.corbel![key];
		});
	}
}

// Carefully copy across only the changed void properties
// if none have changed, leave selected cell as-is
function copyVoidSelectively(editCell: EditCell, selectedCell: SelectedCell, changedProperties: Record<string, boolean> | null, originalCombinedVoid: ElementVoid | null | undefined) {
	// if editCell void is null but original calculated combined void isn't, it means the user has removed voids for these cells
	if (editCell.model.elementVoid === null && originalCombinedVoid !== null) {
		selectedCell.model.elementVoid = null;
		return;
	}

	// if the editCell has a null values, and the originalCombinedVoids has a null value
	// is means no changes have been made
	if (editCell.model.elementVoid === null && originalCombinedVoid === null) return;

	// if nothing changed...just return
	if (JSON.stringify(editCell.model.elementVoid) === JSON.stringify(originalCombinedVoid)) return;

	// if properties have been changed, we need to add or update voids on the selected cell
	if (changedProperties !== null) {
		if (!selectedCell.model.elementVoid) {
			selectedCell.model.elementVoid = {
				width: undefined,
				depth: undefined,
				height: undefined,
				useCellWidth: false,
				useCellDepth: false,
				useCellHeight: false,
			};
		}

		Object.keys(changedProperties).forEach(key => {
			if (changedProperties[key] === true) selectedCell.model.elementVoid![key] = editCell.model.elementVoid![key];
		});
	}
}

export const generateMergedCorbel = (corbels: (Corbel | null | undefined)[]): Corbel | undefined | null => {
	// if none or empty
	if (corbels.length === 0 || corbels.every(c => c === undefined || c === null)) {
		return null;
	}

	// if there's just one, we can use it as source of truth
	if (corbels.length === 1) {
		return corbels[0] ?? null;
	}

	// if mixed, no fields can be combined
	if ((corbels.includes(null) || corbels.includes(undefined)) && corbels.filter(c => !!c).length > 0) {
		return {
			width: undefined,
			depth: undefined,
			height: undefined,
			useCellWidth: false,
			useCellDepth: false,
			useCellHeight: false,
			reoRate: undefined,
		};
	}

	const { areValuesDifferent } = corbels.reduce((acc, corbel) => {
		if (!corbel || !acc.previousCorbel) throw Error('Missing corbel - we should have returned early');

		if (corbel.width !== acc.previousCorbel.width) acc.areValuesDifferent.width = true;
		if (corbel.depth !== acc.previousCorbel.depth) acc.areValuesDifferent.depth = true;
		if (corbel.height !== acc.previousCorbel.height) acc.areValuesDifferent.height = true;

		if (corbel.useCellWidth !== acc.previousCorbel.useCellWidth) acc.areValuesDifferent.useCellWidth = true;
		if (corbel.useCellDepth !== acc.previousCorbel.useCellDepth) acc.areValuesDifferent.useCellDepth = true;
		if (corbel.useCellHeight !== acc.previousCorbel.useCellHeight) acc.areValuesDifferent.useCellHeight = true;

		if (corbel.reoRate !== acc.previousCorbel.reoRate) acc.areValuesDifferent.reoRate = true;

		return {
			...acc,
			previousCorbel: corbel,
		};
	}, {
		areValuesDifferent: {
			width: false,
			depth: false,
			height: false,
			useCellWidth: false,
			useCellDepth: false,
			useCellHeight: false,
			reoRate: false,
		},
		previousCorbel: corbels[0], // we'll loop over the first corbel twice, but it keeps loop more simple and won't effect anything
	});

	// if values are all the same, we can just use the first value
	return {
		width: areValuesDifferent.width ? undefined : corbels[0]!.width,
		depth: areValuesDifferent.depth ? undefined : corbels[0]!.depth,
		height: areValuesDifferent.height ? undefined : corbels[0]!.height,
		useCellWidth: areValuesDifferent.useCellWidth ? false : corbels[0]!.useCellWidth,
		useCellDepth: areValuesDifferent.useCellDepth ? false : corbels[0]!.useCellDepth,
		useCellHeight: areValuesDifferent.useCellHeight ? false : corbels[0]!.useCellHeight,
		reoRate: areValuesDifferent.reoRate ? undefined : corbels[0]!.reoRate,
	};
};

export const generateMergedVoid = (elementVoids: (ElementVoid | null | undefined)[]): ElementVoid | undefined | null => {
	// if none or empty
	if (elementVoids.length === 0 || elementVoids.every(v => v === undefined || v === null)) {
		return null;
	}

	// if there's just one, we can use it as source of truth
	if (elementVoids.length === 1) {
		return elementVoids[0] ?? null;
	}

	// if mixed, no fields can be combined
	if ((elementVoids.includes(null) || elementVoids.includes(undefined)) && elementVoids.filter(c => !!c).length > 0) {
		return {
			width: undefined,
			depth: undefined,
			height: undefined,
			useCellWidth: false,
			useCellDepth: false,
			useCellHeight: false,
		};
	}

	const { areValuesDifferent } = elementVoids.reduce((acc, elementVoid) => {
		if (!elementVoid || !acc.previousVoid) throw Error('Missing void - we should have returned early');

		if (elementVoid.width !== acc.previousVoid.width) acc.areValuesDifferent.width = true;
		if (elementVoid.depth !== acc.previousVoid.depth) acc.areValuesDifferent.depth = true;
		if (elementVoid.height !== acc.previousVoid.height) acc.areValuesDifferent.height = true;

		if (elementVoid.useCellWidth !== acc.previousVoid.useCellWidth) acc.areValuesDifferent.useCellWidth = true;
		if (elementVoid.useCellDepth !== acc.previousVoid.useCellDepth) acc.areValuesDifferent.useCellDepth = true;
		if (elementVoid.useCellHeight !== acc.previousVoid.useCellHeight) acc.areValuesDifferent.useCellHeight = true;

		return {
			...acc,
			previousVoid: elementVoid,
		};
	}, {
		areValuesDifferent: {
			width: false,
			depth: false,
			height: false,
			useCellWidth: false,
			useCellDepth: false,
			useCellHeight: false,
			reoRate: false,
		},
		previousVoid: elementVoids[0], // we'll loop over the first void twice, but it keeps loop more simple and won't effect anything
	});

	// if values are all the same, we can just use the first value
	return {
		width: areValuesDifferent.width ? undefined : elementVoids[0]!.width,
		depth: areValuesDifferent.depth ? undefined : elementVoids[0]!.depth,
		height: areValuesDifferent.height ? undefined : elementVoids[0]!.height,
		useCellWidth: areValuesDifferent.useCellWidth ? false : elementVoids[0]!.useCellWidth,
		useCellDepth: areValuesDifferent.useCellDepth ? false : elementVoids[0]!.useCellDepth,
		useCellHeight: areValuesDifferent.useCellHeight ? false : elementVoids[0]!.useCellHeight,
	};
};

export class SelectionUtils {
	@observable public currentEditType: SelectionItemType = SelectionItemType.None;

	@observable public currentlyEditing: boolean;

	@observable private readonly elementStructure: ElementStructure;

	private readonly disableSelection: boolean;

	private readonly runAfterChange?: (markEdited: boolean) => void | undefined;

	constructor(elementStructure: ElementStructure, disableSelection: boolean, runAfterChange?: (markEdited: boolean) => void | undefined) {
		this.elementStructure = elementStructure;
		this.runAfterChange = runAfterChange;
		this.disableSelection = disableSelection;
	}

	// Comboboxes don't like undefined values. Here we define some values that act as undefined for the purpose of getting the code to work
	public static EditCellComboboxMask = {
		aptusBarType: 'undefined',
		nonAptusBarType: 'undefined',
		aptusBarLigType: 'undefined',
		nonAptusBarLigType: 'undefined',
		concreteStrength: -1,
		transitionColour: 'undefined',
	};

	// MANAGING GENERAL SELECTION & EDITING
	@action public postSelectionSetup = (editingType?: SelectionItemType) => {
		// We've selected one or more items. This function is responsible for emptying the selection lists for the other types.
		// Then it will setup the edit model, and change the currentEditType type

		// If we don't have an editing type passed in, we reuse the current one
		if (editingType) {
			this.currentEditType = editingType;
		}

		// We use different lists and edit models for each type that can be selected
		const editModelMap = {
			[SelectionItemType.Cell]: { editModel: this.editCell, selectedList: this.selectedCells, errors: 'editCellErrors' },
			[SelectionItemType.Column]: { editModel: this.editColumn, selectedList: this.selectedColumns, errors: 'editColumnErrors' },
			[SelectionItemType.ColumnType]: { editModel: this.editColumnType, selectedList: this.selectedColumnTypes, errors: 'editColumnTypeErrors' },
			[SelectionItemType.Level]: { editModel: this.editLevel, selectedList: this.selectedLevels, errors: 'editLevelErrors' },
		};

		// We start by unsetting all selection fields for the unused editing types, and clearing out any cached errors
		Object.keys(editModelMap).forEach(selectionType => {
			this[editModelMap[selectionType].errors] = {};
			if (selectionType !== this.currentEditType) {
				editModelMap[selectionType].selectedList.length = 0;
			}
		});

		// Set lastSelected for the non-selected Object Types
		if (editingType !== SelectionItemType.Column) {
			this.lastSelectedColumn = undefined;
		}
		if (editingType !== SelectionItemType.ColumnType) {
			this.lastSelectedColumnType = undefined;
		}
		if (editingType !== SelectionItemType.Cell) {
			this.lastSelectedCell = undefined;
		}
		if (editingType !== SelectionItemType.Level) {
			this.lastSelectedLevel = undefined;
		}

		// If we currently have nothing selected, we exit early
		if ((!editModelMap[this.currentEditType] || editModelMap[this.currentEditType].selectedList.length === 0)) {
			this.currentlyEditing = false;
			return;
		}

		// Now we want to setup the edit model
		// We start by setting all fields to 'unset'. If they remain unset until the end, they'll become undefined.
		Object.keys(editModelMap[this.currentEditType].editModel.model).forEach(key => {
			editModelMap[this.currentEditType].editModel.model[key] = 'unset';
		});
		if ('info' in editModelMap[this.currentEditType].editModel) {
			Object.keys(editModelMap[this.currentEditType].editModel.info).forEach(key => {
				editModelMap[this.currentEditType].editModel.info[key] = 'unset';
			});
		}

		const excludedNestedObjects = ['corbel', 'elementVoid', 'loadTransfers'];

		// If this is the first object, our edit model will contain the given values
		// for each extra object, the edit model will only retain its values if the new model matches
		editModelMap[this.currentEditType].selectedList.forEach((selectedItem: SelectedItem) => {
			const editModel = editModelMap[this.currentEditType].editModel.model;
			Object.keys(editModel).forEach(key => {
				if (editModel[key] === 'unset') {
					editModel[key] = deepCopy(selectedItem.model[key]);
				} else if (editModel[key] !== selectedItem.model[key] && !excludedNestedObjects.includes(key)) {
					editModel[key] = undefined;
				}
			});
		});

		// Assign a corbel/void representing the combined values of the multiple selected corbels/voids
		if (this.currentEditType === SelectionItemType.Cell) {
			const corbels = editModelMap[this.currentEditType].selectedList
				.map(sel => sel.model.corbel);

			const elementVoids = editModelMap[this.currentEditType].selectedList
				.map(sel => sel.model.elementVoid);

			editModelMap[this.currentEditType].editModel.model.corbel = generateMergedCorbel(corbels);
			editModelMap[this.currentEditType].editModel.model.elementVoid = generateMergedVoid(elementVoids);
		}

		// Columns have some extra setup required, to get the column type and top cell information
		if (this.currentEditType === SelectionItemType.Column) {
			// Get the column type
			this.selectedColumns.forEach((selectedItem: SelectedColumn) => {
				if (this.editColumn.info.typeName === 'unset') {
					this.editColumn.info.typeName = selectedItem.parent.name;
					this.editColumn.info.typeCode = selectedItem.parent.code;
				} else if (this.editColumn.info.typeName !== selectedItem.parent.name) {
					this.editColumn.info.typeName = undefined;
					this.editColumn.info.typeCode = undefined;
				}
			});

			// Get additional load from the top cell of any selected columns
			let additionalLoadSet = false;
			this.selectedColumns.forEach((selectedItem: SelectedColumn) => {
				const topCell: Cell|null = ElementStructureUtils.getTopCellOfColumn(this.elementStructure, selectedItem.model);
				if (topCell === null) {
					this.editColumn.info.topCellAdditionalLoad = undefined;
				} else if (!additionalLoadSet) {
					this.editColumn.info.topCellAdditionalLoad = topCell.additionalLoad;
					additionalLoadSet = true;
				} else if (this.editColumn.info.topCellAdditionalLoad !== topCell.additionalLoad) {
					this.editColumn.info.topCellAdditionalLoad = undefined;
				}

				if (!this.editColumn.model.constructionZone) {
					this.editColumn.model.constructionZone = '1';
				}
			});
		}

		// Cells have some additional setup for cells, since you can't edit them if some of them are deleted
		// We also want to check if all selected cells have no elements, or deleted elements, below them
		// We also check if all selected cells are at the top of the building (deleted cells above don't count)
		if (this.currentEditType === SelectionItemType.Cell) {
			// Get the column type
			this.selectedCells.forEach((selectedItem: SelectedCell) => {
				if (selectedItem.model.deleted) {
					this.editCell.info.deleted = true;
				}
				if (!selectedItem.model.constructionZone) {
					this.editCell.model.constructionZone = '1';
				}
				if (!selectedItem.model.kFactor) {
					this.editCell.model.kFactor = 0.85;
				}
			});

			// Find if any cells have cells below them.
			// Also check if we're above an approved cell
			this.editCell.info.atBottomOfColumn = true;
			this.editCell.info.aboveApprovedCell = false;
			for (let i = 0; i < this.selectedCells.length; i++) {
				const selectedCell = this.selectedCells[i];
				const cellBelow = ElementStructureUtils.findCellBelowGivenCell(this.elementStructure, selectedCell.model);
				if (cellBelow && !cellBelow.deleted) {
					this.editCell.info.atBottomOfColumn = false;
				}
				if (cellBelow && cellBelow.aptusDesignConfiguration === 'nonaptus') {
					this.editCell.info.atBottomOfColumn = true;
				}

				if (cellBelow && cellBelow.approved) {
					this.editCell.info.aboveApprovedCell = true;
				}

				// Break early if possible
				if (this.editCell.info.aboveApprovedCell && this.editCell.info.atBottomOfColumn === false) {
					break;
				}
			}

			// Find if any cells are not at the top of the column
			this.editCell.info.atTopOfBuilding = true;
			for (let i = 0; i < this.selectedCells.length; i++) {
				const selectedCell = this.selectedCells[i];
				const cellAbove = ElementStructureUtils.findCellAboveGivenCell(this.elementStructure, selectedCell.model);
				if (cellAbove) {
					this.editCell.info.atTopOfBuilding = false;
					break;
				}
			}

			this.editCell.info.atTopOfStack = true;
			for (let i = 0; i < this.selectedCells.length; i++) {
				const selectedCell = this.selectedCells[i];
				const cellAbove = ElementStructureUtils.findCellAboveGivenCell(this.elementStructure, selectedCell.model);
				if (cellAbove && !cellAbove.deleted) {
					this.editCell.info.atTopOfStack = false;
				}
			}

			// Get the parts list which applies to all selected cells
			if (this.selectedCells.length >= 1) {
				this.editCell.info.parts = cloneObject(this.selectedCells[0].model.parts);

				for (let i = 1; i < this.selectedCells.length; i++) {
					const selectedCell = this.selectedCells[i];
					this.editCell.info.parts = mergeObjectsKeepingLikeFields(this.editCell.info.parts, selectedCell.model.parts);
				}
			}
		}

		// Change all 'unset' values to undefined
		Object.keys(editModelMap[this.currentEditType].editModel.model).forEach(key => {
			if (editModelMap[this.currentEditType].editModel.model[key] === 'unset') {
				editModelMap[this.currentEditType].editModel.model[key] = undefined;
			}
		});
		if ('info' in editModelMap[this.currentEditType].editModel) {
			Object.keys(editModelMap[this.currentEditType].editModel.info).forEach(key => {
				if (editModelMap[this.currentEditType].editModel.info[key] === 'unset') {
					editModelMap[this.currentEditType].editModel.info[key] = undefined;
				}
			});
		}

		// Levels have some extra setup required, to identify whether the top level has been selected
		if (this.currentEditType === SelectionItemType.Level) {
			this.editLevel.info.topLevelSelected = !!(this.selectedLevels.length === 1 && this.selectedLevels[0].model.id === this.elementStructure.levels[0].id);
		}

		// Since the CellEditView has some comboboxes, we need to change some variables from falsey values so that the dropdowns will work
		if (this.currentEditType === SelectionItemType.Cell) {
			Object.keys(SelectionUtils.EditCellComboboxMask).forEach(key => {
				if (!this.editCell.model[key]) {
					this.editCell.model[key] = SelectionUtils.EditCellComboboxMask[key];
				}
			});
		}

		if (this.currentEditType === SelectionItemType.Cell) {
			Object.keys(SelectionUtils.EditCellComboboxMask).forEach(key => {
				if (!this.editCell.model[key]) {
					this.editCell.model[key] = SelectionUtils.EditCellComboboxMask[key];
				}
			});
		}

		// reindex selected cells
		this.selectedCellsIndex = this.selectedCells.reduce((acc, curr) => {
			return {
				...acc,
				[curr.model.id]: curr,
			};
		}, {});

		// Finally, we change the edit type to show the edit view for forms
		this.currentlyEditing = true;
	};

	// If the element structure has been replaced by a build, then we'll lose reference to it, and our selected cells could be out of date
	// Here we empty and re-fill the selected list, to prevent this from happening
	@action public resetSelection = () => {
		// We use different lists and edit models for each type that can be selected
		const editModelMap = {
			[SelectionItemType.Cell]: { listFn: this.getCellList, selectedList: this.selectedCells },
			[SelectionItemType.Column]: { listFn: this.getColumnList, selectedList: this.selectedColumns },
			[SelectionItemType.ColumnType]: { listFn: this.getColumnTypeList, selectedList: this.selectedColumnTypes },
			[SelectionItemType.Level]: { listFn: this.getLevelList, selectedList: this.selectedLevels },
		};

		// If nothing is selected, then none of this matters
		if (this.currentEditType === SelectionItemType.None) {
			return;
		}

		// Build a dict for ids of currently selected items
		const selectedItemIds = {};
		editModelMap[this.currentEditType].selectedList.forEach((selectedItem: SelectedItem) => {
			selectedItemIds[selectedItem.model.id] = true;
		});

		// Unset our selection list and last selected items
		editModelMap[this.currentEditType].selectedList.length = 0;

		// go through the new element structure, and find all cells to add to our new selected list, or to set as the last selected item
		editModelMap[this.currentEditType].listFn.bind(this)().forEach((item: SelectedItem) => {
			if (selectedItemIds[item.model.id]) {
				editModelMap[this.currentEditType].selectedList.push(item);
			}
		});

		// We've set up our new selected lists, so we update the edit views based upon the new data
		this.postSelectionSetup();
	};

	protected getSelectedListMap = (type: SelectionItemType): SelectionListWrapper => {
		// We use different lists for each type that can be selected
		const selectedListMap = {
			[SelectionItemType.Cell]: this.selectedCells,
			[SelectionItemType.Column]: this.selectedColumns,
			[SelectionItemType.ColumnType]: this.selectedColumnTypes,
			[SelectionItemType.Level]: this.selectedLevels,
		};

		const selectedListIndex: Record<string, SelectedItem> = selectedListMap[type]
			.reduce((acc: Record<string, SelectedItem>, curr: SelectedItem) => {
				return {
					...acc,
					[curr.model.id]: curr,
				};
			}, {});

		const list: SelectedItem[] = selectedListMap[type];
		const listIndex: Record<string, SelectedItem> = selectedListIndex;

		return {
			getList: () => [...list],
			has: (item: SelectedItem) => !!listIndex[item.model.id],
			add: action((item: SelectedItem) => {
				listIndex[item.model.id] = item;
				list.push(item);
			}),
			remove: action((item: SelectedItem) => {
				delete listIndex[item.model.id];

				const itemIndex = list.findIndex(x => x.model.id === item.model.id);
				list.splice(itemIndex, 1);
			}),
		};
	}

	@action protected selectOrDeselectItem = (itemToSelect: SelectedItem, selectedListWrapper: SelectionListWrapper, type: SelectionItemType, action: SelectionAction, itemIndex?: number) => {
		const itemAlreadyExists = selectedListWrapper.has(itemToSelect);

		if (itemAlreadyExists) {
			// The item is already in the list. We remove it if required.
			if ([SelectionAction.Deselect, SelectionAction.Toggle].includes(action)) {
				selectedListWrapper.remove(itemToSelect);
			}
		} else {
			// Special case for cells: We can't select a merged cell
			if (type === SelectionItemType.Cell) {
				const selectedCell = itemToSelect as SelectedCell;
				if (selectedCell.model.merged) {
					return;
				}
			}

			// The item is not in the list. We add it if required.
			if ([SelectionAction.Select, SelectionAction.Toggle].includes(action)) {
				selectedListWrapper.add(itemToSelect);
			}
		}
	};

	@action saveItemChanges = (disableSSLValidation?: boolean) => {
		// regenerate the corbels and voids so any validation we do is on the latest values
		ElementStructureUtils.regenerateCorbelAndVoidDimensions(
			this.elementStructure,
			(cell: Cell) => this.getValidCellHeight([{ model: cell }]),
		);

		// If this is set to true at the end of the saveItemChanges function, then the current selection will be cancelled
		let cancelSelection = false;

		// We use different lists and edit models for each type that can be selected
		const editModelMap = {
			[SelectionItemType.Cell]: { editModel: this.editCell, validate: this.validateEditCell, selectedList: this.selectedCells },
			[SelectionItemType.Column]: { editModel: this.editColumn, validate: this.validateEditColumn, selectedList: this.selectedColumns },
			[SelectionItemType.ColumnType]: { editModel: this.editColumnType, validate: this.validateEditColumnType, selectedList: this.selectedColumnTypes },
			[SelectionItemType.Level]: { editModel: this.editLevel, validate: () => this.validateEditLevel(disableSSLValidation), selectedList: this.selectedLevels },
		};

		// Before we save any changes, we validate that we can continue;
		if (!editModelMap[this.currentEditType].validate()) {
			return;
		}

		// We use the edit model to set the fields for all selected items, for any fields which have been set (and are undefined)
		// Because comboboxes don't like undefined values, we also have backup values, to act as undefined, for specific fields
		const excludedFields = ['loadTransfers', 'corbel', 'elementVoid']; // we need to exclude these, because rather than doing a deep copy we do fancy conditional merging later

		editModelMap[this.currentEditType].selectedList
			.forEach((selectedItem: SelectedItem) => {
				const editModel = editModelMap[this.currentEditType].editModel.model;
				const cellModel = selectedItem.model;

				// Do some ugly casts to keep the type system happy
				copyCellStructure(editModel, cellModel as unknown as Record<string, JSONValue>, ['designOverride'], excludedFields);
			});

		// If we're editing columns, we can also edit the additional load of the top cell of each selected column
		if (this.currentEditType === SelectionItemType.Column) {
			this.selectedColumns.forEach((selectedItem: SelectedColumn) => {
				const topCell: Cell|null = ElementStructureUtils.getTopCellOfColumn(this.elementStructure, selectedItem.model);
				const cells: Cell[] | null = ElementStructureUtils.getCellsOfColumn(this.elementStructure, selectedItem.model);

				if (this.editColumn.info.topCellAdditionalLoad !== undefined && topCell !== null) {
					topCell.additionalLoad = this.editColumn.info.topCellAdditionalLoad;
				}

				if (this.editColumn?.model?.constructionZone !== undefined && cells != null) {
					for (let i = 0; i < cells?.length; i++) {
						cells[i].constructionZone = this.editColumn.model.constructionZone;
					}
				}

				// We need to move the column in the element structure it has been changed
				if (this.editColumn.info.typeCode) {
					const oldColumnType = this.elementStructure.columnTypes.find(x => x.id === selectedItem.parent.id);
					const newColumnType = this.elementStructure.columnTypes.find(x => x.code === this.editColumn.info.typeCode);

					// Only move the column if it actually needs to be moved
					if (oldColumnType && newColumnType && oldColumnType.code !== newColumnType.code) {
						const oldColumnIdx = oldColumnType.columns.findIndex(x => x.id === selectedItem.model.id);
						if (oldColumnIdx !== -1) {
							const [removedColumn] = oldColumnType.columns.splice(oldColumnIdx, 1);

							// Check for duplicate names, if there is one then set it to the question mark name
							if (newColumnType.columns.map(x => x.name).includes(removedColumn.name)) {
								removedColumn.name = '???';
							}

							newColumnType.columns.push(removedColumn);

							// This is needed since the edit model is not correctly updated.
							// Calling this.postSelectionSetup does not correctly update it so this is a bit of a hack
							// to work around that.
							cancelSelection = true;
						}
					}
				}
			});
		}

		// Trigger any dynamic calculation we need to do
		// First we rebuild level heights and recalculate unit masses, if levels were edited
		if (this.currentEditType === SelectionItemType.Level) {
			ElementStructureUtils.regenerateLevelHeights(this.elementStructure);

			const levels = this.getLevelIdsAroundLevels(this.selectedLevels.map(x => x.model));
			this.mapCellsInLevels(levels, cell => ElementStructureUtils.calculateCellUnitMass(this.elementStructure, cell));

			// If we've rebuilt level heights, we'll need to show it in the sidebar
			this.postSelectionSetup(SelectionItemType.Level);
		}

		// Next we calculate new loads, if required. This can happen if either the column or the cell was changed
		// First we manage columns
		if (this.currentEditType === SelectionItemType.Column) {
			// We want to recalculate loads if any load-related fields were changed
			if (this.editColumn.model.load !== undefined
				|| this.editColumn.model.loadArea !== undefined
				|| this.editColumn.info.topCellAdditionalLoad !== undefined) {
				// We changed a load related field, so we want to recalculate loads for all columns which have been modified
				const loadTransferIndex = ElementStructureUtils.generateTransferIndex(this.elementStructure);

				const columnsToRegenerate = new Set<Column>();

				for (const selectedColumn of this.selectedColumns) {
					columnsToRegenerate.add(selectedColumn.model);
					for (const t of this.elementStructure.columnTypes) {
						for (const c of t.columns) {
							if (selectedColumn.model.id) {
								for (const l of this.elementStructure.levels) {
									const cell = this.elementStructure.cells[c.id][l.id];

									const cellTransfers = ElementStructureUtils.getCellLoadTransfers(loadTransferIndex, cell.id).outgoingLoads;

									if (cellTransfers && cellTransfers.data) {
										for (const cT of cellTransfers.data) {
											ElementStructureUtils.cellIterator(this.elementStructure,
												(columnType: ColumnType, column:Column, level:Level) => {
													const cellSecond = this.elementStructure.cells[column.id][level.id];
													if (cellSecond.id === cT.receivingId) {
														columnsToRegenerate.add(column);
													}
												});
										}
									}
								}
							}
						}
					}
				}

				columnsToRegenerate.forEach(column => {
					ElementStructureUtils.regenerateColumnLoad(this.elementStructure, column, loadTransferIndex);
				});
			}
		}

		// Next we manage load calculation if cells were changed
		if (this.currentEditType === SelectionItemType.Cell) {
			if (this.editCell.model.additionalLoad !== undefined || this.editCell.model.applyLoadAtEveryLevel !== undefined) {
				this.recalculateLoadForSelectedColumns();
			}

			// have corbels changed?
			const [changedCorbelProperties, originalCombinedCorbel] = this.determineCorbelPropertiesToSave(this.editCell, this.selectedCells);
			// have voids changed?
			const [changedVoidProperties, originalCombinedVoid] = this.determineVoidPropertiesToSave(this.editCell, this.selectedCells);

			let hasRegenerated = false;

			// apply load transfers ONLY if single cell is selected
			if (this.selectedCells.length === 1) {
				// @ts-ignore
				this.selectedCells[0].model.loadTransfers = deepCopy(this.editCell.model.loadTransfers);
			}

			// this MUST be done AFTER load transfers have been copied to the selected cells, which are referenced by the element structure
			const loadTransferIndex = ElementStructureUtils.generateTransferIndex(this.elementStructure);

			for (let i = 0; i < this.selectedCells.length; i++) {
				const selectedCell = this.selectedCells[i];

				copyCorbelSelectively(this.editCell, selectedCell, changedCorbelProperties, originalCombinedCorbel);
				copyVoidSelectively(this.editCell, selectedCell, changedVoidProperties, originalCombinedVoid);

				if (
					!hasRegenerated
					&& (
						(this.editCell.model.loadTransfers && this.editCell.model.loadTransfers.some(x => !!x))
						|| ElementStructureUtils.hasAssociatedLoads(selectedCell.model, loadTransferIndex)
					)
				) {
					// If even one cell has associated loads, then we want to regenerate columns for the entire project
					for (const t of this.elementStructure.columnTypes) {
						for (const c of t.columns) {
							ElementStructureUtils.regenerateColumnLoad(this.elementStructure, c, loadTransferIndex);
						}
					}
					// If We've regenerated all the columns, we dont need to regenerate them again.
					hasRegenerated = true;
				}
			}

			// Update the cell's part lists
			for (let i = 0; i < this.selectedCells.length; i++) {
				const selectedCell = this.selectedCells[i];
				selectedCell.model.parts = mergeObjectsKeepingAllFields(this.editCell.info.parts, selectedCell.model.parts);

				// set default values
				if (!selectedCell.model.depth) {
					selectedCell.model.depth = 0;
				}

				// Aptus bar
				if (!selectedCell.model.aptusBarsAlongWidth) {
					selectedCell.model.aptusBarsAlongWidth = 2;
				}
				if (!selectedCell.model.aptusBarsAlongDepth) {
					selectedCell.model.aptusBarsAlongDepth = 2;
				}

				// Non-Aptus Bar
				if (!selectedCell.model.nonAptusBarsAlongWidth) {
					selectedCell.model.nonAptusBarsAlongWidth = 0;
				}
				if (!selectedCell.model.nonAptusBarsAlongDepth) {
					selectedCell.model.nonAptusBarsAlongDepth = 0;
				}
			}

			// Next, we calculate unit masses. This can happen if either cells or levels were changed
			// Calculating unit mass will incidentally recalculate our cell height
			// Levels were managed above, but here we manage cells
			if (this.editCell.model.width !== undefined
				|| this.editCell.model.depth !== undefined
				|| this.editCell.model.ssl !== undefined
				|| this.editCell.model.overrideSSL !== undefined) {
				// Recalculate unit mass for all selected cells
				this.recalculateUnitMassForSelectedCells(
					this.editCell.model.ssl !== undefined
					|| this.editCell.model.overrideSSL !== undefined,
				);

				// If we've edited unit masses, it'll also affect unit heights, so we need to update the cell edit view
				this.postSelectionSetup(SelectionItemType.Cell);
			}
		}

		// regenerate corbels and voids again after dimensions changes may have been applied
		ElementStructureUtils.regenerateCorbelAndVoidDimensions(
			this.elementStructure,
			(cell: Cell) => this.getValidCellHeight([{ model: cell }]),
		);

		if (cancelSelection) {
			this.cancelSelection();
		}

		// Then run any code after changing (Often, saving the project)
		this.afterChange();
	};

	public afterChange = (markEdited: boolean = true) => {
		if (this.runAfterChange) {
			this.runAfterChange(markEdited);
		}
	};

	// Deselect all items and close the edit view
	@action cancelSelection = () => {
		this.selectedCells.length = 0;
		this.selectedLevels.length = 0;
		this.selectedColumns.length = 0;
		this.selectedColumnTypes.length = 0;
		this.postSelectionSetup(this.currentEditType);
	};

	// MANAGING CELL SELECTION & EDITING
	// Fields for selecting/editing cells
	private lastSelectedCell?: Cell;

	@observable
	public selectedCells: SelectedCell[] = [];

	@observable
	public selectedCellsIndex: Record<string, SelectedCell> = {};

	@observable editCell: EditCell = {
		model: {
			shape: undefined,
			width: undefined,
			depth: undefined,
			height: undefined,
			levelHeight: undefined,
			slabThicknessAtBase: undefined,
			slabThicknessAtTop: undefined,

			ssl: undefined,
			overrideSSL: undefined,

			topSsl: undefined,
			overrideTopSsl: undefined,

			calculatedLoad: undefined,
			additionalLoad: undefined,
			applyLoadAtEveryLevel: undefined,
			overrideCalculatedLoad: undefined,

			aptusDesignConfiguration: undefined,
			insituElement: undefined,

			useReoRate: undefined,
			reoRate: undefined,

			corbel: undefined,
			elementVoid: undefined,

			forceDesignAsColumn: undefined,
			designOverride: undefined,
			aptusBarsAlongWidth: undefined,
			aptusBarsAlongDepth: undefined,
			aptusBarType: undefined,
			nonAptusBarsAlongWidth: undefined,
			nonAptusBarsAlongDepth: undefined,
			nonAptusBarType: undefined,
			aptusBarLigSpacing: undefined,
			aptusBarLigType: undefined,
			nonAptusBarLigSpacing: undefined,
			nonAptusBarLigType: undefined,
			concreteStrength: undefined,
			unitMass: undefined,
			disableInsituStarters: undefined,

			kFactor: undefined,
			ligDesign: undefined,
			workingMajorAxisMoment: undefined,
			workingMinorAxisMoment: undefined,
			majorAxisMoment: undefined,
			minorAxisMoment: undefined,
			majorInteractionCurve: undefined,
			minorInteractionCurve: undefined,
			majorProppingChkRatio: undefined,
			minorProppingChkRatio: undefined,
			majorAxialChkRatio: undefined,
			minorAxialChkRatio: undefined,

			approved: undefined,

			overrideParts: undefined,
			constructionZone: undefined,

			extensionUnderDepth: undefined,
			extensionOverDepth: undefined,
			loadTransfers: undefined,
		},
		info: {
			deleted: undefined,
			atBottomOfColumn: undefined,
			atTopOfBuilding: undefined,
			atTopOfStack: undefined,
			aboveApprovedCell: undefined,
			parts: undefined,
		},
	};

	// Returns the list of all cells in the element structure, in a format useful for entering into the selected cell list
	public getCellList(): SelectedCell[] {
		const cellList: SelectedCell[] = [];
		this.elementStructure.columnTypes.forEach(columnType => {
			columnType.columns.forEach(column => {
				this.elementStructure.levels.forEach(level => {
				});
			});
		});
		return cellList;
	}

	@action protected validateEditCell = (): boolean => {
		return true;
	};

	public selectedCellsIncludesCell = (cell: Cell): boolean => {
		return !!this.selectedCellsIndex[cell.id];
	};

	@action clickCell = (event: React.MouseEvent, clickedCell: Cell) => {
		// If selection is disabled, we don't do anything
		if (this.disableSelection) {
			return;
		}

		// If ctrl is pressed, we add to the current selection
		// Otherwise we start by clearing the selection
		if (!event.ctrlKey) {
			// We don't use the selectOrDeselectItem function here, since it would take n^2 time, because of all the splicing.
			this.selectedCells.length = 0;
		}

		const selectedListMap = this.getSelectedListMap(SelectionItemType.Cell);

		if (!event.shiftKey || !this.lastSelectedCell) {
			// If shift is not pressed, we simply toggle the current cell
			// If there wasn't a previous clicked cell to select a box from, we pretend that select wasn't pressed
			this.selectOrDeselectItem({ model: clickedCell }, selectedListMap, SelectionItemType.Cell, SelectionAction.Toggle);

			// We also keep track of the last cell selected, so we can use it when shift-selecting in the future
			this.lastSelectedCell = clickedCell;
		} else {
			// If shift is pressed, we need to calculate the box of selected cells
			// We want to loop through the floors/columns, and select anything within the box marked by our current and previous selections
			let startedSelectingLevels: boolean = false;
			const { lastSelectedCell } = this; // We save it into a separate variable so typescript doesn't complain

			this.elementStructure.levels.forEach(level => {
				const selectFromColumn = (level: Level) => {
					// Uses the same basic logic as selecting from levels, down below
					let startedSelectingColumns: boolean = false;

					const selectionListHelper = selectedListMap;

					this.elementStructure.columnTypes.forEach(columnType => {
						columnType.columns.forEach(column => {
							if (startedSelectingColumns) {
								this.selectOrDeselectItem({ model: this.elementStructure.cells[column.id][level.id] }, selectionListHelper, SelectionItemType.Cell, SelectionAction.Select);

								if (column.id === clickedCell.columnId || column.id === lastSelectedCell.columnId) {
									startedSelectingColumns = false;
								}
							} else if (column.id === clickedCell.columnId || column.id === lastSelectedCell.columnId) {
								this.selectOrDeselectItem({ model: this.elementStructure.cells[column.id][level.id] }, selectionListHelper, SelectionItemType.Cell, SelectionAction.Select);

								if (clickedCell.columnId !== lastSelectedCell.columnId) {
									startedSelectingColumns = true;
								}
							}
						});
					});
				};

				if (startedSelectingLevels) {
					// We've previously hit either side of the selection box.
					// We keep selecting, and check if we've reached the end of the box
					selectFromColumn(level);
					if (level.id === clickedCell.levelId || level.id === lastSelectedCell.levelId) {
						startedSelectingLevels = false;
					}
				} else {
					// We check if we've hit the level for either the current or last selected cell. If so, we start selecting
					// If the cells are on different levels, we will keep selecting when we get to the next level.
					if (level.id === clickedCell.levelId || level.id === lastSelectedCell.levelId) {
						selectFromColumn(level);
						if (clickedCell.levelId !== lastSelectedCell.levelId) {
							startedSelectingLevels = true;
						}
					}
				}
			});
		}

		// After we've selected new cells, we always want to change edit form to show appropriate values
		this.postSelectionSetup(SelectionItemType.Cell);

		ElementStructureUtils.cellChangedIdList = [];
	};

	// If you're looking at a list of cells, rather than a grid, then we need to change the selection behaviour
	@action clickCellList = (event: React.MouseEvent, clickedCell: Cell) => {
		// If selection is disabled, we don't do anything
		if (this.disableSelection) {
			return;
		}

		// If ctrl is pressed, we add to the current selection
		// Otherwise we start by clearing the selection
		if (!event.ctrlKey) {
			// We don't use the selectOrDeselectItem function here, since it would take n^2 time, because of all the splicing.
			this.selectedCells.length = 0;
		}

		const selectedListMap = this.getSelectedListMap(SelectionItemType.Cell);

		if (!event.shiftKey || !this.lastSelectedCell) {
			// If shift is not pressed, we simply toggle the current cell
			// If there wasn't a previous clicked cell to select a box from, we pretend that select wasn't pressed
			this.selectOrDeselectItem({ model: clickedCell }, selectedListMap, SelectionItemType.Cell, SelectionAction.Toggle);

			// We also keep track of the last cell selected, so we can use it when shift-selecting in the future
			this.lastSelectedCell = clickedCell;
		} else {
			// If shift is pressed, we need to calculate the line of selected cells
			let startedSelectingItems: boolean = false;
			const { lastSelectedCell } = this; // We save it into a separate variable so typescript doesn't complain

			for (let i = this.elementStructure.levels.length - 1; i >= 0; i--) {
				const level = this.elementStructure.levels[i];

				// eslint-disable-next-line no-loop-func
				this.elementStructure.columnTypes.forEach(columnType => {
					columnType.columns.forEach(column => {
						const cell = this.elementStructure.cells[column.id][level.id];

						// This is happening on a list without deleted cells, so we skip over them when selecting
						if (cell.deleted) {
							return;
						}

						if (startedSelectingItems) {
							// We've previously hit either side of the selection box.
							// We keep selecting, and check if we've reached the end of the box
							this.selectOrDeselectItem({ model: this.elementStructure.cells[column.id][level.id] }, selectedListMap, SelectionItemType.Cell, SelectionAction.Select);
							if (cell.id === clickedCell.id || cell.id === lastSelectedCell.id) {
								startedSelectingItems = false;
							}
						} else {
							// We check if we've hit the level for either the current or last selected cell. If so, we start selecting
							// If the cells are on different levels, we will keep selecting when we get to the next level.
							if (cell.id === clickedCell.id || cell.id === lastSelectedCell.id) {
								this.selectOrDeselectItem({ model: this.elementStructure.cells[column.id][level.id] }, selectedListMap, SelectionItemType.Cell, SelectionAction.Select);
								if (clickedCell.id !== lastSelectedCell.id) {
									startedSelectingItems = true;
								}
							}
						}
					});
				});
			}
		}

		// After we've selected new cells, we always want to change edit form to show appropriate values
		this.postSelectionSetup(SelectionItemType.Cell);

		ElementStructureUtils.cellChangedIdList = [];
	};

	@action mergeCells = () => {
		const mergeCellOptions = this.canMergeCells();
		if (mergeCellOptions === false) {
			// Ideally, this should never happen, because the button will be disabled if the cells can't be merged.
			alert('You cannot merge the selected cell(s)', 'error');
			return;
		}

		const cellsToSelect: Cell[] = [];
		for (const options of mergeCellOptions) {
			if (options.data) {
				// Required to avoid Typescript complaining
				if (options.data.bottomCell !== undefined) {
					// We've passed all our checks. Now we merge our sections, then adjust which sections are selected
					// First we set all sections to inactive, and remove them from our list
					for (const cell of this.selectedCells) {
						cell.model.merged = true;
					}
					this.selectedCells.length = 0;

					// then we go back, and reselect the last section, and give it an appropriate height
					options.data.bottomCell.merged = false;
					options.data.bottomCell.levelHeight = options.data.mergedHeight;

					// if the top cell was at the top of the building, we need to carry down its topSsl and slabThicknessAtTop
					if (options.data.topUnmergedCell.slabThicknessAtTop) {
						options.data.bottomCell.slabThicknessAtTop = options.data.topUnmergedCell.slabThicknessAtTop;
					}
					if (options.data.topUnmergedCell.overrideTopSsl && options.data.topUnmergedCell.topSsl) {
						options.data.bottomCell.overrideTopSsl = options.data.topUnmergedCell.overrideTopSsl;
						options.data.bottomCell.topSsl = options.data.topUnmergedCell.topSsl;
					}

					if (!cellsToSelect.includes(options.data.bottomCell)) cellsToSelect.push(options.data.bottomCell);
				}
			}
		}

		const selectedListMap = this.getSelectedListMap(SelectionItemType.Cell);
		for (const x of cellsToSelect) {
			this.selectOrDeselectItem({ model: x }, selectedListMap, SelectionItemType.Cell, SelectionAction.Select);
		}

		const loadTransferIndex = ElementStructureUtils.generateTransferIndex(this.elementStructure);
		for (const x of this.selectedCells) {
			// Merging a cell could affect its load, so we recalculate it
			const changedColumnId = x.model.columnId;
			for (const columnType of this.elementStructure.columnTypes) {
				for (const column of columnType.columns) {
					if (column.id === changedColumnId) {
						ElementStructureUtils.regenerateColumnLoad(this.elementStructure, column, loadTransferIndex);
					}
				}
			}
		}
		// finally we reset our edit cell and save the project
		this.postSelectionSetup(SelectionItemType.Cell);
		this.recalculateUnitMassForSelectedCells(false);
		this.afterChange();
	};

	canMergeCells = (): {
		columnId: string;
		data: { bottomCell: Cell; topUnmergedCell: Cell; mergedHeight: number } | false
	}[] | false => {
		// We can only merge cells if we have more than one selected
		if (this.selectedCells.length <= 1) {
			return false;
		}
		// We want to check that the cells can actually be merged
		// To do so, all selected cells must be contiguous within the same column

		const selectedLevelIndex: Record<string, boolean> = {};
		const selectedColumnIndex: Record<string, boolean> = {};
		const levelIdsByColumnId: Record<string, Record<string, boolean>> = {};
		const selectedCells: Record<string, SelectedCell> = {};

		this.selectedCells.forEach(cell => {
			selectedLevelIndex[cell.model.levelId] = true;
			selectedColumnIndex[cell.model.columnId] = true;

			levelIdsByColumnId[cell.model.columnId] = {
				...(levelIdsByColumnId[cell.model.columnId] || {}),
				[cell.model.levelId]: true,
			};

			selectedCells[cell.model.id] = cell;
		});

		const loadTransferIndex = ElementStructureUtils.generateTransferIndex(this.elementStructure);
		if (this.selectedCells.find(cell => ElementStructureUtils.hasAssociatedLoads(cell.model, loadTransferIndex))) {
			return false;
		}

		// If we haven't selected more than one level, we return false
		if (Object.keys(selectedLevelIndex).length <= 1) {
			return false;
		}
		// We loop through the floors, and check if there's any gaps which can't be accounted for by existing merged columns
		const selectedGroupArray: {columnId: string, data: {bottomCell: Cell, topUnmergedCell: Cell, mergedHeight: number} | false }[] = [];

		const { elementStructure } = this;
		for (const t of elementStructure.columnTypes) {
			for (const column of t.columns) {
				let columnStarted: boolean = false;
				let currentGap: number = 0;
				let firstCell: Cell | undefined;
				let lastCell: Cell | undefined;
				let noncontiguousColumns: boolean = false;
				let selectedColumnHeight: number = 0;

				if (selectedColumnIndex[column.id]) {
					for (const level of elementStructure.levels) {
						const currentCell = elementStructure.cells[column.id][level.id];
						const levelsInColumn = levelIdsByColumnId[column.id];

						// If the number of cells selected in a column isn't more than one, then we don't want to do anything.
						if (
							selectedLevelIndex[level.id]
							&& currentCell.columnId === column.id
							&& levelsInColumn[level.id]
							&& (Object.keys(levelsInColumn).length > 1 || currentCell.levelHeight > 1)
						) {
							// the current level is included in the selected sections
							if (!firstCell && !currentCell.merged) {
								firstCell = elementStructure.cells[column.id][level.id];
							}
							// Figure out the column height per column
							selectedColumnHeight += currentCell.levelHeight;

							// If there's been a gap above the current section, we want to check that it's covered by the current section
							if (columnStarted && currentGap > 0) {
								if (currentCell.levelHeight <= currentGap) {
									// We'll throw an error and quit, but we need to do it in the original function, not in this callback.
									noncontiguousColumns = true;
								}
							}

							currentGap = 0;
							columnStarted = true;
							lastCell = currentCell;
						} else {
							currentGap++;
						}
					}
					const existingArrayEntry = selectedGroupArray.find(x => x.columnId === column.id);
					// For styling reasons, we don't allow any columns over 30 levels tall
					if (selectedColumnHeight > 30) {
						if (existingArrayEntry) {
							existingArrayEntry.data = false;
						} else {
							selectedGroupArray.push({ columnId: column.id, data: false });
						}
					}
					if (noncontiguousColumns || lastCell === undefined || firstCell === undefined) {
						if (existingArrayEntry) {
							existingArrayEntry.data = false;
						} else {
							selectedGroupArray.push({ columnId: column.id, data: false });
						}
					} else {
						const data = {
							bottomCell: lastCell,
							topUnmergedCell: firstCell,
							mergedHeight: selectedColumnHeight,
						};
						// We can merge. We return some useful values, in case they're needed to proceed with the merge
						if (existingArrayEntry) {
							existingArrayEntry.data = data;
						} else {
							selectedGroupArray.push({ columnId: column.id, data: data });
						}
					}
				}
			}
		}
		if (!selectedGroupArray || selectedGroupArray.some(x => x.data === false)) {
			return false;
		}
		return selectedGroupArray;
	};

	/**
	 * Splits the current selected merged cells.
	 * @param suppressValidation If this is true then canSplitCells will not be called first before splitting.
	 * @returns true if cells were split, false otherwise.
	 */
	@action splitCells = (suppressValidation?: boolean) => {
		if (suppressValidation !== true && !this.canSplitCells()) {
			// This scenario should not happen from te UI since the button will be disabled but it could happen if this
			// is called programatically.
			alert('You cannot split the selected cell(s)', 'error');
			return false;
		}

		// Figure out which columns have been selected (For faster iteration)
		const selectedColumns: string[] = [];
		this.selectedCells.forEach(cell => {
			if (!selectedColumns.includes(cell.model.columnId)) selectedColumns.push(cell.model.columnId);
		});

		const columnToRegen: Column[] = [];

		for (const t of this.elementStructure.columnTypes) {
			for (const c of t.columns) {
				if (selectedColumns.includes(c.id)) {
					columnToRegen.push(c);

					for (const selectedCell of [...this.selectedCells]) {
						// We want to get a list of all floors which this column section covers
						// We get the list of floors (reversing it so it's in the order we want), then count through
						// This will but the list in order of bottom level first to top level last.
						const reversedLevelList = this.elementStructure.levels.slice().reverse();
						let startedCollectingLevels: boolean = false;
						const levelsContainingRelevantCells: Level[] = [];
						let furtherLevelsRequired = selectedCell.model.levelHeight;

						// If level height was one, then there is nothing to split
						if (furtherLevelsRequired <= 1) {
							continue;
						}

						for (const level of reversedLevelList) {
							if (!startedCollectingLevels && level.id === selectedCell.model.levelId) {
								startedCollectingLevels = true;
							}

							if (startedCollectingLevels && furtherLevelsRequired > 0) {
								levelsContainingRelevantCells.push(level);
								furtherLevelsRequired--;
							}
						}
						// The bottom cell might have a custom topSsl or slabThicknessAtTop, which we want to keep track of
						const mergedOverrideTopSsl = selectedCell.model.overrideTopSsl;
						const mergedTopSsl = selectedCell.model.topSsl;
						const mergedSlabThicknessAtTop = selectedCell.model.slabThicknessAtTop;

						// For each floor previously covered by a merged section, we set it to be active again, and set its height to 1
						// We also want to select all the newly-split column sections
						const topLevel = levelsContainingRelevantCells.slice(-1).pop();
						levelsContainingRelevantCells.forEach(level => {
							const cell = this.elementStructure.cells[selectedCell.model.columnId][level.id];
							// If we cannot find a cell in the selected cell array, then add it in
							if (!this.selectedCells.find(x => x.model.id === cell.id)) {
								this.selectedCells.push({ model: cell });
							}
							cell.merged = false;
							cell.levelHeight = 1;
							// Since merging a column could set the bottom cell's slabThicknessAtTop or topSsl, we reset it here
							if (!topLevel || topLevel.id !== level.id) {
								cell.overrideTopSsl = undefined;
								cell.topSsl = undefined;
								cell.slabThicknessAtTop = undefined;
							} else if (topLevel.id === level.id) {
								cell.overrideTopSsl = mergedOverrideTopSsl;
								cell.topSsl = mergedTopSsl;
								cell.slabThicknessAtTop = mergedSlabThicknessAtTop;
							}
						});
					}
				}
			}
		}

		// Merging a cell could affect its load, so we recalculate them
		const loadTransferIndex = ElementStructureUtils.generateTransferIndex(this.elementStructure);
		columnToRegen.forEach(column => {
			ElementStructureUtils.regenerateColumnLoad(this.elementStructure, column, loadTransferIndex);
		});

		// finally we reset our edit cell and save the project
		this.recalculateUnitMassForSelectedCells(false);
		this.postSelectionSetup(SelectionItemType.Cell);
		this.afterChange();

		return true;
	};

	canSplitCells = () => {
		// Cells are only splitable if every cell in the current selection is splittable
		const canSplitCells: boolean[] = [];
		this.selectedCells.forEach(cell => {
			if (cell.model.levelHeight > 1) canSplitCells.push(true);
			else canSplitCells.push(false);
		});
		return canSplitCells.every(x => x);
	};

	@action deleteCells = () => {
		this.selectedCells.forEach(cell => {
			cell.model.deleted = true;
			this.setSlabThicknessAtTopInitialValue(cell, false);
		});

		this.recalculateLoadForSelectedColumns();
		this.afterChange();
	};

	@action restoreDeletedCells = () => {
		this.selectedCells.forEach(cell => {
			cell.model.deleted = false;
			cell.model.errors = [];
			cell.model.warnings = [];
			this.setSlabThicknessAtTopInitialValue(cell, true);
		});

		this.recalculateUnitMassForSelectedCells(false);
		this.recalculateLoadForSelectedColumns();
		this.afterChange();
	};

	@action approveCells = () => {
		this.selectedCells.forEach(cell => {
			cell.model.approved = true;
		});
		this.afterChange();
	};

	@action disapproveCells = () => {
		this.selectedCells.forEach(cell => {
			cell.model.approved = false;
		});
		this.afterChange();
	};

	@action recalculateLoadForSelectedColumns = () => {
		// Start by building a list of all columns which were changed
		const changedColumnIds = {};
		this.selectedCells.forEach(selectedCell => {
			changedColumnIds[selectedCell.model.columnId] = true;
		});

		const changedColumns: Column[] = [];
		this.elementStructure.columnTypes.forEach(columnType => {
			columnType.columns.forEach(column => {
				if (changedColumnIds[column.id] === true) {
					changedColumns.push(column);
				}
			});
		});

		// recalculate load for all changed columns
		const loadTransferIndex = ElementStructureUtils.generateTransferIndex(this.elementStructure);
		changedColumns.forEach(column => {
			ElementStructureUtils.regenerateColumnLoad(this.elementStructure, column, loadTransferIndex);
		});
	};

	@action recalculateUnitMassForSelectedCells = (includeCellsBelow: boolean) => {
		this.selectedCells.forEach(cell => {
			ElementStructureUtils.calculateCellUnitMass(this.elementStructure, cell.model);
			if (includeCellsBelow) {
				const cellBelow = ElementStructureUtils.findCellBelowGivenCell(this.elementStructure, cell.model);
				if (cellBelow && !cellBelow.deleted) {
					ElementStructureUtils.calculateCellUnitMass(this.elementStructure, cellBelow);
				}
			}
		});
	};

	@action recalculateUnitMassForSelectedLevels = () => {
		this.selectedLevels.forEach(level => {
			this.elementStructure.columnTypes.forEach(columnType => {
				columnType.columns.forEach(column => {
					const cell = this.elementStructure.cells[column.id][level.model.id];
					ElementStructureUtils.calculateCellUnitMass(this.elementStructure, cell);
				});
			});
		});
	};

	// MANAGING Column Type SELECTION & EDITING
	// Fields for selecting/editing column types
	private lastSelectedColumnType?: ColumnType;

	@observable
	selectedColumnTypes: SelectedColumnType[] = [];

	@observable editColumnType: EditColumnType = {
		model: {
			code: undefined,
			name: undefined,
			estimatedColumnCount: undefined,
		},
	};

	@observable editColumnTypeErrors: EditColumnTypeErrors = {};

	// Returns the list of all column types in the element structure, in a format useful for entering into the selected column type list
	public getColumnTypeList(): SelectedColumnType[] {
		return this.elementStructure.columnTypes.map(columnType => {
			return { model: columnType };
		});
	}

	@action protected validateEditColumnType = () => {
		this.editColumnTypeErrors = {};

		if (this.editColumnType.model.name) {
			if (this.selectedColumnTypes.length > 1) {
				// The user should not be able to edit the name of multiple column types at once
				this.editColumnTypeErrors.name = 'You cannot edit the name of multiple element types at the same time';
			} else {
				const selectedColumnType = this.selectedColumnTypes[0].model;

				// We check the column type list, to see if any other column types use the name that we're saving
				this.elementStructure.columnTypes.forEach(columnType => {
					// We only want to validate against column types which aren't selected
					if (columnType.id === selectedColumnType.id) {
						return;
					}

					// We're comparing with a different column type from within the same type. If it has the name we plan to use, we throw an error
					if (columnType.name === this.editColumnType.model.name) {
						this.editColumnTypeErrors.name = 'This name is already used by another element type.';
					}
				});
			}
		}

		if (this.editColumnType.model.code) {
			if (this.selectedColumnTypes.length > 1) {
				// The user should not be able to edit the code of multiple column types at once
				this.editColumnTypeErrors.code = 'You cannot edit the code of multiple element types at the same time';
			} else {
				const selectedColumnType = this.selectedColumnTypes[0].model;

				// We check the column types list, to see if any other column types use the code that we're saving
				this.elementStructure.columnTypes.forEach(columnType => {
					// We only want to validate against column types which aren't selected
					if (columnType.id === selectedColumnType.id) {
						return;
					}

					// We're comparing with a different column types from within the same type. If it has the code we plan to use, we throw an error
					if (columnType.code === this.editColumnType.model.code) {
						this.editColumnTypeErrors.code = 'This element type code is already used by another element type.';
					}
				});
			}
		}

		return Object.keys(this.editColumnTypeErrors).length === 0;
	};

	public selectedColumnTypesIncludesColumnType = (columnType: ColumnType) => {
		return this.selectedColumnTypes.some(selectedColumnType => {
			return selectedColumnType.model.id === columnType.id;
		});
	};

	@action clickColumnType = (event: React.MouseEvent, clickedColumnType: ColumnType) => {
		// If selection is disabled, we don't do anything
		if (this.disableSelection) {
			return;
		}

		// If ctrl is pressed, we add to the current selection
		// Otherwise we start by clearing the selection
		if (!event.ctrlKey) {
			// We don't use the selectOrDeselectItem function here, since it would take n^2 time, because of all the splicing.
			this.selectedColumnTypes.length = 0;
		}

		const selectedListMap = this.getSelectedListMap(SelectionItemType.ColumnType);

		if (!event.shiftKey || !this.lastSelectedColumnType) {
			// If shift is not pressed, we simply toggle the current item
			// If there wasn't a previous clicked item to select a box from, we pretend that select wasn't pressed
			this.selectOrDeselectItem({ model: clickedColumnType }, selectedListMap, SelectionItemType.ColumnType, SelectionAction.Toggle);

			// We also keep track of the last item selected, so we can use it when shift-selecting in the future
			this.lastSelectedColumnType = clickedColumnType;
		} else {
			// If shift is pressed, we need to calculate the line of connected items
			let startedSelectingItems: boolean = false;
			const { lastSelectedColumnType } = this; // We save it into a separate variable so typescript doesn't complain
			this.elementStructure.columnTypes.forEach(columnType => {
				if (startedSelectingItems) {
					// We've previously hit either side of the selection area.
					// We keep selecting, and check if we've reached the end of the area
					this.selectOrDeselectItem({ model: columnType }, selectedListMap, SelectionItemType.ColumnType, SelectionAction.Select);
					if (columnType.id === clickedColumnType.id || columnType.id === lastSelectedColumnType.id) {
						startedSelectingItems = false;
					}
				} else {
					// We check if we hit the collection area. If so, we start selecting
					// If the column types are not the same, we will keep selecting when we get to the next column type.
					if (columnType.id === clickedColumnType.id || columnType.id === lastSelectedColumnType.id) {
						this.selectOrDeselectItem({ model: columnType }, selectedListMap, SelectionItemType.ColumnType, SelectionAction.Select);
						if (clickedColumnType.id !== lastSelectedColumnType.id) {
							startedSelectingItems = true;
						}
					}
				}
			});
		}

		// After we've selected new cells, we always want to change edit form to show appropriate values
		this.postSelectionSetup(SelectionItemType.ColumnType);
	};

	@action addColumnType = (index: number) => {
		// Add a column type to the project
		const newColumnType = ElementStructureUtils.addColumnTypeToElementStructure(this.elementStructure, {}, index);

		// Select the new column
		this.selectedColumnTypes.length = 0;
		this.selectedColumnTypes.push({ model: newColumnType });
		this.postSelectionSetup(SelectionItemType.ColumnType);

		// And save
		this.afterChange();
	};

	@action deleteSelectedColumnTypes = () => {
		// We cannot delete a column type if it contains any approved cells
		let containsApproved = false;
		this.selectedColumnTypes.forEach(selectedColumnType => {
			selectedColumnType.model.columns.forEach(column => {
				this.elementStructure.levels.forEach(level => {
					const cell = this.elementStructure.cells[column.id][level.id];
					if (cell.approved) {
						alert('You cannot delete an element type which contains approved elements.', 'error');
						containsApproved = true;
					}
				});
			});
		});
		if (containsApproved) {
			return;
		}

		confirmModal('Please confirm', 'Are you sure you want to delete the selected element type(s)?').then(() => {
			this.selectedColumnTypes.forEach(columnType => {
				ElementStructureUtils.deleteColumnTypeFromElementStructure(this.elementStructure, columnType.model);
			});
			this.postSelectionSetup(SelectionItemType.None);
			this.afterChange();
		});
	};

	// MANAGING COLUMN SELECTION & EDITING
	// Fields for selecting/editing columns
	private lastSelectedColumn?: Column;

	@observable selectedColumns: SelectedColumn[] = [];

	@computed
	public get columnsContainingSelectedCells() {
		return this.selectedCells
			.map(x => x.model.columnId)
			.filter((value, index, self) => self.indexOf(value) === index);
	}

	@computed
	public get levelsContainingSelectedCells() {
		return this.selectedCells
			.map(x => x.model.levelId)
			.filter((value, index, self) => self.indexOf(value) === index);
	}

	@computed
	public get selectionSpansMultipleLevels() {
		if (this.selectedCells.length > 1) {
			return this.selectedCells.some((curr, index) => (
				index === 0
					? false
					: (curr.model.levelId !== this.selectedCells[index - 1].model.levelId)
			));
		}
		return false;
	}

	@computed
	public get selectionContainsDeletedCells(): boolean {
		if (this.selectedCells.length === 0) return false;

		if (this.selectedCells.length > 1) {
			return this.selectedCells.some(x => x.model.deleted);
		}
		return !!this.selectedCells[0].model.deleted;
	}

	@observable editColumn: EditColumn = {
		model: {
			name: undefined,
			load: undefined,
			loadArea: undefined,
			constructionZone: undefined,
		},
		info: {
			typeName: undefined,
			typeCode: undefined,
			topCellAdditionalLoad: undefined,
		},
	};

	@observable editColumnErrors: EditColumnErrors = {};

	// Returns the list of all columns in the element structure, in a format useful for entering into the selected column list
	public getColumnList(): SelectedColumn[] {
		return this.elementStructure.columnTypes.reduce(
			(columnList, columnType) => {
				return columnList.concat(columnType.columns.map(column => {
					return { model: column, parent: columnType };
				}));
			},
			[] as SelectedColumn[],
		);
	}

	@action protected validateEditColumn = (): boolean => {
		this.editColumnErrors = {};

		if (this.editColumn.model.name) {
			if (this.selectedColumns.length > 1) {
				// This should only happen if the user has selected two columns with the same name from different types
				// They shouldn't be able to modify the name, but just in case, we set it as undefined.
				this.editColumn.model.name = undefined;
			} else {
				const selectedColumn = this.selectedColumns[0].model;
				const selectedType = this.selectedColumns[0].parent;

				// We check the column type, to see if any other columns use the name that we're saving
				selectedType.columns.forEach(column => {
					// We only want to validate against columns which aren't selected
					if (column.id === selectedColumn.id) {
						return;
					}

					// We're comparing with a different column from within the same type. If it has the name we plan to use, we throw an error
					if (column.name === this.editColumn.model.name) {
						this.editColumnErrors.name = `This name is already used by another ${selectedType.name} element`;
					}
				});
			}
		}

		if (this.editColumn.info.topCellAdditionalLoad) {
			// We can't edit the additional load of any cell that's approved
			// We loop through each cell, and if it's approved, and we would be changing it's load, then we throw an error
			this.selectedColumns.forEach(selectedColumn => {
				const topCell = ElementStructureUtils.getTopCellOfColumn(this.elementStructure, selectedColumn.model);
				if (topCell && topCell.approved && topCell.additionalLoad !== this.editColumn.info.topCellAdditionalLoad) {
					this.editColumnErrors.topCellAdditionalLoad = 'You cannot apply additional load to the top of an element which has been approved';
				}
			});
		}

		return Object.keys(this.editColumnErrors).length === 0;
	};

	public isColumnHighlighted = (column: Column) => {
		if (this.selectedCells.length > 0) {
			const columns = this.columnsContainingSelectedCells;
			return columns.some(x => x === column.id);
		}
		return this.selectedColumns.some(selectedColumn => {
			return selectedColumn.model.id === column.id;
		});
	};

	@action clickColumn = (event: React.MouseEvent, clickedColumn: Column, parentColumnType: ColumnType) => {
		// If selection is disabled, we don't do anything
		if (this.disableSelection) {
			return;
		}

		// If ctrl is pressed, we add to the current selection
		// Otherwise we start by clearing the selection
		if (!event.ctrlKey) {
			// We don't use the selectOrDeselectItem function here, since it would take n^2 time, because of all the splicing.
			this.selectedColumns.length = 0;
		}

		const selectedListMap = this.getSelectedListMap(SelectionItemType.Column);

		if (!event.shiftKey || !this.lastSelectedColumn) {
			// If shift is not pressed, we simply toggle the current item
			// If there wasn't a previous clicked item to select a box from, we pretend that select wasn't pressed
			this.selectOrDeselectItem({ model: clickedColumn, parent: parentColumnType }, selectedListMap, SelectionItemType.Column, SelectionAction.Toggle);

			// We also keep track of the last item selected, so we can use it when shift-selecting in the future
			this.lastSelectedColumn = clickedColumn;
		} else {
			// If shift is pressed, we need to calculate the line of connected items
			let startedSelectingItems: boolean = false;
			const { lastSelectedColumn } = this; // We save it into a separate variable so typescript doesn't complain
			this.elementStructure.columnTypes.forEach(columnType => {
				columnType.columns.forEach(column => {
					if (startedSelectingItems) {
						// We've previously hit either side of the selection area.
						// We keep selecting, and check if we've reached the end of the area
						this.selectOrDeselectItem({ model: column, parent: columnType }, selectedListMap, SelectionItemType.Column, SelectionAction.Select);
						if (column.id === clickedColumn.id || column.id === lastSelectedColumn.id) {
							startedSelectingItems = false;
						}
					} else {
						// We check if we hit the collection area. If so, we start selecting
						// If the columns are not the same, we will keep selecting when we get to the next level.
						if (column.id === clickedColumn.id || column.id === lastSelectedColumn.id) {
							this.selectOrDeselectItem({ model: column, parent: columnType }, selectedListMap, SelectionItemType.Column, SelectionAction.Select);
							if (clickedColumn.id !== lastSelectedColumn.id) {
								startedSelectingItems = true;
							}
						}
					}
				});
			});
		}

		// After we've selected new cells, we always want to change edit form to show appropriate values
		this.postSelectionSetup(SelectionItemType.Column);
	};

	/* Moving Columns */
	@action moveColumnBulk = (selCols: SelectedColumn[], right: boolean) => {
		const typeArray: ColumnType[] = selCols.map(x => x.parent);
		const uniqueTypesArray: ColumnType[] = typeArray.filter((v, i, a) => a.indexOf(v) === i);
		// For each column type, find if the selected cells belong to it,
		// Sort the selected cells so index swapping works in both directions
		// Then swap indexes where appropriate
		// Show an alert error if attempting to move outside of range
		uniqueTypesArray.forEach(type => {
			const selColsForType = selCols.filter(x => type.columns.find(c => x.model.id === c.id));

			if (selColsForType.every(selCol => this.canMoveColumn(selCol, right))) {
				const colOrder = selColsForType.reduce((acc, curr) => {
					const index = type.columns.findIndex(col => curr.model.id === col.id);
					return {
						...acc,
						[curr.model.id]: index,
					};
				}, {});
				const sortedSelCols = [...selColsForType].sort((a, b) => {
					if (right) {
						return colOrder[b.model.id] - colOrder[a.model.id];
					}
					return colOrder[a.model.id] - colOrder[b.model.id];
				});
				sortedSelCols.forEach(selCol => this.moveColumn(selCol, right));
			} else {
				alertToast(`Cannot move ${selColsForType.length > 1 ? 'Columns' : 'Column'} out of Column Type boundaries`, 'error');
			}
		});
	}

	@action canMoveColumn = (col: SelectedColumn, right: boolean) => {
		const columnType = col.parent;
		const columnIndex = columnType.columns.indexOf(col.model);
		const columnSwap = right ? columnIndex + 1 : columnIndex - 1;
		return !(columnSwap >= columnType.columns.length || columnSwap < 0);
	}

	@action moveColumn = (col: SelectedColumn, right: boolean) => {
		const columnType = col.parent;
		const columnIndex = columnType.columns.indexOf(col.model);
		const columnSwap = right ? columnIndex + 1 : columnIndex - 1;
		columnType.columns = this.swapArray(columnType.columns, columnIndex, columnSwap);
	}

	@action
	swapArray<T>(array: Array<T>, Swap1:number, Swap2:number) : Array<T> {
		const temp = array[Swap1];
		array[Swap1] = array[Swap2];
		array[Swap2] = temp;
		return array;
	}

	@action addColumn = (columnType: ColumnType, index: number) => {
		// Add a column to the project
		const newColumn = ElementStructureUtils.addColumnToElementStructure(this.elementStructure, columnType.id, {}, index);

		// If our column type was invalid, we can't create a column, so we exit early
		if (!newColumn) {
			return;
		}

		// Select the new column
		this.selectedColumns.length = 0;
		this.selectedColumns.push({ model: newColumn, parent: columnType });
		this.postSelectionSetup(SelectionItemType.Column);

		// And save
		this.afterChange();
	};

	@action deleteSelectedColumns = () => {
		// We cannot delete a column if it contains any approved cells
		let containsApproved = false;
		let containsTransfers = false;

		const loadTransferIndex = ElementStructureUtils.generateTransferIndex(this.elementStructure);

		this.selectedColumns.forEach(selectedColumn => {
			this.elementStructure.levels.forEach(level => {
				const cell = this.elementStructure.cells[selectedColumn.model.id][level.id];
				if (cell.approved) {
					containsApproved = true;
				}
				if (ElementStructureUtils.hasAssociatedLoads(cell, loadTransferIndex)) {
					containsTransfers = true;
				}
			});
		});
		if (containsApproved) {
			alert('You cannot delete an element when parts of it have been approved', 'error');
			return;
		} if (containsTransfers) {
			alert('You cannot delete an element when parts of it have load transfers', 'error');
			return;
		}

		confirmModal('Please confirm', 'Are you sure you want to delete the selected element(s)?').then(() => {
			this.selectedColumns.forEach(column => {
				ElementStructureUtils.deleteColumnFromElementStructure(this.elementStructure, column.model);
			});
			this.postSelectionSetup(SelectionItemType.None);
			this.afterChange();
		});
	};

	// MANAGING LEVEL SELECTION & EDITING
	// Fields for selecting/editing levels
	private lastSelectedLevel?: Level;

	@observable
	selectedLevels: SelectedLevel[] = [];

	@observable editLevel: EditLevel = {
		model: {
			code: undefined,
			name: undefined,
			slabThicknessAtBase: undefined,
			groundLevel: undefined,
			ssl: undefined,
			topSsl: undefined,
			calculatedFloorHeight: undefined,
		},
		info: {
			topLevelSelected: undefined,
		},
	};

	@observable editLevelErrors: EditLevelErrors = {};

	// Returns the list of all levels in the element structure, in a format useful for entering into the selected level list
	public getLevelList(): SelectedLevel[] {
		return this.elementStructure.levels.map(level => {
			return { model: level };
		});
	}

	@observable
	private prebuildErrorConsts = {
		sslGreaterError: 'The SSL value cannot be greater than the SSL of the level above.',
		sslLessThanError: 'The SSL value cannot be less than the SSL of the level below.',
		sslCorbelVoidHeightError: "You can't make this change because it will make a Corbel or Void input invalid",
	}

	@action protected validateEditLevel = (disableSSLValidation?: boolean) => {
		this.editLevelErrors = {};

		if (this.editLevel.model.name) {
			if (this.selectedLevels.length > 1) {
				// The user should not be able to edit the name of multiple levels at once
				this.editLevelErrors.name = 'You cannot edit the name of multiple levels at the same time';
			} else {
				const selectedLevel = this.selectedLevels[0].model;

				// We check the levels list, to see if any other levels use the name that we're saving
				this.elementStructure.levels.forEach(level => {
					// We only want to validate against levels which aren't selected
					if (level.id === selectedLevel.id) {
						return;
					}

					// We're comparing with a different level from within the same type. If it has the name we plan to use, we throw an error
					if (level.name === this.editLevel.model.name) {
						this.editLevelErrors.name = 'This name is already used by another level.';
					}
				});
			}
		}

		if (this.editLevel.model.code) {
			if (this.selectedLevels.length > 1) {
				// The user should not be able to edit the code of multiple levels at once
				this.editLevelErrors.code = 'You cannot edit the code of multiple levels at the same time';
			} else {
				const selectedLevel = this.selectedLevels[0].model;

				// We check the levels list, to see if any other levels use the code that we're saving
				this.elementStructure.levels.forEach(level => {
					// We only want to validate against levels which aren't selected
					if (level.id === selectedLevel.id) {
						return;
					}

					// We're comparing with a different level from within the same type. If it has the code we plan to use, we throw an error
					if (level.code === this.editLevel.model.code) {
						this.editLevelErrors.code = 'This level code is already used by another level.';
					}
				});
			}
		}

		if (this.editLevel.model.ssl && !disableSSLValidation) {
			// We can't edit the floor-to-floor height if there are any cells on the selected floors that are approved
			// (Unless they are using a custom SSL)
			this.selectedLevels.forEach(selectedLevel => {
				// If we're not actually changing the floor height, we can break early
				if (selectedLevel.model.ssl === this.editLevel.model.ssl) {
					return;
				}
				this.prebuildSSLValidation(this.editLevel, selectedLevel);
				this.elementStructure.columnTypes.forEach(columnType => {
					columnType.columns.forEach(column => {
						const cell = this.elementStructure.cells[column.id][selectedLevel.model.id];
						if (cell.approved && !cell.overrideSSL) {
							this.editLevelErrors.ssl = 'You cannot change a level\'s SSL while it contains approved elements. You can bypass this by overriding the SSL for any approved elements.';
						}
					});
				});
			});
		}

		if (this.editLevel.model.topSsl && !disableSSLValidation) {
			// We can't edit the floor-to-floor height if there are any cells on the selected floors that are approved
			// (Unless they are using a custom SSL)
			this.selectedLevels.forEach(selectedLevel => {
				// If we're not actually changing the floor height, we can break early
				if (selectedLevel.model.topSsl === this.editLevel.model.topSsl) {
					return;
				}

				this.elementStructure.columnTypes.forEach(columnType => {
					columnType.columns.forEach(column => {
						const cell = this.elementStructure.cells[column.id][selectedLevel.model.id];
						if (cell.approved) {
							this.editLevelErrors.topSsl = 'You cannot change a level\'s SSL at top while it contains approved elements.';
						}
					});
				});
			});
		}

		return Object.keys(this.editLevelErrors).length === 0;
	};

	private prebuildSSLValidation(editLevel: EditLevel, selectedLevel: SelectedLevel) {
		if (editLevel.model.ssl) {
			const aboveLevel = ElementStructureUtils.findLevelAboveGivenLevel(this.elementStructure, selectedLevel.model.id);
			const belowLevel = ElementStructureUtils.findLevelBelowGivenLevel(this.elementStructure, selectedLevel.model.id);
			if (aboveLevel !== undefined) {
				if (editLevel.model.ssl > aboveLevel.ssl) this.editLevelErrors.ssl = this.prebuildErrorConsts.sslGreaterError;
			}
			if (belowLevel !== undefined) {
				if (belowLevel.ssl > editLevel.model.ssl) this.editLevelErrors.ssl = this.prebuildErrorConsts.sslLessThanError;
			}

			// search the cells in each level. If any of the cells have a corbel or void with an invalid height, return an error
			const columnIds = this.elementStructure.columnTypes
				.reduce((acc, colType) => {
					return [...acc, ...colType.columns.map(col => col.id)];
				}, [] as string[]);

			// make Level with updated properties. Careful not to use this for anything other than validation!
			// copy properties explicitly
			const temporaryUpdatedLevel = { ...selectedLevel.model, ...editLevel.model };
			const levels = { [temporaryUpdatedLevel.id]: temporaryUpdatedLevel };

			if (aboveLevel) levels[aboveLevel.id] = aboveLevel;
			if (belowLevel) levels[belowLevel.id] = belowLevel;

			columnIds.forEach(columnId => {
				Object.keys(levels).forEach(levelId => {
					const cell = this.elementStructure.cells[columnId][levelId];
					const { corbel, elementVoid } = cell;

					const level = levels[levelId];

					try {
						const levelHeight = ElementStructureUtils.calculateLevelHeight(this.elementStructure, level.id, level.ssl, level.topSsl);

						if (corbel?.height && corbel?.height > levelHeight) {
							this.editLevelErrors.ssl = "You can't make this change because it will make a Corbel or Void input invalid";
						}

						if (elementVoid?.height && elementVoid?.height > levelHeight) {
							this.editLevelErrors.ssl = "You can't make this change because it will make a Corbel or Void input invalid";
						}
					} catch (err) {
						this.editLevelErrors.ssl = 'An unexpected error ocurred, the ssl cannot be validated';
					}
				});
			});
		}
	}

	// getCellHeight uses editCell to calculate the current cellHeight
	public getCellHeight(selectedCell: SelectedCell, editCell?: EditCell) {
		// In this scenario, the selected cell is rougly the same as the edit cell
		const selectedCellTopSsl = ElementStructureUtils.cellTopSSL(this.elementStructure, selectedCell.model, undefined, true);
		const selectedCellSsl = ElementStructureUtils.cellBottomSSL(this.elementStructure, selectedCell.model);

		const editCellTopSsl = editCell?.model.overrideTopSsl ? editCell?.model.topSsl : null;
		const editCellSsl = editCell?.model.overrideSSL ? editCell?.model.ssl : null;

		const topSsl = (editCellTopSsl ?? selectedCellTopSsl) * 1000;
		const ssl = (editCellSsl ?? selectedCellSsl) * 1000;

		const aboveCell = ElementStructureUtils.findCellAboveGivenCell(this.elementStructure, selectedCell.model);

		let result: number;
		if (editCell?.info.atTopOfBuilding || editCell?.info.atTopOfStack) {
			const slabThicknessAtTop = editCell.model.slabThicknessAtTop ?? selectedCell.model.slabThicknessAtTop ?? 0;
			result = topSsl - ssl - slabThicknessAtTop;
		} else {
			result = topSsl - ssl - (aboveCell?.slabThicknessAtBase ?? 0);
		}

		return Math.round(result);
	}

	// getCellHeight uses editCell to calculate the cellBelowHeight
	public getCellBelowHeight(editCell: EditCell, selectedCell: SelectedCell, belowCell: Cell) {
		const selectedCellTopSsl = ElementStructureUtils.cellTopSSL(this.elementStructure, belowCell, undefined, true);
		const selectedCellSsl = ElementStructureUtils.cellBottomSSL(this.elementStructure, belowCell);

		const editCellSsl = editCell?.model.overrideSSL ? editCell?.model.ssl : null;

		// The ssl of the edit cell is our top ssl since the edit cell is above us
		const topSsl = (editCellSsl ?? selectedCellTopSsl) * 1000;
		const ssl = selectedCellSsl * 1000;

		const slabThicknessAtTop = editCell.model.slabThicknessAtBase ?? selectedCell.model.slabThicknessAtBase;
		return Math.round(topSsl - ssl - slabThicknessAtTop);
	}

	public getValidCellHeight(selectedCells: SelectedCell[], editCell?: EditCell) {
		// Find the Cells Above Values
		const cellAHeightArray = selectedCells
			.map(selectedCell => this.getCellHeight(selectedCell, editCell));
		const minValueAbove = Math.min(...cellAHeightArray);
		const maxValueAbove = Math.max(...cellAHeightArray);

		return minValueAbove <= 0 ? 0 : maxValueAbove;
	}

	public getValidCellBelowHeight(editCell: EditCell, selectedCells: SelectedCell[], elementStructure?: ElementStructure) {
		const elementStruct = elementStructure ?? this.elementStructure;
		/**
		 * Find the height values of cells below
		 * If there's no height values, return an error string
		 */
		const cellBHeightArray = selectedCells
			.reduce((acc: number[], selectedCell) => {
				const cellBelow = ElementStructureUtils.findCellBelowGivenCell(elementStruct, selectedCell.model, false);
				// only return cells below that exist, are not empty and are not deleted
				return (cellBelow && !ElementStructureUtils.cellDimensionsEmpty(cellBelow.width, cellBelow.shape, cellBelow.depth) && !cellBelow.deleted)
					? [...acc, this.getCellBelowHeight(editCell, selectedCell, cellBelow)]
					: acc;
			}, []);

		if (cellBHeightArray.length === 0) return 'No Cell Below';

		const minValueBelow = Math.min(...cellBHeightArray);
		const maxValueBelow = Math.max(...cellBHeightArray);

		return [minValueBelow || 0, maxValueBelow];
	}

	public getEditedLevelHeight(editLevel: EditLevel, selectedLevel: SelectedLevel) {
		// Set the current Cells SSL. We dont want to use the Ssl value defined in the cell
		// if the override has not been checked. Instead we would use the level ssl
		const ssl = editLevel.model.ssl ?? selectedLevel.model.ssl;
		const levelAbove = ElementStructureUtils.findLevelAboveGivenLevel(this.elementStructure, selectedLevel.model.id);

		// Currently can only receive a 0 value as a result of having a level selected whilst rebuilding a project,
		// otherwise has no effect on validation
		const topSsl = editLevel.model.topSsl ?? selectedLevel.model.topSsl ?? levelAbove?.ssl ?? 0;
		const slabThicknessAtBase = levelAbove?.slabThicknessAtBase ?? 0;

		// * 1000 to make the measurement in mm
		return Math.round((topSsl - ssl) * 1000 - slabThicknessAtBase);
	}

	public getLevelBelowHeight(editLevel: EditLevel, selectedLevel: SelectedLevel) {
		// Set the current Level SSL as the top SSL
		const topSsl = editLevel.model.ssl ?? selectedLevel.model.ssl;

		const levelBelow = ElementStructureUtils
			.findLevelBelowGivenLevel(this.elementStructure, selectedLevel.model.id);

		if (!levelBelow) return null;

		const levelBelowSsl = levelBelow.ssl;
		const slabThicknessAtBase = editLevel.model.slabThicknessAtBase ?? selectedLevel.model.slabThicknessAtBase;

		// * 1000 to make the measurement in mm
		return Math.round(((topSsl - levelBelowSsl) * 1000) - slabThicknessAtBase);
	}

	public getValidLevelHeight(editLevel: EditLevel, selectedLevels: SelectedLevel[]) {
		// Find the current level height
		const levelAHeightArray = selectedLevels
			.map(selectedLevel => this.getEditedLevelHeight(editLevel, selectedLevel));

		const levelAminValue = Math.min(...levelAHeightArray);
		const levelAmaxValue = Math.max(...levelAHeightArray);

		return levelAminValue <= 0 ? 0 : levelAmaxValue;
	}

	public getValidLevelBelowHeight(editLevel: EditLevel, selectedLevels: SelectedLevel[]): number | 'No Level Below' {
		// Find the above level height
		const levelBExists = selectedLevels.map(selectedLevel => !!ElementStructureUtils
			.findLevelBelowGivenLevel(this.elementStructure, selectedLevel.model.id));

		if (!levelBExists.find(l => l)) return 'No Level Below';

		const levelBHeightArray = selectedLevels
			.map(selectedLevel => this.getLevelBelowHeight(editLevel, selectedLevel) as number);
		const levelBminValue = Math.min(...levelBHeightArray);
		const levelBmaxValue = Math.max(...levelBHeightArray);

		return levelBminValue <= 0 ? 0 : levelBmaxValue;
	}

	public isLevelHighlighted = (level: Level) => {
		if (this.selectedCells.length > 0) {
			const levels = this.levelsContainingSelectedCells;
			return levels.some(x => x === level.id);
		}
		return this.selectedLevels.some(selectedLevel => {
			return selectedLevel.model.id === level.id;
		});
	};

	public isConfigurationDisabled(editCell: EditCell, selectedCells: SelectedCell[], minWidth: number, minDepth: number, minSlab: number) {
		const invalid: Set<string> = new Set<string>();
		// Check that the current "EditCell" Width and Depth are valid

		if (editCell.model.width && editCell.model.depth) {
			if (editCell.model.width && (editCell.model.width < minWidth)) invalid.add(`User Note: Element width must be equal to or greater than ${minWidth}mm to change design configuration`);
			if (editCell.model.depth && (editCell.model.depth < minDepth)) invalid.add(`User Note: Element depth must be equal to or greater than ${minDepth}mm to change design configuration`);
		} else if (editCell.model.width && (editCell.model.width < minWidth)) { invalid.add(`User Note: Element width must be equal to or greater than ${minWidth}mm to change design configuration`); } else if (editCell.model.depth && (editCell.model.depth < minDepth)) invalid.add(`User Note: Element depth must be equal to or greater than ${minDepth}mm to change design configuration`);

		// Check for each selected Cell that the width and Dpeth are valid
		for (const sel of selectedCells) {
			if (sel.model.width && sel.model.depth) {
				if (sel.model.width && (sel.model.width < minWidth)) { invalid.add(`User Note: Element width must be equal to or greater than ${minWidth}mm to change design configuration`); }
				if (sel.model.depth && (sel.model.depth < minDepth)) invalid.add(`User Note: Element depth must be equal to or greater than ${minDepth}mm to change design configuration`);
			} else if (sel.model.width && (sel.model.width < minWidth)) invalid.add(`User Note: Element width must be equal to or greater than ${minWidth}mm to change design configuration`);
			else if (sel.model.depth && (sel.model.depth < minDepth)) invalid.add(`User Note: Element depth must be equal to or greater than ${minDepth}mm to change design configuration`);
		}
		// Check the slabDepths of each cell
		if (editCell.model.slabThicknessAtBase && editCell.model.slabThicknessAtBase < minSlab) {
			invalid.add(`User Note: Element slab depth at base be equal to or greater than ${minSlab}mm to change design configuration`);
		}
		return [...invalid];
	}

	public getLevelAbove(selectedCells: SelectedCell[]) {
		const levelsAboveArray: {aboveLevel: Level | undefined, cellLevelIds: string}[] = [];
		selectedCells.forEach(selectedCell => {
			const currentLevel = ElementStructureUtils.findTopLevelOfCell(this.elementStructure, selectedCell.model);
			const levelAbove = ElementStructureUtils.findLevelAboveGivenLevel(this.elementStructure, currentLevel.id);
			levelsAboveArray.push({ aboveLevel: levelAbove, cellLevelIds: currentLevel.id });
		});
		return levelsAboveArray;
	}

	@action
	public setSlabThicknessAtTopInitialValue(selectedCell: SelectedCell, restore: boolean) {
		const cellBelow = ElementStructureUtils.findCellBelowGivenCell(this.elementStructure, selectedCell.model);
		const level = ElementStructureUtils.findTopLevelOfCell(this.elementStructure, selectedCell.model);
		if (!restore) {
			if (cellBelow && !cellBelow.slabThicknessAtTop) cellBelow.slabThicknessAtTop = level.slabThicknessAtBase;
		} else if (cellBelow) cellBelow.slabThicknessAtTop = undefined;
	}

	@action clickLevel = (event: React.MouseEvent, clickedLevel: Level) => {
		// If selection is disabled, we don't do anything
		if (this.disableSelection) {
			return;
		}

		// If ctrl is pressed, we add to the current selection
		// Otherwise we start by clearing the selection
		if (!event.ctrlKey) {
			// We don't use the selectOrDeselectItem function here, since it would take n^2 time, because of all the splicing.
			this.selectedLevels.length = 0;
		}

		const selectedListMap = this.getSelectedListMap(SelectionItemType.Level);

		if (!event.shiftKey || !this.lastSelectedLevel) {
			// If shift is not pressed, we simply toggle the current item
			// If there wasn't a previous clicked item to select a box from, we pretend that select wasn't pressed
			this.selectOrDeselectItem({ model: clickedLevel }, selectedListMap, SelectionItemType.Level, SelectionAction.Toggle);

			// We also keep track of the last item selected, so we can use it when shift-selecting in the future
			this.lastSelectedLevel = clickedLevel;
		} else {
			// If shift is pressed, we need to calculate the line of connected items
			let startedSelectingItems: boolean = false;
			const { lastSelectedLevel } = this; // We save it into a separate variable so typescript doesn't complain
			this.elementStructure.levels.forEach(level => {
				if (startedSelectingItems) {
					// We've previously hit either side of the selection area.
					// We keep selecting, and check if we've reached the end of the area
					this.selectOrDeselectItem({ model: level }, selectedListMap, SelectionItemType.Level, SelectionAction.Select);
					if (level.id === clickedLevel.id || level.id === lastSelectedLevel.id) {
						startedSelectingItems = false;
					}
				} else {
					// We check if we hit the collection area. If so, we start selecting
					// If the columns are not the same, we will keep selecting when we get to the next level.
					if (level.id === clickedLevel.id || level.id === lastSelectedLevel.id) {
						this.selectOrDeselectItem({ model: level }, selectedListMap, SelectionItemType.Level, SelectionAction.Select);
						if (clickedLevel.id !== lastSelectedLevel.id) {
							startedSelectingItems = true;
						}
					}
				}
			});
		}

		// After we've selected new cells, we always want to change edit form to show appropriate values
		this.postSelectionSetup(SelectionItemType.Level);
	};

	@action addLevel = (index: number) => {
		// Add a level to the project
		const newLevel = ElementStructureUtils.addLevelToElementStructure(this.elementStructure, {}, index);

		// Regenerate level heights and loads
		ElementStructureUtils.regenerateLevelHeights(this.elementStructure);
		ElementStructureUtils.regenerateColumnLoads(this.elementStructure);

		// Select the new column
		this.selectedLevels.length = 0;
		this.selectedLevels.push({ model: newLevel });
		this.postSelectionSetup(SelectionItemType.Level);

		// And save
		this.afterChange();
	};

	deleteSelectedLevels = () => {
		// We cannot delete the ground level, so first we check if it is selected
		if (this.selectedLevels.some(level => {
			return level.model.groundLevel;
		})) {
			alert('You cannot delete the ground level', 'error');
			return;
		}

		// We cannot delete a level if it contains any approved cells, or if it contains a cell that's been merged into an approved cell
		let containsApproved = false;
		let containsLoadTransfers = false;

		const loadTransferIndex = ElementStructureUtils.generateTransferIndex(this.elementStructure);

		this.selectedLevels.forEach(selectedLevel => {
			this.elementStructure.columnTypes.forEach(columnType => {
				columnType.columns.forEach(column => {
					const cell = this.elementStructure.cells[column.id][selectedLevel.model.id];
					if (cell.approved) {
						containsApproved = true;
					}
					if (ElementStructureUtils.hasAssociatedLoads(cell, loadTransferIndex)) {
						containsLoadTransfers = true;
					}
					if (cell.merged) {
						const mergedCell = ElementStructureUtils.findCellGivenCellIsMergedInto(this.elementStructure, cell);
						if (mergedCell && mergedCell.approved) {
							containsApproved = true;
						}
					}
				});
			});
		});
		if (containsApproved) {
			alert('You cannot delete a level which contains approved elements.', 'error');
			return;
		} if (containsLoadTransfers) {
			alert('You cannot delete a level which contains load transferring elements.', 'error');
			return;
		}

		confirmModal('Please confirm', 'Are you sure you want to delete the selected level(s)?').then(() => {
			this.deleteSelectedLevelsApproved();
		});
	};

	@action deleteSelectedLevelsApproved = () => {
		this.selectedLevels.forEach(level => {
			ElementStructureUtils.deleteLevelFromElementStructure(this.elementStructure, level.model);
		});

		// Regenerate level heights and loads
		ElementStructureUtils.regenerateLevelHeights(this.elementStructure);
		ElementStructureUtils.regenerateColumnLoads(this.elementStructure);

		this.postSelectionSetup(SelectionItemType.None);
		this.afterChange();
	};

	getCellsBelowGivenCells = (selectedCells: SelectedCell[]) => {
		const cells: Cell[] = [];
		selectedCells.forEach(selectedCell => {
			const selectedLevel = ElementStructureUtils.findTopLevelOfCell(this.elementStructure, selectedCell.model);

			this.elementStructure.levels.forEach(level => {
				if (level && selectedLevel.ssl > level.ssl) {
					this.elementStructure.columnTypes.forEach(t => {
						t.columns.forEach(c => {
							const cell = this.elementStructure.cells[c.id][level.id];
							if (!cells.find(c => c === cell) && cell.levelId !== selectedCell.model.levelId) cells.push(cell);
						});
					});
				}
			});
		});
		return cells;
	}

	getLevelIdsAroundLevels = (levels: Level[]): Set<string> => {
		const levelsToRegen = new Set<string>();
		for (const level of levels) {
			const levelAbove = ElementStructureUtils.findLevelAboveGivenLevel(this.elementStructure, level.id);
			const levelBelow = ElementStructureUtils.findLevelBelowGivenLevel(this.elementStructure, level.id);

			levelsToRegen.add(level.id);
			if (levelAbove) {
				levelsToRegen.add(levelAbove.id);
			}
			if (levelBelow) {
				levelsToRegen.add(levelBelow.id);
			}
		}

		return levelsToRegen;
	}

	mapCellsInLevels = (levelIds: Set<string>, func: (cell: Cell) => void) => {
		for (const columnId of Object.keys(this.elementStructure.cells)) {
			for (const levelId of Object.keys(this.elementStructure.cells[columnId])) {
				if (!levelIds.has(levelId)) {
					continue;
				}

				const cell = this.elementStructure.cells[columnId][levelId];
				func(cell);
			}
		}
	}

	cellIterator = (
		elementStructure: ElementStructure,
		func: (columnType: ColumnType,
			column: Column,
			level: Level
		) => any,
	) => {
		for (const columnType of elementStructure.columnTypes) {
			for (const column of columnType.columns) {
				for (const level of elementStructure.levels) {
					func(columnType, column, level);
				}
			}
		}
	}

	minDimensionsForSelectedCells = (): { width: number, depth: number, height: number} => {
		const selectedCellDimensions = this.selectedCells.reduce((acc: Record<string, number[]>, curr) => {
			return {
				width: [...acc.width, ...(curr.model.width ? [curr.model.width] : [])],
				depth: [...acc.depth, ...(curr.model.depth ? [curr.model.depth] : [])],
				height: [...acc.height, ...(this.getValidCellHeight(this.selectedCells, this.editCell) ? [this.getValidCellHeight(this.selectedCells, this.editCell)] : [])],
			};
		}, {
			width: [],
			depth: [],
			height: [],
		});

		return {
			width: Math.min(...selectedCellDimensions.width),
			depth: Math.min(...selectedCellDimensions.depth),
			height: Math.min(...selectedCellDimensions.height),
		};
	}

	determineCorbelPropertiesToSave = (editModel: EditCell, selectedCells: SelectedCell[]): [Record<string, boolean> | null, Corbel | null | undefined] => {
		const editedCorbel = editModel.model.corbel; // when multi selecting, corbel assigned to editModel is a merged Corbel

		const corbels = selectedCells
			.map(sel => sel.model.corbel);

		const originalCombinedCorbel = generateMergedCorbel(corbels);

		if (!editedCorbel && !originalCombinedCorbel) return [null, originalCombinedCorbel];

		const updatedCorbel = editedCorbel || {};

		const corbelKeys = ['width', 'depth', 'height', 'useCellWidth', 'useCellDepth', 'useCellHeight', 'reoRate'];

		return [
			corbelKeys.reduce((acc, key) => {
				return ({
					...acc,
					[key]: !originalCombinedCorbel || (updatedCorbel[key] !== originalCombinedCorbel[key]),
				});
			}, {}),
			originalCombinedCorbel,
		];
	}

	determineVoidPropertiesToSave = (editModel: EditCell, selectedCells: SelectedCell[]): [Record<string, boolean> | null, ElementVoid | null | undefined] => {
		const editedVoid = editModel.model.elementVoid; // when multi selecting, void assigned to editModel is a merged Void

		const elementVoids = selectedCells
			.map(sel => sel.model.elementVoid);

		const originalCombinedVoid = generateMergedVoid(elementVoids);

		if (!editedVoid && !originalCombinedVoid) return [null, originalCombinedVoid];

		const updatedVoid = editedVoid || {};

		const voidKeys = ['width', 'depth', 'height', 'useCellWidth', 'useCellDepth', 'useCellHeight'];

		return [
			voidKeys.reduce((acc, key) => {
				return ({
					...acc,
					[key]: !originalCombinedVoid || (updatedVoid[key] !== originalCombinedVoid[key]),
				});
			}, {}),
			originalCombinedVoid,
		];
	}
}
