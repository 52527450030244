import { ComboboxOption } from '../Views/Components/Combobox/Combobox';
import moment from 'moment';
import { PriceVersionEntity } from '../Models/Entities';

export default function buildPriceVersionList(priceVersions: {[key: string]: PriceVersionEntity} = {}): ComboboxOption<string>[] {
	return Object.keys(priceVersions).map(priceVersionId => {
		const priceVersion = priceVersions[priceVersionId];

		const versionNumber = priceVersion.version.toString().padStart(2, '0');
		const versionDate = moment(priceVersion.created).format('YYYY-MM-DD');
		const display = `Version_${versionNumber}_${versionDate}`;

		return { display, value: priceVersion.id };
	});
}
