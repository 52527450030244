/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
import { observer } from 'mobx-react';
import * as React from 'react';
import {
	Icon, Table, TableHeader, TableRow,
} from 'semantic-ui-react';
import If from '../If/If';
import { Button, Colors, Display } from '../Button/Button';
import { Combobox } from '../Combobox/Combobox';
import { DisplayType } from '../Models/Enums';
import ProjectEntity from '../../../Models/Entities/ProjectEntity';
import classNames from 'classnames';
import { ProjectTableState } from '../../Tiles/ProjectTile';
import { action, computed, observable } from 'mobx';
import { TextField } from '../TextBox/TextBox';

import LottieIcon from 'Views/Components/LottieIcon/LottieIcon';
import LiveAsset from 'Assets/96223-live.json';

export interface IDashboardProjectTableProps {
	selectProject: (project: ProjectEntity) => void;
	truncateString: (string: string, maxLength: number) => string;
	cleanRedirect: (event: React.MouseEvent, path: string) => void;
	newProject: (e?: React.MouseEvent, folderName?: string) => void;
	urlRoot: string;
	projectList: ProjectEntity[];
	projectFolderList: string[];
	selectedProject?: ProjectEntity;
	resetSelectedProject: () => void,
	setProjectList: (projects: ProjectEntity[]) => void,
	getPaginatedFolders: (pageNumber?: number, searchTerm?: string, pageSize?: number) => void,
	getPaginatedProjects: (pageNumber?: number, selectedFolder?: string, searchTerm?: string, pageSize?: number) => void,
	pageListTotal: number,
	selectedFolder?: string,
	setSelectedFolder: (folder?: string) => void,
	searchTerm: string,
	setSearchTerm: (term: string) => void,
	activeProjectsList: string[]
}

@observer
export default class DashboardProjectTable extends React.Component<IDashboardProjectTableProps> {
	@observable public pageSize: 10 | 20 | 50 = 10;
	@observable public pageNumber = 0;

	@computed
	public get searchTerm() {
		return this.props.searchTerm;
	}

	public set searchTerm(term) {
		this.props.setSearchTerm(term);
	}

	componentDidMount() {
		if (this.props.selectedFolder) {
			this.props.getPaginatedProjects(undefined, this.props.selectedFolder);
		} else {
			this.props.getPaginatedFolders();
		}
	}

	componentDidUpdate(prevProps: Readonly<IDashboardProjectTableProps>) {
		if (prevProps.selectedFolder !== this.props.selectedFolder) {
			this.onAfterChangePaginated();
		}
	}

	@action public onAfterChangePaginated(page: number = 0, searchTerm: string = this.searchTerm) {
		page !== 0 ? this.pageNumber += page : this.pageNumber = page;
		this.props.selectedFolder
			? this.props.getPaginatedProjects(this.pageNumber, this.props.selectedFolder, searchTerm, this.pageSize)
			: this.props.getPaginatedFolders(this.pageNumber, searchTerm, this.pageSize);
	}

	@action public backToFolderView = () => {
		this.props.setSelectedFolder();
		this.onAfterChangePaginated();
		this.props.resetSelectedProject();
	}

	// Row format for folders on the dashboard
	private projectFolderListElement(folder: string) {
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		return (
			<a
				key={`folder-row-${folder.replace(/\s+/g, '-')}`}
				className="hover"
				onClick={action(() => {
					this.pageNumber = 0;
					this.props.setSelectedFolder(folder);
					this.props.getPaginatedProjects(this.pageNumber, folder);
				})}
			>
				<div className="project-folder-rows">
					<>
						<Icon
							className="project-folder-icon"
							name="folder outline"
						/>
						<div className="truncate">{folder}</div>
						<Icon
							className="project-folder-icon-right"
							name="angle right"
						/>
					</>
				</div>
			</a>
		);
	}

	// Row format for projects on the dashboard
	private projectListElement(project: ProjectEntity) {
		return (
			<div className="hover">
				<a
					className={classNames('project-list-item', { selected: project === this.props.selectedProject })}
					onClick={() => this.props.selectProject(project)}
					key={project.id}
					onDoubleClick={e => {
						this.props.selectedProject?.completedWizard
							? this.props.cleanRedirect(e, `${this.props.urlRoot}/${ProjectTableState.Elements}`)
							: this.props.cleanRedirect(e, `/projectwizard/${this.props.selectedProject?.id || ''}`);
					}}
				>
					<p className="project-job-number">{project.jobNumber}</p>
					<div className="share-list">
						{project.getShareList().map(orgId => (
							<div className="organisation-image" key={orgId}>
								<img src={`/api/entity/OrganisationEntity/logo/${orgId}`} alt="organisation id" />
							</div>
						))}
						<p className="project-name truncate">{project.name}</p>
					</div>
					<p className="project-last-modified">{project.modified.toLocaleDateString('en-GB')}</p>
					<If condition={this.props.activeProjectsList.includes(project.id)}>
						<LottieIcon autoplay loop animationData={LiveAsset} />
					</If>
				</a>
			</div>
		);
	}

	render() {
		return (
			<>
				<div className="dashboard-header">
					<h1 className="dashboard-title">
						{ !!this.props.selectedFolder
							? (
								<>
									{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
									<a onClick={this.backToFolderView}>
										<Icon name="angle left" />
									</a>
									{this.props.selectedFolder}
								</>
							)
							: ('Projects')}
					</h1>
					<div className="dashboard-action-bar">
						<TextField
							inputProps={{
								className: 'search-project-list',
							}}
							model={this}
							modelProperty="searchTerm"
							placeholder="Search"
							onAfterChange={e => {
								this.onAfterChangePaginated(0, e.target.value);
							}}
						/>
						<Button
							onClick={() => this.props.newProject(undefined, this.props.selectedFolder)}
							className="new-project-btn"
							display={Display.Solid}
							colors={Colors.Primary}
							icon={{ icon: 'plus', iconPos: 'icon-left' }}
						>
							New project
						</Button>
					</div>
				</div>
				<Table className="project-dashboard-list">
					{/* Header Row for Projects List */}
					<If condition={!!this.props.selectedFolder}>
						<TableHeader>
							<div className="project-list-header">
								<h5 className="project-job-number">Job No.</h5>
								<h5 className="project-name">Job Name</h5>
								<h5 className="project-last-modified">Last Modified</h5>
							</div>
						</TableHeader>
					</If>
					{/* Table Contents */}
					{
						!!this.props.selectedFolder
							// Project Name Search
							? this.props.projectList
								.map(project => {
									return (
										<TableRow className="project-list-row">
											{this.projectListElement(project)}
										</TableRow>
									);
								})
							: this.props.projectFolderList
								.map(folder => {
									return (
										<TableRow className="project-list-row">
											{
												this.projectFolderListElement(folder)
											}
										</TableRow>
									);
								})
					}
				</Table>
				<div className="project-list-bottom-toolbar">
					<div className="project-list-button-group">
						{/* Previous Page Button */}
						<Button
							disabled={this.pageNumber - 1 < 0}
							onClick={() => { this.onAfterChangePaginated(-1); }}
						>
							<Icon name="angle left" />
						</Button>
						{/* Current Page Calculations */}
						<span className="project-list-page-readout">{
							`${this.pageNumber + 1}/${Math.ceil(this.props.pageListTotal !== 0 ? this.props.pageListTotal / this.pageSize : 1)}`
						}
						</span>
						{/* Next Page Button */}
						<Button
							disabled={((this.pageNumber + 1) * this.pageSize >= this.props.pageListTotal)}
							onClick={() => { this.onAfterChangePaginated(1); }}
						>
							<Icon name="angle right" />
						</Button>
					</div>
					<div className="project-list-page-size">
						<Combobox
							displayType={DisplayType.INLINE}
							model={this}
							modelProperty="pageSize"
							label="Projects per page"
							labelVisible
							isClearable={false}
							placeholder={`Current Page Size: ${this.pageSize}`}
							options={[
								{ display: '10', value: 10 },
								{ display: '20', value: 20 },
								{ display: '50', value: 50 },
							]}
							onAfterChange={() => this.onAfterChangePaginated(0)}
						/>
					</div>
				</div>
			</>
		);
	}
}
