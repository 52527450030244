import { Level } from 'Util/ElementStructureUtils';
import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

export interface ILevelViewProps {
	level: Level;
	levelHighlighted: boolean;
	onClick: (event: React.MouseEvent) => void;
	clickAddLevel: (index: number) => void;
	levelIndex: number;
	readonly?: boolean;
	temporaryWorks?: boolean;
}

@observer
export default class LevelView extends React.Component<ILevelViewProps> {
	public render() {
		const { temporaryWorks } = this.props;
		const className: string[] = ['level', !!temporaryWorks ? 'temp-works' : ''];

		if (this.props.levelHighlighted) {
			className.push('selected');
		}
		if (this.props.level.groundLevel) {
			className.push('ground');
		}

		return (
			<div className="level-header-wrap">
				{!this.props.readonly && !this.props.temporaryWorks
					? (
						<div className="add-level before">
							<div className="add-btn" onClick={() => this.props.clickAddLevel(this.props.levelIndex)}>+</div>
						</div>
					)
					: null}
				<div className={classNames(...className)}>
					<div className="content" onClick={this.props.onClick}>
						<div className="level-name">{this.props.level.name}</div>
						<div className="level-rl">{this.props.level.ssl.toFixed(3)}</div>
					</div>
				</div>
				{!this.props.readonly && !this.props.temporaryWorks
					? (
						<div className="add-level after">
							<div className="add-btn" onClick={() => this.props.clickAddLevel(this.props.levelIndex + 1)}>+</div>
						</div>
					)
					: null}
			</div>
		);
	}
}
