import * as React from 'react';
import { ProjectTableState } from './ProjectTile';
import { observer } from 'mobx-react';
import ProjectEntity from '../../Models/Entities/ProjectEntity';

export interface TempWorksOverlayProps{
	project: ProjectEntity;
	tableState: ProjectTableState;
}

@observer
export default class ProjectTileTempWorksOverlay extends React.Component<TempWorksOverlayProps> {
	render() {
		return (
			<div className="temporary-works-overlay">
				<p className="temp-works-text">{'Wind Region: '}
					<span className="temp-works-data">
						{this.props.project.parsedElementStructure.info.temporaryWorks.windRegion}
					</span>
				</p>
				<p className="temp-works-text">{'Terrain Category: '}
					<span className="temp-works-data">
						{this.props.project.parsedElementStructure.info.temporaryWorks.terrainCategory}
					</span>
				</p>
				<p className="temp-works-text">{'Project Ground Level RL(m): '}
					<span className="temp-works-data">
						{this.props.project.parsedElementStructure.info.temporaryWorks.groundLevelRL}
					</span>
				</p>
			</div>
		);
	}
}
