import { Column } from 'Util/ElementStructureUtils';
import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

export interface IColumnViewProps {
	column: Column;
	columnTypeName: string;
	columnHighlighted: boolean;
	onClick: (event: React.MouseEvent) => void;
	clickAddColumn: (index: number) => void;
	columnIndex: number;
	readonly?: boolean;
	temporaryWorks?: boolean;
	onRightClick: (event: React.MouseEvent) => void;
}

@observer
export default class ColumnView extends React.Component<IColumnViewProps> {
	public render() {
		const {
			onRightClick, temporaryWorks, columnHighlighted, readonly, onClick, columnTypeName, column,
		} = this.props;
		const className: string[] = ['column', !!temporaryWorks ? 'temp-works' : ''];
		if (columnHighlighted) {
			className.push('selected');
		}

		return (
			<>
				<div className={classNames(...className)} onContextMenu={onRightClick}>
					<div className="content" onClick={onClick}> {columnTypeName + column.name}</div>
				</div>
				{!readonly && !temporaryWorks ? this.renderAddColumnButtons() : null}
			</>
		);
	}

	private renderAddColumnButtons = () => {
		const {
			clickAddColumn, columnIndex,
		} = this.props;
		return (
			<>
				<div className="add-column before">
					<div className="add-btn" onClick={() => clickAddColumn(columnIndex)}>+</div>
				</div>
				<div className="add-column after">
					<div className="add-btn" onClick={() => clickAddColumn(columnIndex + 1)}>+</div>
				</div>
			</>
		);
	}
}
