import * as React from 'react';
import type { EditCell } from 'Util/SelectionUtils';
import { observer } from 'mobx-react';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import classNames from 'classnames';
import CellPartTypeEditView from 'Views/Components/ElementGrid/CellPartTypeEditView';

export interface ICellPartsEditViewProps {
	editCell: EditCell;
	cellIDs: string[];
	saveChanges: () => void;
	cancelSelection: () => void;
	readonly?: boolean;
}

// Duplicate of the PricingPartList interface, except every subfield is optional
// When editing the parts list, if multiple cells are selected, any differing fields will be null
export interface EditPricingPartList {
	bars?: EditPricingBar;
	innerCouplers?: EditPricingPart;
	outerCouplers?: EditPricingPart;
	extensions?: EditPricingPart;
	insituStarterInnerCouplers?: EditPricingPart;
	insituStarterExtensions?: EditPricingPart;
	starterBars?: EditPricingStarterBar;
	insituStarterBars?: EditPricingStarterBar;
	anchorHeads?: EditPricingPart;
}

export interface EditPricingBar {
	barName?: string;
	length?: number;
	quantity?: number;
	basePrice?: number;
	reoPrice?: number;
	combinedPrice?: number;
}
export interface EditPricingStarterBar {
	partName?: string;
	length?: number;
	quantity?: number;
	price?: number;
}
export interface EditPricingPart {
	partName?: string;
	quantity?: number;
	price?: number;
}

@observer
export default class CellPartsEditView extends React.Component<ICellPartsEditViewProps> {
	public render() {
		const {
			editCell, cancelSelection, saveChanges, readonly,
		} = this.props;

		const partTypeOrder = [
			'insituStarterBars',
			'insituStarterInnerCouplers',
			'insituStarterExtensions',
			'outerCouplers',
			'bars',
			'starterBars',
			'innerCouplers',
			'extensions',
			'anchorHeads',
		];

		return (
			<>
				<div className="edit-view-header cell">
					<h4 className="edit-view-title">Edit parts list</h4>
					<button onClick={cancelSelection} className="close-edit-view-btn" aria-label="Close" />
				</div>
				<div className="edit-view-tab-content">
					<Checkbox
						className="override-parts"
						model={editCell.model}
						modelProperty="overrideParts"
						label="Override calculated parts list"
						tooltip="If the element is located at a slab set down or in an area where the slab steps up, tick this check-box. You can then manually enter the SSL at the base of this element, without effecting the SSL of any other elements on this level. This will affect the height of the element below. For that reason, you cannot edit this if the element below has been approved."
						inputProps={{ onBlur: saveChanges }}
						isDisabled={readonly}
					/>

					<p className={classNames('override-parts-note', 'icon-left', 'icon-warning')}>
						Be careful! If you select to override calculated parts list for an element, the parts list will no longer update if you edit any element parameters and rebuild the project. A rebuild may change the design for the element and the required parts. If you change any element parameters you should untick Override calculated parts list for the edited elements before rebuilding the project.
					</p>

					{partTypeOrder.map(partType => editCell.info.parts && editCell.info.parts[partType]
						? (
							<CellPartTypeEditView
								key={partType}
								partTypeName={partType}
								partTypeObject={editCell.info.parts[partType]}
								saveChanges={saveChanges}
								readonly={readonly || editCell.model.overrideParts !== true}
							/>
						)
						: null)}
				</div>
			</>
		);
	}
}
