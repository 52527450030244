import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { FormEvent } from 'react';
import axios from 'axios';
import alert from 'Util/ToastifyUtils';
import { action, observable, runInAction } from 'mobx';
import OrganisationEntity from 'Models/Entities/OrganisationEntity';
import { Combobox } from 'Views/Components/Combobox/Combobox';

interface FormData {
	organisationId?: string,
}

@observer
export default class UploadOrganisationLogoForm extends React.Component<RouteComponentProps> {
	@observable formData: FormData = {
		organisationId: undefined,
	};

	@observable organisationList: OrganisationEntity[];

	@action
	public componentDidMount(): void {
		OrganisationEntity.fetchOrganisations().then(results => {
			runInAction(() => {
				this.organisationList = results;
			});
		}).catch(e => {
		});
	}

	public render() {
		if (!this.organisationList) {
			return null;
		}

		return (
			<>
				<div className="upload-parts-container">
					<form className="form" onSubmit={this.onSubmit}>
						<h1>Upload Organisation Logo</h1>
						<p>Upload a an image file containing the logo for your organisation. The image will be
							resized to a standard size, then used to represent this organisation.
						</p>

						<Combobox
							className="organisation"
							model={this.formData}
							modelProperty="organisationId"
							label="Organisation"
							options={this.getOrganisationDropdownValues(this.organisationList)}
							searchable={false}
						/>

						<input
							type="file"
							id="organisation-logo-input"
							required
						/>
						<button type="submit">Submit</button>
					</form>
				</div>
			</>

		);
	}

	protected getOrganisationDropdownValues = (organisationList: OrganisationEntity[]) => {
		return organisationList.map(org => {
			return {
				display: org.name,
				value: org.id,
			};
		});
	};

	protected onSubmit = async (e: FormEvent) => {
		e.preventDefault();

		const formData = new FormData();
		const logo: HTMLInputElement | null = document.querySelector('#organisation-logo-input');
		if (!this.formData.organisationId) {
			alert('Please select an organisation to upload a logo for', 'error');
		} else if (!logo || !logo.files) {
			alert('Please select a file to upload', 'error');
		} else {
			formData.append('organisationId', this.formData.organisationId);
			formData.append('logo', logo.files[0]);
			axios.post('/api/entity/OrganisationEntity/upload-logo', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
				.then(() => {
					alert('Organisation logo file successfully uploaded', 'success');
				})
				.catch(() => {
					alert('Error uploading organisation logo. Please reload page and try again.', 'error');
				});
		}
	};
}
