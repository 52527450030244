import * as React from 'react';
import ProjectEntity from 'Models/Entities/ProjectEntity';
import { observer } from 'mobx-react';

export interface WizardStepComponentProps {
	project: ProjectEntity;
	projectCanBeEdited?: boolean;
}

@observer
export default class WizardStep extends React.Component<WizardStepComponentProps> {
	// empty functions, intended to be overwritten
	public static validateStep = (project: ProjectEntity) => {
		return true;
	};

	public static afterStep = async (project: ProjectEntity) => {
	};
}
