// Helper function if we need to do a map in reverse order
// Taken from https://stackoverflow.com/questions/36415904/is-there-a-way-to-use-map-on-an-array-in-reverse-order-with-javascript
// export function mapReverse(array: Array<any>, fn: Function) {
// return array.reduceRight((result: Array<any>, el: any) => {
// result.push(fn(el));
// return result;
// }, []);
// }

export function mapReverse<T, R>(array: Array<T>, fn: (element: T) => R): Array<R> {
	return array.reduceRight((result: Array<R>, element: T) => {
		result.push(fn(element));
		return result;
	}, []);
}

// Divide by a number, while avoiding divide by 0 errors
export function safeDivide(dividend: number, divisor: number) {
	if (divisor === 0) {
		return 0;
	}

	return dividend / divisor;
}

// checks against both null and undefined, since it's a pain to check both all the time
// This is specifically for those cases where 0 might be a valid number, so we don't want to just check truthiness
export function notNull(variable: any) {
	return variable !== null && variable !== undefined;
}

// Given two (possibly nested) objects, return all fields with matching fields (and set them to undefined if the field data doesn't equal each other)
// Doesn't handle arrays or anything too fancy
export function mergeObjectsKeepingLikeFields(one: any, two: any): any {
	// Check if one and two are both objects
	if (typeof one === 'object' && one !== null && typeof two === 'object' && two !== null) {
		const returnObj = {} as any;

		for (const key of Object.keys(one)) {
			if (key in two) {
				returnObj[key] = mergeObjectsKeepingLikeFields(one[key], two[key]);
			}
		}

		return returnObj;
	}

	// If one and two are not two objects, we only return them if they equal each other
	if (one === two) {
		return one;
	}
	return undefined;
}

// Given two objects, merge them into one, preferring the first object's fields if they are different
export function mergeObjectsKeepingAllFields(one: any, two: any): any {
	// Check if one and two are both objects
	if (typeof one === 'object' && one !== null && typeof two === 'object' && two !== null) {
		const returnObj = {} as any;

		// Loop through one, and assign all fields
		for (const key of Object.keys(one)) {
			if (key in two) {
				returnObj[key] = mergeObjectsKeepingAllFields(one[key], two[key]);
			} else {
				returnObj[key] = one[key];
			}
		}

		// check for any fields in two which weren't in one
		for (const key of Object.keys(two)) {
			if (!(key in one)) {
				returnObj[key] = two[key];
			}
		}

		return returnObj;
	}

	// They're not objects, so we take one's data, unless it is null or undefined
	if (notNull(one)) {
		return one;
	}
	return two;
}

// Just to make code slightly cleaner
export function cloneObject(object: any): any {
	if (object === undefined) {
		return undefined;
	}
	if (object === null) {
		return null;
	}

	return JSON.parse(JSON.stringify(object));
}
