import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { action, observable, runInAction } from 'mobx';
import { store } from 'Models/Store';
import ProjectEntity from 'Models/Entities/ProjectEntity';
import { ElementStructureUtils } from 'Util/ElementStructureUtils';
import alert from 'Util/ToastifyUtils';
import { gql } from '@apollo/client';
import ProjectWizard from 'Views/Components/ProjectWizard/ProjectWizard';
import ProjectLockoutHelper from 'Util/ProjectLockoutHelper';
import { PriceVersionEntity } from '../../../Models/Entities';

export interface ProjectWizardLoaderProps extends RouteComponentProps {
	projectId?: string;
	organisationId?: string;
	defaultFolder?: string;
}

@observer
export default class ProjectWizardLoader extends React.Component<ProjectWizardLoaderProps> {
	@observable
	public project: ProjectEntity;

	@observable
	private requestState: 'loading' | 'error' | 'done' = 'loading';

	@observable
	private lockoutHelper?: ProjectLockoutHelper.Single;

	public componentDidMount(): void {
		if (this.props.projectId) {
			ProjectEntity.fetch<ProjectEntity>({ args: [[{ path: 'id', comparison: 'equal', value: this.props.projectId }]] })
				.then(d => {
					this.changeRequestState('done', d[0]);
				})
				.catch(e => {
					this.changeRequestState('error');
				});
		} else if (this.props.organisationId) {
			store.apolloClient
				.mutate({
					mutation: gql`
						mutation createProject($projects: [ProjectEntityInput]) {
							createProjectEntity(projectEntitys: $projects, mergeReferences: []) {
								id
								name
								folder
								elementStructure
								organisationId
								lastChangedById
								projectOwnerId
								deleted
								jobNumber
								priceVersionId
							}
						}`,
					variables: {
						projects: [{
							name: 'New Project',
							folder: this.props.defaultFolder,
							elementStructure: JSON.stringify(ElementStructureUtils.getEmptyElementStructure()),
							organisationId: this.props.organisationId,
							lastChangedById: store.userId,
							projectOwnerId: store.userId,
							priceVersionId: this.findLatestPriceVersion().id,
						}],
					},
				})
				.then(results => {
					const newProject = new ProjectEntity(results.data.createProjectEntity[0]);
					console.log('the project:', newProject);
					store.routerHistory.push(`/projectwizard/${newProject.id}`);
					this.changeRequestState('done', newProject);
				});
		} else {
			// We have neither an organisation nor a project set, so we return to the dashboard
			store.routerHistory.push('/');
		}
	}

	componentWillUnmount() {
		runInAction(() => {
			if (this.lockoutHelper) {
				this.lockoutHelper!.cancel();
			}
		});
	}

	@action
	private changeRequestState = (state: 'loading' | 'error' | 'done', project?: ProjectEntity) => {
		// If we couldn't load an in-progress wizard, we return to the dashbaord
		if (state === 'error') {
			this.returnToDashboard();
			return;
		}

		if (project) {
			this.project = project;
			this.lockoutHelper = new ProjectLockoutHelper.Single(project.id);
		}

		// set the state, and show the page
		this.requestState = state;
	};

	private returnToDashboard = () => {
		store.routerHistory.push('/dashboard');
		alert('Unable to find progress for project wizard. Please try again, or contact us for help.', 'error');
	};

	public render() {
		if (this.requestState === 'loading' || !this.lockoutHelper) {
			return <></>;
		} if (!this.project) {
			this.returnToDashboard();
			return <></>;
		}
		return <ProjectWizard {...this.props} project={this.project} lockoutHelper={this.lockoutHelper} />;
	}

	private findLatestPriceVersion(): PriceVersionEntity {
		store.apolloClient
			.query({
				query: gql`
				query getPriceVersion {
					priceVersionEntitys(orderBy: {path: "created", descending: true}, take: 1) {
						id
						created
						modified
						version
					}
				}`,
			})
			.then(results => {
				return new PriceVersionEntity(results.data.priceVersionEntitys[0]);
			}).catch(() => {
				this.changeRequestState('error');
			});

		// There will always be a price version in the DB because of the migration so this should never be triggered
		return new PriceVersionEntity();
	}
}
