import React, { memo } from 'react';
import type { LoadTransfer, ElementStructure, Cell } from 'Util/ElementStructureUtils';

interface LoadLineProps {
    columnTypeIndex: number;
    columnIndex: number;
    levelIndex: number;
    elementStructure: ElementStructure;
    loadTransfers: LoadTransfer[];
    cell: Cell;
    onLineClick: (event: React.MouseEvent, cell: Cell) => any;
}

export default memo(({
	columnTypeIndex,
	columnIndex,
	levelIndex,
	elementStructure,
	loadTransfers,
	onLineClick,
	cell,
}: LoadLineProps) => {
	// @ts-ignore
	const targetCells = [] as any[];
	const columnTypeSums = elementStructure.columnTypes.map(ct => ct.columns.length);

	loadTransfers!.forEach((lt: { receivingId: any; }) => {
		elementStructure.columnTypes.forEach((colType: { columns: any[]; }, colTypeIndex: any) => {
			colType.columns.forEach((col, colIndex) => {
				// @ts-ignore
				elementStructure.levels.forEach((l, lIndex) => {
					const currentCell = elementStructure.cells[col.id][l.id];
					if (currentCell.id === lt.receivingId) {
						targetCells.push({
							cell: currentCell,
							levelIndex: lIndex,
							colIndex: colIndex,
							colTypeIndex,
							traversedColumnTypes: 0,
						});
					}
				});
			});
		});
	});

	// apply transformations to target cell indexes to account for different column types
	targetCells.forEach(tc => {
		if (tc.colTypeIndex !== columnTypeIndex) {
			// offset left
			if (tc.colTypeIndex < columnTypeIndex) {
				for (let i = tc.colTypeIndex; i < columnTypeIndex; i++) {
					tc.colIndex -= columnTypeSums[i];
					tc.traversedColumnTypes -= 1;
				}
			}

			// offset right
			if (tc.colTypeIndex > columnTypeIndex) {
				for (let i = columnTypeIndex; i < tc.colTypeIndex; i++) {
					tc.colIndex += columnTypeSums[i];
					tc.traversedColumnTypes += 1;
				}
			}
		}
	});

	// get triangle dimensions
	const cellDimenions = {
		x: 191,
		y: 111,
	};

	// offset triangle point of origin
	const offsetFromSourceCell = 96;

	// reduce height of triangle slightly to target top of cell
	const offsetFromTargetCell = 60;

	// gap between column types
	const colTypeGap = 8;

	const coords = targetCells.reduce((acc, curr) => {
		// triangle height
		const a = ((curr.levelIndex - levelIndex) * cellDimenions.y - offsetFromTargetCell);

		// triangle width
		const b = (((curr.colIndex - columnIndex) * cellDimenions.x) + ((curr.traversedColumnTypes || 0) * colTypeGap));

		// triangle hypotenuse
		const c = Math.sqrt((a ** 2) + (b ** 2));

		const angle = Math.asin(b / c);

		const final = {
			a,
			b,
			c,
			length: c,
			angle: ((180 * angle) / Math.PI),
		};

		return [
			...acc,
			final,
		];
	}, []);

	return (
		<>
			{coords.map((coord: any) => {
				return (
					<div
						style={{
							top: `${(coord.a / 2) + offsetFromSourceCell}px`,
							left: `${-(coord.c / 2) + (coord.b / 2) + (cellDimenions.x / 2)}px`,
							width: `${coord.length}px`,
							transform: `rotate(${90 - coord.angle}deg)`,
						}}
						className="load-line"
						onClick={e => onLineClick(e, cell)}
					/>
				);
			})}
		</>
	);
});
