import { observer } from 'mobx-react';
import * as React from 'react';
import Lottie from 'react-lottie';

interface ILottieIconProps{
	loop?: boolean;
	autoplay?: boolean;
	animationData: any;
	width?: number;
	height?: number;
}

@observer
export default class LottieIcon extends React.Component<ILottieIconProps> {
	render() {
		const Options = {
			loop: this.props.loop ?? false,
			autoplay: this.props.autoplay ?? false,
			animationData: this.props.animationData,
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid slice',
			},
		};
		return (
			<Lottie options={Options} width={this.props.width} height={this.props.height} />
		);
	}
}
