import { AxialLoadsData } from 'Util/ElementStructureUtils';

export default abstract class DataImportUtils {
	public static parseCsvStringAsAxialLoads(axialLoadsString: string): AxialLoadsData[] {
		const axialLoadsArray: AxialLoadsData[] = [];

		// split the string on newlines first
		const csvRows = axialLoadsString.split(/\r\n|\r|\n/);
		csvRows.forEach((csvRow, index) => {
			// The first row is likely a header row, so we can skip it
			if (index === 0 && csvRow.includes('Element Number')) {
				return;
			}

			// For each line, split into the five fields. If there's not five fields, something went wrong
			const csvFields = csvRow.split(',');
			if (csvFields.length !== 5) {
				return;
			}

			axialLoadsArray.push({
				levelName: csvFields[0],
				columnName: csvFields[1],
				load: parseFloat(csvFields[2]),
				majorAxisMoment: parseFloat(csvFields[3]),
				minorAxisMoment: parseFloat(csvFields[4]),
			});
		});

		return axialLoadsArray;
	}
}
