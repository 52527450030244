/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsProjectEntity from 'Models/Security/Acl/VisitorsProjectEntity';
import UserProjectEntity from 'Models/Security/Acl/UserProjectEntity';
import AdminProjectEntity from 'Models/Security/Acl/AdminProjectEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { runInAction } from 'mobx';
import axios from 'axios';
import { throttle } from 'lodash';
import { gql } from '@apollo/client';

import { store } from 'Models/Store';
import { SERVER_URL } from 'Constants';

import type { ElementStructure, Cell } from 'Util/ElementStructureUtils';
import { ElementStructureUtils } from 'Util/ElementStructureUtils';
import alert from 'Util/ToastifyUtils';
import { buildUrl } from 'Util/FetchUtils';
// % protected region % [Add any further imports here] end

export interface IProjectEntityAttributes extends IModelAttributes {
	name: string;
	jobNumber: string;
	postcode: string;
	suburb: string;
	country: string;
	elementStructure: string;
	completedWizard: boolean;
	deleted: boolean;
	pipedriveDealID: number;
	elementStructureSchemaVersion: number;
	notes: string;
	clientCompany: string;
	clientFirstName: string;
	clientLastName: string;
	clientAddressLine1: string;
	clientAddressLine2: string;
	clientPhone: string;
	clientEmail: string;
	folder: string;

	lastChangedById?: string;
	lastChangedBy?: Models.UserEntity | Models.IUserEntityAttributes;
	projectOwnerId?: string;
	projectOwner?: Models.UserEntity | Models.IUserEntityAttributes;
	pendingConnectionss: Array<
		| Models.PendingConnectionsEntity
		| Models.IPendingConnectionsEntityAttributes
	>;
	projectRevisions: Array<
		| Models.ProjectRevisionEntity
		| Models.IProjectRevisionEntityAttributes
	>;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	priceVersionId?: string;
	priceVersion?: Models.PriceVersionEntity | Models.IPriceVersionEntityAttributes;
	organisationss: Array<
		| Models.OrganisationsSharedProjects
		| Models.IOrganisationsSharedProjectsAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] on begin
// Constants for prebuild validation
export const prebuildValConsts = {
	slabMinAptus: 160,
	slabMinNonAptus: 20,
	slabMax: 2000,
	minDepthAptus: 175,
	minDepthNonAptus: 100,
	maxDepth: 5000,
	minWidthAptus: 300,
	minWidthNonAptus: 100,
	maxWidth: 10000,
	maxHeight: 20000,
	minHeight: 500,
	minKFactor: 0.7,
	maxKFactor: 2.2,
};

@entity('ProjectEntity', 'Project')
// % protected region % [Customise your entity metadata here] end
export default class ProjectEntity extends Model
	implements IProjectEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsProjectEntity(),
		new UserProjectEntity(),
		new AdminProjectEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * Name
	 */
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Job Number'] off begin
	/**
	 * Job Number
	 */
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Job Number',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public jobNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Job Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Postcode'] off begin
	/**
	 * Postcode
	 */
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Postcode',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public postcode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Postcode'] end

	// % protected region % [Modify props to the crud options here for attribute 'Suburb'] off begin
	/**
	 * Suburb
	 */
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Suburb',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public suburb: string;
	// % protected region % [Modify props to the crud options here for attribute 'Suburb'] end

	// % protected region % [Modify props to the crud options here for attribute 'Country'] off begin
	/**
	 * Country
	 */
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Country',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public country: string;
	// % protected region % [Modify props to the crud options here for attribute 'Country'] end

	// % protected region % [Modify props to the crud options here for attribute 'Element Structure'] off begin
	/**
	 * Element Structure
	 */
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Element Structure',
		displayType: 'textarea',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public elementStructure: string;
	// % protected region % [Modify props to the crud options here for attribute 'Element Structure'] end

	// % protected region % [Modify props to the crud options here for attribute 'Completed Wizard'] off begin
	@observable
	@attribute<ProjectEntity, boolean>()
	@CRUD({
		name: 'Completed Wizard',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public completedWizard: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Completed Wizard'] end

	// % protected region % [Modify props to the crud options here for attribute 'Deleted'] off begin
	/**
	 * Deleted
	 */
	@observable
	@attribute<ProjectEntity, boolean>()
	@CRUD({
		name: 'Deleted',
		displayType: 'checkbox',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public deleted: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Deleted'] end

	// % protected region % [Modify props to the crud options here for attribute 'Pipedrive Deal ID'] off begin
	/**
	 * Pipedrive Deal ID
	 */
	@Validators.Integer()
	@observable
	@attribute<ProjectEntity, number>()
	@CRUD({
		name: 'Pipedrive Deal ID',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public pipedriveDealID: number;
	// % protected region % [Modify props to the crud options here for attribute 'Pipedrive Deal ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Element Structure Schema Version'] off begin
	/**
	 * Element Structure Schema Version
	 */
	@Validators.Integer()
	@observable
	@attribute<ProjectEntity, number>()
	@CRUD({
		name: 'Element Structure Schema Version',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public elementStructureSchemaVersion: number;
	// % protected region % [Modify props to the crud options here for attribute 'Element Structure Schema Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Notes'] off begin
	/**
	 * Notes
	 */
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Notes',
		displayType: 'textarea',
		order: 110,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public notes: string;
	// % protected region % [Modify props to the crud options here for attribute 'Notes'] end

	// % protected region % [Modify props to the crud options here for attribute 'Client Company'] off begin
	/**
	 * Client Company
	 */
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Client Company',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public clientCompany: string;
	// % protected region % [Modify props to the crud options here for attribute 'Client Company'] end

	// % protected region % [Modify props to the crud options here for attribute 'Client First Name'] off begin
	/**
	 * Client First Name
	 */
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Client First Name',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public clientFirstName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Client First Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Client Last Name'] off begin
	/**
	 * Client Last Name
	 */
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Client Last Name',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public clientLastName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Client Last Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Client Address Line 1'] off begin
	/**
	 * Client Address Line 1
	 */
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Client Address Line 1',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public clientAddressLine1: string;
	// % protected region % [Modify props to the crud options here for attribute 'Client Address Line 1'] end

	// % protected region % [Modify props to the crud options here for attribute 'Client Address Line 2'] off begin
	/**
	 * Client Address Line 2
	 */
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Client Address Line 2',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public clientAddressLine2: string;
	// % protected region % [Modify props to the crud options here for attribute 'Client Address Line 2'] end

	// % protected region % [Modify props to the crud options here for attribute 'Client Phone'] off begin
	/**
	 * Client Phone
	 */
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Client Phone',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public clientPhone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Client Phone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Client Email'] off begin
	/**
	 * Client Email
	 */
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Client Email',
		displayType: 'textfield',
		order: 180,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public clientEmail: string;
	// % protected region % [Modify props to the crud options here for attribute 'Client Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Folder'] off begin
	@observable
	@attribute<ProjectEntity, string>()
	@CRUD({
		name: 'Folder',
		displayType: 'textfield',
		order: 190,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public folder: string;
	// % protected region % [Modify props to the crud options here for attribute 'Folder'] end

	/**
	 * Last Changed Projects
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Last Changed By'] off begin
		name: 'Last Changed By',
		displayType: 'reference-combobox',
		order: 200,
		referenceTypeFunc: () => Models.UserEntity,
		// % protected region % [Modify props to the crud options here for reference 'Last Changed By'] end
	})
	public lastChangedById?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public lastChangedBy: Models.UserEntity;

	/**
	 * Owned Projects
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Project Owner'] off begin
		name: 'Project Owner',
		displayType: 'reference-combobox',
		order: 210,
		referenceTypeFunc: () => Models.UserEntity,
		// % protected region % [Modify props to the crud options here for reference 'Project Owner'] end
	})
	public projectOwnerId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public projectOwner: Models.UserEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Pending Connections'] off begin
		name: 'Pending Connectionss',
		displayType: 'reference-multicombobox',
		order: 220,
		referenceTypeFunc: () => Models.PendingConnectionsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'pendingConnectionss',
			oppositeEntity: () => Models.PendingConnectionsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Pending Connections'] end
	})
	public pendingConnectionss: Models.PendingConnectionsEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Project Revision'] off begin
		name: 'Project Revisions',
		displayType: 'reference-multicombobox',
		order: 230,
		referenceTypeFunc: () => Models.ProjectRevisionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'projectRevisions',
			oppositeEntity: () => Models.ProjectRevisionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Project Revision'] end
	})
	public projectRevisions: Models.ProjectRevisionEntity[] = [];

	/**
	 * Projects
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 240,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	/**
	 * A project must have a price version
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Price Version'] off begin
		name: 'Price Version',
		displayType: 'reference-combobox',
		order: 250,
		referenceTypeFunc: () => Models.PriceVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Price Version'] end
	})
	public priceVersionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public priceVersion: Models.PriceVersionEntity;

	/**
	 * Shared Projects
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisations'] off begin
		name: 'Organisations',
		displayType: 'reference-multicombobox',
		order: 260,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.OrganisationsSharedProjects,
		optionEqualFunc: makeJoinEqualsFunc('organisationsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'projectEntity',
			oppositeEntityName: 'organisationEntity',
			relationName: 'sharedProjects',
			relationOppositeName: 'organisations',
			entity: () => Models.ProjectEntity,
			joinEntity: () => Models.OrganisationsSharedProjects,
			oppositeEntity: () => Models.OrganisationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Organisations'] end
	})
	public organisationss: Models.OrganisationsSharedProjects[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	/**
	 * Element Structure
	 *
	 * AFTER THE CONSTRUCTOR, ONLY EVER UPDATE THE PROPERTIES ON THIS OBJECT!
	 * NEVER reassigned the parsedElementStructure itself or super confusing things will happen!
	 *
	 * It is likely somewhere in the code, references have been mishandled and setting this observable to a different
	 * object results in UI referencing the old + orphaned element structure. MobX state change propagation will break down
	 * and some things update, some won't.
	 */
	@observable
	public parsedElementStructure: ElementStructure;

	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IProjectEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] on begin
		// After we get the data from the backend, we want to parse the JSON element structure
		try {
			this.parsedElementStructure = JSON.parse(this.elementStructure);
		} catch {
			this.parsedElementStructure = ElementStructureUtils.getEmptyElementStructure();
		}

		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IProjectEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.jobNumber !== undefined) {
				this.jobNumber = attributes.jobNumber;
			}
			if (attributes.postcode !== undefined) {
				this.postcode = attributes.postcode;
			}
			if (attributes.suburb !== undefined) {
				this.suburb = attributes.suburb;
			}
			if (attributes.country !== undefined) {
				this.country = attributes.country;
			}
			if (attributes.elementStructure !== undefined) {
				this.elementStructure = attributes.elementStructure;
			}
			if (attributes.completedWizard !== undefined) {
				this.completedWizard = attributes.completedWizard;
			}
			if (attributes.deleted !== undefined) {
				this.deleted = attributes.deleted;
			}
			if (attributes.pipedriveDealID !== undefined) {
				this.pipedriveDealID = attributes.pipedriveDealID;
			}
			if (attributes.elementStructureSchemaVersion !== undefined) {
				this.elementStructureSchemaVersion = attributes.elementStructureSchemaVersion;
			}
			if (attributes.notes !== undefined) {
				this.notes = attributes.notes;
			}
			if (attributes.clientCompany !== undefined) {
				this.clientCompany = attributes.clientCompany;
			}
			if (attributes.clientFirstName !== undefined) {
				this.clientFirstName = attributes.clientFirstName;
			}
			if (attributes.clientLastName !== undefined) {
				this.clientLastName = attributes.clientLastName;
			}
			if (attributes.clientAddressLine1 !== undefined) {
				this.clientAddressLine1 = attributes.clientAddressLine1;
			}
			if (attributes.clientAddressLine2 !== undefined) {
				this.clientAddressLine2 = attributes.clientAddressLine2;
			}
			if (attributes.clientPhone !== undefined) {
				this.clientPhone = attributes.clientPhone;
			}
			if (attributes.clientEmail !== undefined) {
				this.clientEmail = attributes.clientEmail;
			}
			if (attributes.folder !== undefined) {
				this.folder = attributes.folder;
			}
			if (attributes.lastChangedById !== undefined) {
				this.lastChangedById = attributes.lastChangedById;
			}
			if (attributes.lastChangedBy !== undefined) {
				if (attributes.lastChangedBy === null) {
					this.lastChangedBy = attributes.lastChangedBy;
				} else if (attributes.lastChangedBy instanceof Models.UserEntity) {
					this.lastChangedBy = attributes.lastChangedBy;
					this.lastChangedById = attributes.lastChangedBy.id;
				} else {
					this.lastChangedBy = new Models.UserEntity(attributes.lastChangedBy);
					this.lastChangedById = this.lastChangedBy.id;
				}
			}
			if (attributes.projectOwnerId !== undefined) {
				this.projectOwnerId = attributes.projectOwnerId;
			}
			if (attributes.projectOwner !== undefined) {
				if (attributes.projectOwner === null) {
					this.projectOwner = attributes.projectOwner;
				} else if (attributes.projectOwner instanceof Models.UserEntity) {
					this.projectOwner = attributes.projectOwner;
					this.projectOwnerId = attributes.projectOwner.id;
				} else {
					this.projectOwner = new Models.UserEntity(attributes.projectOwner);
					this.projectOwnerId = this.projectOwner.id;
				}
			}
			if (attributes.pendingConnectionss !== undefined && Array.isArray(attributes.pendingConnectionss)) {
				for (const model of attributes.pendingConnectionss) {
					if (model instanceof Models.PendingConnectionsEntity) {
						this.pendingConnectionss.push(model);
					} else {
						this.pendingConnectionss.push(new Models.PendingConnectionsEntity(model));
					}
				}
			}
			if (attributes.projectRevisions !== undefined && Array.isArray(attributes.projectRevisions)) {
				for (const model of attributes.projectRevisions) {
					if (model instanceof Models.ProjectRevisionEntity) {
						this.projectRevisions.push(model);
					} else {
						this.projectRevisions.push(new Models.ProjectRevisionEntity(model));
					}
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			if (attributes.priceVersionId !== undefined) {
				this.priceVersionId = attributes.priceVersionId;
			}
			if (attributes.priceVersion !== undefined) {
				if (attributes.priceVersion === null) {
					this.priceVersion = attributes.priceVersion;
				} else if (attributes.priceVersion instanceof Models.PriceVersionEntity) {
					this.priceVersion = attributes.priceVersion;
					this.priceVersionId = attributes.priceVersion.id;
				} else {
					this.priceVersion = new Models.PriceVersionEntity(attributes.priceVersion);
					this.priceVersionId = this.priceVersion.id;
				}
			}
			if (attributes.organisationss !== undefined && Array.isArray(attributes.organisationss)) {
				for (const model of attributes.organisationss) {
					if (model instanceof Models.OrganisationsSharedProjects) {
						this.organisationss.push(model);
					} else {
						this.organisationss.push(new Models.OrganisationsSharedProjects(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		organisationss {
			${Models.OrganisationsSharedProjects.getAttributes().join('\n')}
			organisations {
				${Models.OrganisationEntity.getAttributes().join('\n')}
			}
		}
		lastChangedBy {
			${Models.UserEntity.getAttributes().join('\n')}
		}
		projectOwner {
			${Models.UserEntity.getAttributes().join('\n')}
		}
		pendingConnectionss {
			${Models.PendingConnectionsEntity.getAttributes().join('\n')}
		}
		projectRevisions {
			${Models.ProjectRevisionEntity.getAttributes().join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
		}
		priceVersion {
			${Models.PriceVersionEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			organisationss: {},
			pendingConnectionss: {},
			projectRevisions: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'pendingConnectionss',
							'projectRevisions',
							'organisationss',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	@action
	public beforeSave() {
		// Adding project information to element structure
		this.parsedElementStructure.info.project.postcode = this.postcode;
		this.parsedElementStructure.info.project.suburb = this.suburb;
		this.parsedElementStructure.info.project.country = this.country;
		// Before we save, we want to convert the element structure back into json
		this.elementStructure = JSON.stringify(this.parsedElementStructure);
	}

	public static async fetchWithRevisions() {
		const results = await store.apolloClient
			.query({
				query: gql`
					{
						projectEntitys(where: {path: "deleted", comparison: equal, value: "true", negate: true}) {
							id
							created
							modified
							name
							jobNumber
							postcode
							suburb
							country
							completedWizard
							deleted
							organisationId
							projectOwnerId
							folder
						}
					}`,
				fetchPolicy: 'network-only',
			});
		return results.data.projectEntitys.map((r: any) => new this(r));
	}

	@action
	public static cellPostBuildValidation(cell: Cell) {
		/**
		 * Generate warnings for K factor within required bounds
		 */
		const { maxKFactor } = prebuildValConsts;
		const { minKFactor } = prebuildValConsts;

		const additionalCellWarnings: string[] = [];
		const additionalCellErrors: string[] = [];

		if (
			cell.kFactor && !cell.approved
			&& (cell.aptusDesignConfiguration === 'aptus' || cell.aptusDesignConfiguration === 'custom')
			&& (cell.kFactor < minKFactor || cell.kFactor > maxKFactor)) {
			additionalCellWarnings.push('Effective Length outside allowed range 0.7 to 2.2');
		}

		/**
		 * Validate Error for Non-Aptus Element required Non-Aptus Bars
		 */
		if (cell.aptusDesignConfiguration === 'nonaptus'
			&& !ElementStructureUtils.isInsitu(cell)
			&& !ElementStructureUtils.shouldUseReoRate(cell)
			&& ElementStructureUtils.nonAptusBarQuantity(
				cell.shape,
				cell.nonAptusBarsAlongWidth,
				cell.nonAptusBarsAlongDepth,
				cell.aptusDesignConfiguration,
			) === 0) {
			additionalCellErrors.push('Element requires Non-Aptus Bars');
		}

		// add warnings and errors to cell
		cell.warnings = cell.warnings
			? [...cell.warnings, ...additionalCellWarnings]
			: additionalCellWarnings;

		cell.errors = cell.errors
			? [...cell.errors, ...additionalCellErrors]
			: additionalCellErrors;
	}

	@action
	public static elementFatOverThinValidation(cell: Cell, cellBelow: Cell) {
		const additionalCellWarnings: string[] = [];

		// all cells must not be deleted and current cell and cell below must have a depth
		if (
			cellBelow !== undefined
			&& cell.aptusDesignConfiguration !== 'nonaptus' // we only calculate fat element over thin element warnings for Aptus elements (aptus, custom)
			&& !cellBelow.deleted // cell below must not be deleted
			&& cell.depth && cellBelow.depth // all cases require at least the cell and cell below to have a depth
		) {
			// current dimension limit is 150% of dimensions below
			const maxDimensionValue = (val: number) => (val + (val * 0.5));

			// Only Depth check round elements
			if (cell.shape === 'round'
				&& (
					(cell.depth > maxDimensionValue(cellBelow.depth)) // is current cell depth greater than max allowed depth of cell below
					|| (cellBelow.shape === 'rectangular' && cellBelow.width && cell.depth > maxDimensionValue(cellBelow.width)) // is current cell depth greather than max allowed width of cell below
				)
			) {
				additionalCellWarnings.push('Element has a depth 50% greater than that of element below');
			}

			// Width and depth check rectangular elements
			if (cell.shape === 'rectangular') {
				if (
					cell.width && cellBelow.width && cellBelow.shape === 'rectangular'
					&& cell.width >= maxDimensionValue(cellBelow.width)
				) {
					additionalCellWarnings.push('Element has a width 50% greater than that of element below');
				}

				if (cell.depth >= maxDimensionValue(cellBelow.depth)) {
					additionalCellWarnings.push('Element has a depth 50% greater than that of element below');
				}
			}
		}

		// add warnings to cell
		cell.warnings = cell.warnings
			? [...cell.warnings, ...additionalCellWarnings]
			: additionalCellWarnings;
	}

	@action
	public postBuildValidation(elementStructure: ElementStructure) {
		ElementStructureUtils.cellIterator(
			elementStructure,
			(columnType, column, level, levelIndex) => { // iterated per level
				const cell: Cell = elementStructure.cells[column.id][level.id];

				const levelBelow = elementStructure.levels[levelIndex + 1];
				const cellBelow = levelBelow
					? elementStructure.cells[column.id][levelBelow.id]
					: undefined;

				// if there's a cell below, validate fat over thin element (element above other has dimensions that are too large)
				if (cellBelow) {
					ProjectEntity.elementFatOverThinValidation(cell, cellBelow);
				}

				// uncondition cell validation
				ProjectEntity.cellPostBuildValidation(cell);
			},
		);
	}

	public buildProject = async (shouldCleanStructure: boolean = true) => {
		// Before we build, perform prebuild validation checks
		let newParsedElementStructure: ElementStructure;

		if (shouldCleanStructure) {
			// Before we build, clean up the cells for consistency
			ElementStructureUtils.cleanCells(this.parsedElementStructure);
		}

		// Before we save, we want to convert the element structure back into json
		this.elementStructure = JSON.stringify(this.parsedElementStructure);

		const result: any = await axios.post(
			`${SERVER_URL}/api/entity/ProjectEntity/build`,
			this.toJSON(),
		);

		this.elementStructure = result.data.elementStructure;

		try {
			const parsedElementStructure = JSON.parse(this.elementStructure) as ElementStructure;
			await this.postBuildValidation(parsedElementStructure);

			newParsedElementStructure = parsedElementStructure;
		} catch {
			newParsedElementStructure = ElementStructureUtils.getEmptyElementStructure();
		}

		runInAction(() => {
			// Update each property on the parsed structure - see note on parsedElementStructure definition why it MUST be done this way
			this.parsedElementStructure.cells = newParsedElementStructure.cells;
			this.parsedElementStructure.columnTypes = newParsedElementStructure.columnTypes;
			this.parsedElementStructure.levels = newParsedElementStructure.levels;
			this.parsedElementStructure.shutters = newParsedElementStructure.shutters;
			this.parsedElementStructure.info = newParsedElementStructure.info;
		});
		await this.save();
	};

	@action public saveProject = (markEdited: boolean = true) => {
		if (markEdited && this.parsedElementStructure && this.parsedElementStructure.info) {
			this.parsedElementStructure.info.editedSinceLastBuild = true;
		}

		this.throttledSave();
	};

	public throttledSave = throttle(() => {
		this.save().catch(error => alert('You’re disconnected from the server – Please check your connection and log in again.', 'error'));
	}, 10000);

	public getShareList = (): string[] => {
		const organisationIdList: string[] = [this.organisationId || ''];

		if (this.organisationss) {
			this.organisationss.forEach(org => {
				if (org.organisationsId !== this.organisationId) {
					organisationIdList.push(org.organisationsId);
				}
			});
		}

		return organisationIdList;
	};

	public static async fetchFolders() {
		const results = await store.apolloClient
			.query({
				query: gql`
					{
						projectEntitys(where: {path: "deleted", comparison: equal, value: "true", negate: true}) {
							deleted
							folder
						}
					}`,
				fetchPolicy: 'no-cache',
			});
		const foldersArray: string[] = [];
		results.data.projectEntitys.forEach((x: { folder: string; }) => {
			if (!!x.folder && !foldersArray.includes(x.folder)) {
				foldersArray.push(x.folder);
			}
		});
		return foldersArray;
	}

	public static async fetchFolderCount() {
		const results = await store.apolloClient
			.query({
				query: gql`
					{
						countProjectEntitys(where: {path: "deleted", comparison: equal, value: "true", negate: true}) {
							number
						}
					}`,
				fetchPolicy: 'no-cache',
			});
		return results.data.countProjectEntitys.number as number;
	}

	static async fetchWithRevisionsPagination(skip: number = 0, folder: string, searchString: string = '', take: number = 10) {
		const { data } = await axios.get<{ projects: IProjectEntityAttributes[], count: number }>(
			buildUrl(
				`${SERVER_URL}/api/entity/ProjectEntity/get-projects-in-folder`,
				{
					folder,
					searchString,
					skip: skip.toString(),
					take: take.toString(),
				},
			),
		);
		return {
			data: data.projects.map(x => new ProjectEntity(x)),
			count: data.count,
		};
	}

	static async fetchFoldersPagination(skip: number = 0, searchString: string = '', take: number = 10) {
		const { data } = await axios.get<{ folders: string[], count: number }>(buildUrl(`${SERVER_URL}/api/entity/ProjectEntity/get-folder-names`, {
			searchString,
			skip: skip.toString(),
			take: take.toString(),
		}));
		return data;
	}

	public async deleteProject() {
		this.deleted = true;

		const data = await axios.post(
			'/api/entity/ProjectEntity/delete-project',
			{ projectId: this.id },
		)
			.then(() => {
				runInAction(() => {
					this.deleted = true;
				});
			})
			.catch(e => {
				runInAction(() => {
					this.deleted = false;
				});

				throw e;
			});
		return data;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ProjectEntity.prototype, 'created');
CRUD(modifiedAttr)(ProjectEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
