import * as React from 'react';
import ProjectEntity from 'Models/Entities/ProjectEntity';
import { observer } from 'mobx-react';
import { TextField } from 'Views/Components/TextBox/TextBox';
import WizardStep from 'Views/Components/ProjectWizard/WizardStep';
import {
	action, computed, observable, runInAction,
} from 'mobx';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import axios from 'axios';
import _ from 'lodash';
import { DropdownItemProps } from 'semantic-ui-react';
import { gql } from '@apollo/client';
import { store } from '../../../Models/Store';

interface ProjectContactDetails {
	id: string,
	name: string,
	email: string,
	phone: string,
}

enum countries {
	Australia = 'Australia',
	NewZealand = 'New Zealand',
	UK = 'UK',
	Europe = 'Europe',
	USA = 'USA',
	Canada = 'Canada'
}

@observer
export default class WizardSetupStep extends WizardStep {
	@observable
	public folderOptions: {display: string, value: string}[] =[];

	@observable
	public initOptionLength = 0;

	@observable
	public requestState: 'pending' | 'error' | 'success' = 'success';

	@action
	public async fetchAllFolders() {
		runInAction(() => {
			this.requestState = 'pending';
		});

		// Fetch all other project Entities
		const GET_PROJECT_FOLDER_NAMES = gql`
			query fetchProjectFolders {
				projectEntitys {
					folder
				}
			}`;

		const { data } = await store.apolloClient.query({
			query: GET_PROJECT_FOLDER_NAMES,
			fetchPolicy: 'no-cache',
		});

		const projectEntities = data.projectEntitys;
		const foldersArray: string[] = [];

		projectEntities.forEach((value: { folder: string; }) => {
			// If the folder doesn't exist in the data array already add it
			if (!!value.folder && !foldersArray.includes(value.folder)) {
				foldersArray.push(value.folder);
			}
		});

		runInAction(() => {
			this.initOptionLength = this.folderOptions.length;
			this.requestState = 'success';
			this.folderOptions = foldersArray.map(folder => {
				return { display: folder, value: folder };
			});
		});
	}

	@observable
	userList: {[key: string]: ProjectContactDetails} = {};

	public componentDidMount() {
		this.fetchAllFolders();
		axios.get(`/api/entity/ProjectEntity/contact-list/${this.props.project.id}`)
			.then(userList => {
				runInAction(() => {
					userList.data.forEach((user: ProjectContactDetails) => {
						this.userList[user.id] = user;
					});
				});
			})
			.catch(err => {
				console.log(err);
			});
		if (this.props.project.folder) {
			runInAction(() => {
				this.customField = this.props.project.folder;
			});
		}
	}

	@computed
	public get getCountryList() {
		return _.keys(countries).map(country => {
			return { display: countries[country], value: countries[country] };
		});
	}

	@computed
	public get buildProjectContactList() {
		return Object.keys(this.userList).map(userId => {
			const user = this.userList[userId];
			const revisionOption: {display: string, value: string} = { display: user.name, value: user.id };
			return revisionOption;
		});
	}

	@computed
	public get projectContactDetails() {
		const { projectOwnerId } = this.props.project;

		if (this.userList[projectOwnerId!]) {
			return {
				email: this.userList[projectOwnerId!].email,
				phone: this.userList[projectOwnerId!].phone,
			};
		}
		return {
			email: '',
			phone: '',
		};
	}

	@observable
	public customField: string;

	@computed
	public get computedFolderOptions() {
		return this.customField
			? [
				{
					display: this.customField,
					value: this.customField,
				},
				...this.folderOptions,
			]
			: this.folderOptions;
	}

	public render() {
		return (
			<>
				<div className="form-section">
					<h3 className="section-title">Project Information</h3>
					<Combobox
						className="project-folder"
						model={this.props.project}
						modelProperty="folder"
						label="Enter new folder name, or select existing from dropdown"
						tooltip="The folder this project belongs to. This will be shared between revisions on the same project."
						loading={this.requestState === 'pending'}
						isDisabled={!this.props.projectCanBeEdited}
						isRequired
						placeholder="Project Folder"
						options={this.computedFolderOptions}
						searchable={this.generateSearchableFolders()}
					/>
					<TextField
						className="project-name"
						model={this.props.project}
						modelProperty="name"
						label="Project Name"
						tooltip="The name of this project. This will be shared between revisions on the same project."
						isDisabled={!this.props.projectCanBeEdited}
						isRequired
						onAfterChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							if (this.props.project.name.length > 100) {
								this.props.project.name = this.props.project.name.substring(0, 100);
							}
						}}
					/>
					<TextField
						className="job-number"
						model={this.props.project}
						modelProperty="jobNumber"
						label="Job Number"
						tooltip="The job number for this project. This will be shared between revisions on the same project."
						isDisabled={!this.props.projectCanBeEdited}
						isRequired
					/>
					<TextField
						className="postcode"
						model={this.props.project}
						modelProperty="postcode"
						label="Postcode"
						tooltip="The postcode where the building project is located."
						isDisabled={!this.props.projectCanBeEdited}
						isRequired
					/>
					<TextField
						className="location"
						model={this.props.project}
						modelProperty="suburb"
						label="Location"
						tooltip="The suburb where the project is located."
						isDisabled={!this.props.projectCanBeEdited}
						isRequired
					/>
					<Combobox
						className="country"
						model={this.props.project}
						modelProperty="country"
						label="Country"
						tooltip="The country where the project is located."
						options={this.getCountryList}
						isDisabled={!this.props.projectCanBeEdited}
						isRequired
					/>
				</div>
				<div className="form-section">
					<h3 className="section-title">Client Information</h3>
					<TextField
						className="client-company"
						model={this.props.project}
						modelProperty="clientCompany"
						isDisabled={!this.props.projectCanBeEdited}
						label="Client Company"
					/>
					<div className="grid-filler" />
					<TextField
						className="client-first-name"
						model={this.props.project}
						modelProperty="clientFirstName"
						isDisabled={!this.props.projectCanBeEdited}
						label="Client First Name"
					/>
					<TextField
						className="client-last-name"
						model={this.props.project}
						modelProperty="clientLastName"
						isDisabled={!this.props.projectCanBeEdited}
						label="Client Last Name"
					/>
					<TextField
						className="client-address-line-1"
						model={this.props.project}
						modelProperty="clientAddressLine1"
						isDisabled={!this.props.projectCanBeEdited}
						label="Client Address Line 1"
					/>
					<TextField
						className="client-address-line-2"
						model={this.props.project}
						modelProperty="clientAddressLine2"
						isDisabled={!this.props.projectCanBeEdited}
						label="Client Address Line 2"
					/>
					<TextField
						className="client-phone"
						model={this.props.project}
						modelProperty="clientPhone"
						isDisabled={!this.props.projectCanBeEdited}
						label="Client Phone"
					/>
					<TextField
						className="client-email"
						model={this.props.project}
						modelProperty="clientEmail"
						isDisabled={!this.props.projectCanBeEdited}
						label="Client Email"
					/>
				</div>
				<div className="form-section">
					<h3 className="section-title">Project contact details</h3>

					<Combobox
						className="project-owner"
						model={this.props.project}
						modelProperty="projectOwnerId"
						label="Project contact"
						isClearable={false}
						isRequired
						tooltip="Select the user who will be in charge of managing this project"
						options={this.buildProjectContactList}
						isDisabled={!this.props.projectCanBeEdited || (Object.keys(this.userList).length <= 1)}
					/>
					<TextField
						className="email-address"
						model={this.projectContactDetails}
						modelProperty="email"
						label="Email Address"
						tooltip="The email address of the manager in charge of this project."
						isDisabled
					/>

					<div className="grid-filler" />
					<TextField
						className="contact-number"
						model={this.projectContactDetails}
						modelProperty="phone"
						label="Contact Number"
						tooltip="The phone number of the manager in charge of this project."
						isDisabled
					/>
				</div>

			</>
		);
	}

	private generateSearchableFolders() {
		return (options: DropdownItemProps[], inputValue: string) => {
			const value = inputValue.length > 100
				? inputValue.substring(0, 100)
				: inputValue;

			const newOptions = this.folderOptions
				.reduce((acc, curr) => curr.value.startsWith(value)
					? [
						...acc,
						{
							key: curr.value,
							text: curr.value,
							value: curr.value,
						},
					]
					: acc,
					[] as DropdownItemProps[]);

			if (newOptions.find(opt => opt.key === value)) {
				return newOptions;
			}
			runInAction(() => {
				this.customField = value;
			});
			return [
				{
					key: value,
					text: value,
					value: value,
				},
				...newOptions,
			];
		};
	}

	public static validateStep = (project: ProjectEntity): boolean => {
		// Check that all the fields this step cares about are set
		// If any aren't, we return false.
		// Double exclamation marks are there to satisfy typescript
		return !!(project.name
			&& project.jobNumber
			&& project.postcode
			&& project.suburb
			&& project.country);
	};
}
