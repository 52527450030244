import { observer } from 'mobx-react';
import * as React from 'react';
import {
	action, computed, observable, runInAction, IReactionDisposer, reaction,
} from 'mobx';
import { Divider } from 'semantic-ui-react';

import { store } from 'Models/Store';

import type { IPasteBoolean, IPasteDisabler } from 'Util/CopyPasteUtils';
import { CopyPasteUtils, PasteType } from 'Util/CopyPasteUtils';
import { ElementStructure } from 'Util/ElementStructureUtils';
import { SelectionUtils } from 'Util/SelectionUtils';

import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import Modal from 'Views/Components/Modal/Modal';

export interface IPasteSpecialViewProps {
	pasteType: PasteType;
	elementStructure: ElementStructure;
	selectionUtils: SelectionUtils;
	pasteBoolean: IPasteBoolean;
	pasteDisabler: IPasteDisabler;
	pasteCleanup: () => void;
}

@observer
export default class PasteSpecialView extends React.Component<IPasteSpecialViewProps> {
	private reactionCleanup?: IReactionDisposer;

	componentDidMount() {
		this.reactionCleanup = reaction(
			() => this.props.selectionUtils.selectedCells.map(x => x),
			() => this.props.pasteCleanup(),
		);
	}

	componentWillUnmount() {
		this.reactionCleanup?.();
	}

	@action
	checkboxLogic(pasteBoolean: IPasteBoolean, pasteDisabler: IPasteDisabler) {
		// If any Checkbox is unchecked, uncheck the "All" checkbox
		if ((!pasteBoolean.geometry && !pasteDisabler.geometry.disabled) // if geometry unchecked or disabled
			|| (!pasteBoolean.slabThicknessAtBase && !pasteDisabler.slabThicknessAtBase.disabled) // if slabThicknessAtBase unchecked or disabled
			|| (pasteBoolean.geometry
				&& (
					!pasteBoolean.reinforcement
					|| !pasteBoolean.concreteStr
					|| !pasteBoolean.reoRates
				))) {
			pasteBoolean.all = false;
		}
		// If Geometry is unchecked, uncheck Bars & Ligs and ReoRates
		if (!pasteBoolean.geometry || pasteDisabler.geometry.disabled) {
			pasteBoolean.reinforcement = false;
			pasteBoolean.concreteStr = false;
			pasteBoolean.reoRates = false;
		}
	}

	@action
	allCheckboxLogic(pasteBoolean: IPasteBoolean, pasteDisabler: IPasteDisabler) {
		// If the "All" checkbox is checked, all other checkboxes should be set to true
		if (pasteBoolean.all) {
			if (!pasteDisabler.geometry.disabled) pasteBoolean.geometry = true;
			if (!pasteDisabler.slabThicknessAtBase.disabled) pasteBoolean.slabThicknessAtBase = true;
			if (pasteBoolean.geometry && !pasteDisabler.geometry.disabled) {
				pasteBoolean.reinforcement = true;
				pasteBoolean.concreteStr = true;
				pasteBoolean.reoRates = true;
			}
		}
		if (!pasteBoolean.all) {
			pasteBoolean.geometry = false;
			pasteBoolean.slabThicknessAtBase = false;
			pasteBoolean.reinforcement = false;
			pasteBoolean.concreteStr = false;
			pasteBoolean.reoRates = false;
		}
	}

	@computed
	public get geometryLock() {
		return !this.props.pasteBoolean.geometry;
	}

	@observable
	public pasteTooltip = false;

	public render() {
		const {
			pasteBoolean, pasteType, elementStructure, selectionUtils, pasteDisabler,
		} = this.props;
		return (
			<>
				<div className="paste-special-wrapper">
					<div className="paste-special-inner">
						<div className="paste-top">
							<h5 className="paste-header">Paste Special</h5>
							<Tooltip
								id="paste-tooltip"
								content=""
								onClick={() => {
									runInAction(() => { this.pasteTooltip = true; });
								}}
							/>
							<Modal
								isOpen={this.pasteTooltip}
								label=""
								onRequestClose={() => {
									runInAction(() => { this.pasteTooltip = false; });
								}}
							>
								<h5>Paste Special</h5>
								<ul>
									<li>Pasting any data from a Non-Aptus element will change the design configuration of the pasted cell to be Non-Aptus.</li>
									<li>Pasting any data copied from an APTUS-Design cell will change the design configuration of the pasted cell to be APTUS-Custom.</li>
									<li>The user can always change design configurations after pasting if required.</li>
									<li>Note: </li>
									<li>The reo rate and insitu status is copied across to the selected element/s, regardless of the element type.</li>
									<li>Corbels and Voids are always removed from selected element/s when pasted to, to ensure geometry remains valid.</li>
								</ul>
							</Modal>
							<button
								onClick={() => {
									selectionUtils.cancelSelection();
									this.props.pasteCleanup();
								}}
								className="close-paste-view-btn"
								aria-label="Close"
							/>
						</div>
						<div>
							<Checkbox
								className="paste-checkbox all"
								onAfterChecked={() => { this.allCheckboxLogic(pasteBoolean, pasteDisabler); this.checkboxLogic(pasteBoolean, pasteDisabler); }}
								model={pasteBoolean}
								modelProperty="all"
								label="Paste all information (In the list below)"
							/>
							<Divider />
							<Checkbox
								className="paste-checkbox geometry"
								model={pasteBoolean}
								modelProperty="geometry"
								label="Geometry"
								onAfterChecked={() => this.checkboxLogic(pasteBoolean, pasteDisabler)}
								isDisabled={pasteDisabler.geometry.disabled}
							/>
							{pasteDisabler.geometry.disabled ? <p className="paste-checkbox paste-error">{pasteDisabler.geometry.msg}</p> : null}
							<Checkbox
								className="paste-checkbox slabDepth"
								model={pasteBoolean}
								modelProperty="slabThicknessAtBase"
								label="Slab Depth at Base"
								onAfterChecked={() => this.checkboxLogic(pasteBoolean, pasteDisabler)}
								isDisabled={pasteDisabler.slabThicknessAtBase.disabled}
							/>
							{pasteDisabler.slabThicknessAtBase.disabled ? <p className="paste-checkbox paste-error">{pasteDisabler.slabThicknessAtBase.msg}</p> : null}
							{/* BARS AND LIGS */}
							<Checkbox
								className="paste-checkbox reinforcement"
								model={pasteBoolean}
								modelProperty="reinforcement"
								label="Reinforcement"
								onAfterChecked={() => this.checkboxLogic(pasteBoolean, pasteDisabler)}
								isDisabled={this.geometryLock}
							/>
							{/* REO RATE */}
							<Checkbox
								className="paste-checkbox reoRates"
								model={pasteBoolean}
								modelProperty="reoRates"
								label="Reo Rate"
								onAfterChecked={() => this.checkboxLogic(pasteBoolean, pasteDisabler)}
								isDisabled={this.geometryLock}
							/>
							{/* CONCRETE STR */}
							<Checkbox
								className="paste-checkbox concreteStr"
								model={pasteBoolean}
								modelProperty="concreteStr"
								label="Concrete Strength"
								onAfterChecked={() => this.checkboxLogic(pasteBoolean, pasteDisabler)}
								isDisabled={this.geometryLock}
							/>
						</div>
						<div className="bottom-div">
							<Button
								className="paste-cancel"
								type="reset"
								display={Display.Outline}
								colors={Colors.Secondary}
								onClick={action(() => {
									this.props.pasteCleanup();
								})}
							> Cancel
							</Button>
							<Button
								disabled={!(pasteBoolean.reinforcement || pasteBoolean.concreteStr || pasteBoolean.reoRates || pasteBoolean.geometry || pasteBoolean.slabThicknessAtBase)}
								display={Display.Solid}
								colors={Colors.Monochrome}
								className="paste-confirm"
								onClick={action(async () => {
									CopyPasteUtils.executePaste(pasteType, elementStructure, selectionUtils, pasteBoolean, store.copiedCells);
									this.props.pasteCleanup();
								})}
							>Confirm Paste
							</Button>
						</div>
					</div>
				</div>
			</>
		);
	}
}
