import * as React from 'react';
import { observer } from 'mobx-react';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { ElementStructure } from 'Util/ElementStructureUtils';
import { runInAction } from 'mobx';
import { Combobox } from 'Views/Components/Combobox/Combobox';

export interface IColumnEditViewProps {
	editColumn: EditColumn;
	editColumnErrors: EditColumnErrors;
	multipleSelected: boolean;
	saveChanges: () => void;
	deleteColumns: () => void;
	cancelSelection: () => void;
	readonly?: boolean;
	simplified?: boolean;
	elementStructure: ElementStructure;
}

export interface EditColumn {
	model: {
		name?: string;
		load?: number;
		loadArea?: number;
		constructionZone?: string;
	}
	info: {
		typeName?: string;
		typeCode?: string;
		topCellAdditionalLoad?: number;
	}
}

export interface EditColumnErrors {
	name?: string;
	topCellAdditionalLoad?: string;
}

@observer
export default class ColumnEditView extends React.Component<IColumnEditViewProps> {
	public render() {
		const {
			editColumn,
			editColumnErrors,
			multipleSelected,
			readonly,
			elementStructure,
			cancelSelection,
		} = this.props;

		return (
			<>
				<div className="edit-view-header">
					<h4 className="edit-view-title">Edit loads</h4>
					<button onClick={cancelSelection} className="close-edit-view-btn" aria-label="Close" />
				</div>
				<div className="edit-view-tab-content">
					<Combobox
						className="typeName"
						label="Element type"
						tooltip="The type of element"
						options={elementStructure.columnTypes.map(x => ({
							display: x.name,
							value: x.code,
						}))}
						modelProperty="typeCode"
						model={editColumn.info}
						onAfterChange={this.saveColumns}
						isDisabled={readonly}
					/>
					<TextField
						className="name"
						model={editColumn.model}
						modelProperty="name"
						label="Element mark"
						isDisabled={multipleSelected || readonly}
						tooltip="The number of this element"
						onAfterChange={() => runInAction(() => {
							editColumnErrors.name = undefined;
						})}
						onChangeAndBlur={this.saveColumns}
						errors={editColumnErrors.name ? editColumnErrors.name : undefined}
					/>
					<NumberTextField
						className="load"
						model={editColumn.model}
						modelProperty="load"
						tooltip="Uniformly distributed load is used for a quick and simple load rundown for each column.
For a residential project with 200mm slabs, use 12kPa.
For a carpark project with 180mm slabs, use 11 kPa
For a retail project with 250mm slabs, use 20 kPa
For an Office Project with 250mm slabs, use 13 kPa
(These are approximate values for the purposes of generating a preliminary design only - Contact the project structural engineer for accurate column loading)
This loading should be the total ultimate limit state load per square meter. e.g for a project with a live load of 2.0 kPA and a superimposed dead load of 1.5 kPa on 200mm slabs:
0.2x25x1.2+1.5x1.2+2.0x1.5=10.8kPa Ult"
						label="Uniformly distributed load (kPa)"
						isDisabled={readonly}
						onChangeAndBlur={this.saveColumns}
					/>
					<NumberTextField
						className="loadArea"
						model={editColumn.model}
						modelProperty="loadArea"
						tooltip="Tributary area is used for a quick and simple load run-down for each column.
Adjust this value to reflect the tributary floor area being picked up by this element in meters squared."
						label="Area(m²/element)"
						isDisabled={readonly}
						onChangeAndBlur={this.saveColumns}
					/>
					<NumberTextField
						className="topCellAdditionalLoad"
						model={editColumn.info}
						modelProperty="topCellAdditionalLoad"
						tooltip="Enter any additional loading at the top of this element. The automatic load run will be used with this additional load added to the top of the load run-down. An example would be if this element is underneath a transfer slab. The user would calculate the load from the transfer slab into this column and input it here."
						label="Additional load (kN)"
						isDisabled={readonly}
						onAfterChange={() => runInAction(() => {
							editColumnErrors.topCellAdditionalLoad = undefined;
						})}
						onChangeAndBlur={this.saveColumns}
						errors={editColumnErrors.topCellAdditionalLoad ? editColumnErrors.topCellAdditionalLoad : undefined}
					/>
					<p>Load calculations and any additional load will be applied to all required columns beneath this element.</p>
					<TextField
						className="constructionZone"
						model={editColumn.model}
						modelProperty="constructionZone"
						label="Construction Zone"
						tooltip="The user may choose to enter a construction zone to differentiate between pours. This has no effect in the APTUS design tool but will display in the excel output for each element."
						isDisabled={readonly}
						onChangeAndBlur={this.saveColumns}
					/>
					<div className="save-buttons" />
				</div>
				{!readonly ? this.renderActions() : null}
			</>
		);
	}

	private renderActions = () => {
		const { deleteColumns } = this.props;
		return (
			<div className="edit-view-actions">
				<Button onClick={deleteColumns} className="delete-column-btn" display={Display.Solid} colors={Colors.Secondary} icon={{ icon: 'bin-delete', iconPos: 'icon-left' }}>Remove</Button>
			</div>
		);
	};

	private canSaveColumns = () => {
		const { multipleSelected, editColumn } = this.props;

		if (!multipleSelected) {
			if (!editColumn.model.name) {
				return false;
			}
		}
		return true;
	};

	private saveColumns = () => {
		if (this.canSaveColumns()) {
			this.props.saveChanges();
		}
	};
}
