/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

// % protected region % [Customise application id here] off begin
export const APPLICATION_ID = 'fe31d686-0c12-11ea-b91f-6d6f75736579';
// % protected region % [Customise application id here] end

export const validator: unique symbol = Symbol('Validators');
export const validatorMap: unique symbol = Symbol('Validator Map');
export const validatorValueMap: unique symbol = Symbol('Validator Value Map');
export const attributes: unique symbol = Symbol('Attributes');
export const references: unique symbol = Symbol('References');
export const crudOptions: unique symbol = Symbol('CRUDOptions');
export const modelName: unique symbol = Symbol('Model Name');
export const displayName: unique symbol = Symbol('Display Name');
export const crudId: unique symbol = Symbol('Crud Unique Id');
export const fileAttributes: unique symbol = Symbol('File Attributes');
export const serialiseAs: unique symbol = Symbol('Serialise As');
// % protected region % [Add any extra symbols here] off begin
// % protected region % [Add any extra symbols here] end

export const Symbols = {
	validator: validator,
	validatorMap: validatorMap,
	attributes: attributes,
	references: references,
	crudOptions: crudOptions,
	modelName: modelName,
	displayName: displayName,
	crudId: crudId,
	fileAttributes: fileAttributes,
	validatorValueMap: validatorValueMap,
	serialiseAs: serialiseAs,
	// % protected region % [Add extra symbols to the symbol map here] off begin
	// % protected region % [Add extra symbols to the symbol map here] end
};
