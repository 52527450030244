import { action, runInAction } from 'mobx';
import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import type { Cell, Column, Level } from 'Util/ElementStructureUtils';
import { ElementStructureUtils } from 'Util/ElementStructureUtils';
import EngineerApprovalCheck from 'Views/Components/ElementGrid/EngineerApprovalCheck';
import ErrorTile from 'Views/Components/ElementGrid/ErrorTile';

export interface ICellViewProps {
	cell: Cell;
	column: Column;
	level: Level;
	columnTypeName: string;
	cellSelected: boolean;
	onClick: (event: React.MouseEvent, cell: Cell) => void;
	onRightClick: (event: React.MouseEvent) => void;
	simplified?: boolean;
	temporaryWorks?: boolean;
	afterChange?: (markEdited: boolean) => void;
	cellChanged: boolean;
	ignoreWarnings?: boolean;
}

@observer
export default class CellView extends React.Component<ICellViewProps> {
	private readonly transitionColours = {
		default: { hex: 'transparent', fontColour: 'black' },
		lightblue: { hex: '#96DDD1', fontColour: 'black' },
		lightgreen: { hex: '#E2F79F', fontColour: 'black' },
		darkgreen: { hex: '#6FA922', fontColour: 'black' },
		pink: { hex: '#E36DA8', fontColour: 'black' },
		purple: { hex: '#734592', fontColour: 'white' },
		black: { hex: '#000000', fontColour: 'white' },
	};

	private readonly nonAptusColours = {
		grey: { hex: '#E1E1E1' },
		lightblue: { hex: '#96DDD1' },
		lightgreen: { hex: '#E2F79F' },
		darkgreen: { hex: '#6FA922' },
		pink: { hex: '#E36DA8' },
		purple: { hex: '#734592' },
		black: { hex: '#000000' },
	};

	componentDidMount() {
		runInAction(() => {
			const { cell } = this.props;
			cell.hideWarning = !(((cell.errors && cell.errors.length > 0) || (cell.warnings && cell.warnings.length > 0)) && !this.props.ignoreWarnings);
		});
	}

	public render() {
		const {
			cell, cellSelected, level, onClick, onRightClick, simplified, temporaryWorks,
		} = this.props;

		const className: string[] = ['cell', !!temporaryWorks ? 'temp-works' : ''];
		if (cellSelected) {
			className.push('selected');
		}
		if (cell.merged) {
			className.push('merged');
		}
		if (cell.approved) {
			className.push('approved');
		}
		if (level.groundLevel) {
			className.push('ground');
		}

		// Setup classes for colour filters
		className.push(`concrete-${cell.concreteStrength}`);
		className.push(cell.aptusDesignConfiguration !== 'nonaptus' ? `aptus-${cell.aptusBarType}` : '');
		className.push(cell.aptusDesignConfiguration !== 'nonaptus' ? this.getBarCountClass() : '');

		const onCellClick = (e: React.MouseEvent) => onClick(e, cell);

		if (cell.deleted) {
			return (
				<div className={classNames(...className)} onClick={onCellClick} onContextMenu={onRightClick} data-height={cell.levelHeight}>
					<div className="content" />
				</div>
			);
		}

		// If the cell has an error, and we're viewing it on the normal table, then we can't use the cell until we dismiss the error
		if (!this.props.ignoreWarnings
			&& !simplified
			&& !cell.hideWarning
			&& ((cell.errors && cell.errors.length) || (cell.warnings && cell.warnings.length))) {
			return (
				<ErrorTile
					cell={cell}
					onClick={onCellClick}
					onDismiss={this.dismissError}
					className={classNames(...className)}
				/>
			);
		}

		if (ElementStructureUtils.cellDimensionsEmpty(cell.width, cell.shape, cell.depth)) {
			return (
				<div className={classNames(...className)} onClick={onCellClick} onContextMenu={onRightClick} data-height={cell.levelHeight}>
					<div className="content">
						<p className="dimensions-prompt">Enter Dimensions</p>
					</div>
				</div>
			);
		}

		return (
			<div className={classNames(...className)} onClick={onCellClick} onContextMenu={onRightClick} data-height={this.props.cell.levelHeight}>
				{ this.props.cellChanged ? <div className="changed" /> : null }
				{!this.props.simplified
					? (
						<i
							className={`cell-type-indicator ${this.props.cell.aptusDesignConfiguration}`}
							style={{
								backgroundColor: this.props.cell.aptusDesignConfiguration === 'nonaptus'
									? this.setNonAptusColour()
									: '',
							}}
						/>
					)
					: null}
				<div className="content">
					<div className="cell-contents">
						<div className="cell-dimensions">{ElementStructureUtils.cellDimensionString(cell)}</div>
						{
							ElementStructureUtils.cellCVString(cell)
								? (
									<div className="cell-corbel-void">
										{ ElementStructureUtils.cellCVString(cell) }
									</div>
								)
								: null
						}
						{!temporaryWorks && !simplified
							? (
								<>
									<div className="cell-load">{ElementStructureUtils.cellLoadString(cell)}</div>
									{ElementStructureUtils.shouldUseReoRate(cell)
										? <div className="cell-reo-rate">{ElementStructureUtils.cellReoRateString(cell)}</div>
										: (
											<>
												<div className="cell-reo-design">{ElementStructureUtils.cellAptusDesignString(cell)}</div>
												<div className="cell-ligature-design">{ElementStructureUtils.cellLigatureDesignString(cell)}</div>
											</>
										)}
									<div className="cell-concrete-strength">{ElementStructureUtils.cellConcreteStrengthString(cell)}</div>
									<div className="cell-unit-mass">{ElementStructureUtils.cellUnitMassString(cell)}</div>
									{cell.overrideParts && <div className="cell-overridden-exclamation icon-exclamation icon-only" />}
								</>
							)
							: null}
						{temporaryWorks && cell.aptusDesignConfiguration !== 'nonaptus'
							? (
								<>
									<div className="cell-temp-works">{ElementStructureUtils.temporaryWorksDesignString(cell)}</div>
									{cell.overrideParts && <div className="cell-overridden-exclamation icon-exclamation icon-only" />}
								</>
							)
							: null}
					</div>
					{!simplified
						? <div className="cell-transition" style={{ backgroundColor: this.setTransitionBackground(), color: cellSelected ? 'white' : this.setTransitionFont() }}>{cell.transitionDescription}</div>
						: null}
				</div>
				{cell.approved && !temporaryWorks
					? <EngineerApprovalCheck />
					: null}
			</div>
		);
	}

	private getBarCountClass(): string {
		const { cell } = this.props;
		const barCount = ElementStructureUtils.aptusBarQuantity(cell.shape, cell.aptusBarsAlongWidth, cell.aptusBarsAlongDepth);

		const barCountClasses = [
			{ minQuantity: 12, className: 'quantity-huge' },
			{ minQuantity: 10, className: 'quantity-large' },
			{ minQuantity: 8, className: 'quantity-moderate' },
			{ minQuantity: 6, className: 'quantity-small' },
			{ minQuantity: 0, className: 'quantity-tiny' },
		];

		const barCountClass = barCountClasses.find(x => barCount >= x.minQuantity);
		return barCountClass ? barCountClass.className : 'quantity-huge';
	}

	private setNonAptusColour(): string {
		if (this.props.cell.nonAptusColour) {
			return this.nonAptusColours[this.props.cell.nonAptusColour].hex;
		}
		return this.nonAptusColours.grey.hex;
	}

	private setTransitionBackground(): string {
		const { cell } = this.props;
		if (cell.transitionColour) {
			return this.transitionColours[cell.transitionColour].hex;
		}
		return this.transitionColours.default.hex;
	}

	private setTransitionFont(): string {
		const { cell } = this.props;
		if (cell.transitionColour) {
			return this.transitionColours[cell.transitionColour].fontColour;
		}
		return this.transitionColours.default.fontColour;
	}

	@action dismissError = () => {
		const { cell, afterChange } = this.props;
		cell.hideWarning = true;

		if (afterChange) {
			afterChange(false);
		}
	}
}
