import React from 'react';
import { contextMenu } from 'react-contexify';
import { ContextMenu } from 'Views/Components/ContextMenu/ContextMenu';

const menuId = 'column-context-menu';

interface ColumnContextMenuProps {
    onMoveRight: () => any;
    onMoveLeft: () => any;
    disabled: boolean;
}

export const showColumnContextMenu = (e: React.MouseEvent) => {
	contextMenu.show({ id: menuId, event: e });
};

export const ColumnContextMenu = (props: ColumnContextMenuProps) => {
	const {
		onMoveLeft, onMoveRight, disabled,
	} = props;
	return (
		<ContextMenu
			menuId={menuId}
			actions={[
				{
					label: 'Move Column Right',
					onClick: onMoveRight,
					disabled: disabled,
				},
				{
					label: 'Move Column Left',
					onClick: onMoveLeft,
					disabled: disabled,
				},
			]}
		/>
	);
};
