/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-mixed-operators */
import { action, observable } from 'mobx';
import * as uuid from 'uuid';
import { isNaN } from 'lodash';
import alertToast from 'Util/ToastifyUtils';

import { couplerData } from 'Util/CouplerData';
import { SelectedCell } from 'Util/SelectionUtils';

import { ProjectWizardStep } from 'Views/Components/ProjectWizard/ProjectWizard';
import { BarPartSummary, ItemPartSummary } from 'Views/Components/PricingComponents/CostSummary';

import ProjectEntity from 'Models/Entities/ProjectEntity';
import { prebuildValConsts } from 'Models/Entities/ProjectEntity';

/**
 * TYPES
 */

export interface ElementStructure {
	levels: Level[];
	columnTypes: ColumnType[];
	cells: CellOuterDict;
	shutters?: PricingShutter[];
	additionalParts?: AdditionalPart[]
	info: ElementStructureInfo;
}
export interface CellOuterDict {
	[key: string]: CellDict;
}
export interface CellDict {
	[key: string]: Cell;
}

export interface Level {
	id: string;
	code: string;
	name: string;
	slabThicknessAtBase: number;
	groundLevel: boolean;
	ssl: number;
	topSsl?: number;
	calculatedFloorHeight?: number;
}
export interface ColumnType {
	id: string;
	name: string;
	code: string;
	estimatedColumnCount: number;
	columns: Column[];
}
export interface Column {
	id: string;
	name: string;
	load: number;
	loadArea: number;
}
export interface Cell {
	id: string;
	columnId: string;
	levelId: string;

	shape: CellShape;
	width?: number;
	depth: number;
	height?: number; // only used for frontend display. Backend recalculates this to prevent discrepancies.
	slabThicknessAtBase: number;
	levelHeight: number; // This is the number of levels which the cell spans
	slabThicknessAtTop?: number; // Only applies if there's no element above this

	ssl?: number;
	overrideSSL?: boolean;

	// Only applies to top cell in column
	topSsl?: number;
	overrideTopSsl?: boolean;

	additionalLoad: number;
	calculatedLoad?: number;
	applyLoadAtEveryLevel?: boolean;
	overrideCalculatedLoad?: boolean;
	constructionZone?: string;
	loadTransfers?: LoadTransfer[];

	aptusDesignConfiguration: DesignConfiguration;
	insituElement?: boolean;

	useReoRate?: boolean;
	reoRate?: number;

	corbel?: Corbel | null;
	elementVoid?: ElementVoid | null;

	forceDesignAsColumn?: boolean;
	designOverride?: cellHorizontalReoDesign | null;
	aptusBarsAlongWidth?: number;
	aptusBarsAlongDepth?: number;
	aptusBarType?: BarType;
	nonAptusBarsAlongWidth?: number;
	nonAptusBarsAlongDepth?: number;
	nonAptusBarType?: BarType;
	ligDesign?: string;
	aptusBarLigSpacing?: number;
	aptusBarLigType?: BarType;
	nonAptusBarLigSpacing?: number;
	nonAptusBarLigType?: BarType;
	concreteStrength?: number;
	unitMass?: number;

	tempWorksBarType?: BarType;
	tempWorksBarsAlongWidth?: number;
	tempWorksBarsAlongDepth?: number;
	tempWorksWindLoad?: number;
	tempWorksCfig?: number;

	kFactor?: number;
	majorAxisMoment: number;
	minorAxisMoment: number;
	workingMajorAxisMoment?: number;
	workingMinorAxisMoment?: number;
	majorInteractionCurve?: number[][];
	minorInteractionCurve?: number[][];
	majorProppingChkRatio?: number;
	minorProppingChkRatio?: number;
	majorAxialChkRatio?: number;
	minorAxialChkRatio?: number;

	merged: boolean;
	deleted: boolean;
	approved: boolean;

	errors?: string[];
	warnings?: string[];

	parts?: PricingPartList;
	overrideParts?: boolean;
	disableInsituStarters?: boolean;

	transitionDescription?: string;
	transitionColour?: TransitionColour;

	nonAptusColour?: NonAptusColour;
	hideWarning?: boolean;

	aptusBarMassDict?: { [key: string]: number };
	nonAptusBarMassDict?: { [key: string]: number };
	aptusLigMassDict?: { [key: string]: number };
	nonAptusLigMassDict?: { [key: string]: number };

	extensionUnderDepth?: ExtensionUnderDepth;
	extensionOverDepth?: ExtensionOverDepth;

	/**
	 *
	 * DEPRECATED PROPERTIES
	 *
	 * See corresponding comment in backend (search this comment's title)
	 *
		aptusBarMass?: number;
		nonAptusBarMass?: number;
		aptusLigMass?: number;
		nonAptusLigMass?: number;

		optionalAptusWallTieMass?: number;
		optionalNonAptusWallTieMass?: number;
		includeWallTies?: boolean;
	 */
}

/**
 * Type representing a load transfer to a targeted cell
 */
export type LoadTransfer = {
	// cell id of the cell receiving the load (target cell)
	receivingId: string | undefined,
	// percent of the sending cell's load being transferred to target cell
	percent: number | undefined
};

/**
 * Load transfer transferring from cell specified by cellId
 */
type CellLoadTransfer = {
	// cell id from source cell (cell sending the load)
	cellId: string,
	// array of load transfer objects specifying the target cells and amount
	data?: LoadTransfer[]
};

/**
 * Contains objects for outgoing and incoming load transfers, indexed by cell id
 */
type LoadTransferIndex = {
	// object indexed by cell id containing the outgoing load transfers from that cell
	bySendingCellId: Record<string, CellLoadTransfer>,
	// object indexed by cell id containing the incoming load transfers for that cell
	// the cell id should match the id property within the CellLoadTransfer object
	byReceivingCellId: Record<string, CellLoadTransfer[]>
};

export interface ElementStructureInfo {
	project: ProjectInfo;
	temporaryWorks: TemporaryWorksInfo;
	loading: LoadingInfo;
	levelDefaults: LevelDefaultInfo;
	wizardProgress: WizardProgressInfo;
	editedSinceLastBuild: boolean;
	shutterUsage: ShutterUsageInfo;
}
export interface ProjectInfo {
	postcode?: string;
	suburb?: string;
	country?: string;
}
export interface TemporaryWorksInfo {
	windRegion?: WindRegion;
	terrainCategory?: TerrainCategory;
	groundLevelRL?: number; // only used during level generation
	defaultExtensionUnderDepth?: ExtensionUnderDepth;
	defaultExtensionOverDepth?: ExtensionOverDepth;
	structuralDuctilityFactor?: StructuralDuctilityFactor;
}
export interface LoadingInfo {
	columnX?: number;
	columnY?: number;
	tributaryArea?: number;
	distributedLoad: number;
}
export interface LevelDefaultInfo {
	typicalFloorHeight?: number;
	typicalSlabThickness?: number;
}
export interface WizardProgressInfo {
	currentWizardStep?: ProjectWizardStep;
	maxWizardStep?: ProjectWizardStep;
	completedWizard?: boolean;
}
export interface ShutterUsageInfo {
	shutterUsagePct?: number;
	siteTemplateUsagePct?: number;
}

export interface PricingPartList {
	bars?: PricingBar;
	innerCouplers?: PricingPart;
	outerCouplers?: PricingPart;
	extensions?: PricingPart;
	insituStarterInnerCouplers?: PricingPart;
	insituStarterExtensions?: PricingPart;
	starterBars?: PricingStarterBar;
	insituStarterBars?: PricingStarterBar;
	anchorHeads?: PricingPart;
}

export interface PricingBar {
	barName: string;
	length: number;
	quantity: number;
	basePrice: number;
	reoPrice: number;
	combinedPrice: number;
}
export interface PricingStarterBar {
	partName: string;
	length: number;
	quantity: number;
	price: number;
}
export interface PricingPart {
	partName: string;
	quantity: number;
	price: number;
	length?: number;
}

export interface AdditionalPart {
	isBar: boolean;
	partName: string;
	length: number;
	quantity: number;
	basePrice: number;
	reoPrice: number;
}

export interface CellPricesQuantities {
	prices: CellTotal;
	quantities: CellTotal;
	shutters: ExtraTotal;
	siteTemplates: ExtraTotal;
	extras: ExtraTotal;
}

export interface CellTotal {
	starters: number;
	bars: number;
	couplers: number; // inner and outer couplers
	extensions: number;
	anchors: number;
	total: number;
}

export interface Volume {
	height: number | undefined;
	width: number | undefined;
	depth: number | undefined;
}

// A Corbel is an additional volume (and mass) for an element, with its own reo
export interface Corbel extends Volume {
	// if 'useCell<Dimension>' is true, the Volume properties will need to be updated if Cell Dimension changes
	useCellHeight: boolean;
	useCellWidth: boolean;
	useCellDepth: boolean;
	reoRate: number | undefined;
}

// A Void is a subtracted volume from an element
// (prefixed with Element because void is a protected keyword)
export interface ElementVoid extends Volume {
	// if 'useCell<Dimension>' is true, the Volume properties will need to be updated if Cell Dimension changes
	useCellHeight: boolean;
	useCellWidth: boolean;
	useCellDepth: boolean;
}

export interface PricingShutter {
	width: number;
	depth: number;
	aptusBarsAlongWidth: number;
	aptusBarsAlongDepth: number;
	couplerType: string;
	price: number;
	siteTemplatePrice?: number;
	quantity: number;
	calculatedShutterQuantity?: boolean;
	siteTemplateQuantity?: number;
	calculatedSiteTemplateQuantity?: boolean;
	calculatedFullUsageQty?: number;
	isRound: boolean;
}

export interface ShutterTotal {
	shutterCount: 0,
	shutterTotal: 0,
	siteTemplateCount: 0,
	siteTemplateTotal: 0,
}

export interface ExtraTotal {
	quantity: number;
	price: number;
}

export interface AxialLoadsData {
	levelName: string;
	columnName: string;
	load: number;
	majorAxisMoment: number;
	minorAxisMoment: number;
}

export interface AxialLoadsDict {
	[key: string]: AxialLoadsData;
}

export type CellShape = 'rectangular'|'round';
export type DesignConfiguration = 'aptus'|'custom'|'nonaptus';
export type BarType = ''|'undefined'|'N10'|'N12'|'N16'|'N20'|'N24'|'N28'|'N32'|'N36'|'N40';
export type WindRegion = 'A'|'B'|'C'|'D';
export type TerrainCategory = 'TC1'|'TC1.5'|'TC2'|'TC2.5'|'TC3'|'TC4';
export type TransitionColour = 'default'|'lightblue'|'lightgreen'|'darkgreen'|'pink'|'pink'|'black';
export type NonAptusColour = 'grey'|'lightblue'|'lightgreen'|'darkgreen'|'pink'|'pink'|'black';
export type cellHorizontalReoDesign = 'Wall' | 'Column';
export type ExtensionUnderDepth = 'variable-stud-length' | 'blockouts';
export type ExtensionOverDepth = 'blockouts' | 'sx-bars' | 'socket-extensions';
export type StructuralDuctilityFactor = '2.0'|'1.0';

export type LevelSSLErrorKey = 'minHeightError' | 'maxHeightError' | 'minHeightErrorBelow' | 'maxHeightErrorBelow'

// Types used for creating new column/level/etc. types
export interface NewColumnTypeFields {
	code?: string;
	name?: string;
	estimatedColumnCount?: number;
}

export interface NewColumnFields {
	name?: string;
	load?: number;
	loadArea?: number;
}

export interface NewLevelFields {
	code?: string;
	name?: string;
	slabThicknessAtBase?: number;
	groundLevel?: boolean;
	ssl?: number;
	topSsl?: number;
}

export abstract class ElementStructureUtils {
	@observable
	public static cellChangedIdList: string[];

	// Declare some constants
	public static defaultSlabThickness = 200;

	// Used by any dropdown to select bar types
	public static barTypeDropdownValuesAllSizes = [
		{ display: '', value: 'undefined' }, // The combobox library doesn't like undefined values, so we use this as a hack
		{ display: 'N10', value: 'N10' },
		{ display: 'N12', value: 'N12' },
		{ display: 'N16', value: 'N16' },
		{ display: 'N20', value: 'N20' },
		{ display: 'N24', value: 'N24' },
		{ display: 'N28', value: 'N28' },
		{ display: 'N32', value: 'N32' },
		{ display: 'N36', value: 'N36' },
		{ display: 'N40', value: 'N40' },
	];

	// Used by any dropdown to select bar types
	public static barTypeDropdownValuesAptusSizes = [
		{ display: '', value: 'undefined' }, // The combobox library doesn't like undefined values, so we use this as a hack
		{ display: 'N20', value: 'N20' },
		{ display: 'N24', value: 'N24' },
		{ display: 'N28', value: 'N28' },
		{ display: 'N32', value: 'N32' },
		{ display: 'N36', value: 'N36' },
		{ display: 'N40', value: 'N40' },
	];

	// Used by any dropdown to select wind region
	public static windRegionDropdownValues = [
		{ display: 'A', value: 'A' },
		{ display: 'B', value: 'B' },
		{ display: 'C', value: 'C' },
		{ display: 'D', value: 'D' },
	];

	// Used by any dropdown to select bar types
	public static terrainCategoryDropdownValues = [
		{ display: 'TC1', value: 'TC1' },
		{ display: 'TC1.5 (2011)', value: 'TC1.5' },
		{ display: 'TC2', value: 'TC2' },
		{ display: 'TC2.5', value: 'TC2.5' },
		{ display: 'TC3', value: 'TC3' },
		{ display: 'TC4', value: 'TC4' },
	];

	// Used by any dropdown to select bar types
	public static concreteStrengthDropdownValues = [
		{ display: '', value: -1 }, // The combobox library doesn't like undefined values, so we use this as a hack
		{ display: '40 MPa', value: 40 },
		{ display: '50 MPa', value: 50 },
		{ display: '65 MPa', value: 65 },
		{ display: '80 MPa', value: 80 },
		{ display: '100 MPa', value: 100 },
	];

	// Used by any dropdown to select transition colours
	public static transitionColourDropdownValues = [
		{ display: '', value: 'undefined' },
		{ display: 'No colour', value: 'default' },
		{ display: 'Light blue', value: 'lightblue' },
		{ display: 'Light green', value: 'lightgreen' },
		{ display: 'Dark green', value: 'darkgreen' },
		{ display: 'Pink', value: 'pink' },
		{ display: 'Purple', value: 'purple' },
		{ display: 'Black', value: 'black' },
	];

	public static barPartsList = [
		{ name: 'bars', description: 'Aptus Bars (within element)', starterBar: false },
		{ name: 'starterBars', description: 'Aptus Starter Bars (within element)', starterBar: true },
		{ name: 'insituStarterBars', description: 'Aptus Starter Bars (in-situ/site use)', starterBar: true },
	];

	public static itemPartsList = [
		{ name: 'insituStarterInnerCouplers', description: 'Inner Couplers (for in-situ starter bars)' },
		{ name: 'insituStarterExtensions', description: 'Extension Bars (for in-situ starter bar)' },
		{ name: 'outerCouplers', description: 'Outer Couplers (fixed to the base of the element)' },
		{ name: 'innerCouplers', description: 'Inner Couplers (fixed to top of element)' },
		{ name: 'extensions', description: 'Extension Bars (deeper slabs/beams)' },
		{ name: 'anchorHeads', description: 'Anchor Heads (fixed to element)' },
	];

	// If a cell has it's insitu starter bars disabled, these parts are ignored;
	public static insituMask = ['insituStarterBars', 'insituStarterInnerCouplers', 'insituStarterExtensions', 'outerCouplers'];

	public static nonAptusColourDropdownValues = [
		{ display: 'Grey', value: 'grey' },
		{ display: 'Light blue', value: 'lightblue' },
		{ display: 'Light green', value: 'lightgreen' },
		{ display: 'Dark green', value: 'darkgreen' },
		{ display: 'Pink', value: 'pink' },
		{ display: 'Purple', value: 'purple' },
		{ display: 'Black', value: 'black' },
	];

	/* GENERAL */

	public static cellDimensionsEmpty(width?: number, shape?: string, depth?: number) {
		return (!width && shape === 'rectangular') || !depth;
	}

	public static cleanSpecialCharacters(model: {}, stringToClean: string) {
		const regex = /[^a-zA-Z0-9 ]/gi;
		if (model[stringToClean] !== undefined && model[stringToClean].match(regex)) {
			model[stringToClean] = model[stringToClean].replace(regex, '');
		}
	}

	// For number inputs where we need positive integers
	public static cleanInt(model: {}, numberToClean: string, options?: { min?: number, max?: number, valueIfNull?: number }) {
		const { min, max, valueIfNull } = options ?? {};

		if (valueIfNull !== undefined && (model[numberToClean] === undefined || model[numberToClean] === null)) {
			model[numberToClean] = valueIfNull;
		}
		if (model[numberToClean] < 0) {
			model[numberToClean] = Math.abs(model[numberToClean]);
		}

		model[numberToClean] = Math.round(model[numberToClean]);

		if (min !== undefined && model[numberToClean] < min) {
			model[numberToClean] = min;
		}
		if (max !== undefined && model[numberToClean] > max) {
			model[numberToClean] = max;
		}
	}

	// For number inputs where we need positive floating poijnt numbers
	public static cleanFloat(model: {}, key: string) {
		if (model[key] !== undefined) {
			model[key] = parseFloat(model[key].toFixed(5));
		}
	}

	public static formatSSL(model: {}, stringToClean: string) {
		if (model[stringToClean] !== undefined) {
			model[stringToClean] = parseFloat(model[stringToClean].toFixed(3));
		}
	}

	public static getEmptyElementStructure(): ElementStructure {
		return {
			levels: [],
			columnTypes: [],
			cells: {},
			info: {
				project: {},
				temporaryWorks: {
					windRegion: 'B',
					terrainCategory: 'TC2.5',
					defaultExtensionUnderDepth: 'variable-stud-length',
					defaultExtensionOverDepth: 'blockouts',
					structuralDuctilityFactor: '2.0',
				},
				loading: {
					distributedLoad: 12,
				},
				levelDefaults: {},
				wizardProgress: {},
				editedSinceLastBuild: true,
				shutterUsage: {
					shutterUsagePct: 20,
					siteTemplateUsagePct: 50,
				},
			},
		};
	}

	public static getBuildTimeEstimate(project?: ProjectEntity): number {
		if (!project) {
			return 0;
		}

		const levelCount = project.parsedElementStructure.levels.length;
		const columnCount = project.parsedElementStructure.columnTypes.reduce((columnCount: number, columnType: ColumnType) => columnCount + columnType.columns.length, 0);

		return ((levelCount * columnCount) / 10) + 1;
	}

	public static elementStructureTotals(elementStructure: ElementStructure): CellPricesQuantities {
		const totals: CellPricesQuantities = {
			prices: {
				starters: 0.0,
				bars: 0.0,
				couplers: 0.0,
				extensions: 0.0,
				anchors: 0.0,
				total: 0.0,
			},
			quantities: {
				starters: 0,
				bars: 0,
				couplers: 0,
				extensions: 0,
				anchors: 0,
				total: 0,
			},
			shutters: {
				quantity: 0,
				price: 0.0,
			},
			siteTemplates: {
				quantity: 0,
				price: 0.0,
			},
			extras: {
				quantity: 0,
				price: 0.0,
			},
		};

		// Accumulate the prices and quantities from each cell
		elementStructure.columnTypes.forEach(columnType => {
			columnType.columns.forEach(column => {
				elementStructure.levels.forEach(level => {
					const cell = elementStructure.cells[column.id][level.id];

					const cellPrices = this.cellPrices(cell);
					totals.prices.starters += cellPrices.starters;
					totals.prices.bars += cellPrices.bars;
					totals.prices.couplers += cellPrices.couplers;
					totals.prices.extensions += cellPrices.extensions;
					totals.prices.anchors += cellPrices.anchors;

					const cellQuantities = this.cellQuantities(cell);
					totals.quantities.starters += cellQuantities.starters;
					totals.quantities.bars += cellQuantities.bars;
					totals.quantities.couplers += cellQuantities.couplers;
					totals.quantities.extensions += cellQuantities.extensions;
					totals.quantities.anchors += cellQuantities.anchors;
				});
			});
		});

		// Add the individual numbers together for the total
		totals.prices.total = totals.prices.starters + totals.prices.bars + totals.prices.couplers + totals.prices.extensions + totals.prices.anchors;
		totals.quantities.total = totals.quantities.starters + totals.prices.bars + totals.prices.couplers + totals.prices.extensions + totals.prices.anchors;

		// Find the prices and quantities for the shutters
		if (elementStructure.shutters) {
			elementStructure.shutters.forEach(shutter => {
				const shutterQuantity = shutter.quantity >= 0 ? shutter.quantity : 0;
				totals.shutters.quantity += shutterQuantity;
				totals.shutters.price += shutterQuantity * shutter.price;

				const siteTemplateQuantity = shutter.siteTemplateQuantity && shutter.siteTemplateQuantity >= 0 ? shutter.siteTemplateQuantity : 0;
				const siteTemplatePrice = shutter.siteTemplatePrice ? shutter.siteTemplatePrice : 0;
				totals.siteTemplates.quantity += siteTemplateQuantity;
				totals.siteTemplates.price += siteTemplateQuantity * siteTemplatePrice;
			});
		}

		// Find the prices and quantities for any additional parts
		if (elementStructure.additionalParts) {
			elementStructure.additionalParts.forEach(part => {
				const partQuantity = part.quantity >= 0 ? part.quantity : 0;
				const length = part.length >= 0 ? part.length : 0;

				totals.extras.quantity += partQuantity;
				totals.extras.price += (part.basePrice + (part.isBar ? (length / 1000 * part.reoPrice) : 0)) * partQuantity;
			});
		}

		return totals;
	}

	public static shutterTypeString(shutter: PricingShutter): string {
		if (shutter.isRound) {
			return `${shutter.depth} Ø - ${ElementStructureUtils.aptusBarQuantity('round', 0, shutter.aptusBarsAlongDepth)}/${shutter.couplerType}`;
		}
		return `${shutter.width} x ${shutter.depth} - ${ElementStructureUtils.aptusBarQuantity('rectangular', shutter.aptusBarsAlongWidth, shutter.aptusBarsAlongDepth)}/${shutter.couplerType}`;
	}

	public static shutterTypeStringFromCell(cell: Cell): string {
		const barSizeCouplers = {
			N20: 'ACS-24',
			N24: 'ACS-24',
			N28: 'ACS-32',
			N32: 'ACS-32',
			N36: 'ACS-40',
			N40: 'ACS-40',
		};

		if (!cell.aptusBarType || !cell.aptusBarsAlongDepth || (!cell.aptusBarsAlongWidth && cell.shape !== 'round')) {
			return '';
		}
		if (cell.shape === 'round') {
			return `${cell.depth} Ø - ${ElementStructureUtils.aptusBarQuantity('round', 0, cell.aptusBarsAlongDepth)}/${barSizeCouplers[cell.aptusBarType]}`;
		}
		return `${cell.width} x ${cell.depth} - ${ElementStructureUtils.aptusBarQuantity('rectangular', cell.aptusBarsAlongWidth, cell.aptusBarsAlongDepth)}/${barSizeCouplers[cell.aptusBarType]}`;
	}

	// checks whether an element structure has any duplicate level names or columnType/column paired names
	// Needed to check whether any cell will have a duplicate mark, which could cause problems
	public static elementStructureHasNoDuplicateNames(elementStructure: ElementStructure): boolean {
		const levelNameDict = {};
		for (let i = 0; i < elementStructure.levels.length; i++) {
			const level = elementStructure.levels[i];
			if (levelNameDict[level.name]) {
				return false;
			}
			levelNameDict[level.name] = true;
		}

		const columnNameDict = {};
		for (let i = 0; i < elementStructure.columnTypes.length; i++) {
			const columnType = elementStructure.columnTypes[i];
			for (let j = 0; j < columnType.columns.length; j++) {
				const column = columnType.columns[j];
				if (columnNameDict[columnType.code + column.name]) {
					return false;
				}
				columnNameDict[columnType.code + column.name] = true;
			}
		}

		return true;
	}

	// Check whether a column name will be a duplicate, before we add it
	public static columnTypeContainsColumnName(columnType: ColumnType, columnNameToCheck: string): boolean {
		for (let j = 0; j < columnType.columns.length; j++) {
			const column = columnType.columns[j];

			if (columnNameToCheck === column.name) {
				return true;
			}
		}

		return false;
	}

	/* CELLS */

	/**
	 * Searches element structure for cells that meets specified condition
	 * @param elementStructure
	 * @param condition condition that must be matched by a cell to be returned
	 */
	public static findCellsInStructure(elementStructure: ElementStructure, condition: (cell: Cell) => boolean): Array<Cell> {
		const cells = Object.values(elementStructure.cells).reduce((acc: Array<Cell>, cellDict: CellDict) => {
			// search CellDict for cells matching conditions
			const found = Object.values(cellDict)
				.reduce((foundCells: Array<Cell>, currCell: Cell) => condition(currCell)
					? [...foundCells, currCell]
					: foundCells,
				[]);

			return found.length ? [...acc, ...found] : acc;
		}, []);

		return cells;
	}

	public static cellCVString(cell: Cell): string {
		let cellLabel = '';
		if (cell.corbel && cell.corbel.width && cell.corbel.depth && cell.corbel.height) {
			cellLabel += 'C';
		}
		if (cell.elementVoid && cell.elementVoid.width && cell.elementVoid.depth && cell.elementVoid.height) {
			cellLabel += 'V';
		}
		return cellLabel;
	}

	public static cellDimensionString(cell: Cell): string {
		if (cell.shape === 'rectangular') {
			return `${cell.width} x ${cell.depth}`;
		}
		return `${cell.depth} ø`;
	}

	public static cellLoadString(cell: Cell): string {
		if (cell.calculatedLoad) {
			return `Nult (kN) ${cell.calculatedLoad.toFixed(0)}`;
		}
		return '';
	}

	public static cellAptusDesignString(cell: Cell): string {
		// We return a standard message for in-situ elements
		if (cell.aptusDesignConfiguration === 'nonaptus' && cell.insituElement === true) {
			return 'In-Situ design by others';
		}

		let aptusDesignString = '';
		const aptusQuantity = this.aptusBarQuantity(cell.shape, cell.aptusBarsAlongWidth, cell.aptusBarsAlongDepth);
		const nonAptusQuantity = this.nonAptusBarQuantity(cell.shape, cell.nonAptusBarsAlongWidth, cell.nonAptusBarsAlongDepth, cell.aptusDesignConfiguration);

		if (cell.aptusDesignConfiguration !== 'nonaptus' && cell.aptusBarType && aptusQuantity) {
			aptusDesignString = `${aptusQuantity}/${cell.aptusBarType} (Aptus)`;
			if (cell.nonAptusBarType && nonAptusQuantity) {
				aptusDesignString += ' + ';
			}
		}

		if (cell.nonAptusBarType && nonAptusQuantity) {
			aptusDesignString += `${nonAptusQuantity}/${cell.nonAptusBarType}`;
		}

		return aptusDesignString;
	}

	public static temporaryWorksDesignString(cell: Cell): string {
		// We return a standard message for in-situ elements
		if (cell.aptusDesignConfiguration === 'nonaptus' && cell.insituElement === true) {
			return 'In-Situ design by others';
		}

		let tempWorksDesignString = '';
		const tempWorksQuantity = this.aptusBarQuantity(cell.shape, cell.tempWorksBarsAlongWidth, cell.tempWorksBarsAlongDepth);
		if (cell.tempWorksBarType && tempWorksQuantity) {
			tempWorksDesignString = `${tempWorksQuantity}/${cell.tempWorksBarType} (Aptus)`;
		}

		return tempWorksDesignString;
	}

	public static concreteStrengthString(cell: Cell): string {
		// Usually we just return the number, but we make an exception for insitu elements
		if (ElementStructureUtils.isInsitu(cell)) {
			return '';
		}

		return cell.concreteStrength ? cell.concreteStrength.toString() : '';
	}

	public static cellQuantities(cell: Cell): CellTotal {
		const totals: CellTotal = {
			starters: 0,
			bars: 0,
			couplers: 0,
			extensions: 0,
			anchors: 0,
			total: 0,
		};

		if (cell.parts) {
			if (cell.parts.bars) {
				totals.bars += cell.parts.bars.quantity;
			}
			if (cell.parts.innerCouplers) {
				totals.couplers += cell.parts.innerCouplers.quantity;
			}
			if (cell.parts.extensions) {
				totals.extensions += cell.parts.extensions.quantity;
			}
			if (cell.parts.starterBars) {
				totals.starters += cell.parts.starterBars.quantity;
			}
			if (cell.parts.anchorHeads) {
				totals.anchors += cell.parts.anchorHeads.quantity;
			}

			if (!cell.disableInsituStarters) {
				if (cell.parts.insituStarterInnerCouplers) {
					totals.couplers += cell.parts.insituStarterInnerCouplers.quantity;
				}
				if (cell.parts.insituStarterExtensions) {
					totals.extensions += cell.parts.insituStarterExtensions.quantity;
				}
				if (cell.parts.insituStarterBars) {
					totals.starters += cell.parts.insituStarterBars.quantity;
				}
			}

			// Outer couplers usually connect to Aptus bars or normal starters
			// We only want to disable them if this cell is using insitu starter bars
			if (!cell.disableInsituStarters) {
				if (cell.parts.outerCouplers) {
					totals.couplers += cell.parts.outerCouplers.quantity;
				}
			}
		}

		totals.total = totals.starters + totals.couplers + totals.extensions + totals.anchors + totals.bars;

		return totals;
	}

	public static cellPrices(cell: Cell): CellTotal {
		const totals: CellTotal = {
			starters: 0.0,
			bars: 0.0,
			couplers: 0.0,
			extensions: 0.0,
			anchors: 0.0,
			total: 0.0,
		};
		if (cell.parts) {
			if (cell.parts.bars) {
				totals.bars += cell.parts.bars.combinedPrice * cell.parts.bars.quantity;
			}
			if (cell.parts.innerCouplers) {
				totals.couplers += cell.parts.innerCouplers.price * cell.parts.innerCouplers.quantity;
			}
			if (cell.parts.extensions) {
				totals.extensions += cell.parts.extensions.price * cell.parts.extensions.quantity;
			}
			if (cell.parts.starterBars) {
				totals.starters += cell.parts.starterBars.price * cell.parts.starterBars.quantity;
			}
			if (cell.parts.anchorHeads) {
				totals.anchors += cell.parts.anchorHeads.price * cell.parts.anchorHeads.quantity;
			}

			if (!cell.disableInsituStarters) {
				if (cell.parts.insituStarterInnerCouplers) {
					totals.couplers += cell.parts.insituStarterInnerCouplers.price * cell.parts.insituStarterInnerCouplers.quantity;
				}
				if (cell.parts.insituStarterExtensions) {
					totals.extensions += cell.parts.insituStarterExtensions.price * cell.parts.insituStarterExtensions.quantity;
				}
				if (cell.parts.insituStarterBars) {
					totals.starters += cell.parts.insituStarterBars.price * cell.parts.insituStarterBars.quantity;
				}
			}

			// Outer couplers usually connect to Aptus bars or normal starters
			// We only want to disable them if this cell is using insitu starter bars
			if (!cell.disableInsituStarters) {
				if (cell.parts.outerCouplers) {
					totals.couplers += cell.parts.outerCouplers.price * cell.parts.outerCouplers.quantity;
				}
			}
		}

		totals.total = totals.starters + totals.couplers + totals.extensions + totals.anchors + totals.bars;

		return totals;
	}

	public static aptusBarQuantity(cellShape?: CellShape, numberAlongWidth?: number, numberAlongDepth?: number): number {
		// Since this function is called by the CellEditView, and multiple cells can be selected, we won't always have a solid option for any field
		// In that case, we do the best we can do, and default to rectangular cells.
		if (cellShape === undefined) {
			cellShape = 'rectangular';
		}

		// Round Cell
		if (cellShape === 'round') {
			if (numberAlongDepth) {
				return numberAlongDepth;
			}
			return 0;
		}

		// Rectangular Cell
		// This is more complicated than it needs to be, but someone is going to enter a reo of 1, and we'd like our count to be accurate
		if (numberAlongWidth && numberAlongDepth) {
			return (numberAlongWidth * Math.min(2, numberAlongDepth)) + (Math.max(0, numberAlongDepth - 2) * Math.min(2, numberAlongWidth));
		}
		return 0;
	}

	public static nonAptusBarQuantity(cellShape?: CellShape, numberAlongWidth?: number, numberAlongDepth?: number, designConfiguration?: DesignConfiguration): number {
		// If this is a non-Aptus cell, then these will be the primary bars, rather than supporting bars.
		// As such, we use the Aptus bar algorithm to get a count
		if (designConfiguration === 'nonaptus') {
			return this.aptusBarQuantity(cellShape, numberAlongWidth, numberAlongDepth);
		}

		// Since this function is called by the CellEditView, and multiple cells can be selected, we won't always have a solid option for any field
		// In that case, we do the best we can do, and default to rectangular cells.
		if (cellShape === undefined) {
			cellShape = 'rectangular';
		}

		// Round Cell
		if (cellShape === 'round') {
			if (numberAlongDepth) {
				return numberAlongDepth;
			}
			return 0;
		}

		// Rectangular Cell
		if (!numberAlongWidth) {
			numberAlongWidth = 0;
		}
		if (!numberAlongDepth) {
			numberAlongDepth = 0;
		}

		return (numberAlongWidth + numberAlongDepth) * 2;
	}

	public static cellLigatureDesignString(cell: Cell): string {
		// We don't show this for insitu elements
		if (ElementStructureUtils.isInsitu(cell)) {
			return '';
		}

		let design = '';

		if (cell.aptusBarLigType && cell.aptusBarLigSpacing && cell.aptusDesignConfiguration !== 'nonaptus') {
			design += `${cell.aptusBarLigType}-${cell.aptusBarLigSpacing}`;

			if (cell.nonAptusBarLigType && cell.nonAptusBarLigSpacing) {
				design += '; ';
			}
		}
		if (cell.nonAptusBarLigType && cell.nonAptusBarLigSpacing) {
			design += `${cell.nonAptusBarLigType}-${cell.nonAptusBarLigSpacing}`;
		}

		return design;
	}

	public static cellReoRateString(cell: Cell): string {
		if (cell.reoRate) {
			return `Reo Rate ${cell.reoRate} kg/m\u00B3`;
		}
		return '';
	}

	public static cellConcreteStrengthString(cell: Cell): string {
		if (cell.concreteStrength && (cell.aptusDesignConfiguration !== 'nonaptus' || !cell.insituElement)) {
			return `${cell.concreteStrength} MPa`;
		}
		return '';
	}

	public static cellUnitMassString(cell: Cell): string {
		if (cell.unitMass) {
			return `Unit Mass (t)${cell.unitMass.toFixed(2)}`;
		}
		return '';
	}

	public static cellTransitionDescription(cell: Cell): string {
		if (cell.transitionDescription) {
			return `${cell.transitionDescription}`;
		}
		return '';
	}

	public static cellTransitionColour(cell: Cell): string {
		if (cell.transitionColour) {
			return `${cell.transitionColour}`;
		}
		return '';
	}

	public static cellNonAptusColour(cell: Cell): string {
		if (cell.nonAptusColour) {
			return `${cell.nonAptusColour}`;
		}
		return '';
	}

	static calculateCellHeight = (elementStructure: ElementStructure, cell: Cell) => {
		// Get cell ssl in meters
		const topSSL = ElementStructureUtils.cellTopSSL(elementStructure, cell);
		const bottomSSL = ElementStructureUtils.cellBottomSSL(elementStructure, cell);

		const sslDifference = topSSL - bottomSSL;

		// cell height in meters
		return sslDifference < 0
			? 0
			: sslDifference;
	};

	public static calculateCellUnitMass(elementStructure: ElementStructure, cell: Cell) {
		// Get cell height
		const topSSL = ElementStructureUtils.cellTopSSL(elementStructure, cell);
		const bottomSSL = ElementStructureUtils.cellBottomSSL(elementStructure, cell);
		let cellHeight = topSSL - bottomSSL;
		if (cellHeight < 0) {
			cellHeight = 0;
		}

		const [volume, corbelVolume, voidVolume] = ElementStructureUtils.calculateCellVolumes(elementStructure, cell);

		// Mass is volume multiplied by 2.55, for a quick approximation
		cell.unitMass = (volume + corbelVolume - voidVolume) * 2.55;

		// While we're here, we save the cell height. This is displayed on the schedule of rates
		cell.height = Math.round(cellHeight * 1000);
	}

	public static calculateAptusRebarMass = (cell: Cell) => {
		return cell.aptusBarMassDict
			? Object.values(cell.aptusBarMassDict)
				.reduce((acc, curr) => acc + curr, 0.0)
			: 0.0;
	}

	public static calculateNonAptusRebarMass = (elementStructure: ElementStructure, cell: Cell) => {
		if (!cell.nonAptusBarMassDict) return 0.0;

		const corbelReoMass = ElementStructureUtils.calculateCorbelReoMass(elementStructure, cell);

		const nonAptusBarMass = cell.nonAptusBarMassDict
			? Object.values(cell.nonAptusBarMassDict).reduce((acc, curr) => acc + curr, 0.0)
			: 0.0;

		return nonAptusBarMass
			+ corbelReoMass
			+ (cell.aptusLigMassDict ? ElementStructureUtils.calculateAptusLigMass(cell.aptusLigMassDict) : 0.0)
			+ (cell.nonAptusLigMassDict ? ElementStructureUtils.calculateNonAptusLigMass(cell.nonAptusLigMassDict) : 0.0);
	};

	public static calculateAptusLigMass = (aptusLigMassDict: { [key:string]: number }) => {
		return Object.values(aptusLigMassDict).reduce((acc, curr) => acc + curr, 0.0);
	};

	public static calculateNonAptusLigMass = (nonAptusLigMassDict: { [key:string]: number }) => {
		return Object.values(nonAptusLigMassDict).reduce((acc, curr) => acc + curr, 0.0);
	};

	public static cellTopSSL(elementStructure: ElementStructure, cell: Cell, overrideCellAbove?: Cell, includeSlabThickness?: boolean): number {
		// Find the cell above, if any
		let cellAbove: Cell | undefined = overrideCellAbove
			?? ElementStructureUtils.findCellAboveGivenCell(elementStructure, cell);
		// If cellAbove is flagged as deleted, we dont want to use it anyway
		if (cellAbove?.deleted) cellAbove = undefined;

		// We can either grab the top of the precast element, or the top of the concrete slab above it
		let slabThicknessToSubtract = 0;
		if (!includeSlabThickness) {
			if (cellAbove) {
				slabThicknessToSubtract = cellAbove.slabThicknessAtBase / 1000;
			} else if (cell.slabThicknessAtTop) {
				slabThicknessToSubtract = cell.slabThicknessAtTop / 1000;
			} else {
				// Can't find a slab thickness, so we'll use the default
				slabThicknessToSubtract = this.defaultSlabThickness / 1000;
			}
		}

		// If there's a cell above us with a custom SSL, we use that
		if (cellAbove && cellAbove.overrideSSL && (cellAbove.ssl || cellAbove.ssl === 0)) {
			return cellAbove.ssl - slabThicknessToSubtract;
		}

		// If there's no cell above us, we check if we have our own overridden SSL
		if (!cellAbove && cell.overrideTopSsl && cell.topSsl) {
			return cell.topSsl - slabThicknessToSubtract;
		}

		// If it's a merged cell, it could have multiple levels, so we grab the top one
		const topLevel = ElementStructureUtils.findTopLevelOfCell(elementStructure, cell);
		const levelAbove = ElementStructureUtils.findLevelAboveGivenLevel(elementStructure, topLevel.id);
		if (levelAbove) {
			return levelAbove.ssl - slabThicknessToSubtract;
		} if (topLevel.topSsl) {
			return topLevel.topSsl - slabThicknessToSubtract;
		}
		// Top ssl isn't set, so we'll just return the bottom ssl
		return topLevel.ssl;
	}

	public static cellBottomSSL(elementStructure: ElementStructure, cell: Cell): number {
		if (cell.overrideSSL && cell.ssl) {
			return cell.ssl;
		}
		const currentLevel = elementStructure.levels.find(level => level.id === cell.levelId);
		if (currentLevel) {
			return currentLevel.ssl;
		}
		// should never reach here, since each cell should be associated with a level
		throw new Error('Cell does not belong to Element Structure');
	}

	public static createEmptyCell(elementStructure: ElementStructure, column: Column, level: Level, fixMergedCellAnomalies: boolean = false) {
		const newCell: Cell = observable({
			id: uuid.v4(),
			columnId: column.id,
			levelId: level.id,

			shape: 'rectangular',
			width: 0,
			depth: 0,
			slabThicknessAtBase: level.slabThicknessAtBase,
			// Note: We don't handle slabThicknessAtTop here. If this cell is at the top, you'll need to add it after

			calculatedLoad: 0,
			additionalLoad: 0,
			applyLoadAtEveryLevel: true,

			aptusDesignConfiguration: 'aptus',
			aptusBarQuantity: undefined,
			aptusBarType: undefined,
			nonAptusBarQuantity: undefined,
			nonAptusBarType: undefined,
			aptusBarLigSpacing: undefined,
			aptusBarLigType: undefined,
			nonAptusBarLigSpacing: undefined,
			nonAptusBarLigType: undefined,
			concreteStrength: 50,
			unitMass: undefined,

			majorAxisMoment: 0,
			minorAxisMoment: 0,
			kFactor: 0.85,
			ligDesign: undefined,

			levelHeight: 1,
			merged: false,
			deleted: false,
			approved: false,
			changed: false,

			extensionUnderDepth: elementStructure.info.temporaryWorks.defaultExtensionUnderDepth,
			extensionOverDepth: elementStructure.info.temporaryWorks.defaultExtensionOverDepth,
		});

		if (!elementStructure.cells[column.id]) {
			elementStructure.cells[column.id] = {};
		}
		elementStructure.cells[column.id][level.id] = newCell;

		// when adding a new cell between merged cells, we want to heighten the merged cell
		if (fixMergedCellAnomalies) {
			// Build an array of cells for the current column
			const cellArray = elementStructure.levels.map(elementStructureLevel => {
				return elementStructure.cells[column.id][elementStructureLevel.id];
			});

			// Go through the cell list (in reverse order), and figure out if we need to change anything
			let mergedCell: Cell|undefined;
			let remainingHeight = 0;
			for (let i = cellArray.length - 1; i >= 0; i--) {
				const cell = cellArray[i];

				if (cell.id === newCell.id) {
					// We've found the newly created cell. If we're in the middle of a merged cell, we add the new cell to it
					// Otherwise we just leave
					if (mergedCell) {
						newCell.merged = true;
						mergedCell.levelHeight++;
					}
					break;
				} else {
					// This is not the newly created cell. We don't need to edit it, but we'll look for merged cells
					if (cell.levelHeight > 1) {
						mergedCell = cell;
						remainingHeight = cell.levelHeight - 1; // don't count its own height
						return;
					}
					if (mergedCell) {
						remainingHeight--;
						if (remainingHeight <= 0) {
							mergedCell = undefined;
						}
					}
				}
			}
		}

		return newCell;
	}

	@action public static deleteCell(elementStructure: ElementStructure, cell: Cell, fixMergedCellAnomalies: boolean = false) {
		// If this is a merged cell, so we need to find the cell it was merged into, and shorten it
		if (fixMergedCellAnomalies && (cell.merged || cell.levelHeight > 1)) {
			// We find the cell, then look for the next one which has a levelHeight greater than 1
			// Go through the cell list (in reverse order), and figure out if we need to change anything
			let foundCell = false;
			for (let i = 0; i < elementStructure.levels.length; i++) {
				const currentCell = elementStructure.cells[cell.columnId][elementStructure.levels[i].id];

				if (!foundCell && currentCell.id === cell.id) {
					if (currentCell.merged) {
						foundCell = true;
						continue;
					} else {
						// This hasn't been merged into another cell. It's the merged cell itself (levelheight > 1)
						const prevCell = elementStructure.cells[cell.columnId][elementStructure.levels[i - 1].id];
						prevCell.merged = false;
						prevCell.levelHeight = currentCell.levelHeight - 1;
						break;
					}
				}

				if (foundCell && currentCell.levelHeight > 1) {
					currentCell.levelHeight--;
					break;
				}
			}
		}

		delete elementStructure.cells[cell.columnId][cell.levelId];
	}

	// Clean up cells before they're submitted to the backend
	@action
	public static cleanCells(elementStructure: ElementStructure) {
		const columnIndex = elementStructure.columnTypes
			.reduce((acc, curr) => {
				const ids = curr.columns.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {});

				return ({ ...acc, ...ids });
			}, {});

		const levelIndex = elementStructure.levels
			.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {});

		// remove cells belonging to deleted columns or levels
		Object.keys(elementStructure.cells).forEach(colId => {
			if (!columnIndex[colId]) {
				console.log('Found orphaned cells belonging to deleted column - deleting');
				delete elementStructure.cells[colId];
			} else {
				Object.keys(elementStructure.cells[colId]).forEach(levelId => {
					if (!levelIndex[levelId]) {
						console.log('Found orphaned cell belonging to deleted level - deleting');
						delete elementStructure.cells[colId][levelId];
					}
				});
			}
		});

		ElementStructureUtils.cellIterator(elementStructure, (columnType, column, level) => {
			const cell = elementStructure.cells[column.id][level.id];

			// convert any empty cells into deleted cells
			if (!cell.depth || (!cell.width && cell.shape !== 'round')) {
				cell.deleted = true;
			}

			// set all cells to show warnings
			cell.hideWarning = false;
		});
	}

	// Given a merged cell, we want to find the cell it's been merged into
	public static findCellGivenCellIsMergedInto(elementStructure: ElementStructure, cell: Cell) {
		// check if the cell is actually a part of a merged cell
		if (!cell.merged) {
			// The cell might be the bottommost cell in the merged cell
			if (cell.levelHeight > 1) {
				return cell;
			}
			return null;
		}

		// Loop through all cells in this column, to find the merge cell
		let foundCell = false;
		for (let i = 0; i < elementStructure.levels.length; i++) {
			const currentCell = elementStructure.cells[cell.columnId][elementStructure.levels[i].id];

			if (!foundCell && currentCell.id === cell.id) {
				foundCell = true;
				continue;
			}

			if (foundCell && currentCell.levelHeight > 1) {
				return currentCell;
			}
		}

		// Couldn't find the cell, so we return null
		return null;
	}

	// Given a cell, find the cell above it (or undefined if none exists)
	public static findCellAboveGivenCell(elementStructure: ElementStructure, cell: Cell): Cell|undefined {
		for (let i = 0; i < elementStructure.levels.length; i++) {
			if (elementStructure.levels[i].id === cell.levelId) {
				// If this is the top cell, there's nothing above it
				if (i - cell.levelHeight < 0) {
					return undefined;
				}

				// Otherwise we return what's above it, or undefined if that cell is deleted
				return elementStructure.cells[cell.columnId][elementStructure.levels[i - cell.levelHeight].id];
			}
		}
		return undefined;
	}

	// Given a cell, find the cell below it (or undefined if none exists)
	public static findCellBelowGivenCell(elementStructure: ElementStructure, cell: Cell, directlyBelow = true): Cell|undefined {
		let foundCell = false;
		for (let i = 0; i < elementStructure.levels.length; i++) {
			if (!foundCell) {
				if (elementStructure.levels[i].id === cell.levelId) {
					foundCell = true;
				}
			} else {
				const currentCell = elementStructure.cells[cell.columnId][elementStructure.levels[i].id];
				// only continue looking past deleted cells below if not retrieving cell directly below specified cell
				if (!currentCell.merged && (directlyBelow === true || !currentCell.deleted)) {
					return currentCell;
				}
			}
		}
		return undefined;
	}

	// Given a (possibly merged) cell, find the top level it belongs to
	public static findTopLevelOfCell(elementStructure: ElementStructure, cell: Cell): Level {
		for (let i = 0; i < elementStructure.levels.length; i++) {
			if (elementStructure.levels[i].id === cell.levelId) {
				const topLevelId = i - (cell.levelHeight - 1);
				return elementStructure.levels[topLevelId];
			}
		}
		// We should only reach here if the cell didn't belong to any level in the element structure
		throw Error('Cell does not belong to Element Structure');
	}

	@action
	public static invalidAxialLoadCSV(elementStructure: ElementStructure, fileAxialLoads: AxialLoadsData[]) {
		// Get a list of LevelNames and Column Names currently in the project for comparison against entered values in the CSV
		const levelNames = elementStructure.levels.map(l => l.name);
		const columnNames: string [] = [];
		elementStructure.columnTypes.forEach(t => t.columns.forEach(c => columnNames.push(t.code + c.name)));
		// Load values of 0 are not allowed.
		// Non number values for loads and moments are not allowed.
		// ColumnNames and LevelNames that do not exist are not allowed.
		return !!fileAxialLoads.find(x => x.load === 0
			|| isNaN(x.load)
			|| isNaN(x.majorAxisMoment)
			|| isNaN(x.minorAxisMoment)
			|| !columnNames.find(c => x.columnName === c)
			|| !levelNames.find(l => x.levelName === l));
	}

	@action
	public static updateAxialLoads(elementStructure: ElementStructure, axialLoads: AxialLoadsData[], mutateElementStructure = true) {
		// Convert the axial loads data into a dict, to increase the speed of the algorithm
		const axialLoadsDict: AxialLoadsDict = {};

		axialLoads.forEach(load => {
			axialLoadsDict[load.levelName + load.columnName] = load;
		});

		// Keep a count of how many cells have changes (Or would change, if this operation were actually run)
		let cellChangeCount = 0;

		const newChangedCellIds: string[] = [];

		// Loop through all cells, and see if any match our axial loads
		ElementStructureUtils.cellIterator(elementStructure, (columnType, column, level) => {
			const cellName = level.name + columnType.code + column.name;
			if (axialLoadsDict[cellName]) {
				// We won't override loads for approved elements
				const cell = elementStructure.cells[column.id][level.id];
				if (!cell.approved) {
					// Check if this cell actually requires any changes
					if (cell.calculatedLoad !== axialLoadsDict[cellName].load
						|| cell.majorAxisMoment !== axialLoadsDict[cellName].majorAxisMoment
						|| cell.minorAxisMoment !== axialLoadsDict[cellName].minorAxisMoment) {
						cellChangeCount++;

						if (mutateElementStructure) {
							// If we're mutating the structure, update the cell and push to list of ids
							cell.overrideCalculatedLoad = true;
							cell.calculatedLoad = axialLoadsDict[cellName].load;
							cell.majorAxisMoment = axialLoadsDict[cellName].majorAxisMoment;
							cell.minorAxisMoment = axialLoadsDict[cellName].minorAxisMoment;
							newChangedCellIds.push(cell.id);
						}
					}
				}
			}
		});

		if (mutateElementStructure) {
			// If we're mutating the structure, update the list of changed cell ids
			ElementStructureUtils.cellChangedIdList = newChangedCellIds;
		}

		// return the number of cells which have changed/would change
		return cellChangeCount;
	}

	/* CALCULATE CELL VOLUME */

	/**
	 * Calculates volume for rectangular prism
	 * @param width with in mm
	 * @param depth depth in mm
	 * @param height height in mm
	 * @returns Volume of rectangular prism  in m^2
	 */
	static rectangularVolume = (width: number | undefined, depth: number, height: number) => width
		? (height / 1000) * (width / 1000) * (depth / 1000)
		: 0;

	/**
	 * Calculates volume for cylinder
	 * @param depth depth of cell in mm (we use this as the diameter)
	 * @param height height of cell (we use this as the length of the cylinder)
	 * @returns Volume of cylinder in m^2
	 */
	// static roundVolume = (depth: number, height: number) => height * (Math.PI * Math.pow(depth / 2000, 2));

	// we divide the depth by 2000 to convert it to the radius in meters
	static roundVolume = (depth: number, height: number) => height * (Math.PI * (depth / 2000) ** 2);

	/**
	 * Calculates corbel volume
	 * @param corbel Entire Corbel object
	 * @param cellWidth Cell width in mm
	 * @param cellDepth Cell depth in mm
	 * @param cellHeight Cell height in mm
	 * @returns Corbel volume in m^2
	 */
	static corbelVolume = (
		corbel: Corbel,
		cellWidth: number | undefined,
		cellDepth: number | undefined,
		cellHeight: number | undefined,
	): number => {
		const width = corbel.useCellWidth ? cellWidth : corbel.width;
		const depth = corbel.useCellDepth ? cellDepth : corbel.depth;
		const height = corbel.useCellHeight ? cellHeight : corbel.height;

		return (width! / 1000) * (depth! / 1000) * (height! / 1000);
	};

	/**
	 * Calculates Void volume
	 * @param elementVoid Entire Void object
	 * @param cellWidth Cell width in mm
	 * @param cellDepth Cell depth in mm
	 * @param cellHeight Cell height in mm
	 * @returns Corbel volume in m^2
	 */
	static voidVolume = (
		elementVoid: ElementVoid,
		cellWidth: number | undefined,
		cellDepth: number | undefined,
		cellHeight: number | undefined,
	) => {
		const width = elementVoid.useCellWidth ? cellWidth : elementVoid.width;
		const depth = elementVoid.useCellDepth ? cellDepth : elementVoid.depth;
		const height = elementVoid.useCellHeight ? cellHeight : elementVoid.height;

		return (width! / 1000) * (depth! / 1000) * (height! / 1000);
	};

	/**
	 * Calculates the different cell volumes, and returns the different type in an array
	 * @param elementStructure
	 * @param cell
	 * @returns Array of different cell volumes in m^2
	 */
	public static calculateCellVolumes(elementStructure: ElementStructure, cell: Cell): [cellVolume: number, corbelVolume: number, voidVolume: number] {
		// cell height in meters
		const cellHeight = this.calculateCellHeight(elementStructure, cell);

		// different formulas for calculating volume for round and rectangular columns
		const cellVolume = cell.shape === 'rectangular'
			? this.rectangularVolume(cell.width, cell.depth, cellHeight * 1000)
			: this.roundVolume(cell.depth, cellHeight);

		const { corbel, elementVoid } = cell;

		// calculate corbel volume (volume added to cell volume)
		const corbelVolume = corbel && corbel.width && corbel.depth && cellHeight
			? this.corbelVolume(corbel, cell.width, cell.depth, cellHeight * 1000)
			: 0;

		// calculate void volume (volume subtracted from cell volume)
		const voidVolume = elementVoid && elementVoid.width && elementVoid.depth && cellHeight
			? this.voidVolume(elementVoid, cell.width, cell.depth, cellHeight * 1000)
			: 0;

		return [cellVolume, corbelVolume, voidVolume];
	}

	/* COLUMN TYPES */

	@action
	public static addColumnTypeToElementStructure(elementStructure: ElementStructure, columnTypeFields?: NewColumnTypeFields, insertIndex?: number) {
		if (!columnTypeFields) {
			columnTypeFields = {};
		}

		// Create a new level
		const columnType: ColumnType = observable({
			id: uuid.v4(),
			code: columnTypeFields.code ? columnTypeFields.code : '??',
			name: columnTypeFields.name ? columnTypeFields.name : 'Unnamed Column Type',
			estimatedColumnCount: columnTypeFields.estimatedColumnCount ? columnTypeFields.estimatedColumnCount : 0,
			columns: [],
		});

		// We want to add the level to our element structure, then add cells to fill out the level
		// First we add the level
		if (insertIndex !== undefined) {
			elementStructure.columnTypes.splice(insertIndex, 0, columnType);
		} else {
			elementStructure.columnTypes.push(columnType);
		}

		return columnType;
	}

	@action
	public static deleteColumnTypeFromElementStructure(elementStructure: ElementStructure, columnType: ColumnType) {
		// We delete all the columns associated with this column type
		columnType.columns.forEach(column => {
			this.deleteColumnFromElementStructure(elementStructure, column);
		});

		// We remove the column type from the list
		elementStructure.columnTypes = elementStructure.columnTypes.filter(columnTypeToFilter => {
			return columnType.id !== columnTypeToFilter.id;
		});

		return true;
	}

	/* COLUMNS */

	public static getTopCellOfColumn(elementStructure: ElementStructure, column: Column) {
		// get the top Non-empty cell of the column
		for (let i = 0; i < elementStructure.levels.length; i++) {
			if (!elementStructure.cells[column.id][elementStructure.levels[i].id].deleted) {
				return elementStructure.cells[column.id][elementStructure.levels[i].id];
			}
		}
		return null;
	}

	public static getCellsOfColumn(elementStructure: ElementStructure, column: Column) {
		// Get all non empty cells of the column
		const cells: Cell[] = [];

		for (let i = 0; i < elementStructure.levels.length; i++) {
			if (!elementStructure.cells[column.id][elementStructure.levels[i].id].deleted) {
				cells.push(elementStructure.cells[column.id][elementStructure.levels[i].id]);
			}
		}

		if (cells.length > 0) {
			return cells;
		}

		return null;
	}

	@action
	public static addColumnToElementStructure(elementStructure: ElementStructure, typeId: string, columnFields?: NewColumnFields, insertIndex?: number) {
		if (!columnFields) {
			columnFields = {};
		}

		const defaultDistributedLoad = elementStructure.info.loading && elementStructure.info.loading.distributedLoad ? elementStructure.info.loading.distributedLoad : 12;
		const defaultColumnX = elementStructure.info.loading ? elementStructure.info.loading.columnX : 0;
		const defaultColumnY = elementStructure.info.loading ? elementStructure.info.loading.columnY : 0;

		// Create a new level
		const column: Column = observable({
			id: uuid.v4(),
			name: columnFields.name ? columnFields.name : '???',
			load: columnFields.load ? columnFields.load : defaultDistributedLoad,
			loadArea: columnFields.loadArea ? columnFields.loadArea : (defaultColumnX || 0) * (defaultColumnY || 0),
		});

		// Find the column type we're adding to
		// If none match, then we just add this column to the first column type in the list.
		if (elementStructure.columnTypes.length === 0) {
			// there's no column types, then we exit early
			return undefined;
		}
		let columnType: ColumnType = elementStructure.columnTypes[0];
		elementStructure.columnTypes.forEach(type => {
			if (type.id === typeId) {
				columnType = type;
			}
		});

		// We want to add the column to our element structure, then add cells to fill out the column
		// First we add the column
		if (insertIndex !== undefined) {
			columnType.columns.splice(insertIndex, 0, column);
		} else {
			columnType.columns.push(column);
		}

		// Next we create empty cells for each column
		elementStructure.levels.forEach(level => {
			this.createEmptyCell(elementStructure, column, level);
		});

		// The top cell of the new column should have its slabThicknessAtTop set
		if (elementStructure.levels.length > 0) {
			const cell = elementStructure.cells[column.id][elementStructure.levels[0].id];
			cell.slabThicknessAtTop = this.defaultSlabThickness;
		}

		return column;
	}

	@action
	public static deleteColumnFromElementStructure(elementStructure: ElementStructure, column: Column) {
		// We delete all the cells assigned to this column
		delete elementStructure.cells[column.id];

		// We remove the column from the list
		elementStructure.columnTypes.forEach(columnType => {
			columnType.columns = columnType.columns.filter(columnToFilter => {
				return column.id !== columnToFilter.id;
			});
		});

		return true;
	}

	/* LEVELS */

	@action
	public static addLevelToElementStructure(elementStructure: ElementStructure, levelFields?: NewLevelFields, insertIndex?: number) {
		if (!levelFields) {
			levelFields = {};
		}

		// Can't add a level out of bounds. We also want to set it if it's undefined.
		if (insertIndex === undefined || insertIndex > elementStructure.levels.length) {
			insertIndex = elementStructure.levels.length;
		}
		if (insertIndex < 0) {
			insertIndex = 0;
		}

		// Get default values to use for slab thickness
		let slabThicknessAtBase = this.defaultSlabThickness;
		if (elementStructure.info.levelDefaults.typicalSlabThickness) {
			slabThicknessAtBase = elementStructure.info.levelDefaults.typicalSlabThickness;
		}

		// Get a default value to use for the cell's SSL
		// If we add a level above an existing level, we use the SSL of the level below (or its top ssl if we're at the top of the building)
		// If we add a level below, we subtract the level above's floor height from its ssl
		// If there's no existing levels, we just use 0
		let newLevelDefaultSsl = 0.0;
		if (elementStructure.levels.length !== 0) {
			if (insertIndex === elementStructure.levels.length) {
				// We're the last level in the list
				const levelAbove = elementStructure.levels[insertIndex - 1];
				newLevelDefaultSsl = levelAbove.ssl - (levelAbove.calculatedFloorHeight || 0) / 1000;
			} else {
				// We're not the last level in the list, so there's a level below us, which is in our current index position
				const levelBelow = elementStructure.levels[insertIndex];
				newLevelDefaultSsl = (insertIndex === 0 && levelBelow.topSsl) ? levelBelow.topSsl : levelBelow.ssl;
			}
		}

		// Create a new level
		const level: Level = observable({
			id: uuid.v4(),
			code: levelFields.code ? levelFields.code : '??',
			name: levelFields.name ? levelFields.name : 'Unnamed Level',
			slabThicknessAtBase: levelFields.slabThicknessAtBase ? levelFields.slabThicknessAtBase : slabThicknessAtBase,
			groundLevel: levelFields.groundLevel ? levelFields.groundLevel : false,
			ssl: levelFields.ssl ? levelFields.ssl : newLevelDefaultSsl,
			topSsl: levelFields.topSsl ? levelFields.topSsl : undefined,
		});

		// We want to add the level to our element structure, then add cells to fill out the level
		// First we add the level
		elementStructure.levels.splice(insertIndex, 0, level);

		// Next we create empty cells for each column
		elementStructure.columnTypes.forEach(columnType => {
			columnType.columns.forEach(column => {
				this.createEmptyCell(elementStructure, column, level, true);
			});
		});

		// If we've added a level to the top of the building, we'll need a topSsl
		if (insertIndex === 0 || elementStructure.levels.length <= 1) {
			if (elementStructure.levels[0].topSsl === undefined) {
				let typicalFloorHeight = 0;
				if (elementStructure.info.levelDefaults.typicalFloorHeight) {
					typicalFloorHeight = elementStructure.info.levelDefaults.typicalFloorHeight;
				}

				elementStructure.levels[0].topSsl = elementStructure.levels[0].ssl + typicalFloorHeight / 1000;
			}

			// We'll also need to unset the topSsl of the level below, if any
			if (elementStructure.levels.length > 1) {
				elementStructure.levels[1].topSsl = undefined;
			}

			// We'll need to give each cell a slabThicknessAtTop.
			// We'll also override each cell's slabThicknessAtBase with the cell below's slabThicknessAtTop, if any.
			elementStructure.columnTypes.forEach(columnType => {
				columnType.columns.forEach(column => {
					const cell = elementStructure.cells[column.id][elementStructure.levels[0].id];
					cell.slabThicknessAtTop = this.defaultSlabThickness;

					if (elementStructure.levels.length > 1) {
						const cellBelow = elementStructure.cells[column.id][elementStructure.levels[1].id];
						if (cellBelow.slabThicknessAtTop) {
							cell.slabThicknessAtBase = cellBelow.slabThicknessAtTop;
							cellBelow.slabThicknessAtTop = undefined;
						}
					}
				});
			});
		}

		return level;
	}

	@action
	public static deleteLevelFromElementStructure(elementStructure: ElementStructure, level: Level) {
		// Get index of current level
		const levelIndex = elementStructure.levels.indexOf(level);
		if (levelIndex === -1) {
			// This level isn't actually in the array, so we can quit early.
			return false;
		}

		// We can't delete the ground level
		if (level.groundLevel) {
			return false;
		}

		// if this is the top level, we want to assign its ssl to the topSsl of the next level
		if (levelIndex === 0 && elementStructure.levels.length > 1) {
			elementStructure.levels[levelIndex + 1].topSsl = level.ssl;

			// For each cell, inside the top level, we want it to assign its slabThicknessAtBase to the slabThicknessAtTop below
			elementStructure.columnTypes.forEach(columnType => {
				columnType.columns.forEach(column => {
					const cell = elementStructure.cells[column.id][elementStructure.levels[0].id];

					// We want the previous slabThicknessAtBase of the top cell to become the next cell's slabThicknessAtTop
					// But only if the top cell wasn't merged
					if (!cell.merged) {
						const cellBelow = ElementStructureUtils.findCellBelowGivenCell(elementStructure, cell);
						if (cellBelow) {
							cellBelow.slabThicknessAtTop = cell.slabThicknessAtBase;
						}
					}
				});
			});
		}

		// We delete all the cells assigned to this level
		elementStructure.columnTypes.forEach(columnType => {
			columnType.columns.forEach(column => {
				this.deleteCell(elementStructure, elementStructure.cells[column.id][level.id], true);
			});
		});

		// We remove the level from the list
		elementStructure.levels.splice(levelIndex, 1);
		return true;
	}

	@action public static generateLevels(elementStructure: ElementStructure, levelsAboveGround: number, levelsBelowGround: number) {
		// We can only generate levels if our first four inputs are set
		if (levelsAboveGround === undefined
			|| levelsBelowGround === undefined
			|| !elementStructure.info.levelDefaults.typicalFloorHeight
			|| !elementStructure.info.levelDefaults.typicalSlabThickness) {
			return false;
		}

		// First, if there are any levels, we remove them all
		// We even remove the ground level, although we'll add it back soon
		elementStructure.levels = [];

		// Get the ground level SSL
		let groundSsl = 0.0;
		if (elementStructure.info.temporaryWorks.groundLevelRL) {
			groundSsl = elementStructure.info.temporaryWorks.groundLevelRL;
		}

		// Now we add the ground floor
		ElementStructureUtils.addLevelToElementStructure(elementStructure, {
			name: 'Ground Level',
			code: 'GL',
			groundLevel: true,
			ssl: groundSsl,
		});

		// Add floors above ground
		for (let i = 0; i < levelsAboveGround; i++) {
			const levelNumber = (`${i + 1}`).padStart(2, '0');
			ElementStructureUtils.addLevelToElementStructure(elementStructure, {
				name: `Level ${levelNumber}`,
				code: levelNumber,
				ssl: groundSsl + ((i + 1) * elementStructure.info.levelDefaults.typicalFloorHeight) / 1000,
			}, 0);
		}

		// Add floors below ground
		for (let i = 0; i < levelsBelowGround; i++) {
			const levelNumber = `B${i + 1}`;
			ElementStructureUtils.addLevelToElementStructure(elementStructure, {
				name: `Level ${levelNumber}`,
				code: levelNumber,
				ssl: groundSsl - ((i + 1) * elementStructure.info.levelDefaults.typicalFloorHeight) / 1000,
			});
		}

		// Add topSsl to top level
		const topLevel = elementStructure.levels[0];
		topLevel.topSsl = topLevel.ssl + elementStructure.info.levelDefaults.typicalFloorHeight / 1000;

		// Calculate RLs
		ElementStructureUtils.regenerateLevelHeights(elementStructure);

		// We succeeded, so we return true
		return true;
	}

	// Given a cell, find the level above it (or undefined if none exists)
	public static findLevelAboveGivenLevel(elementStructure: ElementStructure, levelId: string): Level|undefined {
		const levelIndex = elementStructure.levels.findIndex(l => l.id === levelId);
		if (levelIndex > 0) {
			return elementStructure.levels[levelIndex - 1];
		}
		return undefined;
	}

	// Given a cell, find the level below it (or undefined if none exists)
	public static findLevelBelowGivenLevel(elementStructure: ElementStructure, levelId: string): Level|undefined {
		const levelIndex = elementStructure.levels.findIndex(l => l.id === levelId);
		if (levelIndex >= 0 && levelIndex < elementStructure.levels.length - 1) {
			return elementStructure.levels[levelIndex + 1];
		}
		return undefined;
	}

	/**
	 * Calculate the height for a given level and SSLs
	 * @param elementStructure current element structure
	 * @param levelId Id of level height to be calculated for
	 * @param ssl specified level ssl in m
	 * @param topSsl specified level top ssl in m
	 * @returns height of level in mm
	 */
	public static calculateLevelHeight(elementStructure: ElementStructure, levelId: string, ssl: number | undefined, topSsl: number | undefined) {
		const levelAbove = ElementStructureUtils
			.findLevelAboveGivenLevel(elementStructure, levelId);

		if (ssl === undefined) {
			throw new Error(`SSL could not be found for Level "${elementStructure.levels.find(l => l.id === levelId)?.name}"`);
		}

		// top ssl in meters (m)
		// order of operations matters here - level above ssl must be preferred since
		// top ssl not used if not top level
		const actualTopSsl = levelAbove?.ssl !== undefined
			? levelAbove?.ssl
			: topSsl;

		if (actualTopSsl === undefined) {
			throw new Error(`Top SSL could not be found for Level "${elementStructure.levels.find(l => l.id === levelId)?.name}"`);
		}

		// Get the slab thickness of the level over the current level, if there is one (in millimeters mm)
		const slabThicknessOver = levelAbove?.slabThicknessAtBase || 0;

		// multiply ssls by 1000 to convert to millimeters
		return Math.round(((actualTopSsl - ssl) * 1000) - slabThicknessOver);
	}

	/**
	 * Calculate the height for the level below the specified level
	 * @param elementStructure current element structure
	 * @param levelId Id of level height to be calculated for
	 * @param ssl specified level ssl in m
	 * @param topSsl specified level top ssl in m
	 * @returns height of level in mm
	 */
	public static calculateLevelBelowHeight(elementStructure: ElementStructure, levelId: string, slabThicknessAtBase: number, ssl: number | undefined) {
		if (ssl === undefined) {
			throw new Error(`SSL could not be found for Level "${elementStructure.levels.find(l => l.id === levelId)?.name}"`);
		}

		const levelBelow = ElementStructureUtils
			.findLevelBelowGivenLevel(elementStructure, levelId);

		if (!levelBelow) return undefined;

		// level below height is the difference between the ssl of the level above and the level below's ssl, minus the slab thickness at base of the level above
		return Math.round(((ssl - levelBelow.ssl) * 1000) - slabThicknessAtBase);
	}

	public static validateLevelSSL(
		elementStructure: ElementStructure,
		levelId: string,
		slabThicknessAtBase: number,
		ssl?: number,
		topSsl?: number,
	): [levelHeight: number, levelBelowHeight: number | undefined, errorKey?: LevelSSLErrorKey] {
		if ((!ssl && ssl !== 0) && (!topSsl && topSsl !== 0)) {
			throw new Error('Level must have SSL or Top SSL specified');
		}

		const levelHeight = ElementStructureUtils
			.calculateLevelHeight(elementStructure, levelId, ssl, topSsl);

		const levelBelowHeight = ElementStructureUtils
			.calculateLevelBelowHeight(elementStructure, levelId, slabThicknessAtBase, ssl);

		// Check the current level height is valid
		if (levelHeight < prebuildValConsts.minHeight) {
			return [levelHeight, levelBelowHeight, 'minHeightError'];
		}
		if (levelHeight > prebuildValConsts.maxHeight) {
			return [levelHeight, levelBelowHeight, 'maxHeightError'];
		}

		if (levelBelowHeight !== undefined) {
			if (levelBelowHeight < prebuildValConsts.minHeight) {
				return [levelHeight, levelBelowHeight, 'minHeightErrorBelow'];
			}
			if (levelBelowHeight > prebuildValConsts.maxHeight) {
				return [levelHeight, levelBelowHeight, 'maxHeightErrorBelow'];
			}
		}

		return [levelHeight, levelBelowHeight];
	}

	/* ELEMENT STRUCTURE VALUE REGENERATION */

	// Regenerates the RL values for all floors, based upon the ground level RL and floor-to-floor heights of various levels
	@action public static regenerateLevelHeights(elementStructure: ElementStructure) {
		// Top level height is between its two ssls, minus slab thickness at top
		if (elementStructure.levels.length >= 1) {
			const topLevel = elementStructure.levels[0];
			if (topLevel.topSsl) {
				topLevel.calculatedFloorHeight = Math.round((topLevel.topSsl - topLevel.ssl) * 1000);
			} else {
				topLevel.calculatedFloorHeight = undefined;
			}
		}

		// For the remaining levels, height is calculated between them bottom ssl and the bottom ssl of the previous level
		for (let i = 1; i < elementStructure.levels.length; i++) {
			const currentLevel = elementStructure.levels[i];
			const levelAbove = elementStructure.levels[i - 1];

			currentLevel.calculatedFloorHeight = Math.round((levelAbove.ssl - currentLevel.ssl) * 1000);
		}
	}

	@action public static regenerateCorbelAndVoidDimensions(elementStructure: ElementStructure, getValidCellHeight: (cell: Cell) => number) {
		ElementStructureUtils.cellIterator(elementStructure, (columnType, column, level) => {
			const cell = elementStructure.cells[column.id][level.id];
			const { corbel, elementVoid } = cell;

			if (corbel) {
				if (corbel.useCellWidth) corbel.width = cell.width;
				if (corbel.useCellDepth) corbel.depth = cell.depth;
				if (corbel.useCellHeight) corbel.height = getValidCellHeight(cell);
			}

			if (elementVoid) {
				if (elementVoid.useCellWidth) elementVoid.width = cell.width;
				if (elementVoid.useCellDepth) elementVoid.depth = cell.depth;
				if (elementVoid.useCellHeight) elementVoid.height = getValidCellHeight(cell);
			}
		});
	}

	// Regenerate loads for all columns
	public static regenerateColumnLoads(elementStructure: ElementStructure) {
		const loadTransferIndex = ElementStructureUtils.generateTransferIndex(elementStructure);
		elementStructure.columnTypes.forEach(columnType => {
			columnType.columns.forEach(column => {
				this.regenerateColumnLoad(elementStructure, column, loadTransferIndex);
			});
		});
	}

	// Regenerate loads for the column passed in
	@action public static regenerateColumnLoad(elementStructure: ElementStructure, column: Column, loadTransferIndex: LoadTransferIndex) {
		// To generate column loads, we start at the top, and add the load going downards
		let accumulatedLoad = 0.0;
		let startedCalculatingLoad = false;

		for (const level of elementStructure.levels) {
			const cell = elementStructure.cells[column.id][level.id];

			// If we hit an approved cell, we stop calculating loads. We use its calculated load instead of our accumulated load.
			if (cell.approved) {
				accumulatedLoad = cell.calculatedLoad || 0;
				continue;
			}

			// We don't calculate load for merged columns
			if (cell.merged) {
				continue;
			}

			// If a column is deleted, it still counts for load if it's in the middle of the column
			// If we haven't reached a good cell yet, we assume we're above the column, and don't add load
			if (cell.deleted && !startedCalculatingLoad) {
				continue;
			}
			// We've started the column, so we make sure to set the flag
			startedCalculatingLoad = true;

			if (cell.deleted) {
				// If the cell above this deleted cell has load transfers, we only use this cells individual load
				const cellAbove = this.findCellAboveGivenCell(elementStructure, cell);
				if (cellAbove
					&& this.hasOutgoingAssociatedLoads(cellAbove, undefined, loadTransferIndex)
					&& cell.calculatedLoad !== undefined
					&& cellAbove.calculatedLoad !== undefined) {
					cell.calculatedLoad -= cellAbove.calculatedLoad;
					continue;
				}
			}

			// If the user has set the load manually, we don't apply any additional load
			// We still want to use its load as the accumulated load, however
			if (cell.overrideCalculatedLoad && cell.calculatedLoad !== undefined) {
				accumulatedLoad = cell.calculatedLoad;
				continue;
			}

			// Add the normal load, multiplying by the additional levels the column supports if necessary
			const heightLoadMultiplier = cell.applyLoadAtEveryLevel ? cell.levelHeight : 1;
			accumulatedLoad += column.load * column.loadArea * heightLoadMultiplier;

			// Add any additional load, and apply the final calculated load to the cell
			// We don't add additional load for deleted columns, because they have no way of editing that value
			if (!cell.deleted) {
				accumulatedLoad += cell.additionalLoad;

				// Add any additional load as a result of load transfers.
				// Recieving Loads
				const associatedLoads = ElementStructureUtils
					.getCellLoadTransfers(loadTransferIndex, cell.id);

				if (associatedLoads.receivingLoads.some(y => y !== undefined)) {
					for (const receivedLoad of associatedLoads.receivingLoads) {
						if (receivedLoad && receivedLoad.data) {
							for (const receiving of receivedLoad.data) {
								if (receiving?.receivingId && cell.id === receiving.receivingId) {
									for (const t of elementStructure.columnTypes) {
										for (const c of t.columns) {
											for (const l of elementStructure.levels) {
												const currentCell = elementStructure.cells[c.id][l.id];
												if (currentCell.id === receivedLoad.cellId && receiving.percent && currentCell.calculatedLoad) {
													accumulatedLoad += (receiving.percent / 100) * currentCell.calculatedLoad;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			cell.calculatedLoad = accumulatedLoad;
		}
	}

	/* PART CALCULATION */

	public static getAptusBarParts(elementStructure: ElementStructure) {
		const aptusParts = {};
		this.barPartsList.forEach(partName => {
			aptusParts[partName.name] = [];
		});

		elementStructure.levels.forEach(level => {
			elementStructure.columnTypes.forEach(columnType => {
				columnType.columns.forEach(column => {
					const cell = elementStructure.cells[column.id][level.id];

					this.barPartsList.forEach(partType => {
						if (cell.parts && cell.parts.insituStarterBars && cell.disableInsituStarters && this.insituMask.includes(partType.name)) {
							// The user has disabled insitu starters, and this part belongs to one
						} else if (cell.parts && cell.parts[partType.name]) {
							const partDetails = cell.parts[partType.name];
							aptusParts[partType.name].push({
								quantity: partDetails.quantity,
								length: partDetails.length * 1000,
								partName: partType.starterBar ? partDetails.partName : partDetails.barName,
								rate: partType.starterBar ? partDetails.price : partDetails.combinedPrice,
							});
						}
					});
				});
			});
		});

		const summary: {[key: string]: BarPartSummary} = {};
		this.barPartsList.forEach(partName => {
			summary[partName.name] = {};
		});

		this.barPartsList.forEach(partName => {
			const partTypeBarsList = aptusParts[partName.name];
			const partTypeSummary = summary[partName.name];

			for (let i = 0; i < partTypeBarsList.length; i++) {
				if (!partTypeSummary.hasOwnProperty(partTypeBarsList[i].partName)) {
					partTypeSummary[partTypeBarsList[i].partName] = {
						totalLength: 0,
						qty: 0,
						totalCost: 0,
					};
				}

				const { quantity } = partTypeBarsList[i];
				partTypeSummary[partTypeBarsList[i].partName].qty += quantity;
				partTypeSummary[partTypeBarsList[i].partName].totalLength += partTypeBarsList[i].length * quantity;
				partTypeSummary[partTypeBarsList[i].partName].totalCost += partTypeBarsList[i].rate * quantity;
			}
		});
		return summary;
	}

	public static getAptusItemParts(elementStructure: ElementStructure) {
		const aptusParts = {};

		this.itemPartsList.forEach(partName => {
			aptusParts[partName.name] = [];
		});

		elementStructure.levels.forEach(level => {
			elementStructure.columnTypes.forEach(columnType => {
				columnType.columns.forEach(column => {
					const cell = elementStructure.cells[column.id][level.id];

					this.itemPartsList.forEach(partType => {
						if (cell.parts && cell.parts.insituStarterBars && cell.disableInsituStarters && this.insituMask.includes(partType.name)) {
							// The user has disabled insitu starters, and this part belongs to one
						} else if (cell.parts && cell.parts[partType.name]) {
							aptusParts[partType.name].push({
								quantity: cell.parts[partType.name].quantity,
								partName: cell.parts[partType.name].partName,
								rate: cell.parts[partType.name].price,
							});
						}
					});
				});
			});
		});

		const summary: { [key: string]: ItemPartSummary } = {};

		this.itemPartsList.forEach(partName => {
			summary[partName.name] = {};

			const partTypeBarsList = aptusParts[partName.name];
			const partTypeSummary = summary[partName.name];

			for (let i = 0; i < partTypeBarsList.length; i++) {
				if (!partTypeSummary.hasOwnProperty(partTypeBarsList[i].partName)) {
					partTypeSummary[partTypeBarsList[i].partName] = {
						qty: 0,
						totalCost: 0,
					};
				}
				const { quantity } = partTypeBarsList[i];
				partTypeSummary[partTypeBarsList[i].partName].qty += quantity;
				partTypeSummary[partTypeBarsList[i].partName].totalCost += partTypeBarsList[i].rate * quantity;
			}
		});
		return summary;
	}

	public static getShuttersTotal(elementStructure: ElementStructure): ShutterTotal {
		const shutterSummary: ShutterTotal = {
			shutterCount: 0,
			shutterTotal: 0,
			siteTemplateCount: 0,
			siteTemplateTotal: 0,
		};

		if (elementStructure.shutters) {
			elementStructure.shutters.forEach(shutter => {
				shutterSummary.shutterCount += shutter.quantity;
				shutterSummary.shutterTotal += shutter.price * shutter.quantity;

				if (shutter.siteTemplateQuantity && shutter.siteTemplatePrice) {
					shutterSummary.siteTemplateCount += shutter.siteTemplateQuantity;
					shutterSummary.siteTemplateTotal += shutter.siteTemplatePrice * shutter.siteTemplateQuantity;
				}
			});
		}

		return shutterSummary;
	}

	public static getShutterPrice(pricingShutter: PricingShutter) {
		const shutterTotal = pricingShutter.price * (pricingShutter.quantity > 0 ? pricingShutter.quantity : 0);

		let siteTemplateTotal = 0;
		if (pricingShutter.siteTemplatePrice && pricingShutter.siteTemplateQuantity) {
			siteTemplateTotal = pricingShutter.siteTemplatePrice * (pricingShutter.siteTemplateQuantity > 0 ? pricingShutter.siteTemplateQuantity : 0);
		}

		return {
			shutterTotal: shutterTotal,
			siteTemplateTotal: siteTemplateTotal,
		};
	}

	public static getPartCombinedPrice = (part: AdditionalPart) => {
		const length = part.isBar && part.length ? part.length : 0;
		return part.basePrice + (part.reoPrice * (length / 1000));
	};

	/**
	 * Calculates Rebar/Reo Mass using cell reo rate
	 * Throws errors if reo rate is missing (this should only be called for specific cell configurations)
	 * or if reo rate is invalid
	 * @returns Rebar/Reo Mass in kg
	 */
	public static calculateRebarMassFromReoRate = (elementStructure: ElementStructure, cell: Cell): number => {
		if (cell.reoRate === undefined || cell.reoRate === null) {
			alertToast(`Element ${ElementStructureUtils.getCellName(elementStructure, cell)} requires a reo rate to be specified`, 'error');
			return 0;
		} if (cell.reoRate <= 0) {
			alertToast(`Element ${ElementStructureUtils.getCellName(elementStructure, cell)} requires a positive reo rate to be specified`, 'error');
			return 0;
		}

		const [volume, , voidVolume] = ElementStructureUtils.calculateCellVolumes(elementStructure, cell);

		const cellReoMass = (volume - voidVolume) * cell.reoRate;

		const corbelReoMass = ElementStructureUtils.calculateCorbelReoMass(elementStructure, cell);

		return cellReoMass + corbelReoMass;
	};

	/**
	 * Calculates the reo mass for a Corbel
	 * If no reo rate is specified for a corbel, it returns zero
	 * @returns Corbel Rebar/Reo Mass in kg
	 */
	public static calculateCorbelReoMass = (elementStructure: ElementStructure, cell: Cell): number => {
		const [, corbelVolume] = ElementStructureUtils.calculateCellVolumes(elementStructure, cell);

		const corbelReoMass = cell.corbel?.reoRate
			? corbelVolume * cell.corbel!.reoRate
			: 0;

		return corbelReoMass;
	};

	/**
	 * Calculates the Horizontal Rebar/Reo Mass
	 * If specified bar type is missing from dictionaries, it will return a value of zero
	 * @param barKey String of bar type mass is being calculated for that is calculated and defined
	 * by CalculateRebarMass.py. (at time of writing, structure is N<number> e.g N10)
	 * @param aptusLigMassDict Cell dictionary of Atpus lig bar masses calculated by CalculateRebarMass.py
	 * @param nonAptusLigMassDict Cell dictionary of Non-Aptus lig bar masses calculated by CalculateRebarMass.py
	 * @returns Horizontal Rebar/Reo Mass in kg
	 */
	public static calculateHorizontalRebarMass = (barKey: string, aptusLigMassDict?: { [key: string]: number }, nonAptusLigMassDict?: { [key: string]: number }): number => {
		return ((aptusLigMassDict && aptusLigMassDict[barKey]) ?? 0) + ((nonAptusLigMassDict && nonAptusLigMassDict[barKey]) ?? 0);
	}

	/**
	 * Calculates Gross Area of a cell of a given shape
	 * @param cell
	 * @returns Gross area in (m^2)
	 */
	public static calculateGrossArea = (cell: Cell) => {
		if (cell.height !== undefined || cell.height !== null) {
			switch (cell.shape) {
				case 'rectangular':
					if (cell.width && cell.height) {
						return (cell.width / 1000) * (cell.height / 1000);
					}
					break;
				case 'round':
					if (cell.depth && cell.height) {
						return (cell.depth / 1000) * (cell.height / 1000);
					}
					break;
				default:
					// we should never reach here, if we do it's chaos/anarchy
					throw new Error('Cell has unknown shape');
			}
		}

		return null;
	}

	/**
	 * Calculates Vertical Reo percentage for given parameters
	 */
	public static calculateVerticalReoPercentage(
		shape?: CellShape,
		aptusBarsAlongDepth?: number,
		depth: number = 0,
		width: number = 0,
		aptusBarType?: BarType,
		aptusBarsAlongWidth?: number,
	): number {
		const noAptusBars = ElementStructureUtils.aptusBarQuantity(shape, aptusBarsAlongWidth, aptusBarsAlongDepth);

		const aptusBarArea = aptusBarType !== undefined
			? (couplerData.find(x => x.barSize === aptusBarType)?.data.A ?? 0)
			: 0;

		const grossCrossSectArea = shape === 'rectangular'
			? (width * depth)
			: (Math.PI) * (depth / 2 ** 2);

		return (((noAptusBars * aptusBarArea) / grossCrossSectArea) * 100);
	}

	public static getLevelForCell(elementStructure: ElementStructure, sel: SelectedCell) {
		return elementStructure.levels.find(lvl => lvl.id === sel.model.levelId);
	}

	public static getColumnForCell(elementStructure: ElementStructure, sel: SelectedCell) {
		for (const columnType of elementStructure.columnTypes) {
			for (const column of columnType.columns) {
				if (column.id === sel.model.columnId) {
					return { columnType, column };
				}
			}
		}

		return { columnType: undefined, column: undefined };
	}

	/**
	 * Generate a object used to more optimally look cells to find their incoming and outgoing loads transfers
	 * @param elementStructure
	 * @returns LoadTransferIndex object
	 */
	public static generateTransferIndex(elementStructure: ElementStructure) : LoadTransferIndex {
		const loadTransferIndex: LoadTransferIndex = {
			bySendingCellId: {},
			byReceivingCellId: {},
		};

		ElementStructureUtils.cellIterator(elementStructure, (columnType, column, level) => {
			const cell = elementStructure.cells[column.id][level.id];

			const loadTransfer = {
				cellId: cell.id,
				data: cell.loadTransfers,
			};

			if (loadTransfer.data?.length && loadTransfer.data.some(x => x.receivingId)) {
				loadTransferIndex.bySendingCellId[cell.id] = loadTransfer;

				loadTransfer.data?.forEach(ct => {
					if (ct.receivingId) {
						loadTransferIndex.byReceivingCellId[ct.receivingId] = loadTransferIndex.byReceivingCellId[ct.receivingId] !== undefined
							? [...loadTransferIndex.byReceivingCellId[ct.receivingId], loadTransfer]
							: [loadTransfer];
					}
				});
			}
		});

		return loadTransferIndex;
	}

	public static getCellLoadTransfers(
		loadTransferIndex: LoadTransferIndex,
		cellId: string,
	) {
		const outgoingLoads = loadTransferIndex.bySendingCellId[cellId] || undefined;
		const receivingLoads = loadTransferIndex.byReceivingCellId[cellId] || [];

		return { outgoingLoads, receivingLoads };
	}

	public static hasAssociatedLoads(selectedCell: Cell, loadTransferIndex: LoadTransferIndex): boolean {
		const associatedLoads = ElementStructureUtils
			.getCellLoadTransfers(loadTransferIndex, selectedCell.id);

		const receivingLoads = associatedLoads.receivingLoads.length > 0;
		const sendingLoads = this.hasOutgoingAssociatedLoads(selectedCell, associatedLoads.outgoingLoads, loadTransferIndex);

		return !!(receivingLoads || sendingLoads);
	}

	public static hasOutgoingAssociatedLoads(
		selectedCell: Cell,
		sending?: CellLoadTransfer | undefined,
		loadTransferIndex?: LoadTransferIndex,
	) {
		if (!sending) {
			if (loadTransferIndex) {
				const associatedLoads = ElementStructureUtils
					.getCellLoadTransfers(loadTransferIndex, selectedCell.id);

				const { outgoingLoads } = associatedLoads;
				return outgoingLoads ? outgoingLoads.data ? outgoingLoads.data.some(x => !!x.receivingId && !!x.percent) : false : false;
			}
			console.error('Load transfer index is required when sending outgoing loads is not provided');
		}
		return sending
			? sending.data
				? sending.data.some(x => !!x.receivingId && !!x.percent)
				: false
			: false;
	}

	public static getCellById(elementStructure: ElementStructure, cellId: string) : Cell | undefined {
		for (const columnType of elementStructure.columnTypes) {
			for (const column of columnType.columns) {
				for (const level of elementStructure.levels) {
					const currentCell = elementStructure.cells[column.id][level.id];
					if (currentCell.id === cellId) {
						return currentCell;
					}
				}
			}
		}
		return undefined;
	}

	public static getColumnById(elementStructure: ElementStructure, columnId: string) : Column | undefined {
		for (const columnType of elementStructure.columnTypes) {
			const column = columnType.columns.find(c => c.id === columnId);
			if (column) return column;
		}
		return undefined;
	}

	public static formatCellName(columnType: ColumnType, column: Column, level: Level) : string {
		return `${level.code}-${columnType.code}${column.name}`;
	}

	public static getCellName(elementStructure: ElementStructure, cell: Cell) : string | undefined {
		let column: Column | undefined;
		const columnType = elementStructure.columnTypes.find(colType => {
			const foundCol = colType.columns.find(col => col.id === cell.columnId);

			if (foundCol !== undefined) {
				column = foundCol;
				return true;
			}
			return false;
		});

		const level = elementStructure.levels.find(lvl => lvl.id === cell.levelId);

		if (columnType && column && level) {
			// @ts-ignore
			return ElementStructureUtils.formatCellName(columnType, column, level);
		}
		throw Error('Could not find require Column Type, Column or Level for element');
	}

	/** *
	 * Cell Iterator
	 * Iterates through columns and levels to perform functions on cells within an elementStructure
	 * @param elementStructure
	 * @param func a function that can interact with the current columnType, column, or level
	 */
	public static cellIterator = (
		elementStructure: ElementStructure,
		levelCallback: (columnType: ColumnType, column: Column, level: Level, levelIndex: number) => any,
	) => {
		for (const columnType of elementStructure.columnTypes) {
			for (const column of columnType.columns) {
				elementStructure.levels.forEach((level, index) => {
					levelCallback(columnType, column, level, index);
				});
			}
		}
	}

	public static shouldUseReoRate = (cell: Cell) => (
		cell.aptusDesignConfiguration === 'nonaptus'
		&& !cell.insituElement
		&& cell.useReoRate
	);

	public static isInsitu = (cell: Cell) => (
		cell.aptusDesignConfiguration === 'nonaptus'
		&& cell.insituElement
	);

	// naming is hard
	public static determineAptusCustomDefaultHorizontalReoDesign(width?: number, depth?: number, nonAptusBarLigSpacing?: number): 'Wall' | 'Column' {
		return width && depth
			&& (width / depth) > 4
			&& (nonAptusBarLigSpacing === 0 || !nonAptusBarLigSpacing)
			? 'Wall'
			: 'Column';
	}

	private static shuttersNeededPerLevel(elementStructure: ElementStructure): Record<string, Record<string, { shutters: number, siteTemplates: number }>> {
		// Loop through every level, and find the shutters needed on that level
		const shuttersNeededPerLevel: Record<string, Record<string, { shutters: number, siteTemplates: number }>> = {};
		for (const level of elementStructure.levels) {
			// Go through each cell on this level, and find the shutters needed
			const shuttersNeededThisLevel: Record<string, { shutters: number, siteTemplates: number }> = {};
			for (const columnType of elementStructure.columnTypes) {
				for (const column of columnType.columns) {
					// Grab the cell, but ignore merged, deleted, or nonaptus cells
					const cell = elementStructure.cells[column.id][level.id];
					if (!cell.merged && !cell.deleted && cell.aptusDesignConfiguration !== 'nonaptus') {
						// Get the shutter for this cell
						const shutter = ElementStructureUtils.shutterTypeStringFromCell(cell);
						if (shutter) {
							// We have a shutter name. We add it to our level, or increment the existing value
							let shutterInLevel = shuttersNeededThisLevel[shutter];
							const cellBelow = ElementStructureUtils.findCellBelowGivenCell(elementStructure, cell);
							const cellBelowExists = cellBelow && !cellBelow.deleted && cellBelow.aptusDesignConfiguration !== 'nonaptus';

							if (!shutterInLevel) {
								shutterInLevel = { shutters: 0, siteTemplates: 0 };
								shuttersNeededThisLevel[shutter] = shutterInLevel;
							}

							shutterInLevel.shutters += 1;

							// We only want to add a site template if there is no cell below this one and starters are
							// enabled on this cell.
							if (!cellBelowExists && !cell.disableInsituStarters) {
								shutterInLevel.siteTemplates += 1;
							}
						}
					}
				}
			}

			// Add this level's shutters to the overall object
			shuttersNeededPerLevel[level.id] = shuttersNeededThisLevel;
		}

		return shuttersNeededPerLevel;
	}

	public static maxShuttersByType(elementStructure: ElementStructure): Record<string, { shutters: number, siteTemplates: number }> {
		const result: Record<string, { shutters: number, siteTemplates: number }> = {};

		const shuttersByLevel = ElementStructureUtils.shuttersNeededPerLevel(elementStructure);
		for (const levelId of Object.keys(shuttersByLevel)) {
			const shutterCountMap = shuttersByLevel[levelId];

			for (const shutterName of Object.keys(shutterCountMap)) {
				const levelShutterCount = shutterCountMap[shutterName];
				const previousMax = result[shutterName];

				const previousMaxShutters = previousMax?.shutters ?? 0;
				const previousMaxSiteTemplates = previousMax?.siteTemplates ?? 0;

				if (previousMax) {
					// Previous max is an object in the result object so if we override values on it then they will be
					// set on the result object
					previousMax.shutters = Math.max(levelShutterCount.shutters, previousMaxShutters);
					previousMax.siteTemplates = Math.max(levelShutterCount.siteTemplates, previousMaxSiteTemplates);
				} else {
					result[shutterName] = { ...levelShutterCount };
				}
			}
		}

		return result;
	}

	@action
	public static getFullUsage(elementStructure: ElementStructure) {
		if (elementStructure.shutters) {
			for (const shutter of elementStructure.shutters) {
				const shutterType = ElementStructureUtils.shutterTypeString(shutter);
				shutter.calculatedFullUsageQty = Math.ceil(ElementStructureUtils.maxShuttersByType(elementStructure)[shutterType].siteTemplates);
				ElementStructureUtils.cleanInt(shutter, 'calculatedFullUsageQty');
			}
		}
	}
}
