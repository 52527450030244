import * as React from 'react';
import { observer } from 'mobx-react';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { ElementStructureUtils } from 'Util/ElementStructureUtils';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import { runInAction } from 'mobx';

export interface IColumnTypeEditViewProps {
	editColumnType: EditColumnType;
	editColumnTypeErrors: EditColumnTypeErrors;
	multipleSelected: boolean;
	saveChanges: () => void;
	cancelSelection: () => void;
	readonly?: boolean;
}

export interface EditColumnType {
	model: {
		code?: string;
		name?: string;
		estimatedColumnCount?: number;
	}
}

export interface EditColumnTypeErrors {
	code?: string;
	name?: string;
	estimatedColumnCount?: string;
}

@observer
export default class ColumnTypeEditView extends React.Component<IColumnTypeEditViewProps> {
	public render() {
		const {
			cancelSelection, editColumnType, editColumnTypeErrors, multipleSelected, readonly,
		} = this.props;
		return (
			<>
				<div className="edit-view-header">
					<h4 className="edit-view-title">Edit element</h4>
					<button onClick={cancelSelection} className="close-edit-view-btn" aria-label="Close" />
				</div>
				<div className="edit-view-tab-content panel-input-group">
					<TextField
						className="code"
						model={editColumnType.model}
						modelProperty="code"
						label="Element abbreviation"
						isDisabled={multipleSelected || readonly}
						tooltip="The abbreviated code to identify elements of this type."
						onAfterChange={() => runInAction(() => {
							editColumnTypeErrors.code = undefined;
							ElementStructureUtils.cleanSpecialCharacters(editColumnType.model, 'code');
						})}
						onChangeAndBlur={this.saveColumnTypes}
						errors={editColumnTypeErrors.code ? editColumnTypeErrors.code : undefined}
					/>
					<TextField
						className="name"
						model={editColumnType.model}
						modelProperty="name"
						label="Element type"
						tooltip="The shared type name for a group of elements. (ie Precast Columns or Precast Walls)"
						isDisabled={multipleSelected || readonly}
						onAfterChange={() => runInAction(() => {
							editColumnTypeErrors.name = undefined;
							ElementStructureUtils.cleanSpecialCharacters(editColumnType.model, 'name');
						})}
						onChangeAndBlur={this.saveColumnTypes}
						errors={editColumnTypeErrors.name ? editColumnTypeErrors.name : undefined}
					/>
					<NumberTextField
						className="floorHeight"
						model={editColumnType.model}
						modelProperty="estimatedColumnCount"
						label="Estimated number of element type"
						tooltip="The estimated number of each element type is only used to generate the element table and is fully editable later. Just provide a ballpark number."
						isDisabled={readonly}
						onAfterChange={() => runInAction(() => {
							editColumnTypeErrors.estimatedColumnCount = undefined;
							ElementStructureUtils.cleanInt(editColumnType.model, 'estimatedColumnCount');
						})}
						onChangeAndBlur={this.saveColumnTypes}
						errors={editColumnTypeErrors.estimatedColumnCount ? editColumnTypeErrors.estimatedColumnCount : undefined}
					/>
				</div>
			</>
		);
	}

	private canSaveColumnTypes = () => {
		const { multipleSelected, editColumnType } = this.props;

		if (!multipleSelected) {
			if (!editColumnType.model.code || !editColumnType.model.name) {
				return false;
			}
		}
		return true;
	};

	private saveColumnTypes = () => {
		if (this.canSaveColumnTypes()) {
			this.props.saveChanges();
		}
	};
}
