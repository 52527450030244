/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import {
	action, computed, observable, runInAction,
} from 'mobx';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import * as React from 'react';
import { observer } from 'mobx-react';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import type {
	Cell,
	CellShape,
	DesignConfiguration,
	ElementStructure,
} from 'Util/ElementStructureUtils';
import {
	ElementStructureUtils,
} from 'Util/ElementStructureUtils';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import classNames from 'classnames';
import { TextField } from '../TextBox/TextBox';
import { MinMaxNumberTextField } from '../NumberTextBox/MinMaxNumberTextBox';
import { prebuildValConsts } from 'Models/Entities/ProjectEntity';
import DeletedCellView from 'Views/Components/ElementGrid/CellEditViewComponents/DeletedCellView';
import InteractionCurveGraph from 'Views/Components/ElementGrid/InteractionCurveGraph';
import AdvancedDimensionsInputs from 'Views/Components/ElementGrid/CellEditViewComponents/AdvancedDimensionsInputs';
import SocketExtensionOptions from 'Views/Components/ElementGrid/CellEditViewComponents/SocketExtensionOptions';
import alertToast from '../../../Util/ToastifyUtils';
import LoadTransfers from './LoadTransfers';
import type { EditCell } from 'Util/SelectionUtils';
import { SelectionUtils } from 'Util/SelectionUtils';
import { store } from '../../../Models/Store';
import { Tooltip } from '../Tooltip/Tooltip';

export interface ICellEditViewProps {
	editCell: EditCell;
	selectionUtils: SelectionUtils,
	cellIDs: string[];
	mergeCells: (event: React.MouseEvent) => void;
	splitCells: (event: React.MouseEvent) => void;
	openBarArrangementModal?: () => void;
	closeBarArrangementModal?: () => void;
	saveChanges: () => void;
	canMergeCells: {columnId: string, data: {topUnmergedCell: Cell, bottomCell: Cell, mergedHeight: number} | false}[] | false,
	canSplitCells: boolean,
	deleteCells: () => void;
	restoreDeletedCells: () => void;
	approveCells: () => void;
	disapproveCells: () => void;
	cancelSelection: () => void;
	getCellHeight: (editCell: EditCell) => number;
	cellSelected?: boolean;
	readonly?: boolean;
	simplified?: boolean;
	elementStructure: ElementStructure;
	getCellsBelow: () => Cell[];
	isConfigurationDisabled: (editCell: EditCell, minWidth: number, minDepth: number, minSlab: number) => string[];
}

enum CellEditState {
	Design = 'design',
	Setup = 'setup',
	Loading = 'loading',
	Output = 'output',
}

export type CellErrorTypes = 'minWidthError' | 'minDepthError' | 'minSlabBaseError' | 'minHeightErrorCurrent'
	| 'maxHeightErrorCurrent' | 'minHeightErrorBelow' | 'maxHeightErrorBelow' | 'maxWidthError'
	| 'maxDepthError' | 'maxSlabBaseError' | 'invalid' | 'minCorbelVoidWidthError' | 'minCorbelVoidDepthError'
	| 'minSlabTopError' | 'maxSlabTopError' | 'maxPercentError' | 'percentWithoutElement' | 'elementToReceiving4'
	| 'nonAptusRequiresHorReo' | 'nonAptusRequiresVertReo';

@observer
export default class CellEditView extends React.Component<ICellEditViewProps> {
	private readonly nonAptusColours = {
		grey: { hex: '#E1E1E1' },
		lightblue: { hex: '#96DDD1' },
		lightgreen: { hex: '#E2F79F' },
		darkgreen: { hex: '#6FA922' },
		pink: { hex: '#E36DA8' },
		purple: { hex: '#734592' },
		black: { hex: '#000000' },
	};

	@observable
	private editState: CellEditState = CellEditState.Design;

	@observable
	private showAdvancedState: boolean = !!(this.props.editCell.model.corbel || this.props.editCell.model.elementVoid);

	@observable
	private onCustomEdit: boolean = false;

	// eslint-disable-next-line @typescript-eslint/ban-types
	public componentDidUpdate(prevProps: Readonly<ICellEditViewProps>, prevState: Readonly<{}>, snapshot?: any) {
		const { cellIDs } = this.props;

		// see if any selected cells weren't previously selected
		let differentCellsSelected = prevProps.cellIDs.length !== cellIDs.length;
		if (!differentCellsSelected) {
			cellIDs.forEach(id => {
				differentCellsSelected = differentCellsSelected || prevProps.cellIDs.indexOf(id) === -1;
			});
		}

		// reset input feedback if different cells have been selected
		if (differentCellsSelected) {
			runInAction(() => {
				this.cellSlabError = undefined;
				this.resultantCellHeight = undefined;
				this.cellHeightError = undefined;
			});
		}

		// If you change cell make sure to turn off onCustomEdit
		if (this.onCustomEdit && differentCellsSelected) {
			this.startCustomEditing(false);
		}
	}

	public render() {
		const {
			editCell, cancelSelection, readonly, simplified, closeBarArrangementModal, selectionUtils,
		} = this.props;

		if (editCell.info && editCell.info.deleted) {
			const cellHeightError = (this.resultantCellHeight !== undefined && this.cellHeightError !== undefined && (editCell.model.overrideSSL || editCell.model.overrideTopSsl))
				? this.prebuildErrorConsts[this.cellHeightError]
				: undefined;

			return (
				<DeletedCellView
					editCell={editCell}
					cancelSelection={cancelSelection}
					closeBarArrangementModal={closeBarArrangementModal}
					readonly={readonly}
					saveCellContent={this.saveCellContent}
					restoreDeletedCells={this.restoreDeletedCells}
					selectionUtils={selectionUtils}
					cellHeightError={cellHeightError}
					resultantCellHeight={this.resultantCellHeight}
				/>
			);
		}

		return (
			<>
				<div className="edit-view-header cell">
					<h4 className="edit-view-title">Edit element</h4>
					<button
						onClick={() => {
							cancelSelection();
							runInAction(() => { this.cellHeightError = undefined; this.resultantCellHeight = undefined; this.cellSlabError = undefined; });
						}}
						className="close-edit-view-btn"
						aria-label="Close"
					/>
				</div>
				{simplified
					? (
						<div className="edit-view-tab-content">
							{this.renderSetupTab()}
						</div>
					) : (
						<>
							<div className="edit-view-tab-wrap tab-wrap">
								<button onClick={() => this.changeEditState(CellEditState.Design)} className={`edit-view-tab design ${this.editState === CellEditState.Design ? 'active' : ''}`}>Design</button>
								<button onClick={() => this.changeEditState(CellEditState.Setup)} className={`edit-view-tab setup ${this.editState === CellEditState.Setup ? 'active' : ''}`}>Dimensions</button>
								<button onClick={() => this.changeEditState(CellEditState.Loading)} className={`edit-view-tab interaction ${this.editState === CellEditState.Loading ? 'active' : ''}`}>Loading</button>
								<button onClick={() => this.changeEditState(CellEditState.Output)} className={`edit-view-tab output ${this.editState === CellEditState.Output ? 'active' : ''}`}>Outputs</button>
							</div>
							<div className="edit-view-tab-content">
								{this.editState === CellEditState.Design ? this.renderDesignTab() : null}
								{this.editState === CellEditState.Setup ? this.renderSetupTab() : null}
								{this.editState === CellEditState.Loading ? this.renderLoadingTab() : null}
								{this.editState === CellEditState.Output ? this.renderOutputTab() : null}
							</div>
						</>
					)}

				{!readonly ? this.renderActions() : null}
			</>
		);
	}

	private renderActions = () => {
		const { editCell, simplified } = this.props;
		return (
			<div className="edit-view-actions">
				{this.renderMergeSplitButton()}
				<Button onClick={this.deleteCells} className="delete-cell-btn btn--tertiary" display={Display.Solid} icon={{ icon: 'bin-delete', iconPos: 'icon-left' }} disabled={editCell.model.approved !== false}>Remove</Button>
				{!simplified ? (
					<>
						{editCell.model.approved === false
							? <Button onClick={this.approveCells} className="approve-cell-btn" display={Display.Solid} icon={{ icon: 'check-circle', iconPos: 'icon-left' }}>Mark as approved</Button>
							: <Button onClick={this.disapproveCells} className="unapprove-cell-btn" display={Display.Solid} icon={{ icon: 'x-circle', iconPos: 'icon-left' }}>Mark as unapproved</Button> }
					</>
				) : null}
			</div>
		);
	};

	@action
	private saveCellContent = () => {
		const { getCellHeight, editCell } = this.props;

		if (this.onCustomEdit) {
			editCell.model.aptusDesignConfiguration = 'custom';
		}

		// update any corbels and voids (if they exist) to reflect element dimensions
		// (if they have 'use element <dimension>' set)
		const { corbel, elementVoid } = editCell.model;
		if (corbel) {
			if (corbel.useCellWidth) corbel.width = editCell.model.width;
			if (corbel.useCellDepth) corbel.depth = editCell.model.depth;
			if (corbel.useCellHeight) corbel.height = getCellHeight(editCell);
		}

		if (elementVoid) {
			if (elementVoid.useCellWidth) elementVoid.width = editCell.model.width;
			if (elementVoid.useCellDepth) elementVoid.depth = editCell.model.depth;
			if (elementVoid.useCellHeight) elementVoid.height = getCellHeight(editCell);
		}

		if (this.props.editCell.model.approved === false && this.saveCellValid()) {
			this.props.saveChanges();
		}
	};

	@action
	private changeEditState = (newEditState: CellEditState) => {
		this.editState = newEditState;
	};

	@computed
	private get isDisabled() {
		const { editCell, readonly } = this.props;
		return editCell.model.approved !== false || readonly;
	}

	@observable
	private resultantCellHeight: number | undefined;

	@observable
	private cellSlabError: string | undefined;

	@observable
	private cellHeightError: string | undefined;

	@computed
	private get wallOrColumnDefaultVal() {
		const { editCell: { model: { width, depth, nonAptusBarLigSpacing } } } = this.props;
		return ElementStructureUtils.determineAptusCustomDefaultHorizontalReoDesign(width, depth, nonAptusBarLigSpacing);
	}

	private renderDesignTab = () => {
		const { editCell, openBarArrangementModal } = this.props;
		return (
			<>
				<h5>Design configuration</h5>
				<div className="design-configuration-tab-wrap tab-wrap">
					<button
						onClick={() => this.changeDesignConfig('aptus')}
						className={`edit-view-tab ${editCell.model.aptusDesignConfiguration === 'aptus' && !this.onCustomEdit ? 'active' : ''}`}
						disabled={
							this.isDisabled
							|| this.props.isConfigurationDisabled(editCell, prebuildValConsts.minWidthAptus, prebuildValConsts.minDepthAptus, prebuildValConsts.slabMinAptus).some(x => !!x)
						}
					>
						Aptus
					</button>
					<button
						onClick={() => this.changeDesignConfig('custom')}
						className={`edit-view-tab ${editCell.model.aptusDesignConfiguration === 'custom' || (this.onCustomEdit && editCell.model.aptusDesignConfiguration !== undefined) ? 'active' : ''}`}
						disabled={
							this.isDisabled
							|| this.props.isConfigurationDisabled(editCell, prebuildValConsts.minWidthAptus, prebuildValConsts.minDepthAptus, prebuildValConsts.slabMinAptus).some(x => !!x)
						}
					>
						Custom
					</button>
					<button
						onClick={() => this.changeDesignConfig('nonaptus')}
						className={`edit-view-tab ${editCell.model.aptusDesignConfiguration === 'nonaptus' ? 'active' : ''}`}
						disabled={
							this.isDisabled
							|| this.props.isConfigurationDisabled(editCell, prebuildValConsts.minWidthNonAptus, prebuildValConsts.minDepthNonAptus, prebuildValConsts.slabMinNonAptus).some(x => !!x)
						}
					>
						Non-Aptus
					</button>
				</div>
				<div className="design-configuration-tab-content">
					<i className={`cell-type-indicator ${this.onCustomEdit && editCell.model.aptusDesignConfiguration !== undefined ? 'custom' : editCell.model.aptusDesignConfiguration}`} style={{ backgroundColor: editCell.model.aptusDesignConfiguration === 'nonaptus' ? this.setNonAptusColour() : '' }} />
					{editCell.model.aptusDesignConfiguration === 'aptus' && !this.onCustomEdit
						? <p className="design-configuration-description">An element using the Aptus config will be updated when rebuild project is run.</p>
						: null}
					{editCell.model.aptusDesignConfiguration === 'custom' || (this.onCustomEdit && editCell.model.aptusDesignConfiguration !== undefined)
						? <p className="design-configuration-description">The Aptus bar design and concrete strength will be checked for axial capacity, moment capacity and temporary propping, returning Pass/Fail. Custom design will NOT check the non-Aptus bar spacing or check tie design. These inputs are the responsibility of the design engineer if using a custom deisgn.</p>
						: null}
					{editCell.model.aptusDesignConfiguration === 'nonaptus'
						? <p className="design-configuration-description">Selecting a Non-Aptus config removes the element from your quote.</p>
						: null}
					{editCell.model.aptusDesignConfiguration === undefined
						? <p className="design-configuration-description">Your selected elements have varying design configurations. If you wish to edit the configuration manually, please choose a custom configuration.</p>
						: null}
				</div>

				{/* GEOMETRY & SLAB DEPTH ERROR MESSAGES */}
				<>
					{this.props.isConfigurationDisabled(
						editCell,
						prebuildValConsts.minWidthAptus,
						prebuildValConsts.minDepthAptus,
						prebuildValConsts.slabMinAptus,
					).some(x => !!x)
						? (
							<p className="text--error">
								{this.props.isConfigurationDisabled(
									editCell,
									prebuildValConsts.minWidthAptus,
									prebuildValConsts.minDepthAptus,
									prebuildValConsts.slabMinAptus,
								).map(x => <div>{x}</div>)}
							</p>
						) : null}
				</>
				<>
					{this.props.isConfigurationDisabled(
						editCell,
						prebuildValConsts.minWidthNonAptus,
						prebuildValConsts.minDepthNonAptus,
						prebuildValConsts.slabMinNonAptus,
					).some(x => !!x)
						? (
							<p className="text--error">
								{this.props.isConfigurationDisabled(
									editCell,
									prebuildValConsts.minWidthNonAptus,
									prebuildValConsts.minDepthNonAptus,
									prebuildValConsts.slabMinNonAptus,
								).map(x => <div>{x}</div>)}
							</p>
						) : null}
				</>

				{editCell.model.aptusDesignConfiguration !== 'nonaptus'
					? (
						<>
							<div className="designed-as-box">
								<p className="vert-reo-text">Aptus Vertical Reo Percentage  <span className="designed-as-box-right">{this.calcTempVertReoPercentage()}</span></p>
								{editCell.model.ligDesign && editCell.model.aptusDesignConfiguration === 'aptus' ? (
									<p className="designed-as-text">Horizontal Reo Designed As {
										(editCell.model.ligDesign.includes('Column')
											? <span className="designed-as-box-right">Column</span>
											: <span className="designed-as-box-right">Wall</span>)
									}
									</p>
								)
									: editCell.model.ligDesign
										? (
											<>
												<span className="designed-as-text">Horizontal reo detailed as</span>
												<div className="wall-or-column-dropdown-wrap">
													<Combobox
														className={!!editCell.model.designOverride ? 'wall-or-column-dropdown shrink' : 'wall-or-column-dropdown'}
														model={editCell.model}
														modelProperty="designOverride"
														label="Wall Or Column"
														labelVisible={false}
														options={[
															{ display: 'Column', value: 'Column' },
															{ display: 'Wall', value: 'Wall' },
														]}
														placeholder={this.wallOrColumnDefaultVal}
														searchable={false}
														inputProps={{ onBlur: this.saveCellContent }}
														isDisabled={this.isDisabled}
													/>
													{!!editCell.model.designOverride
														? (
															<button
																onClick={() => {
																	runInAction(() => { editCell.model.designOverride = null; });
																	this.saveCellContent();
																}}
																className="reset-dropdown-selection"
																aria-label="reset"
																disabled={this.isDisabled}
															/>
														)
														: null}
												</div>
											</>
										) : null}
							</div>
							{editCell.model.aptusDesignConfiguration === 'aptus' ? (
								<>
									<div className="force-design-container">
										<Checkbox
											className="force-design-as-column"
											model={editCell.model}
											modelProperty="forceDesignAsColumn"
											label="Force Design as Column"
											inputProps={{ onBlur: this.saveCellContent }}
											isDisabled={this.isDisabled}
										/>
										<Tooltip
											id="force-design-tooltip"
											content="Click for more information"
											onClick={() => {
												store.modal.show(
													'Information',
													<>
														<p>By default, the tool will check If the aspect ratio of the element is less than 1:4 then design it as a column. If the aspect ratio is over 1:4 it will design the element as a wall.</p>
														<p>If you have thin blade columns with aspect ratios at 4:1 (800x200,1000x250, 1200x300) the tool will design them as walls. You should tick “Force design as columns” as the engineer will likely be detailing them as columns and require full column ties and a higher reo percentage.</p>
														<p>In some instances elements with large aspect ratios will still be need to detailed as columns and the design tool may provide a lighter design than will be required. Watch out for high reo rates in walls and you should tick this box to get a prelim design closer to the engineer&apos;s intent. If in doubt RFI the engineer.</p>
														<div key="actions" className="modal__actions">
															<Button
																onClick={() => store.modal.hide()}
																display={Display.Solid}
																colors={Colors.Secondary}
															>
																Close
															</Button>
														</div>
													</>,
												);
											}}
										/>
									</div>
								</>
							)
								: null}
							<div>
								<h5 style={{ display: 'inline-block' }}>Aptus Bars</h5>
								{editCell.model.shape === 'rectangular' ? (
									<Button
										colors={Colors.None}
										className="modal-preview-button"
										onClick={() => {
											if (openBarArrangementModal) openBarArrangementModal();
										}}
									>Preview
									</Button>
								) : null}
							</div>
							{editCell.model.shape !== 'round'
								// Only rectangular cells, or a combination of rectangular cells and round cells, have been selected
								? (
									<>
										<NumberTextField
											className="aptus-bars-along-width"
											model={editCell.model}
											modelProperty="aptusBarsAlongWidth"
											label="Bars Along Width"
											tooltip="The number of Aptus bars used along the width of the element. This number cannot be less than 2"
											onAfterChange={() => runInAction(() => {
												ElementStructureUtils.cleanInt(editCell.model, 'aptusBarsAlongWidth');
												if (editCell.model.aptusBarsAlongWidth && editCell.model.aptusBarsAlongWidth > 500) editCell.model.aptusBarsAlongWidth = 500;
											})}
											onChangeAndBlur={this.saveCellContent}
											errors={this.validateAptusBarsWidth()}
											inputProps={{ onMouseDown: event => { this.startCustomEditing(true); } }}
											isDisabled={this.isDisabled}
										/>
										<NumberTextField
											className="aptus-bars-along-depth"
											model={editCell.model}
											modelProperty="aptusBarsAlongDepth"
											label="Bars Along Depth"
											tooltip="The number of Aptus bars used along the depth of the element. This number cannot be less than 2"
											onChangeAndBlur={this.saveCellContent}
											onAfterChange={() => runInAction(() => {
												ElementStructureUtils.cleanInt(editCell.model, 'aptusBarsAlongDepth');
												if (editCell.model.aptusBarsAlongDepth && editCell.model.aptusBarsAlongDepth > 500) editCell.model.aptusBarsAlongDepth = 500;
											})}
											errors={this.validateAptusBarsDepth()}
											inputProps={{ onMouseDown: event => { this.startCustomEditing(true); } }}
											isDisabled={this.isDisabled}
										/>
										<p>Total Aptus Bars: {ElementStructureUtils.aptusBarQuantity(editCell.model.shape, editCell.model.aptusBarsAlongWidth, editCell.model.aptusBarsAlongDepth)}</p>

									</>
								)

								// Only round cells have been selected
								: (
									<NumberTextField
										className="aptus-bars-along-depth"
										model={editCell.model}
										modelProperty="aptusBarsAlongDepth"
										label="Bar Quantity"
										tooltip="The number of Aptus bars used in this element."
										errors={this.validateAptusBarsDepth()}
										onAfterChange={() => runInAction(() => {
											ElementStructureUtils.cleanInt(editCell.model, 'aptusBarsAlongDepth');
											if (editCell.model.aptusBarsAlongDepth && editCell.model.aptusBarsAlongDepth > 500) editCell.model.aptusBarsAlongDepth = 500;
										})}
										onChangeAndBlur={this.saveCellContent}
										inputProps={{ onMouseDown: event => { this.startCustomEditing(true); } }}
										isDisabled={this.isDisabled}
									/>
								)}

							<Combobox
								className="aptus-bar-type"
								model={editCell.model}
								modelProperty="aptusBarType"
								label="Type"
								options={ElementStructureUtils.barTypeDropdownValuesAptusSizes}
								searchable={false}
								tooltip="The size of the Aptus bars in the element"
								inputProps={{ onMouseDown: event => { this.startCustomEditing(true); }, onBlur: this.saveCellContent }}
								isDisabled={this.isDisabled}
							/>
						</>
					) : null}

				{editCell.model.aptusDesignConfiguration === 'nonaptus'
					? (
						<Checkbox
							className="insitu-element"
							model={editCell.model}
							modelProperty="insituElement"
							label="Mark as In-situ"
							tooltip={"If ticked, this element's bar count will not appear on any tables or exports for this project."}
							onAfterChecked={() => this.saveCellContent()}
							isDisabled={this.isDisabled}
						/>
					)
					: null}

				{editCell.model.aptusDesignConfiguration === 'nonaptus' && !editCell.model.insituElement
					? (
						<Checkbox
							className="use-reo-rate"
							model={editCell.model}
							modelProperty="useReoRate"
							label={`Use Reo Rate (kg/m${String.fromCharCode(179)})`}
							tooltip="If ticked, the reo mass will be calculated using the specified rate"
							onAfterChecked={() => this.saveCellContent()}
							isDisabled={this.isDisabled}
						/>
					)
					: null}

				{editCell.model.aptusDesignConfiguration === 'nonaptus' && !editCell.model.insituElement && editCell.model.useReoRate
					? (
						<NumberTextField
							model={editCell.model}
							modelProperty="reoRate"
							label={'Reo Rate (kg/m\u00B3)'}
							placeholder=""
							tooltip="Reinforcing rates can be used for non-aptus elements in lieu of specifying individual bars, refer to the Structural Engineers drawings for guidance on applicable rates."
							onAfterChange={() => runInAction(() => ElementStructureUtils.cleanFloat(editCell.model, 'reoRate'))}
							errors={this.validationReoRate()}
							onChangeAndBlur={this.saveCellContent}
							isDisabled={this.isDisabled}
						/>
					)
					: null}

				{editCell.model.aptusDesignConfiguration === 'nonaptus'
					? (
						<Combobox
							className="non-aptus-colour"
							model={editCell.model}
							modelProperty="nonAptusColour"
							label="Non Aptus Colour"
							options={ElementStructureUtils.nonAptusColourDropdownValues}
							searchable={false}
							placeholder="Select Colour"
							onAfterChange={this.saveCellContent}
							isDisabled={this.isDisabled}
						/>
					)
					: null}

				{(editCell.model.aptusDesignConfiguration !== 'nonaptus' || !editCell.model.insituElement)
					? (
						<>
							<div>
								<h5 style={{ display: 'inline-block' }}>Non-Aptus Bars</h5>
							</div>
							{editCell.model.shape !== 'round'
							// Only rectangular cells, or a combination of rectangular cells and round cells, have been selected
								? (
									<>
										<NumberTextField
											className="non-aptus-bars-along-width"
											model={editCell.model}
											modelProperty="nonAptusBarsAlongWidth"
											label="Bars Along Width"
											tooltip="The number of Non-Aptus bars used along the width of the element."
											onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(editCell.model, 'nonAptusBarsAlongWidth'))}
											onChangeAndBlur={this.saveCellContent}
											isDisabled={editCell.model.aptusDesignConfiguration === 'aptus' || this.isDisabled}
										/>
										<NumberTextField
											className="non-aptus-bars-along-depth"
											model={editCell.model}
											modelProperty="nonAptusBarsAlongDepth"
											label="Bars Along Depth"
											tooltip="The number of Non-Aptus bars used along the depth of the element."
											onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(editCell.model, 'nonAptusBarsAlongDepth'))}
											onChangeAndBlur={this.saveCellContent}
											isDisabled={editCell.model.aptusDesignConfiguration === 'aptus' || this.isDisabled}
										/>
										<p>Total Non-Aptus Bars: {ElementStructureUtils.nonAptusBarQuantity(editCell.model.shape, editCell.model.nonAptusBarsAlongWidth, editCell.model.nonAptusBarsAlongDepth, editCell.model.aptusDesignConfiguration)}</p>
									</>
								)
							// Only round cells have been selected
								: (
									<NumberTextField
										className="non-aptus-bars-along-depth"
										model={editCell.model}
										modelProperty="nonAptusBarsAlongDepth"
										label="Bar Quantity"
										tooltip="The number of Non-Aptus bars used in this element."
										onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(editCell.model, 'nonAptusBarsAlongDepth'))}
										onChangeAndBlur={this.saveCellContent}
										isDisabled={editCell.model.aptusDesignConfiguration === 'aptus' || this.isDisabled}
									/>
								)}

							{this.validateNonAptusVerticalReo() !== 'valid'
								? <p className="dimensions-error-text">{this.prebuildErrorConsts[this.validateNonAptusVerticalReo()]}</p>
								: null}

							<Combobox
								className="non-aptus-bar-type"
								model={editCell.model}
								modelProperty="nonAptusBarType"
								label="Type"
								options={ElementStructureUtils.barTypeDropdownValuesAllSizes}
								searchable={false}
								tooltip="The size of the non-Aptus bars in the element"
								inputProps={{ onBlur: this.saveCellContent }}
								isDisabled={editCell.model.aptusDesignConfiguration === 'aptus' || this.isDisabled}
							/>
						</>
					)
					: null }

				{editCell.model.aptusDesignConfiguration !== 'nonaptus'
					? (
						<>
							<h5>Aptus bar ligs / ties</h5>
							<Combobox
								className="aptus-bar-lig-type"
								model={editCell.model}
								modelProperty="aptusBarLigType"
								label="Type"
								options={ElementStructureUtils.barTypeDropdownValuesAllSizes}
								searchable={false}
								tooltip="The size of the ligatures for the Aptus bars"
								inputProps={{ onMouseDown: event => { this.startCustomEditing(true); }, onBlur: this.saveCellContent }}
								isDisabled={this.isDisabled}
							/>
							<NumberTextField
								className="aptus-bar-lig-spacing"
								model={editCell.model}
								modelProperty="aptusBarLigSpacing"
								label="Spacing"
								tooltip="The max spacing of the ligatures for the Aptus bars"
								onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(editCell.model, 'aptusBarLigSpacing'))}
								onChangeAndBlur={this.saveCellContent}
								inputProps={{ onMouseDown: event => { this.startCustomEditing(true); } }}
								isDisabled={this.isDisabled}
							/>
						</>
					) : null}

				{(editCell.model.aptusDesignConfiguration !== 'nonaptus' || (editCell.model.aptusDesignConfiguration === 'nonaptus' && !editCell.model.insituElement))
					? (
						<>
							<h5>Non-Aptus bar ligs / ties</h5>
							<Combobox
								className="non-aptus-bar-lig-type"
								model={editCell.model}
								modelProperty="nonAptusBarLigType"
								label="Type"
								options={ElementStructureUtils.barTypeDropdownValuesAllSizes}
								searchable={false}
								tooltip="The size of the ligatures for the non-Aptus bars."
								inputProps={{ onBlur: this.saveCellContent }}
								isDisabled={editCell.model.aptusDesignConfiguration === 'aptus' || this.isDisabled}
							/>
							<NumberTextField
								className="non-aptus-bar-lig-spacing"
								model={editCell.model}
								modelProperty="nonAptusBarLigSpacing"
								label="Spacing"
								tooltip="The max spacing of the ligatures for the non-Aptus bars."
								onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(editCell.model, 'nonAptusBarLigSpacing'))}
								onChangeAndBlur={this.saveCellContent}
								isDisabled={editCell.model.aptusDesignConfiguration === 'aptus' || this.isDisabled}
							/>
							{this.validateNonAptusHorizontalReo() !== 'valid'
								? <p className="dimensions-error-text">{this.prebuildErrorConsts[this.validateNonAptusHorizontalReo()]}</p>
								: null}
						</>
					)
					: null}

				{editCell.model.aptusDesignConfiguration !== 'nonaptus' && editCell.info.atBottomOfColumn
					? (
						<>
							<h5>Aptus Starter Bars</h5>
							<Checkbox
								className="disable-starters"
								model={editCell.model}
								modelProperty="disableInsituStarters"
								label="Remove Starters"
								tooltip="For elements with no Aptus sections below them, you would typically use Aptus starter bars. You can instead choose to use other methods of securing the element, and the starter bars will be removed from your quote. This has no effect on elements with Aptus elements below them."
								inputProps={{ onBlur: this.saveCellContent }}
								isDisabled={this.isDisabled}
							/>
						</>
					) : null }

				{editCell.model.aptusDesignConfiguration !== 'nonaptus' || !editCell.model.insituElement
					? (
						<>
							<h5>Concrete</h5>
							<Combobox
								className="concrete-strength"
								model={editCell.model}
								modelProperty="concreteStrength"
								label="Type"
								options={ElementStructureUtils.concreteStrengthDropdownValues}
								searchable={false}
								tooltip="The strength of the concrete used within this element"
								inputProps={{ onBlur: this.saveCellContent }}
								isDisabled={editCell.model.aptusDesignConfiguration === 'aptus' || this.isDisabled}
							/>
						</>
					)
					: null}

				<h5>Transition at base</h5>
				<TextField
					className="transition-description"
					model={editCell.model}
					modelProperty="transitionDescription"
					label="Transition description"
					onChangeAndBlur={this.saveCellContent}
					tooltip="Enter a custom description and choose a custom colour for the transition between this element and the element below. This is just a description for your own purposes and has no bearing on the design or pricing."
					isDisabled={this.isDisabled}
				/>
				<Combobox
					className="transition-colour"
					model={editCell.model}
					modelProperty="transitionColour"
					label="Colour"
					options={ElementStructureUtils.transitionColourDropdownValues}
					placeholder="Select colour"
					searchable={false}
					onAfterChange={this.saveCellContent}
					isDisabled={this.isDisabled}
				/>

				<h5>Zone</h5>
				<TextField
					className="construction-zone"
					model={editCell.model}
					modelProperty="constructionZone"
					label="Construction zone"
					onChangeAndBlur={this.saveCellContent}
					tooltip="The user may choose to enter a construction zone to differentiate between pours. This has no effect in the APTUS design tool but will display in the excel output for each element."
					isDisabled={this.isDisabled}
				/>
			</>
		);
	};

	private setNonAptusColour(): string {
		const {
			editCell,
		} = this.props;
		if (editCell.model.nonAptusColour) {
			return this.nonAptusColours[editCell.model.nonAptusColour].hex;
		}
		return this.nonAptusColours.grey.hex;
	}

	@action private startCustomEditing = (customEdit : boolean) => {
		this.onCustomEdit = customEdit;
	};

	@computed
	private get prebuildErrorConsts(): Record<CellErrorTypes, string> {
		const {
			editCell,
		} = this.props;
		return {
			minWidthError: `Width must be at least ${editCell.model.aptusDesignConfiguration !== 'nonaptus' ? prebuildValConsts.minWidthAptus : prebuildValConsts.minWidthNonAptus} mm`,
			minDepthError: `Depth must be at least ${editCell.model.aptusDesignConfiguration !== 'nonaptus' ? prebuildValConsts.minDepthAptus : prebuildValConsts.minDepthNonAptus} mm`,
			minSlabBaseError: `Slab Depth at Base must be at least ${editCell.model.aptusDesignConfiguration !== 'nonaptus' ? prebuildValConsts.slabMinAptus : prebuildValConsts.slabMinNonAptus} mm`,
			minHeightErrorCurrent: `Height must at least ${prebuildValConsts.minHeight} mm`,
			maxHeightErrorCurrent: `Height must be less than ${prebuildValConsts.maxHeight} mm`,
			minHeightErrorBelow: `Height of cell below must at least ${prebuildValConsts.minHeight} mm`,
			maxHeightErrorBelow: `Height of cell below must be less than ${prebuildValConsts.maxHeight} mm`,
			maxWidthError: `Width must be less than ${prebuildValConsts.maxWidth} mm`,
			maxDepthError: `Depth must be less than ${prebuildValConsts.maxDepth} mm`,
			maxSlabBaseError: `Slab Depth at Base must be less than ${prebuildValConsts.slabMax} mm`,
			invalid: 'Invalid Function: Contact Support',
			minCorbelVoidWidthError: 'The element width cannot be less than the width of the corbel/void',
			minCorbelVoidDepthError: 'The element depth cannot be less than the depth of the corbel/void',
			minSlabTopError: `Slab Depth at Top must be at least ${editCell.model.aptusDesignConfiguration !== 'nonaptus' ? prebuildValConsts.slabMinAptus : prebuildValConsts.slabMinNonAptus} mm`,
			maxSlabTopError: `Slab Depth at Top must be less than ${prebuildValConsts.slabMax} mm`,
			maxPercentError: 'The sum of the percentages of the loads being transferred must equal 100%',
			percentWithoutElement: 'A load percentage cannot be transferred to nothing. Set an element to transfer to.',
			elementToReceiving4: 'Cannot transfer more than 4 loads to the same cell. Check your Load Transfers.',
			nonAptusRequiresVertReo: 'You are missing conventional reinforcing, please specify a reo rate or vertical bars with ties.',
			nonAptusRequiresHorReo: 'You are missing conventional reinforcing, please specify a reo rate or vertical bars with ties.',
		};
	}

	private renderSetupTab = () => {
		const { editCell, readonly } = this.props;
		return (
			<>
				<h5>Element Shape</h5>
				<div className="element-shape-tab-wrap tab-wrap">
					<button onClick={() => this.changeCellShape('rectangular')} className={`edit-view-tab ${editCell.model.shape === 'rectangular' ? 'active' : ''}`} disabled={this.isDisabled}>Rectangular</button>
					<button onClick={() => this.changeCellShape('round')} className={`edit-view-tab ${editCell.model.shape === 'round' ? 'active' : ''}`} disabled={this.isDisabled}>Round</button>
				</div>

				{editCell.model.shape === 'rectangular'
					? (
						<div className="dimensions-wrap">
							<NumberTextField
								className="width"
								model={editCell.model}
								modelProperty="width"
								label="Width (mm)"
								tooltip="The width or larger dimension of the element"
								onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(editCell.model, 'width'))}
								onChangeAndBlur={this.saveCellContent}
								isDisabled={this.isDisabled}
							/>
							<div className="width-depth-divider">x</div>
							<NumberTextField
								className="depth"
								model={editCell.model}
								modelProperty="depth"
								label="Depth (mm)"
								tooltip="The thickness or smaller dimension of the element"
								onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(editCell.model, 'depth'))}
								onChangeAndBlur={this.saveCellContent}
								isDisabled={this.isDisabled}
							/>
						</div>
					)
					: null}
				{editCell.model.shape === 'round'
					? (
						<>
							<NumberTextField
								className="depth round-cell"
								model={editCell.model}
								modelProperty="depth"
								label="Diameter (mm)"
								tooltip="The diameter of the precast element"
								onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(editCell.model, 'depth'))}
								onChangeAndBlur={this.saveCellContent}
								isDisabled={this.isDisabled}
							/>
						</>
					)
					: null}
				{!this.widthDimensionLarger()
					? <p className="dimensions-error-text">Width must be larger than depth</p>
					: null}
				{CellEditView.validCellDimensions(editCell) !== 'valid'
					? <p className="dimensions-error-text">{this.prebuildErrorConsts[CellEditView.validCellDimensions(editCell)]}</p>
					: null}

				<NumberTextField
					model={editCell.model}
					className="slab-thickness-at-base-input"
					modelProperty="slabThicknessAtBase"
					label="Slab thickness at base (mm)"
					tooltip="The thickness of the concrete slab below this element. This will affect the height of the element below. For that reason, you cannot edit this if the element below has been approved."
					onAfterChange={() => runInAction(() => {
						this.validCellSlabThickness('Base');
					})}
					onChangeAndBlur={() => {
						ElementStructureUtils.cleanInt(editCell.model, 'slabThicknessAtBase');
						this.saveCellContent();
					}}
					isDisabled={editCell.model.approved !== false || editCell.info.aboveApprovedCell || readonly}
				/>

				<SocketExtensionOptions
					editCell={editCell}
					saveCellContent={this.saveCellContent}
					readonly={readonly}
				/>

				{editCell.info.aboveApprovedCell
					? (
						<p className={classNames('above-approved-cell-note', 'icon-left', 'icon-x-circle')}>
							Editing is disabled because it would affect the height of an approved element below.
						</p>
					)
					: null }
				{this.cellSlabError && this.cellSlabError.toLowerCase().includes('base')
					? <p className="dimensions-error-text">{this.prebuildErrorConsts[this.cellSlabError]}</p>
					: null }

				{editCell.info.atTopOfStack || editCell.info.atTopOfBuilding
					? (
						<>
							<NumberTextField
								model={editCell.model}
								className="slab-thickness-at-top"
								modelProperty="slabThicknessAtTop"
								label="Slab thickness at top (mm)"
								tooltip="The thickness of the concrete slab above this element."
								onAfterChange={() => runInAction(() => {
									this.validCellSlabThickness('Top');
								})}
								onChangeAndBlur={() => {
									ElementStructureUtils.cleanInt(editCell.model, 'slabThicknessAtTop');
									if (!editCell.model.slabThicknessAtTop) {
										editCell.model.slabThicknessAtTop = ElementStructureUtils.defaultSlabThickness;
									}
									this.saveCellContent();
								}}
								isDisabled={this.isDisabled}
							/>
							{this.cellSlabError && this.cellSlabError.toLowerCase().includes('top')
								? <p className="dimensions-error-text">{this.prebuildErrorConsts[this.cellSlabError]}</p>
								: null }
						</>
					)
					: null}
				{this.resultantCellHeight !== undefined && this.cellHeightError !== undefined && !(editCell.model.overrideSSL || editCell.model.overrideTopSsl)
					? (
						<>
							<p className="dimensions-error-text">{this.prebuildErrorConsts[this.cellHeightError]}</p>
							<p className="dimensions-error-text">Resultant Cell Height: {this.resultantCellHeight} mm</p>
						</>
					)
					: null}
				{/* Override the level's default RL, if needed */}
				{this.props.selectionUtils.selectionSpansMultipleLevels
					? null
					: (
						<>
							<Checkbox
								className="override-ssl"
								model={editCell.model}
								modelProperty="overrideSSL"
								label="Override element base SSL"
								tooltip="If the element is located at a slab set down or in an area where the slab steps up, tick this check-box. You can then manually enter the SSL at the base of this element, without effecting the SSL of any other elements on this level. This will affect the height of the element below. For that reason, you cannot edit this if the element below has been approved."
								inputProps={{ onBlur: this.saveCellContent }}
								isDisabled={editCell.model.approved !== false || editCell.info.aboveApprovedCell || readonly}
							/>
							{editCell.model.overrideSSL
								? (
									<NumberTextField
										className="ssl"
										model={editCell.model}
										modelProperty="ssl"
										onAfterChange={() => runInAction(() => this.validCellHeight())}
										onChangeAndBlur={() => {
											ElementStructureUtils.formatSSL(editCell.model, 'ssl');
											this.saveCellContent();
										}}
										label="Element SSL (m)"
										placeholder="e.g. 3.000"
										tooltip="Enter the custom SSL at the base of this element. Note that adjusting this SSL will change the height of the element below. All elements must have a minimum height of 300mm. If you adjust the SSL at the base of this element until the element below is less than 300mm you will get an error. In this case you should merge cells in the element table to create a double height element. You cannot edit this if the element below has been approved."
										isDisabled={editCell.model.approved !== false || editCell.info.aboveApprovedCell || readonly}
									/>
								)
								: null}
						</>
					)}

				{editCell.info.aboveApprovedCell
					? (
						<p className={classNames('above-approved-cell-note', 'icon-left', 'icon-x-circle', editCell.model.overrideSSL ? null : 'beneath-checkbox')}>
							Editing is disabled because it would affect the height of an approved element below.
						</p>
					)
					: null}

				{editCell.info.atTopOfBuilding
					? (
						<>
							<Checkbox
								className="override-top-ssl"
								model={editCell.model}
								modelProperty="overrideTopSsl"
								label="Override element top SSL"
								tooltip="If the element is located at a slab set down or in an area where the slab steps up, tick this check-box. You can then manually enter the SSL at the top of this element, without effecting the SSL of any other elements on this level."
								inputProps={{ onBlur: this.saveCellContent }}
								isDisabled={this.isDisabled}
							/>
							{editCell.model.overrideTopSsl
								? (
									<NumberTextField
										className="topSsl"
										model={editCell.model}
										modelProperty="topSsl"
										onAfterChange={() => runInAction(() => this.validCellHeight())}
										onChangeAndBlur={() => {
											ElementStructureUtils.formatSSL(editCell.model, 'topSsl');
											this.saveCellContent();
										}}
										label="Element Top SSL (m)"
										placeholder="e.g. 3.000"
										tooltip="Enter the custom SSL at the top of this element. Note that adjusting this SSL will change the height of this element. All elements must have a minimum height of 300mm. If you adjust the SSL at the top of this element until the element is less than 300mm you will get an error. In this case you should merge cells in the element table to create a double height element."
										isDisabled={this.isDisabled}
									/>
								)
								: null}
						</>
					)
					: null}

				{this.resultantCellHeight !== undefined && this.cellHeightError !== undefined && (editCell.model.overrideSSL || editCell.model.overrideTopSsl)
					? (
						<>
							<p className="dimensions-error-text">{this.prebuildErrorConsts[this.cellHeightError]}</p>
							<p className="dimensions-error-text">Resultant Cell Height: {this.resultantCellHeight} mm</p>
						</>
					)
					: null}

				<NumberTextField
					className="height"
					model={editCell.model}
					modelProperty="height"
					label="Element Height (mm)"
					isDisabled
				/>
				<Button
					onClick={this.toggleShowAdvancedState}
					className="show-advanced-btn"
					display={Display.Text}
					colors={Colors.Primary}
					icon={{ icon: this.showAdvancedState ? 'chevron-up' : 'chevron-down', iconPos: 'icon-right' }}
					disabled={editCell.model.approved !== false}
				>
					Advanced
				</Button>
				{this.showAdvancedState
					? (
						<AdvancedDimensionsInputs
							editCell={this.props.editCell}
							selectionUtils={this.props.selectionUtils}
							saveCellContent={() => this.saveCellContent()}
							isDisabled={this.isDisabled}
							getCellHeight={this.props.getCellHeight}
						/>
					)
					: null}
			</>
		);
	};

	private renderLoadingTab = () => {
		const {
			editCell, canSplitCells, readonly, elementStructure,
		} = this.props;
		return (
			<>
				<h5>Calculated Load</h5>
				<NumberTextField
					className="calculated-load"
					model={editCell.model}
					modelProperty="calculatedLoad"
					label={editCell.model.overrideCalculatedLoad ? 'Load (kN)' : 'Calculated load (kN)'}
					tooltip="Input the manually calculated load, in kN, applied to the top this element. This value should be the total factored ultimate load. This value ignores the automatically calculated load run down."
					onChangeAndBlur={this.saveCellContent}
					isDisabled={!editCell.model.overrideCalculatedLoad || this.isDisabled}
				/>
				<Checkbox
					className="override-load"
					model={editCell.model}
					modelProperty="overrideCalculatedLoad"
					label="Override calculated load"
					tooltip="Instead of automatically calculating the load for this element, you can set the correct load directly."
					inputProps={{ onBlur: this.saveCellContent }}
					isDisabled={this.isDisabled}
				/>
				{!editCell.model.overrideCalculatedLoad
					? (
						<>
							{canSplitCells
								? (
									<Checkbox
										className="apply-load"
										model={editCell.model}
										modelProperty="applyLoadAtEveryLevel"
										label="Apply load at every level"
										tooltip="If the selected element(s) are in a void, they might not be supporting any loads from the intermediary levels. In that case, you can uncheck this option to reduce load."
										inputProps={{ onBlur: this.saveCellContent }}
										isDisabled={this.isDisabled}
									/>
								)
								: null}
							<NumberTextField
								className="additional-load"
								model={editCell.model}
								modelProperty="additionalLoad"
								label="Additional load (kN)"
								tooltip="Use this input to account for any additional load applied at this level. This value will be added to the automatically calculated load run down based on the tributary areas and uniformly distributed load"
								onChangeAndBlur={this.saveCellContent}
								isDisabled={editCell.model.overrideCalculatedLoad || this.isDisabled}
							/>
						</>
					)
					: null}
				{editCell.info.atBottomOfColumn && this.props.selectionUtils?.selectedCells?.length <= 1
					? (
						<LoadTransfers
							saveCellContent={this.saveCellContent}
							isDisabled={this.isDisabled}
							editCell={editCell}
							elementStructure={elementStructure}
							getCellsBelow={() => this.props.getCellsBelow()}
						/>
					)
					: null}
				{editCell.info.atBottomOfColumn && this.props.selectionUtils?.selectedCells?.length <= 1 && this.validLoadTransfers() !== 'valid'
					? <p className="dimensions-error-text">{this.prebuildErrorConsts[this.validLoadTransfers()]}</p>
					: null}
				<h5 className="axis-section major">Major Axis</h5>
				<NumberTextField
					className={classNames('axis-moment', 'major')}
					model={editCell.model}
					modelProperty="majorAxisMoment"
					label="Major axis moment"
					tooltip="The user can input the major axis moment if known. If no value is input, the minimum moment as per AS 3600:2018 Cl 10.1.2 will be used."
					onChangeAndBlur={this.saveCellContent}
					isDisabled={editCell.model.approved !== false || readonly}
				/>

				<h5 className="axis-section minor">Minor Axis</h5>
				<NumberTextField
					model={editCell.model}
					className={classNames('axis-moment', 'minor')}
					modelProperty="minorAxisMoment"
					label="Minor axis moment"
					tooltip="The user can input the minor axis moment if known. If no value is input, the minimum moment as per AS 3600:2018 Cl 10.1.2 will be used."
					onChangeAndBlur={this.saveCellContent}
					isDisabled={editCell.model.approved !== false || readonly}
				/>

				<h5>Effective Length Factor</h5>
				<MinMaxNumberTextField
					min={editCell.model.aptusDesignConfiguration !== 'nonaptus' ? 0.7 : undefined}
					max={editCell.model.aptusDesignConfiguration !== 'nonaptus' ? 2.2 : undefined}
					model={editCell.model}
					className={classNames('axis-moment', 'minor')}
					modelProperty="kFactor"
					label="Effective Length Factor ke"
					onChangeAndBlur={this.saveCellContent}
					isDisabled={this.isDisabled}
				/>
			</>
		);
	};

	private renderOutputTab = () => {
		const { editCell } = this.props;
		const {
			majorAxialChkRatio, majorProppingChkRatio, minorAxialChkRatio, minorProppingChkRatio,
		} = editCell.model;
		return (
			<>
				<h5>Interaction Curves</h5>
				{editCell.model.majorInteractionCurve
					? (
						<div className="interaction-curve-wrap major">
							<p>Major interaction curve</p>
							<InteractionCurveGraph
								curve={editCell.model.majorInteractionCurve}
								load={editCell.model.calculatedLoad}
								mult={editCell.model.workingMajorAxisMoment ? editCell.model.workingMajorAxisMoment : editCell.model.majorAxisMoment}
							/>
						</div>
					)
					: null }
				{majorProppingChkRatio
					? (
						<p className="propping-check-ratio major">
							<span className="label">Major Axis Propping Ratio: </span>
							<span className={classNames('percent', majorProppingChkRatio > 100 ? 'error' : null)}>
								{majorProppingChkRatio.toLocaleString('en-AU', { style: 'decimal', maximumFractionDigits: 1 })}%
							</span>
						</p>
					)
					: null }
				{majorAxialChkRatio
					? (
						<p className="axial-check-ratio major">
							<span className="label">Major Axis Strength Ratio: </span>
							<span className={classNames('percent', majorAxialChkRatio > 100 ? 'error' : null)}>
								{majorAxialChkRatio.toLocaleString('en-AU', { style: 'decimal', maximumFractionDigits: 1 })}%
							</span>
						</p>
					)
					: null }
				{editCell.model.minorInteractionCurve
					? (
						<div className="interaction-curve-wrap minor">
							<p>Minor interaction curve</p>
							<InteractionCurveGraph
								curve={editCell.model.minorInteractionCurve}
								load={editCell.model.calculatedLoad}
								mult={editCell.model.minorAxisMoment ? editCell.model.workingMinorAxisMoment : editCell.model.workingMinorAxisMoment}
							/>
						</div>
					)
					: null }
				{minorProppingChkRatio
					? (
						<p className="propping-check-ratio minor">
							<span className="label">Minor Axis Propping Ratio: </span>
							<span className={classNames('percent', minorProppingChkRatio > 100 ? 'error' : null)}>
								{minorProppingChkRatio.toLocaleString('en-AU', { style: 'decimal', maximumFractionDigits: 1 })}%
							</span>
						</p>
					)
					: null }
				{minorAxialChkRatio
					? (
						<p className="propping-check-ratio minor">
							<span className="label">Minor Axis Strength Ratio: </span>
							<span className={classNames('percent', minorAxialChkRatio > 100 ? 'error' : null)}>
								{minorAxialChkRatio.toLocaleString('en-AU', { style: 'decimal', maximumFractionDigits: 1 })}%
							</span>
						</p>
					)
					: null }
			</>
		);
	};

	private calcTempVertReoPercentage() {
		const { editCell } = this.props;

		const verticalPercent = ElementStructureUtils.calculateVerticalReoPercentage(
			editCell.model.shape,
			editCell.model.aptusBarsAlongDepth,
			editCell.model.depth,
			editCell.model.width,
			editCell.model.aptusBarType,
			editCell.model.aptusBarsAlongWidth,
		);

		// when we select multiple cells of different bar types, we can't resolve the vertical reo percentage to a single value
		// @ts-ignore
		return (editCell.model.aptusBarType === 'undefined' || Number.isNaN(verticalPercent) || verticalPercent === Infinity) ? 'N/A' : `${verticalPercent.toFixed(2)}%`;
	}

	private validateAptusBarsWidth() {
		const { editCell } = this.props;
		if (editCell.model.aptusDesignConfiguration === 'aptus') {
			return '';
		}

		if (editCell.model.shape === 'rectangular') {
			if (editCell.model.aptusBarsAlongWidth || editCell.model.aptusBarsAlongWidth === 0) {
				if (editCell.model.aptusBarsAlongWidth < 2) {
					return 'There must be at least 2 bars along width';
				}
				if (editCell.model.aptusBarsAlongWidth > 30) {
					return 'There cannot be more than 30 bars along width';
				}
			}
		}

		return '';
	}

	private validateAptusBarsDepth() {
		const { editCell } = this.props;
		if (editCell.model.aptusDesignConfiguration === 'aptus') {
			return '';
		}

		if (editCell.model.shape === 'rectangular') {
			if (editCell.model.aptusBarsAlongDepth || editCell.model.aptusBarsAlongDepth === 0) {
				if (editCell.model.aptusBarsAlongDepth < 2) {
					return 'There must be at least 2 bars along depth';
				}
				if (editCell.model.aptusBarsAlongDepth > 30) {
					return 'There cannot be more than 30 bars along depth';
				}
			}
		} else if (editCell.model.aptusBarsAlongDepth || editCell.model.aptusBarsAlongDepth === 0) {
			if (editCell.model.aptusBarsAlongDepth < 2) {
				return 'There must be at least 2 Aptus bars';
			}
			if (editCell.model.aptusBarsAlongDepth > 60) {
				return 'There cannot be more than 60 Aptus bars';
			}
		}

		return '';
	}

	private validationReoRate() {
		const { editCell: { model: { useReoRate, reoRate } } } = this.props;

		if (useReoRate) {
			if (reoRate === undefined || reoRate === null) {
				return 'A reo rate is required';
			}

			if (reoRate <= 0) {
				return 'The reo rate must be greater than zero';
			}
		}

		return '';
	}

	private widthDimensionLarger() {
		const { editCell } = this.props;
		if (editCell.model.width && editCell.model.depth) {
			if (editCell.model.width < editCell.model.depth && editCell.model.shape === 'rectangular') {
				return false;
			}
		}
		return true;
	}

	@action
	private validCellSlabThickness(type?: 'Base' | 'Top') {
		const { editCell } = this.props;

		this.cellSlabError = undefined;
		this.resultantCellHeight = undefined;
		this.cellHeightError = undefined;

		// Do both checks if type is not specified

		// Validate against constants
		const minSlabValueForDesignConfig = editCell.model.aptusDesignConfiguration === undefined || editCell.model.aptusDesignConfiguration !== 'nonaptus'
			? prebuildValConsts.slabMinAptus
			: prebuildValConsts.slabMinNonAptus;

		if ((type === 'Base' || !type) && editCell.model.slabThicknessAtBase !== undefined && editCell.model.slabThicknessAtBase !== null) {
			if (editCell.model.slabThicknessAtBase < minSlabValueForDesignConfig) {
				this.cellSlabError = 'minSlabBaseError';
				return this.cellSlabError;
			} if (editCell.model.slabThicknessAtBase > prebuildValConsts.slabMax) {
				this.cellSlabError = 'maxSlabBaseError';
				return this.cellSlabError;
			}
		}

		if ((type === 'Top' || !type) && editCell.model.slabThicknessAtTop !== undefined && editCell.model.slabThicknessAtTop !== null && (editCell.info.atTopOfStack || editCell.info.atTopOfBuilding)) {
			console.log('validating slab thickness at top', editCell.model.slabThicknessAtTop);
			if (editCell.model.slabThicknessAtTop < minSlabValueForDesignConfig) {
				this.cellSlabError = 'minSlabTopError';
				return this.cellSlabError;
			} if (editCell.model.slabThicknessAtTop > prebuildValConsts.slabMax) {
				this.cellSlabError = 'maxSlabTopError';
				return this.cellSlabError;
			}
		}

		// Validate against below cell level values
		const belowCellValid = this.validBelowCellHeight();
		if (belowCellValid !== 'valid') return belowCellValid;

		const currentCellValid = this.validCurrentCellHeight();
		if (currentCellValid !== 'valid') return currentCellValid;

		// clear errors
		this.cellSlabError = undefined;
		this.cellHeightError = undefined;
		this.resultantCellHeight = undefined;

		return 'valid';
	}

	@action
	private validCurrentCellHeight() {
		const { getCellHeight, editCell } = this.props;
		// Check the height of the current cell is valid
		const heightAbove = getCellHeight(editCell);
		this.resultantCellHeight = heightAbove;

		if (heightAbove < prebuildValConsts.minHeight) {
			this.cellHeightError = 'minHeightErrorCurrent';
			return this.cellHeightError;
		}
		if (heightAbove > prebuildValConsts.maxHeight) {
			this.cellHeightError = 'maxHeightErrorCurrent';
			return this.cellHeightError;
		}
		this.cellHeightError = undefined;
		this.resultantCellHeight = undefined;

		return 'valid';
	}

	@action
	private validBelowCellHeight() {
		const { selectionUtils, editCell } = this.props;
		// Check the height of the cell below is valid
		const belowHeights = selectionUtils.getValidCellBelowHeight(editCell, selectionUtils.selectedCells);

		if (belowHeights !== 'No Cell Below') {
			const [minHeightBelow, maxHeightBelow] = belowHeights;
			if (minHeightBelow < prebuildValConsts.minHeight) {
				this.cellHeightError = 'minHeightErrorBelow';
				this.resultantCellHeight = minHeightBelow;
				return this.cellHeightError;
			}
			if (maxHeightBelow > prebuildValConsts.maxHeight) {
				this.cellHeightError = 'maxHeightErrorBelow';
				this.resultantCellHeight = maxHeightBelow;
				return this.cellHeightError;
			}
		}

		this.cellHeightError = undefined;
		this.resultantCellHeight = undefined;

		return 'valid';
	}

	@action
	private validCellHeight() {
		const { editCell } = this.props;
		if (editCell.model.overrideSSL || editCell.model.overrideTopSsl) {
			// Check the height of the current cell is valid
			const currentCellValid = this.validCurrentCellHeight();
			if (currentCellValid !== 'valid') return currentCellValid;

			// Check the height of the cell below is valid
			const belowCellValid = this.validBelowCellHeight();
			if (belowCellValid !== 'valid') return belowCellValid;
		}
		this.resultantCellHeight = undefined;
		this.cellHeightError = undefined;

		return 'valid';
	}

	public static validCellDimensions(editCell: EditCell) {
		if (editCell.model.width !== undefined) {
			if (editCell.model.corbel || editCell.model.elementVoid) {
				if ((
					editCell.model.corbel?.width
					&& editCell.model.width < editCell.model.corbel?.width
					&& editCell.model.corbel.useCellWidth !== true
				)
				|| (
					editCell.model.elementVoid?.width
					&& editCell.model.width < editCell.model.elementVoid?.width
					&& editCell.model.elementVoid.useCellWidth !== true
				)) {
					return 'minCorbelVoidWidthError';
				}

				if ((
					editCell.model.corbel?.depth
					&& editCell.model.depth
					&& editCell.model.depth < editCell.model.corbel?.depth
					&& editCell.model.corbel.useCellDepth !== true
				)
				|| (
					editCell.model.elementVoid?.depth
					&& editCell.model.depth
					&& editCell.model.depth < editCell.model.elementVoid?.depth
					&& editCell.model.elementVoid.useCellDepth !== true
				)) {
					return 'minCorbelVoidDepthError';
				}
			}

			if (
				editCell.model.width < (editCell.model.aptusDesignConfiguration !== 'nonaptus' ? prebuildValConsts.minWidthAptus : prebuildValConsts.minWidthNonAptus)
				&& editCell.model.shape === 'rectangular'
			) {
				return 'minWidthError';
			} if (editCell.model.width > prebuildValConsts.maxWidth) {
				return 'maxWidthError';
			}
		}
		if (editCell.model.depth !== undefined) {
			if (editCell.model.depth < (editCell.model.aptusDesignConfiguration !== 'nonaptus' ? prebuildValConsts.minDepthAptus : prebuildValConsts.minDepthNonAptus)) {
				return 'minDepthError';
			} if (editCell.model.depth > prebuildValConsts.maxDepth) {
				return 'maxDepthError';
			}
		}
		return 'valid';
	}

	private validLoadTransfers(): CellErrorTypes | 'valid' | string {
		const { editCell, elementStructure } = this.props;
		if (!!editCell.model.loadTransfers) {
			// Check if there are any percentages without an element associated
			const percentWithoutElement = editCell.model.loadTransfers.filter(load => !load.receivingId && !!load.percent);
			if (percentWithoutElement.some(x => !!x)) return 'percentWithoutElement';

			// Check if the max percentage of the loads transferred add up to 100%
			const percentageArray: number[] = editCell.model.loadTransfers.map(load => !!load.percent ? load.percent : 0);
			const sum = percentageArray.reduce((partialSum, a) => partialSum + a, 0);
			if (sum !== 100 && sum !== 0) return 'maxPercentError';

			let error: string | undefined;
			editCell.model.loadTransfers.forEach(x => {
				if (x.receivingId) {
					const currentLoads = ElementStructureUtils
						.getCellLoadTransfers(ElementStructureUtils.generateTransferIndex(elementStructure), x.receivingId);

					if (currentLoads.receivingLoads && currentLoads.receivingLoads.filter(x => !!x).length > 4) {
						error = 'elementToReceiving4';
					}
				}
			});

			if (error !== undefined) return error;
		}
		return 'valid';
	}

	private validateNonAptusVerticalReo(): CellErrorTypes | 'valid' {
		const {
			editCell: {
				model: {
					useReoRate, shape, nonAptusBarsAlongWidth, nonAptusBarsAlongDepth, aptusDesignConfiguration,
				},
			},
		} = this.props;

		return !useReoRate && ElementStructureUtils.nonAptusBarQuantity(shape, nonAptusBarsAlongWidth, nonAptusBarsAlongDepth, aptusDesignConfiguration) === 0
			? 'nonAptusRequiresVertReo'
			: 'valid';
	}

	private validateNonAptusHorizontalReo(): CellErrorTypes | 'valid' {
		const { editCell: { model: { useReoRate, nonAptusBarLigType, nonAptusBarLigSpacing } } } = this.props;

		return (!useReoRate && (nonAptusBarLigType === undefined || nonAptusBarLigType === 'undefined' || (!nonAptusBarLigSpacing && nonAptusBarLigSpacing !== 0)))
			? 'nonAptusRequiresHorReo'
			: 'valid';
	}

	private saveCellValid() {
		const { editCell, selectionUtils } = this.props;

		// validate only cell height for empty or selected deleted cells (because SSL at base can still be overridden)
		// we can skip in-depth validation when deleted cells are selected because a basic 'restore' UI is shown instead of
		// element inputs when at least 1 deleted cell is selected
		if (ElementStructureUtils.cellDimensionsEmpty(editCell.model.width, editCell.model.shape, editCell.model.depth)
			|| selectionUtils.selectionContainsDeletedCells
		) {
			return (
				this.validCellSlabThickness() === 'valid'
				&& this.validCellHeight() === 'valid'
				&& this.validLoadTransfers() === 'valid'
			);
		} if (
			CellEditView.validCellDimensions(editCell) !== 'valid'
			|| this.validCellSlabThickness() !== 'valid'
			|| this.validCellHeight() !== 'valid'
			|| !this.widthDimensionLarger()
			|| this.validateAptusBarsWidth()
			|| this.validateAptusBarsDepth()
			|| this.validLoadTransfers() !== 'valid'
			|| this.validationReoRate()
		) {
			return false;
		}

		return true;
	}

	@action private changeDesignConfig = (newDesignConfig: DesignConfiguration) => {
		newDesignConfig === 'aptus' ? this.startCustomEditing(false)
			: newDesignConfig === 'custom' ? this.startCustomEditing(true)
				: this.startCustomEditing(false);
		this.props.editCell.model.aptusDesignConfiguration = newDesignConfig;
		this.saveCellContent();
	};

	@action private changeCellShape = (newCellShape: CellShape) => {
		this.props.editCell.model.shape = newCellShape;
		this.saveCellContent();
	};

	private renderMergeSplitButton = () => {
		const {
			editCell, canSplitCells, splitCells, canMergeCells, mergeCells,
		} = this.props;
		if (canSplitCells) {
			return (
				<Button
					onClick={splitCells}
					className="split-cell-btn"
					display={Display.Solid}
					colors={Colors.Primary}
					icon={{ icon: 'spreadsheet', iconPos: 'icon-left' }}
					disabled={editCell.model.approved !== false}
				>
					Split Cells
				</Button>
			);
		}
		return (
			<div className="merge-cell-wrap">
				<Button
					onClick={mergeCells}
					className="merge-cell-btn btn--tertiary"
					display={Display.Solid}
					icon={{ icon: 'spreadsheet', iconPos: 'icon-left' }}
					disabled={canMergeCells === false || editCell.model.approved !== false}
				>
					Merge Cells
				</Button>
				<div className="merge-explanation-tooltip">To merge cells, you&apos;ll need to select multiple adjacent elements within a single column. You can use &apos;shift&apos; to select a range of cells.</div>
			</div>
		);
	};

	@action private toggleShowAdvancedState = () => {
		this.showAdvancedState = !this.showAdvancedState;
	};

	@action
	private deleteCells = () => {
		const {
			editCell, deleteCells, selectionUtils, elementStructure,
		} = this.props;

		// APT-667 if cell is transferring or receiving loads we do not want it to be deleted.
		let deletable = true;

		selectionUtils.selectedCells.forEach(({ model: cell }) => {
			if (!cell) {
				console.error('Cell could not be found');
				return;
			}

			const associatedLoads = ElementStructureUtils
				.getCellLoadTransfers(ElementStructureUtils.generateTransferIndex(elementStructure), cell.id);

			// build list of load transferring cell names preventing deletion
			let receivedLoadDisplayList: string = '';

			associatedLoads.receivingLoads.forEach(x => {
				if (x) {
					const name = ElementStructureUtils.getCellName(elementStructure, ElementStructureUtils.getCellById(elementStructure, x.cellId)!);
					if (name) {
						receivedLoadDisplayList = receivedLoadDisplayList
							? `${receivedLoadDisplayList}, ${name}`
							: name;
					}
				}
			});

			let outgoingLoadDisplayList: string = '';

			associatedLoads.outgoingLoads?.data?.forEach(x => {
				if (x && x.receivingId) {
					const name = ElementStructureUtils.getCellName(elementStructure, ElementStructureUtils.getCellById(elementStructure, x.receivingId)!);
					if (name) {
						outgoingLoadDisplayList = outgoingLoadDisplayList
							? `${outgoingLoadDisplayList}, ${name}`
							: name;
					}
				}
			});

			// if there's any ingoing or outgoing loads, prevent deletion
			if (outgoingLoadDisplayList.length || receivedLoadDisplayList.length) {
				deletable = false;
			}

			// show alert toast depending on which loads are present
			if (outgoingLoadDisplayList.length && receivedLoadDisplayList.length) {
				alertToast(`Error: You cannot delete elements that have load transferred to or from them.\
					Remove any transferred loads to ${outgoingLoadDisplayList} \
					and loads received from ${receivedLoadDisplayList}`, 'error');
			} else if (receivedLoadDisplayList.length) {
				alertToast(`Error: You cannot delete elements that have load transferred to or from them.\
					Remove loads received from ${receivedLoadDisplayList}`, 'error');
			} else if (outgoingLoadDisplayList.length) {
				alertToast(`Error: You cannot delete elements that have load transferred to or from them.\
					Remove any transferred loads to ${outgoingLoadDisplayList}.`, 'error');
			}
		});

		if (deletable) {
			editCell.info.deleted = true;
			deleteCells();
		}
	};

	@action
	private restoreDeletedCells = () => {
		const {
			editCell, restoreDeletedCells, selectionUtils, elementStructure,
		} = this.props;
		let restorable = false;
		if (selectionUtils.selectedCells) {
			selectionUtils.selectedCells.forEach(({ model: cell }) => {
				if (cell) {
					const cellAbove = ElementStructureUtils.findCellAboveGivenCell(elementStructure, cell);

					if (cellAbove) {
						const loadTransferIndex = ElementStructureUtils.generateTransferIndex(elementStructure);

						if (ElementStructureUtils.hasAssociatedLoads(cellAbove, loadTransferIndex)) {
							// build list of cell names for load transferring cells for cell above cell attempting to be restored
							// so we can tell the user which cells they need to look at removing load transfers
							const associatedLoads = ElementStructureUtils
								.getCellLoadTransfers(loadTransferIndex, cellAbove.id);

							const receivedLoadCellNames: string[] = [];
							associatedLoads.receivingLoads.forEach(x => {
								if (x) {
									const name = ElementStructureUtils
										.getCellName(elementStructure, ElementStructureUtils.getCellById(elementStructure, x.cellId)!);
									if (name) receivedLoadCellNames.push(name);
								}
							});

							const outgoingLoadCellNames: string[] = [];
							associatedLoads.outgoingLoads?.data?.forEach(x => {
								if (x && x.receivingId) {
									const name = ElementStructureUtils
										.getCellName(elementStructure, ElementStructureUtils.getCellById(elementStructure, x.receivingId)!);
									if (name) outgoingLoadCellNames.push(name);
								}
							});

							const cellAboveName = ElementStructureUtils.getCellName(elementStructure, cellAbove);

							if (receivedLoadCellNames.length && outgoingLoadCellNames.length) {
								alertToast(`Error: You cannot restore elements under elements that have load transferred to/from them. \
								Remove any transferred loads from ${cellAboveName} to ${outgoingLoadCellNames.slice(1, outgoingLoadCellNames.length).reduce((acc, curr) => `${acc}, ${curr}`, outgoingLoadCellNames[0])} \
								and received loads from ${receivedLoadCellNames.slice(1, receivedLoadCellNames.length).reduce((acc, curr) => `${acc}, ${curr}`, receivedLoadCellNames[0])}`, 'error');
							} else if (receivedLoadCellNames.length) {
								alertToast(`Error: You cannot restore elements under elements that have load transferred to/from them. \
								Remove received loads to ${cellAboveName} from ${receivedLoadCellNames.slice(1, receivedLoadCellNames.length).reduce((acc, curr) => `${acc}, ${curr}`, receivedLoadCellNames[0])}`, 'error');
							} else if (outgoingLoadCellNames.length) {
								alertToast(`Error: You cannot restore elements under elements that have load transferred to/from them. \
								Remove any transferred loads from ${cellAboveName} to ${outgoingLoadCellNames.slice(1, outgoingLoadCellNames.length).reduce((acc, curr) => `${acc}, ${curr}`, outgoingLoadCellNames[0])}.`, 'error');
							}
						} else {
							restorable = true;
						}
					} else {
						restorable = true;
					}
				}
			});
		}
		if (restorable) {
			editCell.info.deleted = false;
			restoreDeletedCells();
		}
	};

	@action
	private approveCells = () => {
		const { editCell, approveCells } = this.props;
		editCell.model.approved = true;
		approveCells();
	};

	@action
	private disapproveCells = () => {
		const { editCell, disapproveCells } = this.props;
		editCell.model.approved = false;
		disapproveCells();
	};
}
