import { observer } from 'mobx-react';
import * as React from 'react';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import { runInAction } from 'mobx';
import { ElementStructureUtils } from 'Util/ElementStructureUtils';
import { TextField } from 'Views/Components/TextBox/TextBox';

export interface ICellPartTypeEditViewProps {
	partTypeName: string;
	partTypeObject: any;
	saveChanges: () => void;
	readonly?: boolean;
}

@observer
export default class CellPartTypeEditView extends React.Component<ICellPartTypeEditViewProps> {
	// Map bar type name to human readable string
	private barTypeNameDict = {
		bars: 'Aptus Bars (within element)',
		innerCouplers: 'Inner Couplers (at top)',
		outerCouplers: 'Outer Couplers (at base)',
		extensions: 'Extension Bars (at top)',
		insituStarterInnerCouplers: 'Inner Couplers (for in-situ starters)',
		insituStarterExtensions: 'Extension Bars (for in-situ starters)',
		starterBars: 'Aptus Starter Bars (within element)',
		insituStarterBars: 'In-situ Starter Bars',
		anchorHeads: 'Aptus Threaded Anchors',
	};

	public render() {
		const {
			partTypeName, partTypeObject, readonly, saveChanges,
		} = this.props;

		// We can only show anything if this part is actually set
		if (partTypeObject === null) {
			return null;
		}

		// We render a slightly different thing depending on what part type we have
		// Bars
		if (partTypeName === 'bars') {
			return (
				<>
					<h5>{this.barTypeNameDict[partTypeName]}</h5>
					<NumberTextField
						model={partTypeObject}
						className={`${partTypeName}-quantity`}
						modelProperty="quantity"
						label="Quantity"
						tooltip="The number of parts needed."
						onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(partTypeObject, 'quantity'))}
						onChangeAndBlur={saveChanges}
						isDisabled={readonly}
					/>

					<TextField
						model={partTypeObject}
						className={`${partTypeName}-name`}
						modelProperty="barName"
						label="Bar Name"
						isDisabled
					/>
				</>
			);
		}

		// Starter Bars
		if (['starterBars', 'insituStarterBars'].includes(partTypeName)) {
			return (
				<>
					<h5>{this.barTypeNameDict[partTypeName]}</h5>
					<NumberTextField
						model={partTypeObject}
						className={`${partTypeName}-quantity`}
						modelProperty="quantity"
						label="Quantity"
						tooltip="The number of parts needed."
						onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(partTypeObject, 'quantity'))}
						onChangeAndBlur={saveChanges}
						isDisabled={readonly}
					/>

					<TextField
						model={partTypeObject}
						className={`${partTypeName}-name`}
						modelProperty="partName"
						label="Starter Bar Name"
						isDisabled
					/>
				</>
			);
		}

		// Other Parts
		if (['innerCouplers', 'outerCouplers', 'extensions', 'insituStarterInnerCouplers', 'insituStarterExtensions', 'anchorHeads'].includes(partTypeName)) {
			return (
				<>
					<h5>{this.barTypeNameDict[partTypeName]}</h5>
					<NumberTextField
						model={partTypeObject}
						className={`${partTypeName}-quantity`}
						modelProperty="quantity"
						label="Quantity"
						tooltip="The number of parts needed."
						onAfterChange={() => runInAction(() => ElementStructureUtils.cleanInt(partTypeObject, 'quantity'))}
						onChangeAndBlur={saveChanges}
						isDisabled={readonly}
					/>

					<TextField
						model={partTypeObject}
						className={`${partTypeName}-name`}
						modelProperty="partName"
						label="Starter Bar Name"
						isDisabled
					/>
				</>
			);
		}

		return null;
	}
}
