import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import type { EditCell } from 'Util/SelectionUtils';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import { ElementStructureUtils } from 'Util/ElementStructureUtils';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { SelectionUtils } from 'Util/SelectionUtils';

export interface IDeletedCellViewProps {
    editCell: EditCell;
    cancelSelection: () => any;
    closeBarArrangementModal?: () => void;
    readonly?: boolean;
    saveCellContent: () => void;
    restoreDeletedCells: () => void;
    selectionUtils: SelectionUtils,
    cellHeightError?: string,
    resultantCellHeight?: number,
}

const DeletedCellView = ({
	editCell, cancelSelection, closeBarArrangementModal, readonly, saveCellContent, restoreDeletedCells, selectionUtils, cellHeightError, resultantCellHeight,
}: IDeletedCellViewProps) => {
	return (
		<>
			<div className="edit-view-header cell deleted">
				<h4 className="edit-view-title">Edit element</h4>
				<button
					onClick={() => {
						cancelSelection();
						if (closeBarArrangementModal) closeBarArrangementModal();
					}}
					className="close-edit-view-btn"
					aria-label="Close"
				/>
				<p>
					{'One or more selected elements has been deleted. \
                    You cannot edit deleted elements, unless you restore \
                    them first. You may edit an element\'s base SSL, since \
                    this will affect the height of the element below.'}
				</p>
			</div>
			{!readonly
				? (
					<>
						<div className="edit-view-tab-content">
							{/* Override the level's default RL, if needed */}

							{editCell.info.aboveApprovedCell
								? (
									<p className={classNames('above-approved-cell-note', 'icon-left', 'icon-x-circle')}>
										Editing is disabled because it would affect the height of an approved element below.
									</p>
								)
								: null}
							{selectionUtils.selectionSpansMultipleLevels
								? null
								: (
									<>
										<Checkbox
											className="override-ssl"
											model={editCell.model}
											modelProperty="overrideSSL"
											label="Override element base SSL"
											tooltip={
												'If the element is located at a slab set down or \
                                        in an area where the slab steps up, tick this check-box. \
                                        You can then manually enter the SSL at the base of this \
                                        element, without effecting the SSL of any other elements \
                                        on this level. This will affect the height of the element \
                                        below. For that reason, you cannot edit this if the element \
                                        below has been approved.'
											}
											inputProps={{ onBlur: saveCellContent }}
											isDisabled={editCell.model.approved !== false || editCell.info.aboveApprovedCell || readonly}
										/>
										{editCell.model.overrideSSL
											? (
												<NumberTextField
													className="ssl"
													model={editCell.model}
													modelProperty="ssl"
													onChangeAndBlur={() => {
														ElementStructureUtils.formatSSL(editCell.model, 'ssl');
														saveCellContent();
													}}
													label="Element SSL (m)"
													placeholder="e.g. 3.000"
													tooltip={'Enter the custom SSL at the base of this element. \
                                            Note that adjusting this SSL will change the height of the \
                                            element below. All elements must have a minimum height of \
                                            300mm. If you adjust the SSL at the base of this element \
                                            until the element below is less than 300mm you will get an \
                                            error. In this case you should merge cells in the element \
                                            table to create a double height element. You cannot edit \
                                            this if the element below has been approved.'}
													isDisabled={editCell.model.approved !== false || editCell.info.aboveApprovedCell || readonly}
												/>
											)
											: null}
										{ cellHeightError
											? (
												<>
													<p className="dimensions-error-text">{cellHeightError}</p>
													<p className="dimensions-error-text">Resultant Cell Height: {resultantCellHeight} mm</p>
												</>
											)
											: null}
									</>
								)}

							{editCell.info.aboveApprovedCell
								? (
									<p className={classNames('above-approved-cell-note', 'icon-left', 'icon-x-circle', editCell.model.overrideSSL ? null : 'beneath-checkbox')}>
										Editing is disabled because it would affect the height of an approved element below.
									</p>
								)
								: null}
						</div>
						<div className="edit-view-actions">
							<Button
								onClick={restoreDeletedCells}
								className="restore-cell-btn"
								display={Display.Solid}
								colors={Colors.Primary}
							>
								Restore
							</Button>
						</div>
					</>
				)
				: null}

		</>
	);
};

export default observer(DeletedCellView);
