import * as React from 'react';
import ProjectEntity from 'Models/Entities/ProjectEntity';
import { observer } from 'mobx-react';
import WizardStep from 'Views/Components/ProjectWizard/WizardStep';
import EditElementsGrid from 'Views/Components/ProjectWizard/EditElementsGrid';
import { ColumnType, ElementStructureUtils } from 'Util/ElementStructureUtils';
import { action } from 'mobx';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';

@observer
export default class WizardElementsStep extends WizardStep {
	public componentDidMount(): void {
		this.setupColumnTypes();
	}

	@action setupColumnTypes = () => {
		// If there are no column types, we want to set up some basic ones
		if (this.props.project.parsedElementStructure.columnTypes.length === 0) {
			const createColumnsTypesList = [
				{ name: 'Precast Columns', code: 'PC' },
				{ name: 'Precast Walls', code: 'PW' },
				{ name: 'Basement Columns', code: 'BC' },
				{ name: 'Tower Columns', code: 'TC' },
				{ name: 'Core Walls', code: 'CW' },
			];

			createColumnsTypesList.forEach(columnTypeToCreate => {
				ElementStructureUtils.addColumnTypeToElementStructure(this.props.project.parsedElementStructure, columnTypeToCreate);
			});
		}

		// Next, we set the estimated column count for each column type
		this.props.project.parsedElementStructure.columnTypes.forEach((columnType: ColumnType) => {
			columnType.estimatedColumnCount = columnType.columns.length;
		});

		// Save the project
		this.props.project.parsedElementStructure.info.editedSinceLastBuild = true;
		if (this.props.projectCanBeEdited) {
			this.props.project.saveProject();
		}
	};

	public render() {
		return (
			<>
				<div className="form-section">
					<div className="header-text">
						<h3 className="section-title">Add elements</h3>
					</div>
					<EditElementsGrid project={this.props.project} projectCanBeEdited={this.props.projectCanBeEdited} />
				</div>
			</>
		);
	}

	public static validateStep = (project: ProjectEntity): boolean => {
		// This step is complete if there's at least one column type with a positive estimated column type
		for (let i = 0; i < project.parsedElementStructure.columnTypes.length; i++) {
			const columnType = project.parsedElementStructure.columnTypes[i];
			if (columnType.estimatedColumnCount > 0) {
				return true;
			}
		}
		return false;
	};

	public static afterStep = async (project: ProjectEntity) => {
		// We want to generate the columns and cells to fill out the element structure
		// Things are a bit complicated here, because a user could be revisiting this page after already having generated cells
		// In that case, we warn the user if their actions will be removing any existing columns

		// Do a check to see if there are any column types which are being shrunk
		let columnTypesShrunk = false;
		project.parsedElementStructure.columnTypes.forEach(columnType => {
			if (columnType.estimatedColumnCount < columnType.columns.length) {
				columnTypesShrunk = true;
			}
		});

		if (columnTypesShrunk) {
			await confirmModal('Please confirm', 'Removing this element type would also remove any elements within it. Are you sure you want to continue?')
				.then(() => {
					WizardElementsStep.generateColumnsAndCells(project);
				});
		} else {
			WizardElementsStep.generateColumnsAndCells(project);
		}
	};

	@action public static generateColumnsAndCells = (project: ProjectEntity) => {
		// Loop through each column type, and add or remove columns as needed
		project.parsedElementStructure.columnTypes.forEach(columnType => {
			let columnsCount = columnType.columns.length;
			if (columnType.estimatedColumnCount < columnsCount) {
				// Our desired column count is smaller than the current column count, so we need to remove columns
				columnType.columns.length = columnType.estimatedColumnCount;
			} else
			if (columnType.estimatedColumnCount > columnsCount) {
				// Our desired column count is larger than the current column count, so we need to add columns
				let columnNameIndex = columnsCount;
				while (columnsCount < columnType.estimatedColumnCount) {
					// Get the next column name
					let newColumnName = (columnNameIndex + 1).toString().padStart(3, '0');
					while (ElementStructureUtils.columnTypeContainsColumnName(columnType, newColumnName)) {
						columnNameIndex++;
						newColumnName = (columnNameIndex + 1).toString().padStart(3, '0');
					}

					ElementStructureUtils.addColumnToElementStructure(
						project.parsedElementStructure,
						columnType.id,
						{
							name: newColumnName,
						},
						columnsCount,
					);

					// We've added a new column, so we increment the count
					columnsCount++;
					columnNameIndex++;
				}
			}
		});

		// Once we've finished generating cells, we calculate their loads
		ElementStructureUtils.regenerateColumnLoads(project.parsedElementStructure);
	};
}
