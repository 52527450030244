import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { FormEvent } from 'react';
import axios, { AxiosError } from 'axios';
import alert from 'Util/ToastifyUtils';
import { Combobox } from '../Combobox/Combobox';
import { observable, runInAction } from 'mobx';
import { PriceVersionEntity, ProjectEntity } from '../../../Models/Entities';
import buildPriceVersionList from '../../../Util/PriceVersionUtils';
import { gql } from '@apollo/client';
import { store } from '../../../Models/Store';

// This is a subset of the ASP.NET problem data type
type ErrorResponse = {
	detail?: string;
}

@observer
export default class UploadPartsCsvForm extends React.Component<RouteComponentProps> {
	input = React.createRef<HTMLInputElement>();

	@observable
	private priceVersions: {[key: string]: PriceVersionEntity} = {};

	@observable
	private selectedPriceVersion: {priceVersionId: string} = { priceVersionId: '' };

	componentDidMount() {
		this.fetchPriceVersions();
	}

	private fetchPriceVersions = () => {
		const query = gql`query fetchPriceVersions {
			priceVersionEntitys {
				${PriceVersionEntity.getAttributes()}
			}
		}`;

		if (store.apolloClient) {
			store.apolloClient.query({ query, fetchPolicy: 'network-only' }).then(({ data }) => data.priceVersionEntitys.forEach((details: Partial<ProjectEntity>) => {
				const priceVersion = new PriceVersionEntity(details);
				runInAction(() => {
					this.priceVersions[priceVersion.id] = priceVersion;
					this.selectedPriceVersion.priceVersionId = priceVersion.id;
				});
			}));
		}
	}

	public render() {
		const downloadCsv = `/api/entity/PartEntity/export-csv/${this.selectedPriceVersion.priceVersionId}`;
		return (
			<>
				<div className="upload-parts-container">
					<form className="form" onSubmit={this.onSubmit}>
						<h1>Upload Part List</h1>
						<p>Upload a csv file containing a list of part codes and their costs, and the prices for those parts will be automatically updated.</p>
						<input
							type="file"
							id="csv-parts-input"
							ref={this.input}
							required
						/>
						<button type="submit">Submit</button>
					</form>

					<h1>Download Part List</h1>
					<Combobox
						label=""
						isClearable={false}
						searchable={false}
						isDisabled={false}
						model={this.selectedPriceVersion}
						modelProperty="priceVersionId"
						options={buildPriceVersionList(this.priceVersions)}
					/>
					<a className="icon-download icon-left" href={downloadCsv} download>Get Parts List</a>
				</div>
			</>

		);
	}

	protected onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		const files = this.input.current?.files;

		if (!files) {
			alert('Please select a file to upload', 'error');
		} else {
			const formData = new FormData();
			formData.append('csv', files[0]);
			axios.post('/api/entity/PartEntity/upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
				.then(() => {
					alert('CSV file successfully uploaded', 'success');
				})
				.catch((error: AxiosError<ErrorResponse>) => {
					if (error.response?.data.detail) {
						alert(error.response.data.detail, 'error');
					} else {
						alert('Error uploading csv file. Please reload page and try again.', 'error');
					}
				});
		}
	};
}
