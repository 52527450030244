import React from 'react';
import { contextMenu } from 'react-contexify';
import { ContextMenu } from 'Views/Components/ContextMenu/ContextMenu';

const menuId = 'copy-paste-cell-context-menu';

interface CellContextMenuProps {
    onCopy: () => any;
    onPaste: () => any;
    disabled: boolean;
}

export const showCellContextMenu = (e: React.MouseEvent) => {
	contextMenu.show({ id: menuId, event: e });
};

export const CellContextMenu = (props: CellContextMenuProps) => {
	const {
		onCopy, disabled, onPaste,
	} = props;
	return (
		<ContextMenu
			menuId={menuId}
			actions={[
				{
					label: 'Copy',
					onClick: onCopy,
					disabled: disabled,
				},
				{
					label: 'Paste',
					onClick: onPaste,
					disabled: disabled,
				},
			]}
		/>
	);
};
