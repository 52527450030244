import * as React from 'react';
import { observer } from 'mobx-react';
import type { EditCell } from 'Util/SelectionUtils';
import { RadioButtonGroup } from 'Views/Components/RadioButton/RadioButtonGroup';

export interface ISocketExtensionOptionsProps {
	editCell: EditCell;
    readonly?: boolean;
    saveCellContent: () => void;
}

const SocketExtensionOptions = ({
	editCell, readonly, saveCellContent,
}: ISocketExtensionOptionsProps) => {
	return (
		<div className="socket-extension-options">
			{editCell.model.slabThicknessAtBase && editCell.model.slabThicknessAtBase <= 515
				? (
					<RadioButtonGroup
						name="slab depths under 515mm"
						model={editCell.model}
						modelProperty="extensionUnderDepth"
						options={[
							{ value: 'variable-stud-length', display: 'Variable Stud Length' },
							{ value: 'blockouts', display: 'Use APTUS blockout detail' },
						]}
						optionsTooltips={[
							'Longer threaded studs',
							'Bars are extended to protrude further with 180mm socket spacing',
						]}
						onAfterChange={saveCellContent}
						isDisabled={readonly}
					/>
				)
				: (
					<RadioButtonGroup
						name="slab depths over 515mm"
						model={editCell.model}
						modelProperty="extensionOverDepth"
						options={[
							{ value: 'blockouts', display: 'Use APTUS blockout detail' },
							{ value: 'sx-bars', display: 'SX Bars' },
							{ value: 'socket-extensions', display: 'Socket Extensions' },
						]}
						optionsTooltips={[
							'Bars are extended to protrude further with 180mm socket spacing',
							'SX Bars with 180mm socket spacing',
							'330m Socket Extension with threaded stud',
						]}
						onAfterChange={saveCellContent}
						isDisabled={readonly}
					/>
				)}
		</div>
	);
};

export default observer(SocketExtensionOptions);
