/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import {
	Button,
	Display,
	Colors,
	Sizes,
} from '../Components/Button/Button';
import { action, observable, runInAction } from 'mobx';
import { store } from 'Models/Store';
import axios from 'axios';
import * as queryString from 'querystring';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import If from 'Views/Components/If/If';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import _ from 'lodash';
import { Model } from 'Models/Model';
import { isRequired } from 'Util/EntityUtils';
import { getAttributeComponent } from 'Views/Components/CRUD/Attributes/AttributeFactory';
import alertToast from '../../Util/ToastifyUtils';
import { SERVER_URL } from 'Constants';
import { getErrorMessages } from 'Util/GraphQLUtils';
import Spinner from 'Views/Components/Spinner/Spinner';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { IUserEntity, UserFields } from 'Models/UserEntity';
// % protected region % [Add any extra imports or interfaces here] on begin
import LoginWrap from 'Views/Components/LoginWrap/LoginWrap';
import * as Models from 'Models/Entities';
// % protected region % [Add any extra imports or interfaces here] end

interface IRegistrationState {
	errors: {
		selectedRegisterType?: string,
		[attr: string]: string | undefined,
	};
	selectedRegisterType: string,
	modelToRegister: Model & IUserEntity | null,
	emailSending: boolean,
	emailSent: boolean,
	// % protected region % [Add extra registration state properties here] off begin
	// % protected region % [Add extra registration state properties here] end
}

const getDefaultRegistrationState = (): IRegistrationState => ({
	selectedRegisterType: '',
	modelToRegister: null,
	errors: {},
	emailSending: false,
	emailSent: false,
	// % protected region % [Instantiate extra registration state properties here] off begin
	// % protected region % [Instantiate extra registration state properties here] end
});

// % protected region % [Customise class signature and class properties] off begin
@observer
export default class RegistrationPage extends React.Component<RouteComponentProps> {
	// % protected region % [Customise class signature and class properties] end
	@observable
	private registrationState: IRegistrationState = getDefaultRegistrationState();

	@observable
	private displayMode: 'select-type' | 'register' = 'register';

	private registerableEntities: {value: string, display: string}[] = [
	];

	// % protected region % [Add extra constructor logic here] on begin
	constructor(props: RouteComponentProps, context: any) {
		super(props, context);
		// @ts-ignore
		this.registerableEntities = [{ display: 'UserEntity', value: 'UserEntity' }];

		if (this.registerableEntities.length > 1) {
			this.displayMode = 'select-type';
		} else {
			this.displayMode = 'register';
			if (this.registerableEntities.length === 1) {
				this.registrationState.selectedRegisterType = this.registerableEntities[0].display;
				this.registrationState.modelToRegister = this.userEntityFactory(this.registerableEntities[0].value);
			}
		}

		if (this.token) {
			axios.get(`/api/entity/InvitedUserEntity/organisation-data/${this.token}`)
				.then(result => {
					console.error(result);
					runInAction(() => {
						this.organisationId = result.data.id;
						this.organisationName = result.data.name;
					});
				});
		}
	}

	// % protected region % [Add extra constructor logic here] end

	public render() {
		let contents = null;

		if (store.loggedIn) {
			// % protected region % [Override redirect here] off begin
			return <Redirect to="/" />;
			// % protected region % [Override redirect here] end
		}

		// % protected region % [Override contents here] on begin
		const entityAttrs = this.getRegisterEntityAttributes();

		const registerNodeDefault = (
			<form>
				<p>To begin using our design tool, please
					<a href="https://aptus.systems/partner.html"> become an Aptus partner
					</a>. We will get back to you with an invite link.
				</p>
				<p>If you are employed by an Aptus partner, please speak to your organisational admin to be granted
					access.
				</p>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="register-buttons">
					<Button
						className="cancel-register"
						colors={Colors.Secondary}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel_register' }}
						onClick={this.onCancelRegisterClicked}
					>Login
					</Button>
				</ButtonGroup>
			</form>
		);

		const registerNodeInvitedUser = (
			<form className="register" onSubmit={this.onSubmitRegisterClicked}>
				<div className="organisation-header">
					<p className="invited-text"> You&apos;ve been invited to join:</p>
					<div className="organisation-data">
						<div className="organisation-image">
							{this.organisationId
								? <img src={`/api/entity/OrganisationEntity/logo/${this.organisationId}`} alt="organisation logo" />
								: '?'}
						</div>
						<h2 className="organisation-name">{this.organisationName}</h2>
					</div>
				</div>
				<div className="entity-attrs">
					{entityAttrs}

					{/* The inbuilt Checkbox doesn't allow links in label */}
					<div
						className="read-tos input-group input-group__checkbox input-group-block"
						aria-label="I have read and I accept the Terms and Conditions for use of the design tool"
					>
						<input type="checkbox" id="agreed-tos-checkbox" />
						<label htmlFor="agreed-tos-checkbox">
							I have read and I accept the
							<a href="/termsofuse" target="_blank">Terms and
								Conditions
							</a> for use of the design tool
						</label>
					</div>

					{/* The inbuilt Checkbox doesn't allow links in label */}
					<div
						className="read-tos input-group input-group__checkbox input-group-block"
						aria-label="I have read and I accept the Privacy Policy"
					>
						<input type="checkbox" id="agreed-privacy-policy-checkbox" />
						<label htmlFor="agreed-privacy-policy-checkbox">
							I have read and I accept th
							<a
								href="https://aptus.systems/privacy-policy.html"
								target="_blank"
								rel="noreferrer"
							>Privacy Policy
							</a>
						</label>
					</div>
				</div>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="register-buttons">
					<Button
						type="submit"
						className="submit-register"
						display={Display.Solid}
						colors={Colors.Primary}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'submit_register' }}
					>Register
					</Button>
					<Button
						className="cancel-register"
						colors={Colors.Secondary}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel_register' }}
						onClick={this.onCancelRegisterClicked}
					>Cancel
					</Button>
				</ButtonGroup>
			</form>
		);

		const emailSentMessageNode = (
			<div className="registration registration-success">
				<h2>Registration successful</h2>
				<p>Registration for email
					<span className="bold">
						{this.registrationState.modelToRegister ? this.registrationState.modelToRegister.email : 'the user'}
					</span> is
					successful.
				</p>
				<p> We sent you a confirmation email. You have to confirm your email address before you can login</p>
				<Button
					onClick={this.onLoginClick}
					className="login-link"
					display={Display.Solid}
					colors={Colors.Primary}
					sizes={Sizes.Medium}
				>Login
				</Button>
			</div>
		);

		contents = (
			<LoginWrap>
				{this.registrationState.emailSending && <Spinner />}
				<If condition={!this.registrationState.emailSent}>
					{this.token
						? registerNodeInvitedUser
						: registerNodeDefault}
				</If>
				<If condition={this.registrationState.emailSent}>
					{emailSentMessageNode}
				</If>
			</LoginWrap>
		);
		// % protected region % [Override contents here] end

		return contents;
	}

	private getRegisterEntityAttributes = () => {
		// % protected region % [Override getRegisterEntityAttributes here] on begin
		// Used to show a custom list of fields, and to set the order they display in
		const registrationFieldList: string[] = [
			'email',
			'password',
			'_confirmPassword',
			'firstName',
			'lastName',
			'phone',
		];

		if (!this.registrationState.modelToRegister) {
			return null;
		}
		const attributeOptions = this.registrationState.modelToRegister.getAttributeCRUDOptions();
		const model = this.registrationState.modelToRegister;
		return attributeOptions
			.filter(attributeOption => registrationFieldList.indexOf(attributeOption.attributeName) >= 0)
			.sort((a, b) => registrationFieldList.indexOf(a.attributeName) - registrationFieldList.indexOf(b.attributeName))
			.map(attributeOption => {
				return (
					<React.Fragment key={attributeOption.attributeName}>
						{getAttributeComponent(
							attributeOption,
							model,
							model.getErrorsForAttribute(attributeOption.attributeName),
							EntityFormMode.CREATE,
							isRequired(model, attributeOption.attributeName),
							undefined,
							() => model ? model.validate.bind(model)() : undefined,
						)}
					</React.Fragment>
				);
			});

		// % protected region % [Override getRegisterEntityAttributes here] end
	};

	@action
	private onSubmitRegisterClicked = async (event: React.FormEvent<HTMLFormElement>) => {
		// % protected region % [Override onSubmitRegisterClicked here] on begin
		event.preventDefault();

		this.registrationState.errors = {};
		if (this.registrationState.modelToRegister) {
			await this.registrationState.modelToRegister.validate();
			if (this.registrationState.modelToRegister.hasValidationError) {
				return;
			}

			// User can only register if they've agreed to terms of use and privacy policy
			const tosCheckbox = (document.getElementById('agreed-tos-checkbox') as HTMLInputElement);
			if (!tosCheckbox || !tosCheckbox.checked) {
				alert('Please agree to our Terms of Use');
				return;
			}
			const privacyPolicyCheckbox = (document.getElementById('agreed-privacy-policy-checkbox') as HTMLInputElement);
			if (!privacyPolicyCheckbox || !privacyPolicyCheckbox.checked) {
				alert('Please agree to our Privacy Policy');
				return;
			}

			this.submitRegister();
		}
		// % protected region % [Override onSubmitRegisterClicked here] end
	};

	@action
	private submitRegister = () => {
		// % protected region % [Override submitRegister here] on begin
		if (this.registrationState.modelToRegister) {
			// Set up the user token
			const { token } = queryString.parse(this.props.location.search.substring(1));
			if (!token || typeof (token) !== 'string') {
				alert('Invalid registration link. Please check your invitation email and try again.');
				return;
			}

			const userType = this.registrationState.selectedRegisterType;
			const data = this.registrationState.modelToRegister.toJSON({ password: {} });

			// Add our token
			const dataWithToken = {
				...data,
				token: token,
			};

			this.registrationState.emailSending = true;

			axios.post(
				`${SERVER_URL}/api/register/${_.kebabCase(userType)}`, dataWithToken,
			)
				.then(({ data }) => {
					this.onRegistrationEmailSentSuccess();
				})
				.catch(response => {
					runInAction(() => {
						this.registrationState.emailSending = false;
					});
					const errorMessages = getErrorMessages(response).map((error: any) => (<p>{error.message}</p>));
					alert(
						<div>
							<h6>Registration is not successful</h6>
							{errorMessages}
						</div>,
					);
				});
		}
		// % protected region % [Override submitRegister here] end
	};

	@action
	private onCancelRegisterClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		// % protected region % [Override onCancelRegisterClicked here] off begin
		event.preventDefault();
		this.resetRegistration();
		const { redirect } = queryString.parse(this.props.location.search.substring(1));
		store.routerHistory.push(`/login?${!!redirect ? `redirect=${redirect}` : ''}`);
		// % protected region % [Override onCancelRegisterClicked here] end
	};

	@action
	private onTypeConfirmed = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		// % protected region % [Override onTypeConfirmed here] off begin
		event.preventDefault();

		this.registrationState.errors = {};
		if (this.registerableEntities.length > 1 && !this.registrationState.selectedRegisterType) {
			this.registrationState.errors.selectedRegisterType = 'You need to choose the registration type';
		}
		if (Object.keys(this.registrationState.errors).length === 0) {
			let entityToRegister = null;
			if (this.registerableEntities.length > 1 && !!this.registrationState.selectedRegisterType) {
				entityToRegister = this.registrationState.selectedRegisterType;
			} else if (this.registerableEntities.length === 1) {
				entityToRegister = this.registerableEntities[0].value;
			}
			this.displayMode = 'register';
			this.registrationState.modelToRegister = this.userEntityFactory(entityToRegister);
		}
		// % protected region % [Override onTypeConfirmed here] end
	};

	private userEntityFactory = (entityToRegister: string | null): Model & IUserEntity | null => {
		// % protected region % [Override userEntityFactory here] on begin
		switch (entityToRegister) {
			case 'UserEntity':
				return new Models.UserEntity();
			default:
				return null;
		}
		// % protected region % [Override userEntityFactory here] end
	};

	@action
	private onChangeUserType = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		// % protected region % [Override onChangeUserType here] off begin
		e.preventDefault();
		this.resetRegistration();
		this.displayMode = 'select-type';
		// % protected region % [Override onChangeUserType here] end
	};

	private resetRegistration = () => {
		// % protected region % [Override resetRegistration here] off begin
		this.registrationState = getDefaultRegistrationState();
		// % protected region % [Override resetRegistration here] end
	};

	@action
	private onRegistrationEmailSentSuccess = () => {
		// % protected region % [Override onRegistrationEmailSentSuccess here] off begin
		this.registrationState.emailSending = false;
		this.registrationState.emailSent = true;
		// % protected region % [Override onRegistrationEmailSentSuccess here] end
	};

	private onLoginClick = () => {
		// % protected region % [Override onLoginClick here] off begin
		const { redirect } = queryString.parse(this.props.location.search.substring(1));
		store.routerHistory.push(`/login?redirect=${redirect}`);
		// % protected region % [Override onLoginClick here] end
	};

	// % protected region % [Add class methods here] on begin
	@observable organisationName = '';
	@observable organisationId = '';
	@observable token = queryString.parse(this.props.location.search.substring(1)).token;
	// % protected region % [Add class methods here] end
}

// % protected region % [Add extra features here] off begin
// % protected region % [Add extra features here] end
