/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

export type organisationType =
	// % protected region % [Override organisationType keys here] off begin
	'PRECASTER' |
	'ENGINEER' |
	'BUILDER' |
	'INSTALLER';
	// % protected region % [Override organisationType keys here] end

export const organisationTypeOptions: { [key in organisationType]: string } = {
	// % protected region % [Override organisationType display fields here] off begin
	PRECASTER: 'Precaster',
	ENGINEER: 'Engineer',
	BUILDER: 'Builder',
	INSTALLER: 'Installer',
	// % protected region % [Override organisationType display fields here] end
};

export type role =
	// % protected region % [Override role keys here] off begin
	'MEMBER' |
	'ADMIN';
	// % protected region % [Override role keys here] end

export const roleOptions: { [key in role]: string } = {
	// % protected region % [Override role display fields here] off begin
	MEMBER: 'Member',
	ADMIN: 'Admin',
	// % protected region % [Override role display fields here] end
};

// % protected region % [Add any extra enums here] off begin
// % protected region % [Add any extra enums here] end
