// This Information was taken from CouplerData.csv
export interface couplerDataInterface {
	barSize: barSizes,
	data: {
		I: number;
		J: number;
		BarDia: number;
		z: number;
		A: number; // Area of Aptus Bar
		Dia: number;
		TieSize: string;
	}
}

export type barSizes = 'N10' | 'N12' | 'N16' | 'N20' | 'N24' | 'N28' | 'N32' | 'N36' | 'N40';

// please preserve formatting, it is nice
export const couplerData: couplerDataInterface[] = [
	{
		barSize: 'N10',
		data: {
			I: 1, J: 1, BarDia: 10, z: 1, A: 78, Dia: 1, TieSize: 'N10',
		},
	},
	{
		barSize: 'N12',
		data: {
			I: 1, J: 1, BarDia: 12, z: 1, A: 110, Dia: 1, TieSize: 'N10',
		},
	},
	{
		barSize: 'N16',
		data: {
			I: 1, J: 1, BarDia: 16, z: 1, A: 200, Dia: 1, TieSize: 'N10',
		},
	},
	{
		barSize: 'N20',
		data: {
			I: 7850, J: 15708, BarDia: 20, z: 785, A: 314, Dia: 60, TieSize: 'N10',
		},
	},
	{
		barSize: 'N24',
		data: {
			I: 16278, J: 32572, BarDia: 24, z: 1356, A: 452, Dia: 65, TieSize: 'N10',
		},
	},
	{
		barSize: 'N28',
		data: {
			I: 30157, J: 60344, BarDia: 28, z: 2154, A: 616, Dia: 70, TieSize: 'N10',
		},
	},
	{
		barSize: 'N32',
		data: {
			I: 51446, J: 102944, BarDia: 32, z: 3215, A: 804, Dia: 75, TieSize: 'N12',
		},
	},
	{
		barSize: 'N36',
		data: {
			I: 82406, J: 164896, BarDia: 36, z: 4578, A: 1020, Dia: 80, TieSize: 'N12',
		},
	},
	{
		barSize: 'N40',
		data: {
			I: 125600, J: 251327, BarDia: 40, z: 6280, A: 1260, Dia: 85, TieSize: 'N16',
		},
	},
];
