import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { action, observable, runInAction } from 'mobx';
import ProjectRevisionEntity from 'Models/Entities/ProjectRevisionEntity';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { store } from 'Models/Store';
import alert from 'Util/ToastifyUtils';
import ElementGrid from 'Views/Components/ElementGrid/ElementGrid';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import ProjectEntity from 'Models/Entities/ProjectEntity';
import Modal from 'Views/Components/Modal/Modal';
import { TextField } from 'Views/Components/TextBox/TextBox';
import axios from 'axios';
import SummaryView from 'Views/Components/ElementGrid/SummaryView';
import ShuttersTable from 'Views/Components/PricingComponents/ShuttersTable';
import ScheduleOfRates from 'Views/Components/PricingComponents/ScheduleOfRates';
import { SERVER_URL } from 'Constants';
import CostSummary from 'Views/Components/PricingComponents/CostSummary';

enum ProjectTableState {
	Elements = 'elements',
	DetailedSchedule = 'schedule',
	TemporaryWorks = 'tempworks',
	Shutters = 'shutters',
	CostSummary = 'costsummary'
}

export interface ProjectRevisionTileProps extends RouteComponentProps {
	revisionId: string;
}

@observer
export default class ProjectRevisionTile extends React.Component<ProjectRevisionTileProps> {
	public projectRevision: ProjectRevisionEntity;

	@observable
	private requestState: 'loading' | 'error' | 'done' = 'loading';

	@observable
	private modalState = {
		visible: false,
		duplicateName: '',
		jobNumber: '',
		nameError: '',
		jobError: '',
	};

	@observable public revisionOwnerName = '';

	public componentDidMount(): void {
		ProjectRevisionEntity.fetchWithProjectAndShares(this.props.revisionId)
			.then(d => {
				this.changeRequestState('done', d);
			})
			.catch(e => {
				this.changeRequestState('error');
			});
	}

	@action
	private changeRequestState = (state: 'loading' | 'error' | 'done', projectRevision?: ProjectRevisionEntity) => {
		if (state === 'error') {
			this.returnToDashboard();
			return;
		}

		if (projectRevision) {
			this.projectRevision = projectRevision;

			// Get the name of the person who created this project, so we can pass it down to the summary view
			axios.get(`${SERVER_URL}/api/entity/ProjectRevisionEntity/owner/${projectRevision.id}`)
				.then(({ data }) => runInAction(() => {
					this.revisionOwnerName = data;
				}));

			// Get the price version entity attached to the revision, so we can pass it down to the summary view
			axios.get(`${SERVER_URL}/api/entity/PriceVersionEntity/${projectRevision.priceVersionId}`)
				.then(({ data }) => runInAction(() => {
					this.projectRevision.priceVersion = data;
				}));
		}
		this.requestState = state;
	};

	private returnToDashboard = () => {
		store.routerHistory.push('/dashboard');
		alert('Unable to find project revision. Please try again, or contact us for help.', 'error');
	};

	@observable
	protected tableState: ProjectTableState = ProjectTableState.Elements;

	public render() {
		return (
			<>
				<div className="project-page-content">
					<div className="project-sidebar">
						<SummaryView
							state={this.tableState}
							revision={this.projectRevision}
							lastChangedName={this.revisionOwnerName}
						/>

					</div>
					<div id="main-content" className="main-content">
						<div className="project-page-buttons">
							<Button onClick={this.activateModal} className="duplicate-btn" display={Display.Solid} colors={Colors.Primary}>Save Revision as New Project</Button>
							<Button onClick={this.revertToThisRevision} className="revert-btn" display={Display.Solid} colors={Colors.Primary}>Revert to this Revision</Button>
							<Button onClick={this.closeProject} className="save-close-btn" display={Display.Solid} colors={Colors.Primary}>Close</Button>
						</div>
						{/* Doing tabs manually, because the provided tabs were sluggish */}
						<div className="outer-tab-wrap">
							<div className="tab-wrap">
								<button onClick={() => this.changeTableState(ProjectTableState.Elements)} className={`tab ${this.tableState === ProjectTableState.Elements ? 'active' : ''}`}>Elements Table</button>
								<button onClick={() => this.changeTableState(ProjectTableState.TemporaryWorks)} className={`tab ${this.tableState === ProjectTableState.TemporaryWorks ? 'active' : ''}`}>Temporary Works</button>
								<button onClick={() => this.changeTableState(ProjectTableState.DetailedSchedule)} className={`tab ${this.tableState === ProjectTableState.DetailedSchedule ? 'active' : ''}`}>Detailed Schedule</button>
								<button onClick={() => this.changeTableState(ProjectTableState.Shutters)} className={`tab ${this.tableState === ProjectTableState.Shutters ? 'active' : ''}`}>Shutters</button>
								<button onClick={() => this.changeTableState(ProjectTableState.CostSummary)} className={`tab ${this.tableState === ProjectTableState.CostSummary ? 'active' : ''}`}>Cost Summary</button>
							</div>
						</div>
						<div className="tab-content">
							{this.tableState === ProjectTableState.Elements ? this.renderElementsTable() : null}
							{this.tableState === ProjectTableState.TemporaryWorks ? this.renderTemporaryWorksTable() : null}
							{this.tableState === ProjectTableState.DetailedSchedule ? this.renderScheduleTable() : null}
							{this.tableState === ProjectTableState.Shutters ? this.renderShuttersTable() : null}
							{this.tableState === ProjectTableState.CostSummary ? this.renderCostSummaryTable() : null}
						</div>
					</div>
				</div>
				<Modal isOpen={this.modalState.visible} label="Project Duplication Modal" onRequestClose={this.closeModal}>
					<h4>Duplicate Project</h4>
					<h5>Please choose a name for your new revision.</h5>
					<TextField
						model={this.modalState}
						modelProperty="duplicateName"
						label="Duplicate project name"
						tooltip="This revision will be duplicated as a new project"
						errors={this.modalState.nameError ? this.modalState.nameError : undefined}
					/>
					<TextField
						model={this.modalState}
						modelProperty="jobNumber"
						label="Job number"
						tooltip="This revision will be duplicated as a new project"
						errors={this.modalState.jobError ? this.modalState.jobError : undefined}
					/>
					<div key="actions" className="modal__actions">
						<Button onClick={this.closeModal} display={Display.Solid} colors={Colors.Secondary}>Cancel</Button>
						<Button onClick={this.duplicateRevisionModal} display={Display.Solid} colors={Colors.Primary}>Save</Button>
					</div>
				</Modal>
			</>
		);
	}

	@action private activateModal = () => {
		this.modalState.visible = true;
	};

	@action private duplicateRevisionModal = async () => {
		if (!this.modalState.duplicateName || !this.modalState.jobNumber) {
			if (!this.modalState.duplicateName) {
				this.modalState.nameError = 'Please choose a name for the duplicate of this project.';
			}
			if (!this.modalState.jobNumber) {
				this.modalState.jobError = 'Please choose a job number for the duplicate of this project.';
			}
			return;
		}
		axios.post('/api/entity/ProjectRevisionEntity/duplicaterevision',
			{ Name: this.modalState.duplicateName, Id: this.projectRevision.id, JobNumber: this.modalState.jobNumber });
		this.closeModal();
		alert('Project Duplicate Successfully Saved', 'success');
	};

	@action private closeModal = () => {
		this.modalState = {
			visible: false,
			duplicateName: '',
			jobNumber: '',
			nameError: '',
			jobError: '',
		};
	};

	private closeProject = () => {
		// Project is already saved whenever the user makes a change, we just need to leave
		store.routerHistory.push('/dashboard');
	};

	@action private changeTableState = (newTableState: ProjectTableState) => {
		this.tableState = newTableState;
	};

	private renderElementsTable = () => {
		// Show element grid if project is loaded
		if (this.requestState === 'loading') {
			return <></>;
		} if (!this.projectRevision) {
			this.returnToDashboard();
			return <></>;
		}
		return (
			<ElementGrid
				sideBarWidth={300}
				setSideBarWidth={() => undefined}
				elementStructure={this.projectRevision.parsedElementStructure}
				readonly
			/>
		);
	};

	private renderScheduleTable = () => {
		// Show schedule of rates if project is loaded
		if (this.requestState === 'loading') {
			return <></>;
		} if (!this.projectRevision) {
			this.returnToDashboard();
			return <></>;
		}
		return (
			<ScheduleOfRates
				elementStructure={this.projectRevision.parsedElementStructure}
				tile="ProjectRevisionTile"
				readonly
			/>
		);
	};

	private renderTemporaryWorksTable = () => {
		// Show Element grid if Temporary Works is loaded
		if (!this.projectRevision) {
			return <></>;
		}
		return (
			<ElementGrid
				sideBarWidth={300}
				setSideBarWidth={() => undefined}
				elementStructure={this.projectRevision.parsedElementStructure}
				temporaryWorks
			/>
		);
	};

	private renderShuttersTable = () => {
		// Show Shutters if project is loaded
		if (!this.projectRevision) {
			return <></>;
		}
		return <ShuttersTable elementStructure={this.projectRevision.parsedElementStructure} />;
	};

	private renderCostSummaryTable = () => {
		// Show Cost Summary if project is loaded
		if (!this.projectRevision) {
			return <></>;
		}
		return (
			<CostSummary
				elementStructure={this.projectRevision.parsedElementStructure}
				priceVersionId={this.projectRevision.priceVersionId}
				readonly
			/>
		);
	};

	@action private revertToThisRevision = () => {
		// We can only save if the revision name is set
		confirmModal('Please confirm', 'Are you sure you want to revert the project to this revision? You will lose any project changes since your last revision.').then(() => {
			// Load the project this revision belongs to
			ProjectEntity.fetch<ProjectEntity>({ args: [[{ path: 'id', comparison: 'equal', value: this.projectRevision.projectId }]] })
				.then(d => {
					if (d.length < 1) {
						alert('Unable to revert the project.', 'error');
						return;
					}

					const parentProject = d[0];
					parentProject.parsedElementStructure = this.projectRevision.parsedElementStructure;
					parentProject.priceVersionId = this.projectRevision.priceVersionId;
					parentProject.save()
						.then(() => {
							// We've finished reverting the project, now we redirect to the project page, where the user can edit
							store.routerHistory.push(`/project/${this.projectRevision.projectId}`);
						});
				})
				.catch(e => {
					alert('Unable to revert the project.', 'error');
				});
		});
	};
}
