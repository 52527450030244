import * as React from 'react';
import type { EditCell } from 'Util/SelectionUtils';
import { action } from 'mobx';
import { Cell, ElementStructure } from 'Util/ElementStructureUtils';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { MinMaxNumberTextField } from 'Views/Components/NumberTextBox/MinMaxNumberTextBox';

interface LoadTransfersInterface {
	saveCellContent: () => void;
	isDisabled: boolean | undefined;
	getCellsBelow: () => Cell[];
	editCell: EditCell;
	elementStructure: ElementStructure;
}

export default class LoadTransfers extends React.Component<LoadTransfersInterface> {
	@action
	private initLoadTransfers() {
		const { editCell } = this.props;
		if (!editCell.model.loadTransfers || (editCell.model.loadTransfers && editCell.model.loadTransfers.length !== 4)) {
			editCell.model.loadTransfers = [];
			editCell.model.loadTransfers.length = 4;
			editCell.model.loadTransfers.fill({ receivingId: undefined, percent: undefined });
		}
	}

	@action
	private loadTransferOptions() {
		const { elementStructure } = this.props;

		const validCellsBelow = this.props.getCellsBelow().filter(v => !v.deleted && !v.merged);

		const options: { display: string | null, value: string | null }[] = [{ display: null, value: null }];

		// Build the option Array for Load Transfers
		validCellsBelow.forEach(v => {
			let column: string | undefined;
			let type: string | undefined;

			for (const columnType of elementStructure.columnTypes) {
				const currentColumn = columnType.columns.find(c => v.columnId === c.id);
				if (currentColumn) {
					column = currentColumn.name;
					type = columnType.code;
					break;
				}
			}

			const level = elementStructure.levels.find(l => l.id === v.levelId)?.code;

			if (column && level) {
				options.push({
					display: `${level}-${type}${column}`, value: v.id,
				});
			}
		});
		return { options, validCellsBelow };
	}

	render() {
		const { editCell, isDisabled } = this.props;
		let inputArray: React.ReactNode[] = [];

		const { options, validCellsBelow } = this.loadTransferOptions();
		this.initLoadTransfers();

		if (editCell.model.loadTransfers) {
			const currentSelections = editCell.model.loadTransfers.map(load => load.receivingId);

			inputArray = editCell.model.loadTransfers.map((t, index) => {
				// Remove cells already with load transfers from the selectable list of cells
				const optionsToRemove = currentSelections
					.filter((selection, selectionIndex) => index !== selectionIndex)
					.map(x => x);

				const filteredOptions = options.filter(option => option.value === null || !optionsToRemove.includes(option.value));

				return (
					<div
						className="load-transfer-wrap"
						key={`LoadTransfer${index + 1}`}
					>
						<Combobox
							placeholder={`Element ${index + 1}`}
							className="load-transfer-name"
							model={t}
							modelProperty="receivingId"
							label={`Load Transfer ${index + 1}`}
							labelVisible={false}
							options={filteredOptions}
							searchable
							onAfterChange={this.props.saveCellContent}
							isDisabled={isDisabled}
						/>
						<MinMaxNumberTextField
							min={0}
							max={100}
							className="load-transfer-percentage"
							model={t}
							modelProperty="percent"
							onChangeAndBlur={this.props.saveCellContent}
							placeholder="0%"
							isDisabled={isDisabled}
						/>
					</div>
				);
			});
		} else {
			inputArray.push(<p className="text--error">Load Transfers table has either not been initialized or could not be found</p>);
		}
		return (
			<>
				<h5>Load Transfers</h5>
				<p>This Cell has {validCellsBelow.length} valid Elements below it </p>
				{inputArray}
			</>
		);
	}
}
