/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import * as React from 'react';
import {
	runInAction, observable, computed, action,
} from 'mobx';
import { observer } from 'mobx-react';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import type { Volume } from 'Util/ElementStructureUtils';
import { ElementStructureUtils } from 'Util/ElementStructureUtils';

import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import type { EditCell } from 'Util/SelectionUtils';
import { SelectionUtils } from 'Util/SelectionUtils';

export interface IAdvancedDimensionsProps {
	editCell: EditCell;
	selectionUtils: SelectionUtils;
    saveCellContent: () => any;
    isDisabled?: boolean;
    getCellHeight: (editCell: EditCell) => number;
}

/**
 * This component is responsible for adding/editing element corbels and voids
 */
@observer
export default class AdvancedDimensionsInputs extends React.Component<IAdvancedDimensionsProps> {
    @observable
    public errors: { [key: string]: { [key: string]: string[] } } = {
        corbel: {},
        elementVoid: {},
    };

    @computed
    public get corbel(): boolean {
		const {
			editCell,
		} = this.props;
    	return !!editCell.model.corbel;
    }

    public set corbel(toggle: boolean) {
		const {
			editCell,
		} = this.props;
    	editCell.model.corbel = toggle
    		? {
    			height: undefined,
    			width: undefined,
    			depth: undefined,
    			useCellHeight: false,
    			useCellWidth: false,
    			useCellDepth: false,
    			reoRate: undefined,
    		} : null;
    }

    @computed
    public get elementVoid(): boolean {
		const {
			editCell,
		} = this.props;
    	return !!editCell.model.elementVoid;
    }

    public set elementVoid(toggle: boolean) {
		const {
			editCell,
		} = this.props;
    	editCell.model.elementVoid = toggle
    		? {
    			height: undefined,
    			width: undefined,
    			depth: undefined,
    			useCellHeight: false,
    			useCellWidth: false,
    			useCellDepth: false,
    		} : null;
    }

    @computed
    public get corbelVolume(): string {
    	const { editCell: { model: { corbel } }, editCell, getCellHeight } = this.props;
    	if (corbel?.width && corbel?.depth && corbel?.height) {
    		return ElementStructureUtils.corbelVolume(corbel, editCell.model.width, editCell.model.depth, getCellHeight(editCell)).toFixed(2);
    	}
    	return '0';
    }

    @computed
    public get voidVolume(): string {
    	const { editCell: { model: { elementVoid } }, editCell, getCellHeight } = this.props;
    	if (elementVoid?.width && elementVoid?.depth && elementVoid?.height) {
    		return ElementStructureUtils.voidVolume(elementVoid, editCell.model.width, editCell.model.depth, getCellHeight(editCell)).toFixed(2);
    	}
    	return '0';
    }

    @action
    public overrideVolumeWithCell(checked: boolean, volume: Volume, property: string) {
		const {
			editCell,
		} = this.props;
    	if (checked) {
    		const { model } = editCell;
    		volume[property] = model[property];
    	}
    }

    @action
    public validateAndSave = (volumeType: 'corbel' | 'elementVoid', property: 'width' | 'depth' | 'height') => {
    	if (property) {
    		this.errors[volumeType][property] = [];
    		const addError = (msg: string) => this.errors[volumeType][property].push(msg);

    		// add errors for any field that is invalid
    		const {
    			editCell: {
    				model: {
    			width, depth, corbel, elementVoid,
    		},
    			}, getCellHeight, selectionUtils,
    		} = this.props;

    		const height = getCellHeight(this.props.editCell);

    		// set the max allowed values to the edit cell dimensions. if a single cell is selected, this is just that cell's dimensions
    		const maxAllowedValues = { width, depth, height };

    		// We get the minimum values from each dimension of the selected cells. This value is the max allowed value,
    		// since the inputted corbel or void dimensions during multi select CANNOT be greater than any of the selected
    		// cell dimensions
    		if (selectionUtils.selectedCells.length > 1) {
    			const minValues = selectionUtils.minDimensionsForSelectedCells();
    			maxAllowedValues.width = minValues.width;
    			maxAllowedValues.depth = minValues.depth;
    			maxAllowedValues.height = minValues.height;
    		}

    		// map the dimension to the property on corbel/void responsible for toggling that corbel/void dimension on and off
    		const mapDimensionToVolumeToggleKey = {
    			width: 'useCellWidth',
    			depth: 'useCellDepth',
    			height: 'useCellHeight',
    		};
    		const cellToggleKey = mapDimensionToVolumeToggleKey[property];

    		// if validating a corbel and corbel exists
    		if (volumeType === 'corbel' && corbel && corbel[property] !== undefined) {
    			// clear errors
    			this.errors.corbel[property] = [];

    			if (corbel[property] === 0) {
    				addError(`The ${property} of a corbel must be greater than 0mm`);
    			}

    			if (corbel[property]! > maxAllowedValues[property]! && !corbel[cellToggleKey]) {
    				addError(`The ${property} of a corbel cannot be greater than the ${property} of the element`);
    			}
    		}

    		// if validating a void and void exists
    		if (volumeType === 'elementVoid' && elementVoid && elementVoid[property] !== undefined) {
    			// clear errors
    			this.errors.elementVoid[property] = [];

    			if (elementVoid[property] === 0) {
    				addError(`The ${property} of a void must be greater than 0mm`);
    			}

    			if (elementVoid[property]! > maxAllowedValues[property]! && !elementVoid[cellToggleKey]) {
    				addError(`The ${property} of a void cannot be greater than the ${property} of the element`);
    			}
    		}
    	}

    	// if there's no errors for any of the fields, save cell
    	if (!(Object.values(this.errors.corbel).find(errs => errs.length > 0)
            || Object.values(this.errors.elementVoid).find(errs => errs.length > 0))) {
    		this.props.saveCellContent();
    	}
    }

    public render() {
    	const {
			editCell: { model: { elementVoid, corbel } }, selectionUtils, saveCellContent, isDisabled,
		} = this.props;

    	const isMultiSelect = selectionUtils.selectedCells.length > 1;

    	return (
	<div className="advanced-dimensions-inputs">
		<Checkbox
			className="corbel-config-checkbox"
			model={this}
			modelProperty="corbel"
			label="Corbel"
			onAfterChecked={() => saveCellContent()}
			isDisabled={isDisabled}
		/>
		{this.corbel
    				? (
	<>
		<div className="dimensions-wrap">
			{this.NumberFieldFactory('corbel', 'width', 'Width (mm)', corbel?.useCellWidth, isMultiSelect, corbel?.useCellWidth)}
			<div className="width-depth-divider" />
			{this.CheckboxFactory('corbel', 'useCellWidth', 'width', 'Use Element Width', corbel?.useCellDepth && corbel?.useCellHeight)}
		</div>
		<div className="dimensions-wrap">
			{this.NumberFieldFactory('corbel', 'depth', 'Depth (mm)', corbel?.useCellDepth, isMultiSelect, corbel?.useCellDepth)}
			<div className="width-depth-divider" />
			{this.CheckboxFactory('corbel', 'useCellDepth', 'depth', 'Use Element Depth', corbel?.useCellWidth && corbel?.useCellHeight)}
		</div>
		<div className="dimensions-wrap">
			{this.NumberFieldFactory('corbel', 'height', 'Height (mm)', corbel?.useCellHeight, isMultiSelect, corbel?.useCellHeight)}
			<div className="width-depth-divider" />
			{this.CheckboxFactory('corbel', 'useCellHeight', 'height', 'Use Element Height', corbel?.useCellWidth && corbel?.useCellDepth)}
		</div>
		<NumberTextField
			className="reo-rate-input"
			model={this.props.editCell.model.corbel}
			modelProperty="reoRate"
			placeholder={`Reo Rate (k/m${String.fromCharCode(179)})`}
			isDisabled={this.props.isDisabled}
			onChangeAndBlur={this.props.saveCellContent}
			onAfterChange={() => runInAction(() => corbel
                                && ElementStructureUtils.cleanInt(corbel, 'reoRate'))}
		/>
		<NumberTextField
			model={this}
			modelProperty="corbelVolume"
			label={`Volume of Corbel (m${String.fromCharCode(179)})`}
			placeholder="Volume"
			onChangeAndBlur={this.props.saveCellContent}
			isDisabled
		/>
	</>
    				)
    				: null }
		<Checkbox
			className="void-config-checkbox"
			model={this}
			modelProperty="elementVoid"
			label="Void"
			onAfterChecked={() => this.props.saveCellContent()}
			isDisabled={this.props.isDisabled}
		/>
		{this.elementVoid
    				? (
	<>
		<div className="dimensions-wrap">
			{this.NumberFieldFactory('elementVoid', 'width', 'Width (mm)', elementVoid?.useCellWidth, isMultiSelect, elementVoid?.useCellWidth)}
			<div className="width-depth-divider" />
			{this.CheckboxFactory('elementVoid', 'useCellWidth', 'width', 'Use Element Width', elementVoid?.useCellDepth || elementVoid?.useCellHeight)}
		</div>
		<div className="dimensions-wrap">
			{this.NumberFieldFactory('elementVoid', 'depth', 'Depth (mm)', elementVoid?.useCellDepth, isMultiSelect, elementVoid?.useCellDepth)}
			<div className="width-depth-divider" />
			{this.CheckboxFactory('elementVoid', 'useCellDepth', 'depth', 'Use Element Depth', elementVoid?.useCellWidth || elementVoid?.useCellHeight)}
		</div>
		<div className="dimensions-wrap">
			{this.NumberFieldFactory('elementVoid', 'height', 'Height (mm)', elementVoid?.useCellHeight, isMultiSelect, elementVoid?.useCellHeight)}
			<div className="width-depth-divider" />
			{this.CheckboxFactory('elementVoid', 'useCellHeight', 'height', 'Use Element Height', elementVoid?.useCellWidth || elementVoid?.useCellDepth)}
		</div>
		<NumberTextField
			model={this}
			modelProperty="voidVolume"
			label={`Volume of Void (m${String.fromCharCode(179)})`}
			placeholder="Volume"
			onChangeAndBlur={this.props.saveCellContent}
			isDisabled
		/>
	</>
    				)
    				: null }
	</div>
    	);
    }

    NumberFieldFactory = (
    	volume: 'corbel' | 'elementVoid',
    	property: 'width' | 'depth' | 'height',
    	placeholder: string,
    	isReadOnly: boolean | undefined,
    	isMultiSelect: boolean,
    	useCellDimensionToggle?: boolean,
    ) => (
	<>
		{/* Swap model to display N/A if using useCell<dimension> and multiselect */}
		<NumberTextField
			model={isMultiSelect && useCellDimensionToggle
    				? { [property]: 'N/A' }
    				: this.props.editCell.model[volume]}
			modelProperty={property}
			placeholder={placeholder}
			isDisabled={this.props.isDisabled}
			onChangeAndBlur={() => {
    				this.validateAndSave(volume, property);
    			}}
			onAfterChange={() => runInAction(() => {
    				if (this.props.editCell.model[volume]) {
    					ElementStructureUtils.cleanInt(this.props.editCell.model[volume]!, property);
    				}
    				this.validateAndSave(volume, property);
    			})}
			isReadOnly={isReadOnly || (isMultiSelect && useCellDimensionToggle)}
			errors={this.errors[volume][property]}
		/>
	</>
    );

    CheckboxFactory = (
    	volume: 'corbel' | 'elementVoid',
    	checkboxProperty: 'useCellWidth' | 'useCellDepth' | 'useCellHeight',
    	overrideProperty: 'width' | 'depth' | 'height',
    	label: string,
    	disabled?: boolean,
    ) => (
	<Checkbox
		model={this.props.editCell.model[volume]}
		modelProperty={checkboxProperty}
		label={label}
		isDisabled={this.props.isDisabled || disabled}
		onAfterChecked={(event, checked) => {
    			this.validateAndSave(volume, overrideProperty);
    			runInAction(() => {
    				if (checked) {
    					const { model } = this.props.editCell;
                        this.props.editCell.model[volume]![overrideProperty] = model[overrideProperty] || 0;
    				}
    			});
    		}}
	/>
    );
}
