import * as React from 'react';
import { observer } from 'mobx-react';
import { ElementStructure, ElementStructureUtils, PricingShutter } from 'Util/ElementStructureUtils';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import { action, computed } from 'mobx';
import classNames from 'classnames';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import { store } from 'Models/Store';
import { Button, Colors, Display } from 'Views/Components/Button/Button';

export interface IShuttersTableProps {
	elementStructure: ElementStructure;
	afterChange?: () => void;
	readonly?: boolean;
}

@observer
export default class ShuttersTable extends React.Component<IShuttersTableProps> {
	@computed
	get shutterUsagePct() {
		return this.props.elementStructure.info.shutterUsage.shutterUsagePct ?? 0;
	}

	set shutterUsagePct(value) {
		this.props.elementStructure.info.shutterUsage.shutterUsagePct = value;
	}

	@computed
	get siteTemplateUsagePct() {
		return this.props.elementStructure.info.shutterUsage.siteTemplateUsagePct ?? 0;
	}

	set siteTemplateUsagePct(value) {
		this.props.elementStructure.info.shutterUsage.siteTemplateUsagePct = value;
	}

	/**
	 * Map from level id to a map of shutter names to shutters.
	 * In simpler terms, for each level id, what count of shutter and site template types do we need.
	 */
	@computed
	private get shuttersNeededPerLevel(): Record<string, Record<string, { shutters: number, siteTemplates: number }>> {
		const { elementStructure } = this.props;

		// Loop through every level, and find the shutters needed on that level
		const shuttersNeededPerLevel: Record<string, Record<string, { shutters: number, siteTemplates: number }>> = {};
		for (const level of elementStructure.levels) {
			// Go through each cell on this level, and find the shutters needed
			const shuttersNeededThisLevel: Record<string, { shutters: number, siteTemplates: number }> = {};
			for (const columnType of elementStructure.columnTypes) {
				for (const column of columnType.columns) {
					// Grab the cell, but ignore merged, deleted, or nonaptus cells
					const cell = elementStructure.cells[column.id][level.id];
					if (!cell.merged && !cell.deleted && cell.aptusDesignConfiguration !== 'nonaptus') {
						// Get the shutter for this cell
						const shutter = ElementStructureUtils.shutterTypeStringFromCell(cell);
						if (shutter) {
							// We have a shutter name. We add it to our level, or increment the existing value
							let shutterInLevel = shuttersNeededThisLevel[shutter];
							const cellBelow = ElementStructureUtils.findCellBelowGivenCell(elementStructure, cell);
							const cellBelowExists = cellBelow && !cellBelow.deleted && cellBelow.aptusDesignConfiguration !== 'nonaptus';

							if (!shutterInLevel) {
								shutterInLevel = { shutters: 0, siteTemplates: 0 };
								shuttersNeededThisLevel[shutter] = shutterInLevel;
							}

							shutterInLevel.shutters += 1;

							// We only want to add a site template if there is no cell below this one and starters are
							// enabled on this cell.
							if (!cellBelowExists && !cell.disableInsituStarters) {
								shutterInLevel.siteTemplates += 1;
							}
						}
					}
				}
			}

			// Add this level's shutters to the overall object
			shuttersNeededPerLevel[level.id] = shuttersNeededThisLevel;
		}

		return shuttersNeededPerLevel;
	}

	/**
	 * A map from shutter name to the number of shutters and site templates needed.
	 */
	@computed
	private get maxShuttersByType(): Record<string, { shutters: number, siteTemplates: number }> {
		const result: Record<string, { shutters: number, siteTemplates: number }> = {};

		for (const levelId of Object.keys(this.shuttersNeededPerLevel)) {
			const shutterCountMap = this.shuttersNeededPerLevel[levelId];

			for (const shutterName of Object.keys(shutterCountMap)) {
				const levelShutterCount = shutterCountMap[shutterName];
				const previousMax = result[shutterName];

				const previousMaxShutters = previousMax?.shutters ?? 0;
				const previousMaxSiteTemplates = previousMax?.siteTemplates ?? 0;

				if (previousMax) {
					// Previous max is an object in the result object so if we override values on it then they will be
					// set on the result object
					previousMax.shutters = Math.max(levelShutterCount.shutters, previousMaxShutters);
					previousMax.siteTemplates = Math.max(levelShutterCount.siteTemplates, previousMaxSiteTemplates);
				} else {
					result[shutterName] = { ...levelShutterCount };
				}
			}
		}

		return result;
	}

	public render() {
		const { elementStructure } = this.props;
		return (
			<table className="shutters-table">
				<thead>
					<tr>
						<th colSpan={4} className="shutter-quantity-header">
							<div>
								<span>Shutters</span>
								<span>
									<div>
										<NumberTextField
											model={this}
											modelProperty="shutterUsagePct"
											label="Usage (%)"
											onChangeAndBlur={this.recomputeShutterUsages}
										/>
										<div className="input-group">
											<Tooltip
												id="shutter-usage-tooltip"
												content="Click for more information"
												onClick={() => {
													store.modal.show(
														'Information',
														<>
															<p>APTUS Shutters are required for casting all APTUS elements. Care should be taken by the user to ensure they do not under or overestimated Shutter quantities. Default values are a guide only, and should be thoroughly reviewed by the user.</p>
															<p>The ‘Usage’ percentage is used to calculate the default value shown in ‘Shutter Pairs Needed’ for each unique element type. The default ‘Usage’ is 20% which can be changed by the user. The default value in “Shutter Pairs Needed” for each unique element type is the ‘Usage’ percentage multiplied by the maximum count that unique element type appears on any given level. The user can then manually adjust the ‘Shutter Pairs Needed’ value higher or lower if they choose.</p>
															<div key="actions" className="modal__actions">
																<Button
																	onClick={() => store.modal.hide()}
																	display={Display.Solid}
																	colors={Colors.Secondary}
																>
																	Close
																</Button>
															</div>
														</>,
													);
												}}
											/>
										</div>
									</div>
								</span>
							</div>
						</th>
						<th colSpan={4} className="shutter-quantity-header">
							<div>
								<span>Site Templates</span>
								<span>
									<div>
										<NumberTextField
											model={this}
											modelProperty="siteTemplateUsagePct"
											label="Usage (%)"
											onChangeAndBlur={this.recomputeShutterUsages}
										/>
										<div className="input-group">
											<Tooltip
												id="shutter-usage-tooltip"
												content="Click for more information"
												onClick={() => {
													store.modal.show(
														'Information',
														<>
															<p>APTUS Site Templates must be used whenever an APTUS element is not starting from grout tubes. They are typically used on-site to accurately cast in APTUS Starters Bars into in-situ elements. The APTUS precast element can then be landed on top of the in-situ element prior to the slab pour.</p>
															<p>The number of site templates needed for each unique element type is governed by how many sets of APTUS Starter Bars need to be cast in at the same time, on any given level.</p>
															<p>The ‘Required for 100% Usage’ value is a count of the maximum number of times a unique element type is present with APTUS Starters Bars at the base of a stack on the same level. ‘Site Templates Needed’ is then this value multiplied by the ‘Usage’ percentage. The default ‘Usage’ percentage is 50% but can be changed by the user. The default values shown in ‘Site Templates Needed’ can be edited by the user. Default values are a guide only and should be thoroughly reviewed by the user.</p>
															<div key="actions" className="modal__actions">
																<Button
																	onClick={() => store.modal.hide()}
																	display={Display.Solid}
																	colors={Colors.Secondary}
																>
																	Close
																</Button>
															</div>
														</>,
													);
												}}
											/>
										</div>
									</div>
								</span>
							</div>
						</th>
						<th className="elements-per-level-shutters" colSpan={elementStructure.levels.length}>Elements per Level</th>
					</tr>
					<tr>
						<th>Shutter</th>
						<th>Shutter Pairs Needed</th>
						<th>Shutter Pair Rate</th>
						<th>Total</th>
						<th>Required for 100% usage</th>
						<th>Site Templates Needed</th>
						<th>Site Template Rate</th>
						<th>Total</th>
						{elementStructure.levels.reverse().map(level => {
							return (
								<th key={level.id}>{level.name}</th>
							);
						})}
					</tr>
				</thead>
				<tbody>
					{elementStructure.shutters ? elementStructure.shutters.map(shutter => {
						const shutterType = ElementStructureUtils.shutterTypeString(shutter);
						const wrapShutterType = shutterType.split(' - ');
						const totals = ElementStructureUtils.getShutterPrice(shutter);
						return (
							<tr className="shutter-type" key={`${shutter.width} ${shutter.depth} ${shutter.aptusBarsAlongWidth} ${shutter.aptusBarsAlongDepth} ${shutter.couplerType}`}>
								<td className="shutter-name">{wrapShutterType[0]} {wrapShutterType[1]}</td>
								<td className="shutter-quantity">
									<NumberTextField
										acceptWholeNumbersOnly
										className={classNames(
											'quantity-input',
											'shutter-quantity-input',
											shutter.calculatedShutterQuantity ? 'calculated' : 'manual',
										)}
										model={shutter}
										modelProperty="quantity"
										onChangeAndBlur={() => {
											this.onFinishEditingShutter(shutterType, shutter);
											this.props.afterChange?.();
										}}
										isDisabled={this.props.readonly}
									/>
								</td>
								<td className="pair-rate">{shutter.price.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
								<td className={classNames('shutter-total-rate', elementStructure.info.editedSinceLastBuild ? 'edited' : null)}>{totals.shutterTotal.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
								<td className="required-full-usage">
									{this.maxShuttersByType[shutterType]?.siteTemplates}
								</td>
								<td className="site-template-quantity">
									<NumberTextField
										acceptWholeNumbersOnly
										className={classNames(
											'quantity-input',
											'site-template-quantity-input',
											shutter.calculatedSiteTemplateQuantity ? 'calculated' : 'manual',
										)}
										model={shutter}
										modelProperty="siteTemplateQuantity"
										onChangeAndBlur={() => {
											this.onFinishEditingSiteTemplate(shutterType, shutter);
											this.props.afterChange?.();
										}}
										isDisabled={this.props.readonly}
									/>
								</td>
								<td className="site-template-rate">{shutter.siteTemplatePrice ? shutter.siteTemplatePrice.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }) : null}</td>
								<td className={classNames('site-template-total-rate', elementStructure.info.editedSinceLastBuild ? 'edited' : null)}>{totals.siteTemplateTotal.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>

								{[...elementStructure.levels].reverse().map(level => {
									return (
										<td key={level.id}>{
											this.shuttersNeededPerLevel[level.id] && this.shuttersNeededPerLevel[level.id][shutterType]
												? this.shuttersNeededPerLevel[level.id][shutterType].shutters
												: null
										}
										</td>
									);
								})}
							</tr>
						);
					}) : null}
				</tbody>
			</table>
		);
	}

	@action
	public onFinishEditingShutter = (shutterType: string, shutter: PricingShutter) => {
		const maxShutters = this.maxShuttersByType[shutterType];

		// If the shutter amount is not set then fallback to the computed percentage
		if (shutter.quantity === null || shutter.quantity === undefined) {
			shutter.quantity = Math.ceil(maxShutters.shutters * (this.shutterUsagePct / 100));
			shutter.calculatedShutterQuantity = true;
		} else {
			shutter.calculatedShutterQuantity = false;
		}

		ElementStructureUtils.cleanInt(shutter, 'quantity');
	}

	@action
	public onFinishEditingSiteTemplate = (shutterType: string, shutter: PricingShutter) => {
		const maxShutters = this.maxShuttersByType[shutterType];

		// If the site template amount is not set then fallback to the computed percentage
		if (shutter.siteTemplateQuantity === null || shutter.siteTemplateQuantity === undefined) {
			shutter.siteTemplateQuantity = Math.ceil(maxShutters.siteTemplates * (this.siteTemplateUsagePct / 100));
			shutter.calculatedSiteTemplateQuantity = true;
		} else {
			shutter.calculatedSiteTemplateQuantity = false;
		}

		ElementStructureUtils.cleanInt(shutter, 'siteTemplateQuantity');
	}

	@action
	recomputeShutterUsages = () => {
		const { elementStructure } = this.props;

		ElementStructureUtils.cleanInt(this, 'shutterUsagePct', { min: 0, max: 100, valueIfNull: 0 });
		ElementStructureUtils.cleanInt(this, 'siteTemplateUsagePct', { min: 0, max: 100, valueIfNull: 0 });

		// Force a recompute of any computed quantities
		if (elementStructure.shutters) {
			for (const shutter of elementStructure.shutters) {
				// @ts-ignore Ignore type error since setting to undefined is how we force it to recompute
				if (shutter.calculatedShutterQuantity) shutter.quantity = undefined;
				if (shutter.calculatedSiteTemplateQuantity) shutter.siteTemplateQuantity = undefined;

				const shutterType = ElementStructureUtils.shutterTypeString(shutter);
				shutter.calculatedFullUsageQty = Math.ceil(this.maxShuttersByType[shutterType].siteTemplates);
				ElementStructureUtils.cleanInt(shutter, 'calculatedFullUsageQty');

				this.onFinishEditingShutter(shutterType, shutter);
				this.onFinishEditingSiteTemplate(shutterType, shutter);
			}
		}

		this.props.afterChange?.();
	}
}
