/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-await */
import {
	action, observable, runInAction, computed,
} from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import axios from 'axios';

import ProjectRevisionEntity from 'Models/Entities/ProjectRevisionEntity';

import { ElementStructure, ElementStructureUtils } from 'Util/ElementStructureUtils';
import { safeDivide } from 'Util/CodeUtils';
import alert from 'Util/ToastifyUtils';

import AdditionalParts from 'Views/Components/PricingComponents/AdditionalParts';
import { Button, Colors, Display } from 'Views/Components/Button/Button';

export interface ICostSummaryProps {
    elementStructure: ElementStructure;
    onChangeExtraParts?: () => void;
    readonly?: boolean;
    quoteRevision?: () => Promise<ProjectRevisionEntity | undefined>;
	priceVersionId?: string;
}

export interface BarPartSummary {
    [key: string]: {
        totalLength: number;
        totalCost: number;
        qty: number;
    };
}
export interface ItemPartSummary {
    [key: string]: {
        totalCost: number;
        qty: number;
    };
}

@observer
export default class CostSummary extends React.Component<ICostSummaryProps> {
	@computed
	private get aptusBarParts() {
		return ElementStructureUtils.getAptusBarParts(this.props.elementStructure);
	}

	@computed
	private get aptusItemParts() {
		return ElementStructureUtils.getAptusItemParts(this.props.elementStructure);
	}

	@computed
	private get aptusShutters() {
		return ElementStructureUtils.getShuttersTotal(this.props.elementStructure);
	}

	public render() {
		// Start with just the shutters in our totals, and add the other parts when we loop through them below
		let totalCost = this.aptusShutters.shutterTotal + this.aptusShutters.siteTemplateTotal;
		let totalQuantity = this.aptusShutters.shutterCount + this.aptusShutters.siteTemplateCount;

		// Add additional parts to total cost
		this.props.elementStructure.additionalParts && this.props.elementStructure.additionalParts.forEach(part => {
			totalCost += ElementStructureUtils.getPartCombinedPrice(part) * part.quantity;
			totalQuantity += part.quantity;
		});

		return (
			<div className="cost-summary-outer-wrap">
				<div className="additional-parts-header">
					<div className="header-left" />
					<div className="header-right" style={{ marginBottom: '10px' }}>
						<Button disabled={this.buttonState.disabled || this.props.readonly} onClick={this.requestConfirmedQuote} className="request-confirmed-quote-btn" display={Display.Solid} colors={Colors.Primary}>Request a confirmed quote</Button>
					</div>
				</div>
				<AdditionalParts
					elementStructure={this.props.elementStructure}
					onChangeExtraParts={this.props.onChangeExtraParts}
					priceVersionId={this.props.priceVersionId}
					readonly={this.props.readonly}
				/>

				<h4>Aptus Parts included in quote</h4>
				<p className="summary-text">The following parts are already included in your quote.</p>
				{ElementStructureUtils.barPartsList.map(barType => {
					const barParts = this.aptusBarParts[barType.name];
					let total = 0.0;

					return Object.keys(barParts).length > 0
						? (
							<div key={barType.name} className="cost-summary-inner-wrap">
								<table className="cost-summary">
									<thead>
										<tr>
											<th>{barType.description}</th>
											<th>Ave Length</th>
											<th>Qty</th>
											<th>Ave Rate</th>
											<th>Total (AUD)</th>
										</tr>
									</thead>
									<tbody>
										{Object.entries(barParts).map(([partName, summary]) => {
											// Build totals
											total += summary.totalCost;

											totalCost += summary.totalCost;
											totalQuantity += summary.qty;

											return (
												<tr key={partName}>
													<td className="bars-name">{partName}</td>
													<td className="bars-ave-length">{(summary.totalLength / summary.qty).toLocaleString('en-AU', { style: 'decimal', maximumFractionDigits: 2 })}</td>
													<td className="bars-qty">{summary.qty}</td>
													<td className="bars-ave-rate">{(summary.totalCost / summary.qty).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
													<td className="bars-total">{(summary.totalCost).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
												</tr>
											);
										})}
										<tr>
											<td colSpan={4} className="aggregated-total-description">Total:</td>
											<td className="aggregated-total">{total.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
										</tr>
									</tbody>
								</table>
							</div>
						)
						: null;
				})}
				{ElementStructureUtils.itemPartsList.map(itemType => {
					const itemParts = this.aptusItemParts[itemType.name];
					let total = 0.0;
					return Object.keys(itemParts).length > 0
						? (
							<div key={itemType.name} className="cost-summary-inner-wrap">
								<table className="cost-summary">
									<thead>
										<tr>
											<th>{itemType.description}</th>
											<th>Unit</th>
											<th>Qty</th>
											<th>Ave Rate</th>
											<th>Total (AUD)</th>
										</tr>
									</thead>
									<tbody>
										{Object.entries(itemParts).map(([partName, summary]) => {
											// Build totals
											total += summary.totalCost;

											totalCost += summary.totalCost;
											totalQuantity += summary.qty;

											return (
												<tr key={partName}>
													<td className="bars-name">{partName}</td>
													<td className="bars-ave-length">each</td>
													<td className="bars-qty">{summary.qty}</td>
													<td className="bars-ave-rate">{(summary.totalCost / summary.qty).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
													<td className="bars-total">{(summary.totalCost).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
												</tr>
											);
										})}
										<tr>
											<td colSpan={4} className="aggregated-total-description">Total:</td>
											<td className="aggregated-total">{total.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
										</tr>
									</tbody>
								</table>
							</div>
						)
						: null;
				})}

				<div className="cost-summary-inner-wrap">
					<table className="cost-summary">
						<thead>
							<tr>
								<th>Shutters & Site Templates (refer to Shutters)</th>
								<th>Unit</th>
								<th>Qty</th>
								<th>Ave Rate</th>
								<th>Total (AUD)</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="bars-name">Shutter Pairs (estimate)</td>
								<td className="bars-ave-length">each</td>
								<td className="bars-qty">{this.aptusShutters.shutterCount}</td>
								<td className="bars-ave-rate">{safeDivide(this.aptusShutters.shutterTotal, this.aptusShutters.shutterCount).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
								<td className="bars-total">{(this.aptusShutters.shutterTotal).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
							</tr>
						</tbody>
						<tbody>
							<tr>
								<td className="bars-name">Site Templates (estimate)</td>
								<td className="bars-ave-length">each</td>
								<td className="bars-qty">{this.aptusShutters.siteTemplateCount}</td>
								<td className="bars-ave-rate">{safeDivide(this.aptusShutters.siteTemplateTotal, this.aptusShutters.siteTemplateCount).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
								<td className="bars-total">{(this.aptusShutters.siteTemplateTotal).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
							</tr>
							<tr>
								<td colSpan={4} className="aggregated-total-description">Total:</td>
								<td className="aggregated-total">{(this.aptusShutters.shutterTotal + this.aptusShutters.siteTemplateTotal).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div className="cost-summary-inner-wrap">
					<table className="cost-summary">
						<thead>
							<tr>
								<th>Totals</th>
								<th>Unit</th>
								<th>Qty</th>
								<th>Ave Rate</th>
								<th>Total (AUD)</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="bars-name">All Parts</td>
								<td className="bars-ave-length">each</td>
								<td className="bars-qty">{totalQuantity}</td>
								<td className="bars-ave-rate">{(totalCost / totalQuantity).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
								<td className="bars-total">{(totalCost).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}

    @observable
	private buttonState = {
		disabled: false,
	};

	@action private disableQuoteButton = () => {
		this.buttonState = {
			disabled: true,
		};
	};

	@action private enableQuoteButton = () => {
		this.buttonState = {
			disabled: false,
		};
	};

	private requestConfirmedQuote = async () => {
		this.disableQuoteButton();
		const quoteRevision = await this.getQuoteDetails();
		if (quoteRevision) {
			axios.post('/api/entity/ProjectEntity/request-confirmed-quote', { Id: quoteRevision.projectId, RevisionName: quoteRevision.name })
				.then(() => {
					setTimeout(() => runInAction(() => {
						this.enableQuoteButton();
					}), 1000);
					alert('Confirmed quote has been requested.', 'success');
				})
				.catch(() => {
					alert('Sending request failed. Please try again.', 'error');
					this.enableQuoteButton();
				});
		} else {
			alert('Unable to get confirmed quote. Please try again.', 'error');
			this.enableQuoteButton();
		}
	};

	private async getQuoteDetails() {
		if (this.props.quoteRevision) {
			return await this.props.quoteRevision();
		}
		return undefined;
	}
}
