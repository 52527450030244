import * as React from 'react';
import { observer } from 'mobx-react';
import { ColumnType, ElementStructure, ElementStructureUtils } from 'Util/ElementStructureUtils';
import { Button, Display } from 'Views/Components/Button/Button';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import classNames from 'classnames';
import ProjectEntity from 'Models/Entities/ProjectEntity';
import { SelectionUtils } from 'Util/SelectionUtils';
import ColumnTypeEditView from 'Views/Components/ElementGrid/ColumnTypeEditView';

export interface EditElementsGridProps {
	project: ProjectEntity;
	projectCanBeEdited?: boolean;
}

@observer
export default class EditElementsGrid extends React.Component<EditElementsGridProps> {
	get elementStructure(): ElementStructure {
		return this.props.project.parsedElementStructure;
	}

	private afterChange = () => {
		if (this.props.projectCanBeEdited) {
			this.props.project.saveProject();
		}
	};

	private selectionUtils: SelectionUtils = new SelectionUtils(this.elementStructure, false, this.afterChange);

	public render() {
		return (
			<div className="edit-column-types-grid">
				<div className="edit-headers">
					<div className="header">Element type</div>
					<div className="header">Estimated number of this element type per level</div>
				</div>
				<div className="edit-rows">
					{this.elementStructure.columnTypes.map((columnType, index) => {
						return (
							<div className={classNames('edit-row', { selected: this.selectionUtils.selectedColumnTypesIncludesColumnType(columnType) })} key={columnType.id}>
								<div className="info-cell-wrap" onClick={(event: React.MouseEvent) => this.selectionUtils.clickColumnType(event, columnType)}>
									<div className="column-type-name-code info-cell">
										<span className="code">{columnType.code}</span>
										<span className="name">{columnType.name}</span>
									</div>
									<div className="estimated-count info-cell">{columnType.estimatedColumnCount}</div>
								</div>
								<div className="btn-wrap">
									<div className="add-btn-wrap">
										<Button onClick={() => this.selectionUtils.addColumnType(index + 1)} disabled={!this.props.projectCanBeEdited} className="add-btn" display={Display.Text} icon={{ icon: 'plus-circle', iconPos: 'icon-left' }}>Add element below</Button>
									</div>
									<div className="remove-btn-wrap">
										<Button onClick={() => this.removeColumnType(columnType)} disabled={!this.props.projectCanBeEdited} className="remove-btn" display={Display.Text} icon={{ icon: 'bin-delete', iconPos: 'icon-left' }}>Remove</Button>
									</div>
								</div>
							</div>
						);
					})}
				</div>
				<div className={classNames('edit-view', { visible: this.selectionUtils.currentlyEditing })}>
					<div className="edit-view-inner">
						<ColumnTypeEditView
							editColumnType={this.selectionUtils.editColumnType}
							editColumnTypeErrors={this.selectionUtils.editColumnTypeErrors}
							multipleSelected={this.selectionUtils.selectedColumnTypes.length > 1}
							saveChanges={this.selectionUtils.saveItemChanges}
							cancelSelection={this.selectionUtils.cancelSelection}
							readonly={!this.props.projectCanBeEdited}
						/>
					</div>
				</div>
			</div>
		);
	}

	private removeColumnType = (columnType: ColumnType) => {
		// If we're deleting a column type that has some associated cells, then we confirm with the user first
		// We assume that if this column type has any columns, and if there are any levels, then there must be associated cells
		if (columnType.columns.length > 0 && this.props.project.parsedElementStructure.levels.length > 0) {
			confirmModal('Please confirm', 'Removing this element type would also remove any elements within it. Are you sure you want to continue?').then(() => {
				ElementStructureUtils.deleteColumnTypeFromElementStructure(this.elementStructure, columnType);
				this.afterChange();
			});
		} else {
			ElementStructureUtils.deleteColumnTypeFromElementStructure(this.elementStructure, columnType);
			this.afterChange();
		}
	};
}
