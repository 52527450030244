/* eslint-disable no-nested-ternary */
import React, {
	useState, useMemo, MouseEvent, Dispatch, SetStateAction,
} from 'react';
import classNames from 'classnames';
import { Button, Display } from 'Views/Components/Button/Button';
import { Cell } from 'Util/ElementStructureUtils';
import Modal from 'Views/Components/Modal/Modal';

interface IErrorTileProps {
    cell: Cell;
    onClick: (event: MouseEvent) => void;
    onDismiss: () => any;
    className?: string,
}

const renderErrorModal = (
	visible: boolean,
	setVisible: Dispatch<SetStateAction<boolean>>,
	onDismiss: () => any,
	errors?: string[],
	warnings?: string[],
) => {
	return (
		<Modal
			isOpen={visible}
			onRequestClose={() => setVisible(false)}
			label="errors and warnings"
			className="errors-warnings-modal"
		>
			<h4>All errors &amp; warnings</h4>
			<div className="modal__content">
				{errors && errors.map(error => {
					return (
						<div className="error">
							<div className="content">
								<p className="error-header">Error</p>
								<p className="error-description">{error}</p>
							</div>
						</div>
					);
				})}
				{warnings && warnings.map(warning => {
					return (
						<div className="error warning">
							<div className="content">
								<p className="error-header">Warning</p>
								<p className="error-description">{warning}</p>
							</div>
						</div>
					);
				})}
			</div>
			<div className="modal__actions">
				<Button
					key="dismiss"
					onClick={() => {
						onDismiss();
						setVisible(false);
					}}
					display={Display.Text}
				>
					Dismiss All
				</Button>
			</div>
		</Modal>
	);
};

const ErrorTile = ({
	cell, onClick, onDismiss, className,
}: IErrorTileProps) => {
	const [visible, setVisible] = useState(false);

	const [isWarning, displayText, totalCount] = useMemo(() => {
		// display as warning if only warning present and no errors present (errors)
		const isWarning = (!cell.errors || (cell.errors && cell.errors.length === 0)) && (cell.warnings && cell.warnings.length);

		const text = (cell.errors && cell.errors.length && cell.errors[0])
            || (cell.warnings && cell.warnings.length && cell.warnings[0]);

		const totalCount = ((cell.errors && cell.errors.length) || 0) + ((cell.warnings && cell.warnings.length) || 0);

		return [isWarning, text, totalCount];
	}, [cell.errors, cell.warnings]);

	return (
		<>
			{renderErrorModal(visible, setVisible, onDismiss, cell.errors, cell.warnings)}
			<div className={classNames('error', { warning: isWarning }, className)} onClick={onClick} data-height={cell.levelHeight}>
				<div className="content">
					<p className="error-header">{isWarning ? 'Warning' : 'Error'}</p>
					<p className="error-description">{displayText}</p>
					{totalCount > 0
						? (
					// eslint-disable-next-line jsx-a11y/anchor-is-valid
							<a className="open-modal-link" onClick={() => { setVisible(true); }}>
								View {
									totalCount > 1
										? `${totalCount - 1} other${(totalCount - 1) > 1 ? 's' : ''}`
										: cell.errors && cell.errors.length
											? 'error'
											: 'warning'
								}
							</a>
						)
						: null}
					<Button
						onClick={onDismiss}
						className="dismiss-error-btn"
						aria-label="Dismiss Error"
						icon={{ icon: 'cross', iconPos: 'icon-left' }}
					/>
				</div>
			</div>
		</>
	);
};

export default ErrorTile;
